import React from 'react';
import { isEmpty } from 'ramda';

import ChartTable from '../../../shared/components/chartTable';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { useSelector } from 'react-redux';
import sessionSelectors from 'features/shared/services/session/selectors';
import Heading2 from 'features/report/shared/components/heading2';
import { i18n } from 'i18n';

const useStyles = createUseStyles(theme => ({
  root: {
    marginTop: '26px',
    breakInside: 'avoid-page'
  },
  grid: { display: 'flex' },
  gridColumn: {
    flex: '1 1 auto',
    '& + &': {
      marginLeft: '20px'
    }
  },
  section: {
    marginTop: 25
  },
  smallerFees: {
    display: 'flex',
    justifyContent: 'space-between',
    columnGap: 20,

    '& > *': {
      width: '50%'
    }
  },
  asterisk: {
    marginTop: 15,
    textAlign: 'right'
  }
}));

const YearsTable = ({ data, maxYears }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const reportConfig = useSelector(sessionSelectors.getReportConfig);

  const headers = [
    { title: '', enabled: true },
    {
      title: `${i18n('report.cashflow.year', translationsConfig)} 1`,
      enabled: true
    },
    {
      title: `${i18n('report.cashflow.year', translationsConfig)} ${maxYears}`,
      enabled: maxYears > 1
    }
  ]
    .filter(item => item.enabled)
    .map(item => item.title);

  const secondaryHeaders = [
    { title: '', enabled: true },
    { title: '%*', enabled: true },
    { title: i18n('report.shared.amount', translationsConfig), enabled: true },
    { title: '%*', enabled: maxYears > 1 },
    {
      title: i18n('report.shared.amount', translationsConfig),
      enabled: maxYears > 1
    }
  ]
    .filter(item => item.enabled)
    .map(item => item.title);

  const aggregatedCostDataKeys = [
    { key: 'field', enabled: true },
    { key: 'firstYear', enabled: true },
    { key: 'lastYear', enabled: maxYears > 1 }
  ]
    .filter(item => item.enabled)
    .map(item => item.key);

  const aggregatedOngoingFeesDataKeys = [
    { key: 'field', enabled: true },
    { key: 'firstYearPercent', enabled: true },
    { key: 'firstYearValue', enabled: true },
    { key: 'lastYearPercent', enabled: maxYears > 1 },
    { key: 'lastYearValue', enabled: maxYears > 1 }
  ]
    .filter(item => item.enabled)
    .map(item => item.key);

  return (
    <div className={classes.root}>
      {reportConfig.yearsTableItems?.costEffect &&
        !isEmpty(data.aggregatedCostEffect) && (
          <>
            <Heading2>
              {i18n(
                'costChart.costTable.aggregatedCostEffect',
                translationsConfig
              )}
            </Heading2>
            <div>
              <ChartTable
                headerTitles={headers}
                dataKeys={aggregatedCostDataKeys}
                data={data.aggregatedCostEffect}
              />
            </div>
          </>
        )}
      {reportConfig.yearsTableItems?.ongoingFees &&
        !isEmpty(data.aggregatedOngoingFees) && (
          <>
            <Heading2 className={classes.section}>
              {i18n(
                'costChart.costTable.aggregatedOngoingFees',
                translationsConfig
              )}
            </Heading2>
            <div>
              <ChartTable
                headerTitles={headers}
                secondHeaderTitles={secondaryHeaders}
                dataKeys={aggregatedOngoingFeesDataKeys}
                data={data.aggregatedOngoingFees}
                highlightLastRow
              />
            </div>
          </>
        )}
      <div className={classes.smallerFees}>
        {reportConfig.yearsTableItems?.oneTimeFee &&
          !isEmpty(data.aggregatedOneTimeFee) && (
            <div>
              <Heading2 className={classes.section}>
                {i18n(
                  'costChart.costTable.aggregatedOneTimeFee',
                  translationsConfig
                )}
              </Heading2>
              <ChartTable
                headerTitles={[
                  '',
                  '%',
                  i18n('report.shared.amount', translationsConfig)
                ]}
                dataKeys={['field', 'percent', 'value']}
                data={data.aggregatedOneTimeFee}
              />
            </div>
          )}
        {reportConfig.yearsTableItems?.returnCommissionPaidToAdvisor &&
          !isEmpty(data.aggregatedReturnCommissionPaidToAdvisor) && (
            <div>
              <Heading2 className={classes.section}>
                {i18n(
                  'costChart.costTable.aggregatedReturnCommissionPaidToAdvisor',
                  translationsConfig
                )}
              </Heading2>
              <ChartTable
                headerTitles={[
                  '',
                  '%',
                  i18n('report.shared.amount', translationsConfig)
                ]}
                dataKeys={['field', 'percent', 'value']}
                data={data.aggregatedReturnCommissionPaidToAdvisor}
              />
            </div>
          )}
      </div>
      <div className={classes.asterisk}>
        {i18n('costChart.default.asterisk', translationsConfig)}
      </div>
      <div style={{ pageBreakAfter: 'always' }} />
    </div>
  );
};

export default YearsTable;
