import * as R from 'ramda';
import axios, { CancelTokenSource } from 'axios';
import { useEffect, useRef } from 'react';
import { useStore, useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';

import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import { utcNow } from 'features/shared/utils/dateTime';
import { usePageStore } from 'features/counseling/montecarlo/services/pageStore.js';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { mapServerPortfolioItemsToClient } from 'features/counseling/backtest/services/mapping';
import { creators as notificationActionCreators } from 'features/notification/services/actions.js';
import {
  createAnalyzeForecast,
  getQAuthAccessToken,
  readPortfolio,
  readSelection
} from 'features/shared/api/index.js';
import {
  mapServerForecastToClient,
  getYearOffset
} from '../services/mapping.js';
import { NotificationTypes } from 'features/notification/constants/index.js';
import { DEFAULT_CONFIDENCE } from 'features/shared/constants/chart';
import { RoboFrontParams } from 'features/shared/utils/types';
import { TimeHorizonConfigType } from 'features/report/body/constants';

export function useInitPage() {
  const reduxStore = useStore();
  const dispatch = useDispatch();
  const pageStore = usePageStore();
  const horizon = useSelector(userOptionsSelectors.getHorizon);
  const horizonsConfig: TimeHorizonConfigType = useSelector(
    sessionSelectors.getHorizonsConfig
  );
  const horizonItems = horizonsConfig?.items;
  const { roboPortfolioPrecision } = useCustomerConfig();
  const { customerId } = useParams<RoboFrontParams>();
  const cancelTokenSourceRef = useRef<CancelTokenSource>();

  const getMontecarloData = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const useOptions = userOptionsSelectors.getUserOptions(
      reduxStore.getState()
    );

    try {
      pageStore.setIsReadExpectedReturnPending(true);

      const accessToken = await getQAuthAccessToken(
        customerId,
        cancelTokenSource.token
      );

      const portfolioResponses = await Promise.all([
        readPortfolio(accessToken, cancelTokenSource.token, {
          risk_tolerance: useOptions?.riskCategory,
          optionals: useOptions?.counseling?.theme,
          precision: roboPortfolioPrecision
        }),
        readSelection(accessToken, cancelTokenSource.token)
      ]);

      const portfolioItems = mapServerPortfolioItemsToClient(
        portfolioResponses[0].data.Portfolio,
        portfolioResponses[1].data.Selection
      );

      if (portfolioItems.length < portfolioResponses[0].data.Portfolio.length) {
        dispatch(
          notificationActionCreators.showNotification({
            message: 'counseling.backtest.portfolioInstrumentsAreMissing',
            type: NotificationTypes.error
          })
        );

        return;
      }

      const createAnalyzeForecastResponse = await createAnalyzeForecast(
        accessToken,
        cancelTokenSource.token,
        {
          confidence_interval: DEFAULT_CONFIDENCE,
          initial_deposit: useOptions?.counseling?.savingsPlan?.firstDeposit,
          monthly_saving: useOptions?.counseling?.savingsPlan?.monthlyDeposit,
          portfolio: portfolioItems,
          time_horizon: getYearOffset(horizonItems, horizon)
        }
      );

      const { expectedValue, averageTimeseries, deviationTimeseries } =
        mapServerForecastToClient(
          horizonItems,
          createAnalyzeForecastResponse.data.forecast,
          useOptions.horizon,
          utcNow()
        );

      pageStore.setExpectedReturn({
        expectedValue,
        averageTimeseries,
        deviationTimeseries
      });
      pageStore.setIsReadExpectedReturnPending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        pageStore.setExpectedReturn({
          expectedValue: null,
          averageTimeseries: null,
          deviationTimeseries: null
        });
        pageStore.setIsReadExpectedReturnPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: 'shared.getMontecarloDataErrorMessage',
            type: NotificationTypes.error
          })
        );
      }
    }
  };

  useEffect(() => {
    getMontecarloData();
  }, []);
}
