export const ClientTypes = {
  company: 'company',
  person: 'person'
} as const;

export const TimeHorizonTypes = {
  radio: 'radio',
  slider: 'slider'
} as const;

export type TimeHorizonConfigType = {
  type: keyof typeof TimeHorizonTypes;
  items: {
    id: number;
    riskHorizonValue: number;
    analysisProjectionYearsValue: number;
    yearIntervalStart: number;
    yearIntervalStop: number;
    title: string;
    yearsNumberTitle: string;
    icon: string;
  }[];
};
