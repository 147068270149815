import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  head: {
    fill: theme.primaryColor
  }
}));

const Asking = () => {
  const classes = useStyles();

  return (
    <svg
      width="110"
      height="80"
      viewBox="65 35 110 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="134" cy="93" r="9" fill="#132737" />
      <circle cx="110" cy="93" r="9" fill="#132737" />
      <path
        d="M129.206 67.1213C129.536 65.2845 131.142 63.952 133.006 64.0448C134.927 64.1404 137.343 64.3233 139.194 64.6713C140.839 64.9806 142.887 65.5801 144.55 66.1118C146.326 66.6798 147.362 68.4881 147.032 70.3233C146.657 72.4087 144.655 73.7893 142.655 73.0917C141.012 72.5188 139.567 71.9141 137.943 71.6218C136.25 71.3173 134.362 71.3522 132.337 71.2701C130.223 71.1844 128.831 69.2039 129.206 67.1213Z"
        fill="#132737"
      />
      <path
        d="M116.93 74.4306C116.883 72.562 115.492 71.0008 113.633 70.8121C111.664 70.6123 109.165 70.4206 107.259 70.4834C105.611 70.5377 103.574 70.8099 101.906 71.0775C100.068 71.3722 98.7768 73.0013 98.8236 74.862C98.877 76.9859 100.65 78.662 102.737 78.263C104.37 77.9508 105.81 77.5843 107.437 77.5434C109.173 77.4997 111.123 77.8272 113.211 78.0537C115.31 78.2813 116.983 76.5412 116.93 74.4306Z"
        fill="#132737"
      />
      <mask
        id="mask5"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="34"
        y="41"
        width="173"
        height="162"
      >
        <path
          d="M190.793 122.449H172.125V106.197V51.8906C172.125 49.2053 171.058 46.63 169.16 44.7312C167.261 42.8324 164.685 41.7656 162 41.7656H81C78.3147 41.7656 75.7394 42.8324 73.8405 44.7312C71.9417 46.63 70.875 49.2053 70.875 51.8906L70.875 106.629H58.8516C42.1048 106.629 34.1719 116.144 34.1719 132.891V159.785H50.625V132.891C50.625 127.312 55.1711 122.766 60.75 122.766H70.875V186.363H60.75V202.5H95.5547V186.363H85.4297V156.938H157.57V186.363H147.445V202.5H182.25V186.363H172.125V126.82V122.449V106.197H190.793C208.828 106.197 206.93 113.791 206.93 132.891V161.252H190.793V132.891V122.449ZM85.4297 57.2696H157.57V122.449H85.4297V57.2696Z"
          fill="#900032"
        />
      </mask>
      <g mask="url(#mask5)">
        <circle cx="122" cy="-14" r="130" className={classes.head} />
      </g>
    </svg>
  );
};

export default Asking;
