import { useSelector } from 'react-redux';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { FontWeights } from '../../shared/constants/fonts';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Section from '../../shared/components/section.js';
import Heading1 from '../../shared/components/heading1';
import { i18n } from 'i18n/index.js';
import {
  Table,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell
} from '../../shared/components/table';
import { formatNumber } from 'features/shared/utils/number.js';
import GoalTableIcon from 'features/report/shared/components/goalTableIcon';

const useStyles = createUseStyles(theme => ({
  text: {
    marginBottom: '20px'
  },
  valueCell: {
    fontWeight: FontWeights.semiBold
  },
  goalIcon: {
    fill: theme.accentColor,
    width: 30
  },
  numberCell: {
    textAlign: 'right'
  }
}));

const CashflowTable = ({ cashflowGoals }) => {
  const classes = useStyles();
  const cultureCode = useSelector(sessionSelectors.getCultureCode);

  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  return (
    <Section data-testid={'cashflow-table-section'}>
      <Heading1>{i18n('report.cashflow.header', translationsConfig)}</Heading1>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell></TableHeaderCell>
            <TableHeaderCell>
              {i18n('report.purpose.goal', translationsConfig)}
            </TableHeaderCell>
            <TableHeaderCell className={classes.numberCell}>
              {i18n('report.cashflow.withdrawal', translationsConfig)}
            </TableHeaderCell>
            <TableHeaderCell className={classes.numberCell}>
              {i18n('cashflow.capitalNeed', translationsConfig)}
            </TableHeaderCell>
            <TableHeaderCell>
              {i18n('report.cashflow.frequency', translationsConfig)}
            </TableHeaderCell>
            <TableHeaderCell className={classes.numberCell}>
              {i18n('report.purpose.years', translationsConfig)}
            </TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {cashflowGoals.map(
            ({
              capitalNeed,
              frequency,
              goalId,
              icon,
              name,
              withdrawal,
              year
            }) => (
              <TableBodyRow key={goalId}>
                <TableBodyCell className={classes.goalIcon}>
                  <GoalTableIcon name={name} icon={icon} />
                </TableBodyCell>
                <TableBodyCell>{name}</TableBodyCell>
                <TableBodyCell className={classes.numberCell}>
                  {withdrawal}
                </TableBodyCell>
                <TableBodyCell className={classes.numberCell}>
                  {typeof capitalNeed === 'number'
                    ? formatNumber(cultureCode, capitalNeed, 0, 2)
                    : capitalNeed}
                </TableBodyCell>
                <TableBodyCell>{frequency}</TableBodyCell>
                <TableBodyCell className={classes.numberCell}>
                  {year}
                </TableBodyCell>
              </TableBodyRow>
            )
          )}
        </TableBody>
      </Table>
    </Section>
  );
};

export default CashflowTable;
