import * as React from 'react';
import { useSelector } from 'react-redux';
import {
  SettingsProvider as QfUiSettingsProvider,
  ThemeProvider as QfUiThemeProvider
} from '@quantfoliorepo/ui-components';

import sessionSelectors from 'features/shared/services/session/selectors.js';
import ThemeProvider from 'features/shared/components/themeProvider/index.js';
import { CustomerConfigContext } from './context';

export const BaseCustomerConfigProvider = ({ children, customerConfig }) => {
  return (
    <CustomerConfigContext.Provider value={customerConfig}>
      <ThemeProvider theme={customerConfig.styles}>
        <QfUiSettingsProvider value={customerConfig}>
          <QfUiThemeProvider theme={customerConfig.styles}>
            {children}
          </QfUiThemeProvider>
        </QfUiSettingsProvider>
      </ThemeProvider>
    </CustomerConfigContext.Provider>
  );
};

export const CustomerConfigProvider = ({ children }) => {
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const stylesConfig = useSelector(sessionSelectors.getStylesConfig);
  const roboPortfolioPrecisionConfig = useSelector(
    sessionSelectors.getRoboPortfolioPrecisionConfig
  );
  const expectedValueConfig = useSelector(
    sessionSelectors.getExpectedValueConfig
  );

  const customerConfig = React.useMemo(
    () => ({
      translations: translationsConfig,
      styles: stylesConfig,
      roboPortfolioPrecision: roboPortfolioPrecisionConfig,
      expectedValue: expectedValueConfig
    }),
    [
      translationsConfig,
      stylesConfig,
      roboPortfolioPrecisionConfig,
      expectedValueConfig
    ]
  );

  return (
    <BaseCustomerConfigProvider customerConfig={customerConfig}>
      {children}
    </BaseCustomerConfigProvider>
  );
};
