import * as R from 'ramda';

export const mapServerPortfolioItemsToClient = (portfolioItems, selection) => {
  const categoriesSelection = R.reduce(
    (acc, c) => {
      if (R.isNil(c.Instruments) || R.isEmpty(c.Instruments)) {
        return acc;
      }

      const instrument = c.Instruments[0];

      return R.assoc(c.CategoryId, instrument.Ticker, acc);
    },
    {},
    selection
  );

  return R.pipe(
    R.map(i => ({
      id: categoriesSelection[i.AssetClass.CategoryId],
      weight: Number(i.Weight)
    })),
    R.reject(i => R.isNil(i.id))
  )(portfolioItems);
};

export const mapServerHistoricalReturnToClient = portfolio => {
  const firstPoint = R.isEmpty(portfolio) ? null : portfolio[0];
  const lastPoint = R.isEmpty(portfolio)
    ? null
    : portfolio[R.length(portfolio) - 1];

  return {
    portfolioTimeseries: R.map(
      i => [new Date(i.date).getTime(), i.value],
      portfolio
    ),
    portfolioDetails:
      !R.isNil(firstPoint) && !R.isNil(lastPoint) && R.length(portfolio) > 1
        ? {
            startDate: new Date(firstPoint.date),
            increase: lastPoint.value / firstPoint.value - 1
          }
        : null
  };
};
