import {
  dataSourcesMapping,
  PORTFOLIO_MODES
} from '@quantfoliorepo/ui-components';
import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useRef } from 'react';
import { useParams } from 'react-router';
import { useDispatch, useStore } from 'react-redux';

import {
  createRiskReturn,
  getQAuthAccessToken,
  readPortfolio,
  readSelection
} from 'features/shared/api/index.js';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import { usePageStore } from '../services/pageStore';
import { creators as notificationActionCreators } from 'features/notification/services/actions.js';
import { NotificationTypes } from 'features/notification/constants/index.js';
import { PageStatuses } from 'features/shared/constants/statuses';
import { RoboFrontParams } from 'features/shared/utils/types';

export const useReadRiskReturn = () => {
  const { roboPortfolioPrecision } = useCustomerConfig();
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const { customerId } = useParams<RoboFrontParams>();
  const reduxStore = useStore();
  const pageStore = usePageStore();
  const dispatch = useDispatch();

  const readRiskReturn = async () => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const useOptions = userOptionsSelectors.getUserOptions(
      reduxStore.getState()
    );

    try {
      pageStore.setReadRiskReturnStatus(PageStatuses.pending);
      const accessToken = await getQAuthAccessToken(
        customerId,
        cancelTokenSource.token
      );

      const [getRoboPortfolioResponse, getRoboSelectionResponse] =
        await Promise.all([
          readPortfolio(accessToken, cancelTokenSource.token, {
            risk_tolerance: useOptions?.riskCategory,
            optionals: useOptions?.counseling?.theme,
            precision: roboPortfolioPrecision
          }),
          readSelection(accessToken, cancelTokenSource.token)
        ]);

      const roboPortfolio = getRoboPortfolioResponse?.data;
      const roboSelection = getRoboSelectionResponse?.data;

      const portfolio = dataSourcesMapping.getPortfolio(
        PORTFOLIO_MODES.standard,
        {
          dataSources: {
            roboPortfolio,
            roboSelection
          }
        }
      );

      const createRiskReturnResponse = await createRiskReturn(
        accessToken,
        cancelTokenSource.token,
        {
          portfolio
        }
      );

      const returnValue = createRiskReturnResponse?.data?.return;

      pageStore.setReturnValue(returnValue);
      pageStore.setReadRiskReturnStatus(PageStatuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        pageStore.setReturnValue(null);
        pageStore.setReadRiskReturnStatus(PageStatuses.failed);

        dispatch(
          notificationActionCreators.showNotification({
            message: 'shared.getForecastErrorMessage',
            type: NotificationTypes.error
          })
        );
      }
    }
  };

  return readRiskReturn;
};
