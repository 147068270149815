import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { useSelector } from 'react-redux';

import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { FontWeights } from '../../shared/constants/fonts.js';
import Heading1 from '../../shared/components/heading1';
import Section from '../../shared/components/section.js';
import RiskQuestions from 'features/report/body/components/riskQuestions';

const useStyles = createUseStyles(theme => ({
  root: {
    marginTop: '26px'
  },
  valueCell: {
    fontWeight: FontWeights.semiBold
  },
  answer: {
    '& + &': {
      marginTop: '10px'
    }
  }
}));

const KnowledgeAndExperienceSection = ({ knowledgeAndExperience }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  return (
    <Section data-testid={'knowledge-and-experience-section'}>
      <Heading1>
        {i18n('report.knowledgeAndExperience.header', translationsConfig)}
      </Heading1>
      <div>
        {i18n('report.knowledgeAndExperience.text', translationsConfig)}
      </div>
      <div className={classes.root}>
        <RiskQuestions riskQuestions={knowledgeAndExperience} />
      </div>
    </Section>
  );
};

export default KnowledgeAndExperienceSection;
