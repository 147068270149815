import t from 'tcomb';
import * as R from 'ramda';

import { createReducer } from 'framework/tcomb/utils.js';
import { types } from 'features/shared/services/userOptions/actions.js';
import { types as sessionActionTypes } from 'features/shared/services/session/actions.js';
import {
  startedType,
  succeedType,
  failedType
} from 'features/shared/utils/actions.js';

const FinancialSituation = t.interface(
  {
    left: t.maybe(t.Number),
    debt: t.dict(t.String, t.maybe(t.Number)),
    assets: t.dict(t.String, t.maybe(t.Number)),
    buffer: t.maybe(t.Boolean),
    creditCard: t.maybe(t.Number)
  },
  'FinancialSituation'
);

const ListItem = t.interface(
  {
    id: t.String,
    title: t.String,
    icon: t.String
  },
  'ListItem'
);

const Knowledge = t.interface(
  {
    fundQuiz: t.String,
    affectFactors: t.String
  },
  'Knowledge'
);

const SavingsPlan = t.interface(
  {
    monthlyDeposit: t.maybe(t.Number),
    firstDeposit: t.maybe(t.Number)
  },
  'SavingsPlan'
);

const Counseling = t.interface(
  {
    theme: t.list(t.String),
    savingsPlan: SavingsPlan
  },
  'Counseling'
);

const UserOptionsState = t.interface(
  {
    goal: ListItem,
    horizon: t.maybe(t.Number),
    horizonYear: t.maybe(t.Number),
    risk: t.maybe(t.Number),
    return: t.maybe(t.Number),
    financialSituation: FinancialSituation,
    react: t.maybe(t.Number),
    loss: t.maybe(t.Number),
    knowledges: Knowledge,
    investmentKnowledges: t.list(t.Number),
    trading: t.maybe(t.Number),
    counseling: Counseling,
    riskCategory: t.maybe(t.String),
    initialRiskCategory: t.maybe(t.String),
    montecarloData: t.maybe(t.Object),
    feesData: t.maybe(t.Object)
  },
  'UserOptionsState'
);

const defaultState = {
  goal: { id: '', title: '', icon: '' },
  horizon: null,
  risk: null,
  return: null,
  react: null,
  loss: null,
  investmentKnowledges: [],
  trading: null,
  financialSituation: {
    left: 0,
    debt: {},
    assets: {},
    buffer: null,
    creditCard: 0
  },
  knowledges: {
    fundQuiz: '',
    affectFactors: ''
  },
  counseling: {
    theme: [],
    savingsPlan: {
      monthlyDeposit: null,
      firstDeposit: 0
    }
  },
  riskCategory: null,
  initialRiskCategory: null,
  montecarloData: null,
  feesData: null
};

const Reducer = createReducer(UserOptionsState);

const reducer = Reducer.of((state = defaultState, action) => {
  switch (action.type) {
    case succeedType(sessionActionTypes.INITIALIZE): {
      const config = action.payload;

      return R.pipe(
        s => {
          const assetsConfig = R.path(['financialSituation', 'assets'], config);

          if (R.isNil(assetsConfig)) {
            return s;
          }

          return R.over(
            R.lensPath(['financialSituation', 'assets']),
            assets =>
              R.reduce(
                (acc, a) => R.assoc(a.id, a.default, acc),
                assets,
                assetsConfig
              ),
            s
          );
        },
        s => {
          const debtConfig = R.path(['financialSituation', 'debt'], config);

          if (R.isNil(debtConfig)) {
            return s;
          }

          return R.over(
            R.lensPath(['financialSituation', 'debt']),
            debt =>
              R.reduce(
                (acc, d) => R.assoc(d.id, d.default, acc),
                debt,
                debtConfig
              ),
            s
          );
        }
      )(state);
    }
    case types.USER_OPTION_CHANGED: {
      const { type, value } = action.payload;

      return {
        ...state,
        [type]: value
      };
    }
    case types.SAVING_GOAL_OPTION_CHANGED: {
      const { value } = action.payload;

      return R.pipe(
        state => ({
          ...state,
          goal: value
        }),
        state => {
          const { id } = value;

          if (id === 'shortTerm') {
            return {
              ...state,
              horizon: 1,
              risk: 1,
              return: 1
            };
          }

          if (id === 'longTerm') {
            return {
              ...state,
              horizon: 4,
              risk: null,
              return: null
            };
          }

          return {
            ...state,
            horizon: null,
            risk: null,
            return: null
          };
        }
      )(state);
    }
    case types.USER_LIST_OPTION_CHANGED: {
      const { type, optionId, isSelected } = action.payload;

      const isOptionSelected = R.includes(optionId, state[type]);

      if (isSelected === isOptionSelected) {
        return state;
      }

      if (isSelected) {
        return R.over(R.lensProp(type), R.append(optionId), state);
      } else {
        return R.over(R.lensProp(type), R.reject(R.equals(optionId)), state);
      }
    }
    case types.USER_NO_SPECIFIC_GOAL_CHANGED: {
      const value = action.payload;

      return R.set(R.lensPath(['goal', 'title']), value, state);
    }
    case types.USER_KNOWLEDGES_CHANGED: {
      const { type, value } = action.payload;

      return R.set(R.lensPath(['knowledges', type]), value, state);
    }
    case types.USER_INVESTMENT_KNOWLEDGES_CHANGED: {
      const { value } = action.payload;

      if (R.indexOf(value, state.investmentKnowledges) === -1) {
        if (value === 1) {
          return R.set(R.lensPath(['investmentKnowledges']), [value], state);
        } else {
          if (R.indexOf(1, state.investmentKnowledges) === -1) {
            return R.over(
              R.lensPath(['investmentKnowledges']),
              R.append(value),
              state
            );
          } else {
            return R.set(R.lensPath(['investmentKnowledges']), [value], state);
          }
        }
      } else {
        return R.over(
          R.lensPath(['investmentKnowledges']),
          R.reject(item => item === value),
          state
        );
      }
    }
    case types.USER_FINANCIAL_SITUATION_LEFT_CHANGED: {
      const { value } = action.payload;

      return R.set(R.lensPath(['financialSituation', 'left']), value, state);
    }
    case types.USER_FINANCIAL_SITUATION_DEBT_CHANGED: {
      const { type, value } = action.payload;

      return R.set(
        R.lensPath(['financialSituation', 'debt', type]),
        value,
        state
      );
    }
    case types.USER_FINANCIAL_SITUATION_ASSETS_CHANGED: {
      const { type, value } = action.payload;

      return R.set(
        R.lensPath(['financialSituation', 'assets', type]),
        value,
        state
      );
    }
    case types.USER_FINANCIAL_SITUATION_BUFFER_CHANGED: {
      const value = action.payload;

      return R.set(R.lensPath(['financialSituation', 'buffer']), value, state);
    }
    case types.USER_FINANCIAL_SITUATION_CREDITCARD_CHANGED: {
      const { value } = action.payload;

      return R.set(
        R.lensPath(['financialSituation', 'creditCard']),
        value,
        state
      );
    }
    case types.RESET_USER_QUIZ_ANSWERS: {
      return {
        ...state,
        knowledges: {
          fundQuiz: '',
          affectFactors: ''
        }
      };
    }
    case types.USER_COUNSELING_CHANGED: {
      const { type, value } = action.payload;

      return R.set(R.lensPath(['counseling', type]), value, state);
    }
    case types.USER_COUNSELING_THEMES_CHANGED: {
      const { value } = action.payload;

      if (R.indexOf(value, state.counseling.theme) === -1) {
        if (R.length(state.counseling.theme) < 3) {
          return R.over(
            R.lensPath(['counseling', 'theme']),
            R.append(value),
            state
          );
        } else {
          return state;
        }
      } else {
        return R.over(
          R.lensPath(['counseling', 'theme']),
          R.reject(item => item === value),
          state
        );
      }
    }
    case types.USER_COUNSELING_SAVINGS_PLAN_CHANGED: {
      const { type, value } = action.payload;

      return R.set(
        R.lensPath(['counseling', 'savingsPlan', type]),
        value,
        state
      );
    }
    case succeedType(types.GET_USER_RISK): {
      const { riskScore, isInitialRiskScore } = action.payload;

      return {
        ...state,
        riskCategory: riskScore,
        initialRiskCategory: isInitialRiskScore
          ? riskScore
          : state.initialRiskCategory
      };
    }
    case failedType(types.GET_USER_RISK): {
      const { isInitialRiskScore } = action.payload;

      return {
        ...state,
        riskCategory: null,
        initialRiskCategory: isInitialRiskScore
          ? null
          : state.initialRiskCategory
      };
    }
    case succeedType(types.GET_USER_FEES): {
      const data = action.payload;

      return {
        ...state,
        feesData: data
      };
    }
    case failedType(types.GET_USER_FEES): {
      return {
        ...state,
        feesData: null
      };
    }
    case types.SET_MONTHLY_DEPOSIT: {
      const data = action.payload;

      return R.set(
        R.lensPath(['counseling', 'savingsPlan', 'monthlyDeposit']),
        data,
        state
      );
    }
    case types.RESET_USER_OPTIONS: {
      return defaultState;
    }
    default:
      return state;
  }
});

export default reducer;
