import { isNil } from 'ramda';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { creators as userOptionsCreator } from 'features/shared/services/userOptions/actions.js';
import userOptionsSelectors, {
  getSummary
} from 'features/shared/services/userOptions/selectors.js';
import { useReadExpectedValue } from './useReadExpectedValue';
import { useReadRiskReturn } from './useReadRiskReturn';

export const useInitPage = () => {
  const dispatch = useDispatch();
  const userCounselingOptions: any = useSelector(
    userOptionsSelectors.getCounselingOptions
  );
  const summary = useSelector(getSummary);
  const setMonthlyDeposit = value => {
    dispatch(userOptionsCreator.setMonthlyDeposit(value));
  };
  const readRiskReturn = useReadRiskReturn();
  const readExpectedValue = useReadExpectedValue();

  useEffect(() => {
    if (isNil(userCounselingOptions.savingsPlan.monthlyDeposit)) {
      setMonthlyDeposit(summary.countToSave);
    }

    const initPage = async () => {
      await readRiskReturn();

      await readExpectedValue();
    };

    initPage();
  }, []);
};
