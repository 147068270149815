import { Fragment, useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty } from 'ramda';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { Colors } from 'features/report/shared/constants/colors';
import { i18n } from 'i18n';
import sessionSelectors from 'features/shared/services/session/selectors';
import PreferenceCriteria from './preferenceCriteria';
import AlignmentTableStandard from './alignmentTableStandard';
import AlignmentTableWeighted from './alignmentTableWeighted';
import {
  FundAnalysis,
  Goal,
  SustainabilityDataReport,
  sustainabilityTableType
} from 'features/report/shared/services/reportStore';
import { FontWeights, TextFontSizes } from 'features/shared/constants/fonts';
import Section from 'features/report/shared/components/section';

const answers = {
  0: 'report.sustainability.undecided',
  1: 'report.sustainability.notImportant',
  2: 'report.sustainability.slightlyImportant',
  3: 'report.sustainability.moderatelyImportant',
  4: 'report.sustainability.important',
  5: 'report.sustainability.veryImportant'
};

const useStyles = createUseStyles({
  pageHeader: {
    fontSize: 18,
    fontWeight: FontWeights.bold,
    lineHeight: '22px',
    marginBottom: 32
  },
  sustainabilityAssessmentHeader: {
    fontSize: 18,
    fontWeight: FontWeights.medium,
    lineHeight: '22px',
    marginTop: 32
  },
  body: {
    fontWeight: FontWeights.semiBold,
    fontSize: 14,
    lineHeight: '21px',
    marginTop: 16
  },
  answer: {
    fontSize: 14,
    lineHeight: '21px',
    marginTop: 8
  },
  advisorNoteContainer: {
    backgroundColor: Colors.gray600,
    marginTop: 16
  },
  advisorNoteHeader: {
    fontWeight: FontWeights.semiBold,
    fontSize: 14,
    lineHeight: '21px'
  },
  advisorNote: {
    fontSize: 12,
    lineHeight: '21px',
    marginTop: 8,
    whiteSpace: 'pre-line'
  },
  preferenceCriteriaHeader: {
    fontSize: TextFontSizes.mediumPx,
    lineHeight: '25px',
    margin: '32px 0 16px'
  }
});

type Props = {
  goals: Goal[];
  sustainabilityData: SustainabilityDataReport;
};

const Sustainability = ({ goals, sustainabilityData }: Props) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const cultureCode = useSelector(sessionSelectors.getCultureCode);

  const fundAnalysisData = useMemo(
    () =>
      (sustainabilityData.data || [])
        .map(({ data }) => data.fundAnalysis)
        .flat()
        .reduce<FundAnalysis[]>((acc, curr) => {
          const index = acc.findIndex(
            ({ isin, ticker }) =>
              (isin && isin === curr.isin) || (ticker && ticker === curr.ticker)
          );
          if (index !== -1) {
            return acc;
          }
          return [...acc, curr];
        }, []),
    [sustainabilityData.data]
  );

  const totalValue = useMemo(
    () =>
      goals.reduce(
        (acc, curr) =>
          acc + (curr.data.firstDeposit || curr.data.monthlyDeposit || 0),
        0
      ),
    [goals]
  );

  return (
    <>
      <Section>
        <div className={classes.pageHeader}>
          {i18n('report.sustainability.header', translationsConfig)}
        </div>

        {sustainabilityData?.genericAssessment?.enabled && (
          <>
            <div className={classes.sustainabilityAssessmentHeader}>
              {sustainabilityData?.genericAssessment?.header}
            </div>

            <div className={classes.body}>
              {sustainabilityData?.genericAssessment?.description}
            </div>

            <div className={classes.answer}>
              {sustainabilityData?.genericAssessment?.answerText}
            </div>

            {sustainabilityData?.genericAssessment?.comment && (
              <div className={classes.advisorNoteContainer}>
                <div className={classes.advisorNoteHeader}>
                  {i18n(
                    'report.sustainability.advisorNotes',
                    translationsConfig
                  )}
                </div>

                <div className={classes.advisorNote}>
                  {sustainabilityData?.genericAssessment?.comment}
                </div>
              </div>
            )}
          </>
        )}

        {sustainabilityData.sustainabilityAssessment.map(
          ({ header, body, answer, advisorNote }) => (
            <Fragment key={header}>
              <div className={classes.sustainabilityAssessmentHeader}>
                {header}
              </div>

              <div className={classes.body}>{body}</div>

              <div className={classes.answer}>
                {`${i18n(
                  'report.sustainability.yourPreference',
                  translationsConfig
                )}: ${answer} - ${i18n(answers[answer], translationsConfig)}`}
              </div>

              {advisorNote && (
                <div className={classes.advisorNoteContainer}>
                  <div className={classes.advisorNoteHeader}>
                    {i18n(
                      'report.sustainability.advisorNotes',
                      translationsConfig
                    )}
                  </div>

                  <div className={classes.advisorNote}>{advisorNote}</div>
                </div>
              )}
            </Fragment>
          )
        )}
      </Section>

      {!isEmpty(sustainabilityData?.data) &&
        (!sustainabilityData?.genericAssessment?.enabled ||
          sustainabilityData?.genericAssessment?.answer) && (
          <Section>
            <div className={classes.pageHeader}>
              {i18n('report.sustainability.header', translationsConfig)}
            </div>

            {sustainabilityData.tableType ===
              sustainabilityTableType.weighted && (
              <AlignmentTableWeighted
                cultureCode={cultureCode}
                fundAnalysisData={fundAnalysisData}
                goals={goals}
                sustainabilityData={sustainabilityData.data}
                totalValue={totalValue}
              />
            )}

            {sustainabilityData.tableType ===
              sustainabilityTableType.standard && (
              <AlignmentTableStandard
                cultureCode={cultureCode}
                fundAnalysisData={fundAnalysisData}
                goals={goals}
                sustainabilityData={sustainabilityData}
                totalValue={totalValue}
              />
            )}

            {!!sustainabilityData.preferenceCriteria?.length && (
              <>
                <div className={classes.preferenceCriteriaHeader}>
                  {i18n(
                    'report.sustainability.preferenceCriteria',
                    translationsConfig
                  )}
                </div>

                <PreferenceCriteria
                  fundAnalysisData={fundAnalysisData}
                  preferenceCriteria={sustainabilityData.preferenceCriteria}
                  sustainabilityPreference={
                    sustainabilityData.sustainabilityPreference
                  }
                />
                {sustainabilityData.preferenceCriteriaAdvisorNotes && (
                  <div className={classes.advisorNoteContainer}>
                    <div className={classes.advisorNoteHeader}>
                      {i18n(
                        'report.sustainability.advisorNotes',
                        translationsConfig
                      )}
                    </div>

                    <div className={classes.advisorNote}>
                      {sustainabilityData.preferenceCriteriaAdvisorNotes}
                    </div>
                  </div>
                )}
              </>
            )}
          </Section>
        )}
    </>
  );
};

export default Sustainability;
