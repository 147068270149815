import React from 'react';
import { useSelector } from 'react-redux';
import { ExpectedValueChart } from '@quantfoliorepo/ui-components';
import { isEmpty, isNil, min } from 'ramda';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { useReportStore } from 'features/report/shared/services/reportStore';
import Section from '../../shared/components/section.js';
import Heading1 from '../../shared/components/heading1';
import Card from '../../shared/components/card.js';
import ChartTable from '../../shared/components/chartTable.js';
import { formatNumber } from 'features/shared/utils/number.js';

const useStyles = createUseStyles({
  card: {
    margin: '26px 0'
  }
});

const ExpectedValueSection = ({ chartWidth, header, text }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const reportStore = useReportStore();

  const expectedValueChartData = reportStore.userData?.expectedValueChartData;
  const expectedValueConfig = reportStore.userData?.expectedValueConfig;

  const formattedTableData = React.useMemo(() => {
    return expectedValueChartData?.expectedValueTable &&
      expectedValueChartData?.expectedValueTable.length
      ? expectedValueChartData.expectedValueTable.map(
          ({ year, accDeposit, accReturn, value }) => ({
            year,
            accDeposit: formatNumber(cultureCode, accDeposit, 0, 0),
            accReturn: formatNumber(cultureCode, accReturn, 0, 0),
            value: formatNumber(cultureCode, value, 0, 0)
          })
        )
      : [];
  }, [expectedValueChartData?.expectedValueTable]);

  const isChartDataEmpty =
    isNil(expectedValueChartData) ||
    isEmpty(expectedValueChartData.savingPlan) ||
    isEmpty(expectedValueChartData.savingInBankAccount);

  const minYValue = isChartDataEmpty
    ? 0
    : Math.floor(
        expectedValueChartData.savingPlan
          .concat(expectedValueChartData.savingInBankAccount)
          .reduce(
            (acc, val) => min(acc, val.y),
            expectedValueChartData.savingPlan[0].y
          )
      );

  return (
    <Section>
      <Heading1>{i18n(header, translationsConfig)}</Heading1>
      <div>
        {i18n(text, translationsConfig)}
        <Card className={classes.card}>
          <ExpectedValueChart
            cultureCode={cultureCode}
            chartData={{
              savingPlan: expectedValueChartData?.savingPlan,
              savingInBankAccount: expectedValueChartData?.savingInBankAccount
            }}
            isInteractive={false}
            onLoad={() => window.loadedCharts.push('expectedValue')}
            width={chartWidth}
            yMinValue={minYValue}
            customColors={
              reportStore.userData?.chartColorsConfig.expectedValue as any
            }
            bankReturn={expectedValueConfig?.bankReturn}
            savingPlanOnly={!expectedValueConfig?.compareWithBank}
          />
        </Card>
      </div>
      {!!formattedTableData.length && (
        <ChartTable
          headerTitles={[
            i18n('roboAdvice.proposal.year', translationsConfig),
            i18n('roboAdvice.proposal.accumulatedDeposits', translationsConfig),
            i18n('roboAdvice.proposal.accumulatedReturns', translationsConfig),
            i18n('roboAdvice.proposal.totalValue', translationsConfig)
          ]}
          dataKeys={['year', 'accDeposit', 'accReturn', 'value']}
          data={formattedTableData}
          customClasses={undefined}
          asterisk={undefined}
        />
      )}
    </Section>
  );
};

export default ExpectedValueSection;
