import React from 'react';
import { injectSheet } from 'features/sharedModules/styles/components/styles.js';
import classNames from 'classnames';
import * as R from 'ramda';
import { connect } from 'react-redux';

import { Colors } from 'features/shared/constants/colors.js';
import Icon from 'features/shared/components/icon/index';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';

const styles = theme => {
  return {
    layoutColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    container: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      height: '72px',
      background: Colors.white,
      boxShadow:
        '0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 4px 3px ' +
        Colors.listShadowColor
    },
    button: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      height: '100%',
      width: '50%',
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.mediumPx,
      lineHeight: TextFontSizes.mediumLineHeightPx,

      '&$selected': {
        backgroundColor: 'rgba(0, 0, 0, 0.2)'
      }
    },
    selected: {},
    divider: {
      width: '1px',
      height: '100%',
      backgroundColor: theme.placeholderColor
    },
    marginRight11: {
      marginRight: '11px'
    }
  };
};

const YesNoInput = ({
  className,
  classes,
  translations,
  onSelect,
  selectedOption
}) => {
  return (
    <div className={classNames(classes.container, className)}>
      <div
        className={classNames(classes.button, {
          [classes.selected]: selectedOption === true
        })}
        onClick={() => onSelect(true)}
      >
        <Icon type="yes" className={classes.marginRight11} />
        {i18n('shared.yes', translations)}
      </div>
      <div className={classes.divider} />
      <div
        className={classNames(classes.button, {
          [classes.selected]: selectedOption === false
        })}
        onClick={() => onSelect(false)}
      >
        <Icon type="no" className={classes.marginRight11} />
        {i18n('shared.no', translations)}
      </div>
    </div>
  );
};

export default R.compose(
  connect(state => {
    const translations = sessionSelectors.getTranslationsConfig(state);

    return {
      translations
    };
  }),
  injectSheet(styles)
)(YesNoInput);
