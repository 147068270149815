export const defaultTheme = {
  //#region New config
  secondaryColor: '#6A8595',

  successColor: '#41B851',

  errorNegativeColor: '#EB5757',

  warningColor: '#F2C94C',

  itemBackgroundColor_1: '#222E35',
  itemBackgroundColor_2: '#28363D',

  chartPortfolioColors: [
    '#73DDFF',
    '#78DC29',
    '#FBA96F',
    '#257CCD',
    '#FFCF24',
    '#EF6345',
    '#53FFEA',
    '#04C5BA',
    '#744CE5',
    '#00660B',
    '#C8C8C8'
  ],

  reportHighlightColor: '#E0E0ED'

  //#endregion New config
};
