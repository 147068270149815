import React from 'react';
import * as R from 'ramda';
import { StylesProvider } from '@material-ui/core/styles';
// import MuiTextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { i18n } from 'i18n/index.js';
import { Colors } from 'features/shared/constants/colors.js';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import ErrorMessage from 'features/shared/components/errorMessage.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { useField } from './useField.js';
import BaseTextInput from './baseTextInpit.js';

const indexedReduce = R.addIndex(R.reduce);

const useStyles = createUseStyles(theme => ({}));

const TextInput = ({ name, fieldConfig, placeholder }) => {
  const classes = useStyles();
  const {
    input: { value, onChange, onFocus, onBlur },
    meta: { error, touched }
  } = useField(name, fieldConfig);
  const translations = useSelector(sessionSelectors.getTranslationsConfig);

  const isErrorShown = touched && !R.isNil(error);

  let errorText = null;
  if (isErrorShown) {
    errorText = R.is(String, error)
      ? i18n(error, translations)
      : indexedReduce(
          (acc, arg, index) => acc.replace(`{${index}}`, arg),
          i18n(error.text, translations),
          error.args
        );
  }

  return (
    <StylesProvider injectFirst>
      <div>
        <BaseTextInput
          onFocus={onFocus}
          onBlur={onBlur}
          value={value || ''}
          onChange={onChange}
          placeholder={placeholder}
          autoComplete={'off'}
          isInvalid={isErrorShown}
        />
        {isErrorShown && <ErrorMessage>{`* ${errorText}`}</ErrorMessage>}
      </div>
    </StylesProvider>
  );
};

export default TextInput;
