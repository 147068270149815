import * as R from 'ramda';
import t from 'tcomb/index';

import { types } from './actions.js';
import { createReducer } from 'framework/tcomb/utils.js';

const Notification = t.interface(
  {
    id: t.String,
    type: t.String,
    message: t.String,
    isHideEnabled: t.maybe(t.Boolean),
    isAutohideEnabled: t.maybe(t.Boolean),
    autohideMilliseconds: t.maybe(t.Number),
    isShown: t.maybe(t.Boolean)
  },
  'Notification'
);

const Notifications = t.interface(
  {
    entities: t.dict(t.String, Notification),
    ids: t.list(t.String)
  },
  'Notifications'
);

const Reducer = createReducer(Notifications);

const reducer = Reducer.of((state = { entities: {}, ids: [] }, action) => {
  switch (action.type) {
    case types.SHOW_NOTIFICATION: {
      const { notification } = action.payload;

      return {
        entities: R.assoc(notification.id, notification, state.entities),
        ids: R.append(notification.id, state.ids)
      };
    }
    case types.HIDE_NOTIFICATION: {
      const { id: notificationId } = action.payload;

      return {
        entities: R.dissoc(notificationId, state.entities),
        ids: R.reject(id => id === notificationId, state.ids)
      };
    }
    default:
      return state;
  }
});

export default reducer;
