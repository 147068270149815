import { isNil, isEmpty, zip } from 'ramda';

import { formatNumber } from 'features/shared/utils/number.js';
import { ClientTypes } from '../constants';
import { ReportStoreData } from 'features/report/shared/services/reportStore.js';

export const getClientInformation = (reportStore: ReportStoreData) => {
  const { userData } = reportStore;

  return userData?.clientInformation;
};

export const getAdviceInformation = (reportStore: ReportStoreData) => {
  const { userData } = reportStore;

  return userData?.adviceInformation;
};

export const getOrderInformation = (reportStore: ReportStoreData) => {
  const { userData } = reportStore;

  return userData?.orderInformation;
};

export const getCashflowGoals = (reportStore: ReportStoreData) => {
  const { userData } = reportStore;

  return userData?.cashflowGoals;
};

export const getCashflowGoalChartsData = (reportStore: ReportStoreData) => {
  const { userData } = reportStore;

  return userData?.cashflowGoalChartsData;
};

export const getCashflowForecastChartData = (reportStore: ReportStoreData) => {
  const { userData } = reportStore;

  return userData?.cashflowForecastChartData;
};

export const getCashflowTableData = (reportStore: ReportStoreData) => {
  const { userData } = reportStore;

  return userData?.cashflowTableData;
};

export const getOrderSummaryData = (reportStore: ReportStoreData) => {
  const { userData } = reportStore;

  return userData?.orderSummaryData;
};

export const getSustainabilityData = (reportStore: ReportStoreData) => {
  const { userData } = reportStore;

  return userData?.sustainabilityData;
};

export const getGoals = (reportStore: ReportStoreData) => {
  const { userData } = reportStore;

  return userData?.goals;
};

export const getGeneralInformation = (reportStore: ReportStoreData) => {
  const { userData } = reportStore;

  return {
    name: userData?.generalInformation?.name
  };
};

export const getPurposeAndRisk = (reportStore: ReportStoreData) => {
  const { userData } = reportStore;

  return userData?.purposeAndRisk;
};

export const getKnowledgeAndExperience = (reportStore: ReportStoreData) => {
  const { userData } = reportStore;

  return userData?.knowledgeAndExperience || [];
};

export const getAdvancedSuitability = (reportStore: ReportStoreData) => {
  const { userData } = reportStore;

  return userData?.advancedSuitability;
};

const getCompanyFinancialSituation = (
  assets: {
    title: string;
    value: string | null;
  }[],
  debt: {
    title: string;
    value: string | null;
  }[]
) => {
  const lengthsDiff = assets.length - debt.length;

  if (lengthsDiff) {
    const lengthsDiffArray = Array(lengthsDiff).fill({
      title: null,
      value: null
    });

    if (lengthsDiff > 0) {
      return zip(assets, [...debt, ...lengthsDiffArray]);
    }

    if (lengthsDiff < 0) {
      return zip([...assets, ...lengthsDiffArray], debt);
    }
  }

  return zip(assets, debt);
};

export const getFinancialSituation = (reportStore: ReportStoreData) => {
  const { userData } = reportStore;

  const clientType = userData?.clientInformation?.clientType;
  const personFinancialSituation = userData?.personFinancialSituation || [];

  const companyFinancialSituationInvestableAssets =
    userData?.companyFinancialSituation?.investableAssets || [];
  const companyFinancialSituationAccountingFigures =
    userData?.companyFinancialSituation?.accountingFigures || [];
  const companyFinancialSituationDebt =
    userData?.companyFinancialSituation?.debt || [];
  const liquidity = userData?.liquidity || [];

  const companyFinancialSituation = getCompanyFinancialSituation(
    companyFinancialSituationInvestableAssets,
    companyFinancialSituationDebt
  );

  const expectingFinancialChanges =
    userData?.expectingFinancialChanges || false;

  const expectingFinancialChangesNote =
    userData?.expectingFinancialChangesNote || null;

  let isFinancialSituationEmpty: boolean | undefined;
  if (clientType === ClientTypes.person) {
    isFinancialSituationEmpty = isEmpty(personFinancialSituation);
  } else if (clientType === ClientTypes.company) {
    isFinancialSituationEmpty =
      isEmpty(companyFinancialSituationInvestableAssets) &&
      isEmpty(companyFinancialSituationAccountingFigures) &&
      isEmpty(companyFinancialSituationDebt);
  } else {
    isFinancialSituationEmpty = true;
  }

  return {
    clientType,
    personFinancialSituation,
    companyFinancialSituation,
    companyFinancialSituationAccountingFigures,
    liquidity,
    isEmpty: isFinancialSituationEmpty,
    expectingFinancialChanges,
    expectingFinancialChangesNote
  };
};

export const getInvestmentPlan = (cultureCode, reportStore) => {
  const { userData } = reportStore;
  const firstDeposit = userData?.investmentPlan.firstDeposit;
  const monthlyDeposit = userData?.investmentPlan.monthlyDeposit;
  const expectedValue = userData?.investmentPlan.expectedValue;
  const horizon = userData?.investmentPlan.horizon;
  const isExpectedValueVisible =
    userData?.investmentPlan.isExpectedValueVisible;

  return {
    initialInvestment: isNil(firstDeposit)
      ? null
      : `${formatNumber(cultureCode, firstDeposit, 0, 2)}`,
    monthlySaving: isNil(monthlyDeposit)
      ? null
      : `${formatNumber(cultureCode, monthlyDeposit, 0, 2)}`,
    expectedValue: isNil(expectedValue)
      ? null
      : formatNumber(cultureCode, expectedValue),
    horizon,
    isExpectedValueVisible
  };
};

export const getAutomaticClientClassification = (
  reportStore: ReportStoreData
) => {
  const { userData } = reportStore;

  return userData?.automaticClientClassification;
};
