import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import classNames from 'classnames';

import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';

export const useStyles = createUseStyles(theme => ({
  header: {
    paddingTop: '50px',
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    color: Colors.grayDark,
    paddingBottom: '40px',
    textAlign: 'center'
  }
}));

const Header = ({ children, className }) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.header, className)}>{children}</div>
  );
};

export default Header;
