export const Colors = {
  black: '#000000',
  white: '#FFFFFF',
  gray50: '#6a8595',
  gray100: '#2F2137',
  gray200: '#E5E5E5',
  gray300: '#F8F8FB',
  gray400: '#73657B',
  gray500: '#FCFCFD',
  gray600: '#F8F8FB',
  blue100: '#020106',
  blue200: '#756381'
};
