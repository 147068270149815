import { all } from 'redux-saga/effects';

import {
  renderReport,
  renderOrderExecutionReport,
  renderApplication,
  renderErrorPage
} from './render.js';
import applicationSagas from 'features/shared/services/application/sagas.js';
import routerSagas from 'features/shared/services/router/sagas.js';
import sessionSagas from 'features/shared/services/session/sagas.js';
import userOptionsSagas from 'features/shared/services/userOptions/sagas.js';
import notificationSagas from 'features/notification/services/sagas.js';

export function* sagas() {
  yield all([
    applicationSagas({
      renderReport,
      renderOrderExecutionReport,
      renderApplication,
      renderErrorPage
    }),
    routerSagas(),
    sessionSagas(),
    userOptionsSagas(),
    notificationSagas()
  ]);
}
