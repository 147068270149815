import React from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import { i18n } from 'i18n/index.js';
import { FontWeights } from '../../shared/constants/fonts.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Heading1 from '../../shared/components/heading1';
import Section from '../../shared/components/section.js';
import {
  Table,
  TableBody,
  TableBodyRow,
  TableBodyCell
} from '../../shared/components/table';

const useStyles = createUseStyles(theme => ({
  valueCell: {
    fontWeight: FontWeights.bold
  },
  text: {
    marginBottom: '20px'
  }
}));

const mapExpectationOfResultToTranslationKey = expectationOfRisk => {
  const mapping = {
    1: 'report.risk.expectationOfRisk.lowRisk',
    2: 'report.risk.expectationOfRisk.mediumRisk',
    3: 'report.risk.expectationOfRisk.highRisk',
    4: 'report.risk.expectationOfRisk.maximumRisk',
    5: 'report.risk.expectationOfRisk.ultraRisk'
  };

  return mapping[expectationOfRisk] || '';
};

const mapRiskStrategyToTranslationKey = riskStrategy => {
  const mapping = {
    1: 'report.risk.riskStrategy.scenarioA',
    2: 'report.risk.riskStrategy.scenarioB',
    3: 'report.risk.riskStrategy.scenarioC',
    4: 'report.risk.riskStrategy.scenarioD',
    5: 'report.risk.riskStrategy.scenarioE',
    6: 'report.risk.riskStrategy.scenarioF',
    7: 'report.risk.riskStrategy.scenarioG'
  };

  return mapping[riskStrategy] || '';
};

const RiskSection = ({ purposeAndRisk }) => {
  const classes = useStyles();

  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  return (
    <Section data-testid={'risk-section'} className={classes.purposeSection}>
      <Heading1>{i18n('report.risk.header', translationsConfig)}</Heading1>
      <div className={classes.text}>
        {i18n('report.risk.text', translationsConfig)}
      </div>
      <Table>
        <TableBody>
          {purposeAndRisk.expectationOfRisk && (
            <TableBodyRow>
              <TableBodyCell>
                {i18n(
                  'report.risk.expectationOfRisk.question',
                  translationsConfig
                )}
              </TableBodyCell>
              <TableBodyCell className={classes.valueCell}>
                {i18n(
                  mapExpectationOfResultToTranslationKey(
                    purposeAndRisk.expectationOfRisk
                  ),
                  translationsConfig
                )}
              </TableBodyCell>
            </TableBodyRow>
          )}
          {purposeAndRisk.riskStrategy && (
            <TableBodyRow>
              <TableBodyCell>
                {i18n('report.risk.riskStrategy.question', translationsConfig)}
              </TableBodyCell>
              <TableBodyCell className={classes.valueCell}>
                {i18n(
                  mapRiskStrategyToTranslationKey(purposeAndRisk.riskStrategy),
                  translationsConfig
                )}
              </TableBodyCell>
            </TableBodyRow>
          )}
        </TableBody>
      </Table>
    </Section>
  );
};

export default RiskSection;
