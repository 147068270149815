import { useSelector } from 'react-redux';

import sessionSelectors from 'features/shared/services/session/selectors';
import Heading2 from 'features/report/shared/components/heading2';
import ChartTable from '../../../../shared/components/chartTable';
import { i18n } from 'i18n';
import useStyles from './styles';
import { useReportStore } from 'features/report/shared/services/reportStore';
import AdvisorNotesSection from '../../advisorNotesSection';

const OngoingFees = ({ data }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const { userData } = useReportStore();
  const overrideFundOngoingFeesAdvisorNotes =
    userData?.cost?.overrideFundOngoingFeesAdvisorNotes;

  return (
    <>
      <div className={classes.section}>
        <Heading2>
          {i18n('costChart.costTable.ongoingFeesInPercent', translationsConfig)}
        </Heading2>
        <ChartTable
          customClasses={{
            headerCell: classes.headerCell,
            cell: classes.cell
          }}
          headerTitles={[
            i18n('costChart.fundsTable.fund', translationsConfig),
            i18n('costChart.costTable.fundManagementFee', translationsConfig),
            i18n('costChart.costTable.fundTransactionCost', translationsConfig),
            i18n(
              'costChart.costTable.fundReturnCommissionPaidToClient',
              translationsConfig
            ),
            i18n('costChart.costTable.performanceFee', translationsConfig),
            i18n('costChart.costTable.platformFee', translationsConfig),
            i18n('costChart.costTable.insuranceCost', translationsConfig),
            i18n('costChart.costTable.custodyFee', translationsConfig),
            i18n('costChart.costTable.totalExpenseRatio', translationsConfig)
          ]}
          data={data.percent.ongoingFees}
          dataKeys={[
            'fund',
            'fundManagementFee',
            'fundTransactionCost',
            'fundReturnCommissionPaidToClient',
            'performanceFee',
            'platformFee',
            'insuranceCost',
            'custodyFee',
            'totalExpenseRatio'
          ]}
          highlightLastRow
          addPercentToAllValues
        />
      </div>

      {overrideFundOngoingFeesAdvisorNotes && (
        <AdvisorNotesSection
          className={classes.advisorNotes}
          header={i18n(
            'costChart.fundsTable.reasoningForOversteeredCost',
            translationsConfig
          )}
          text={overrideFundOngoingFeesAdvisorNotes}
        />
      )}

      <div className={classes.section}>
        <Heading2>
          {i18n(
            'costChart.costTable.ongoingFeesInCurrency',
            translationsConfig
          ).replace(
            '{0}',
            i18n('report.shared.amount', translationsConfig)?.toLowerCase()
          )}
        </Heading2>
        <ChartTable
          customClasses={{
            headerCell: classes.headerCell,
            cell: classes.cell
          }}
          headerTitles={[
            i18n('costChart.fundsTable.fund', translationsConfig),
            i18n('costChart.costTable.fundManagementFee', translationsConfig),
            i18n('costChart.costTable.fundTransactionCost', translationsConfig),
            i18n(
              'costChart.costTable.fundReturnCommissionPaidToClient',
              translationsConfig
            ),
            i18n('costChart.costTable.performanceFee', translationsConfig),
            i18n('costChart.costTable.platformFee', translationsConfig),
            i18n('costChart.costTable.insuranceCost', translationsConfig),
            i18n('costChart.costTable.custodyFee', translationsConfig),
            i18n('costChart.costTable.totalExpenseRatio', translationsConfig)
          ]}
          data={data.currency.ongoingFees}
          dataKeys={[
            'fund',
            'fundManagementFee',
            'fundTransactionCost',
            'fundReturnCommissionPaidToClient',
            'performanceFee',
            'platformFee',
            'insuranceCost',
            'custodyFee',
            'totalExpenseRatio'
          ]}
          highlightLastRow
        />
      </div>
    </>
  );
};

export default OngoingFees;
