import React from 'react';
import { Route, Switch } from 'react-router-dom';

import MainLayout from 'features/shared/components/mainLayout/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import Intro from 'features/onboarding/intro/components/index.js';
import HowWork from 'features/onboarding/howWork/components/index.js';
import WhyUse from 'features/onboarding/whyUse/components/index.js';
import SavingGoals from 'features/onboarding/savingGoals/components/index.js';
import GoalPension from 'features/onboarding/goalPension/components/index.js';
import SavingHorizon from 'features/onboarding/savingHorizon/components/index.js';

import RiskAssessment from 'features/onboarding/riskAssessment/intro/components/index.js';
import WhatIsRisk from 'features/onboarding/riskAssessment/intro/components/whatIsRiskScreen.js';
import RiskPreference from 'features/onboarding/riskAssessment/riskPreference/components/index.js';
import ReturnPreference from 'features/onboarding/riskAssessment/returnPreference/components/index.js';
import DisconnectedAnswers from 'features/onboarding/riskAssessment/disconnectedAnswers/components/disconnectedAnswers.js';

import ExperienceAssessment from 'features/onboarding/knowledgeAndExperience/experienceAssessment/components/index.js';
import Loss from 'features/onboarding/knowledgeAndExperience/loss/components/index.js';
import Fund from 'features/onboarding/knowledgeAndExperience/fund/components/index.js';
import WhatIsFund from 'features/onboarding/knowledgeAndExperience/fund/components/whatIsFundScreen.js';
import AffectFactors from 'features/onboarding/knowledgeAndExperience/affectFactors/components/index.js';
import ResultKnowledge from 'features/onboarding/knowledgeAndExperience/result/components/index.js';
import InvestmentKnowledge from 'features/onboarding/knowledgeAndExperience/investmentKnowledge/components/index.js';
import TradingKnowledge from 'features/onboarding/knowledgeAndExperience/trading/components/index.js';

import Left from 'features/onboarding/financialSituation/left/components/index.js';
import Debt from 'features/onboarding/financialSituation/debt/components/index.js';
import Assets from 'features/onboarding/financialSituation/assets/components/index.js';
import Buffer from 'features/onboarding/financialSituation/buffer/components/index.js';
import WhatIsBuffer from 'features/onboarding/financialSituation/buffer/components/whatIsBufferScreen.js';
import CreditCard from 'features/onboarding/financialSituation/creditCard/components/index.js';

import Summary from 'features/onboarding/summary/components/index.js';
import CreditCardStopScreen from 'features/onboarding/stopScreen/components/creditCard/creditCard';
import StopScreen from 'features/onboarding/stopScreen/components/default';

const Onboarding = () => {
  return (
    <MainLayout>
      <Switch>
        <Route path={routeTemplates.intro.config} component={Intro} />
        <Route path={routeTemplates.howWork.config} component={HowWork} />
        <Route path={routeTemplates.whyUse.config} component={WhyUse} />
        <Route
          path={routeTemplates.savingGoals.config}
          component={SavingGoals}
        />
        <Route
          path={routeTemplates.goalPension.config}
          component={GoalPension}
        />
        <Route
          path={routeTemplates.savingHorizon.config}
          component={SavingHorizon}
        />
        <Route
          path={routeTemplates.riskAssessmentIntro.config}
          component={RiskAssessment}
        />
        <Route path={routeTemplates.whatIsRisk.config} component={WhatIsRisk} />
        <Route
          path={routeTemplates.riskPreference.config}
          component={RiskPreference}
        />
        <Route
          path={routeTemplates.returnPreference.config}
          component={ReturnPreference}
        />

        <Route
          path={routeTemplates.experienceAssessment.config}
          component={ExperienceAssessment}
        />
        <Route path={routeTemplates.loss.config} component={Loss} />
        <Route path={routeTemplates.fund.config} component={Fund} />
        <Route path={routeTemplates.whatIsFund.config} component={WhatIsFund} />
        <Route
          path={routeTemplates.affectFactor.config}
          component={AffectFactors}
        />
        <Route
          path={routeTemplates.resultKnowledge.config}
          component={ResultKnowledge}
        />

        <Route
          path={routeTemplates.investmentKnowledge.config}
          component={InvestmentKnowledge}
        />
        <Route
          path={routeTemplates.tradingKnowledge.config}
          component={TradingKnowledge}
        />

        <Route path={routeTemplates.left.config} component={Left} />
        <Route path={routeTemplates.debt.config} component={Debt} />
        <Route path={routeTemplates.assets.config} component={Assets} />
        <Route path={routeTemplates.buffer.config} component={Buffer} />
        <Route
          path={routeTemplates.whatIsBuffer.config}
          component={WhatIsBuffer}
        />
        <Route path={routeTemplates.creditCard.config} component={CreditCard} />

        <Route path={routeTemplates.summary.config} component={Summary} />
        <Route path={routeTemplates.stopScreen.config} component={StopScreen} />
        <Route
          path={routeTemplates.creditCardStopScreen.config}
          component={CreditCardStopScreen}
        />
        <Route
          path={routeTemplates.disconnectedAnswers.config}
          component={DisconnectedAnswers}
        />
      </Switch>
    </MainLayout>
  );
};

export default Onboarding;
