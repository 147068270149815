import create from 'zustand';
import * as R from 'ramda';

const defaultData = {
  portfolioTimeseries: null,
  portfolioDetails: null,
  isReadPortfolioDataPending: false
};

export const usePageStore = create((set, get) => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  setPortfolioData: ({ portfolioTimeseries, portfolioDetails }) => {
    set({ portfolioTimeseries, portfolioDetails });
  },
  setIsReadPortfolioDataPending: isReadPortfolioDataPending => {
    set({ isReadPortfolioDataPending });
  }
}));
