import create from 'zustand';
import { mergeWith, add, omit, isNil, max } from 'ramda';

import { FundNameForPresentation } from 'modules/shared/constants/settings';

const GoalsDataFields = {
  goalsData: 'goalsData',
  originalGoalsData: 'originalGoalsData'
};

export const createUseCostChartStore = (
  intialState = {
    chartData: {},
    tableData: {},
    goalsData: [],
    originalGoalsData: [],
    originalTableData: {}
  }
) =>
  create(set => ({
    ...intialState,
    reset: () => {
      set(intialState);
    },
    initialize(initialData) {
      set(
        state => ({
          ...state,
          ...initialData
        }),
        true
      );
    },
    setCostData({ chartData, tableData }) {
      set({ chartData, tableData });
    },
    setOriginalTableData({ originalTableData }) {
      set({ originalTableData });
    },
    addGoalsData: (
      goals,
      fundNameForPresentation = FundNameForPresentation.fundStandardName,
      field = GoalsDataFields.goalsData
    ) => {
      set(state => ({
        ...state,
        [field]: [
          ...state[field],
          ...goals.map(
            ({ portfolio, startingCapital, data, goalId, i18n }) => ({
              chartData: getChartData(data.forecast),
              tableData: getTableData(
                data.forecast,
                startingCapital,
                data.summary,
                portfolio,
                state[field],
                goals,
                fundNameForPresentation,
                i18n
              ),
              startingCapital,
              goalId
            })
          )
        ]
      }));
    },
    editGoalsData: (
      goals,
      fundNameForPresentation = FundNameForPresentation.fundStandardName,
      field = GoalsDataFields.goalsData
    ) => {
      set(state => {
        return {
          ...state,
          [field]: state[field].map(goalData => {
            const updatedGoalsIds = goals.map(({ goalId }) => goalId);
            const updatedGoal = goals.find(
              ({ goalId }) => goalId === goalData.goalId
            );
            if (updatedGoal) {
              return {
                chartData: getChartData(updatedGoal.data.forecast),
                tableData: getTableData(
                  updatedGoal.data.forecast,
                  updatedGoal.startingCapital,
                  updatedGoal.data.summary,
                  updatedGoal.portfolio,
                  state[field].filter(
                    ({ goalId }) => !updatedGoalsIds.includes(goalId)
                  ),
                  goals,
                  fundNameForPresentation,
                  updatedGoal.i18n
                ),
                startingCapital: updatedGoal.startingCapital,
                goalId: updatedGoal.goalId
              };
            }
            return goalData;
          })
        };
      });
    },
    resetCostData: () => {
      set({
        chartData: {},
        tableData: {},
        goalsData: [],
        originalGoalsData: [],
        originalTableData: {}
      });
    }
  }));

const getChartData = forecast =>
  forecast
    .filter((item, index) => (index + 1) % 12 === 0)
    .map(el => ({
      valueAfterCost: el.value,
      valueBeforeCost: el.value_ex_cost,
      deposits: el.deposit
    }));

const getSummaryData = portfolio => {
  const annualOngoingFees = {
    fundManagementFee: calculateIfNotNull(
      portfolio.portfolio_management_fee,
      portfolio.portfolio_management_fee
    ),
    fundReturnCommissionPaidToClient: calculateIfNotNull(
      portfolio.portfolio_commission_paid_to_client,
      portfolio.portfolio_commission_paid_to_client
    ),
    fundTransactionCost: calculateIfNotNull(
      portfolio.portfolio_transaction_cost,
      portfolio.portfolio_transaction_cost
    ),
    performanceFee: calculateIfNotNull(
      portfolio.portfolio_performance_fee,
      portfolio.portfolio_performance_fee
    ),
    platformFee: calculateIfNotNull(
      portfolio.portfolio_platform_fee,
      portfolio.portfolio_platform_fee
    ),
    insuranceCost: calculateIfNotNull(
      portfolio.portfolio_insurance_cost,
      portfolio.portfolio_insurance_cost
    ),
    custodyFee: calculateIfNotNull(
      portfolio.portfolio_custody_fee,
      portfolio.portfolio_custody_fee
    ),
    totalPortfolioFee: calculateIfNotNull(
      portfolio.portfolio_total_ongoing_fees,
      portfolio.portfolio_total_ongoing_fees
    )
  };

  const oneTimeFees = {
    fundPurchaseFee: calculateIfNotNull(
      portfolio.portfolio_purchasing_fee,
      portfolio.portfolio_purchasing_fee
    ),
    advisoryPurchaseFee: calculateIfNotNull(
      portfolio.portfolio_advisory_purchasing_fee,
      portfolio.portfolio_advisory_purchasing_fee
    ),
    advisoryOnboardingFee: calculateIfNotNull(
      portfolio.portfolio_advisory_onboarding_fee,
      portfolio.portfolio_advisory_onboarding_fee
    )
  };

  const annualReturnCommissionPaidToAdvisor = {
    returnCommissionPaidToAdvisor: calculateIfNotNull(
      portfolio.portfolio_commission_paid_to_advisor,
      portfolio.portfolio_commission_paid_to_advisor
    )
  };

  const custodyFees = {
    custodyFeeAmount: portfolio.portfolio_custody_fee_amount
  };

  return {
    annualOngoingFees,
    oneTimeFees,
    annualReturnCommissionPaidToAdvisor,
    custodyFees
  };
};

const getVal = (val1, val2) => {
  return val2 ? val1 / val2 : 0;
};

const calculateIfNotNull = (val, expr) => {
  return isNil(val) ? null : expr;
};

const getTotalExpenseRatio = el => {
  return (
    el.fund_management_fee +
    el.fund_transaction_cost -
    el.fund_return_commission_paid_to_client +

    el.fund_performance_fee +
    el.fund_platform_fee +
    el.fund_insurance_cost +
    el.fund_custody_fee
  );
};

const getSumFunds = (data, key, i18n) => {
  const result = data.reduce((sum, val) => {
    return mergeWith(add, sum, omit(['fund'], val[key]));
  }, {});

  result.fund = i18n ? i18n('portfolioChart.default.portfolio') : 'Portfolio';

  return result;
};

const getFundDetails = (
  forecast,
  portfolio,
  startingCapital,
  weights,
  goalsData,
  newGoalsData,
  fundNameForPresentation,
  i18n
) => {
  const ongoingFees = forecast.map(el => {
    const weight = weights[el.Ticker] / 100;

    const weightCapital = weight * startingCapital;

    const currency = {
      fund:
        fundNameForPresentation === FundNameForPresentation.fundStandardName &&
        el.FundStandardName
          ? el.FundStandardName
          : el.Name,
      fundManagementFee: calculateIfNotNull(
        el.fund_management_fee,
        weightCapital * el.fund_management_fee
      ),
      fundTransactionCost: calculateIfNotNull(
        el.fund_transaction_cost,
        weightCapital * el.fund_transaction_cost
      ),
      fundReturnCommissionPaidToClient: calculateIfNotNull(
        el.fund_return_commission_paid_to_client,
        weightCapital * el.fund_return_commission_paid_to_client
      ),
      performanceFee: calculateIfNotNull(
        el.fund_performance_fee,
        weightCapital * el.fund_performance_fee
      ),
      platformFee: calculateIfNotNull(
        el.fund_platform_fee,
        weightCapital * el.fund_platform_fee
      ),
      insuranceCost: calculateIfNotNull(
        el.fund_insurance_cost,
        weightCapital * el.fund_insurance_cost
      ),
      totalExpenseRatio: calculateIfNotNull(
        getTotalExpenseRatio(el),
        weightCapital * getTotalExpenseRatio(el)
      )
    };

    const percent = {
      fund:
        fundNameForPresentation === FundNameForPresentation.fundStandardName &&
        el.FundStandardName
          ? el.FundStandardName
          : el.Name,
      fundManagementFee: calculateIfNotNull(
        el.fund_management_fee,
        el.fund_management_fee * 100
      ),
      fundTransactionCost: calculateIfNotNull(
        el.fund_transaction_cost,
        el.fund_transaction_cost * 100
      ),
      fundReturnCommissionPaidToClient: calculateIfNotNull(
        el.fund_return_commission_paid_to_client,
        el.fund_return_commission_paid_to_client * 100
      ),
      performanceFee: calculateIfNotNull(
        el.fund_performance_fee,
        el.fund_performance_fee * 100
      ),
      platformFee: calculateIfNotNull(
        el.fund_platform_fee,
        el.fund_platform_fee * 100
      ),
      insuranceCost: calculateIfNotNull(
        el.fund_insurance_cost,
        el.fund_insurance_cost * 100
      ),
      totalExpenseRatio: calculateIfNotNull(
        getTotalExpenseRatio(el),
        getTotalExpenseRatio(el) * 100
      )
    };

    return { currency, percent };
  });

  // Add 'Portfolio' row summary
  ongoingFees.push({
    currency: getSumFunds(ongoingFees, 'currency', i18n),
    percent: {
      fund: i18n ? i18n('portfolioChart.default.portfolio') : 'Portfolio',
      fundManagementFee: calculateIfNotNull(
        portfolio.portfolio_management_fee,
        portfolio.portfolio_management_fee * 100
      ),
      fundTransactionCost: calculateIfNotNull(
        portfolio.portfolio_transaction_cost,
        portfolio.portfolio_transaction_cost * 100
      ),
      fundReturnCommissionPaidToClient: calculateIfNotNull(
        portfolio.portfolio_commission_paid_to_client,
        portfolio.portfolio_commission_paid_to_client * 100
      ),
      performanceFee: calculateIfNotNull(
        portfolio.portfolio_performance_fee,
        portfolio.portfolio_performance_fee * 100
      ),
      platformFee: calculateIfNotNull(
        portfolio.portfolio_platform_fee,
        portfolio.portfolio_platform_fee * 100
      ),
      insuranceCost: calculateIfNotNull(
        portfolio.portfolio_insurance_cost,
        portfolio.portfolio_insurance_cost * 100
      ),
      totalExpenseRatio: calculateIfNotNull(
        portfolio.portfolio_total_ongoing_fees,
        portfolio.portfolio_total_ongoing_fees * 100
      )
    }
  });

  const oneTimeFees = forecast.map(el => {
    const weight = weights[el.Ticker] / 100;

    const weightCapital = weight * startingCapital;

    const currency = {
      fund:
        fundNameForPresentation === FundNameForPresentation.fundStandardName &&
        el.FundStandardName
          ? el.FundStandardName
          : el.Name,
      fundPurchaseFee: calculateIfNotNull(
        el.fund_purchasing_fee,
        weightCapital * el.fund_purchasing_fee
      ),
      advisoryPurchaseFee: calculateIfNotNull(
        el.fund_advisory_purchasing_fee,
        weightCapital * el.fund_advisory_purchasing_fee
      ),
      advisoryOnboardingFee: calculateIfNotNull(
        el.fund_advisory_onboarding_fee,
        weightCapital * el.fund_advisory_onboarding_fee
      )
    };

    const percent = {
      fund:
        fundNameForPresentation === FundNameForPresentation.fundStandardName &&
        el.FundStandardName
          ? el.FundStandardName
          : el.Name,
      fundPurchaseFee: calculateIfNotNull(
        el.fund_purchasing_fee,
        el.fund_purchasing_fee * 100
      ),
      advisoryPurchaseFee: calculateIfNotNull(
        el.fund_advisory_purchasing_fee,
        el.fund_advisory_purchasing_fee * 100
      ),
      advisoryOnboardingFee: calculateIfNotNull(
        el.fund_advisory_onboarding_fee,
        el.fund_advisory_onboarding_fee * 100
      )
    };

    return { currency, percent };
  });

  // Add 'Portfolio' row summary
  oneTimeFees.push({
    currency: getSumFunds(oneTimeFees, 'currency', i18n),
    percent: {
      fund: i18n ? i18n('portfolioChart.default.portfolio') : 'Portfolio',
      fundPurchaseFee: calculateIfNotNull(
        portfolio.portfolio_fund_purchase_fee,
        portfolio.portfolio_fund_purchase_fee * 100
      ),
      advisoryPurchaseFee: calculateIfNotNull(
        portfolio.portfolio_advisory_purchasing_fee,
        portfolio.portfolio_advisory_purchasing_fee * 100
      ),
      advisoryOnboardingFee: calculateIfNotNull(
        portfolio.portfolio_advisory_onboarding_fee,
        portfolio.portfolio_advisory_onboarding_fee * 100
      )
    }
  });
  let custodyFee = goalsData.reduce(
    (acc, val) => max(acc, val.tableData.summary.custodyFees.custodyFeeAmount),
    portfolio.portfolio_custody_fee_amount
  );

  if (isNil(custodyFee)) {
    custodyFee = newGoalsData.reduce(
        (acc, val) => max(acc, val.data.summary.portfolio.portfolio_custody_fee_amount),
        portfolio.portfolio_custody_fee_amount
    );
  }

  const custodyFees = forecast.map(el => {
    const weight = weights[el.Ticker] / 100;
    const currency = {
      fund:
        fundNameForPresentation === FundNameForPresentation.fundStandardName &&
        el.FundStandardName
          ? el.FundStandardName
          : el.Name,
      custodyFee: calculateIfNotNull(custodyFee, custodyFee * weight)
    };

    const percent = {
      fund:
        fundNameForPresentation === FundNameForPresentation.fundStandardName &&
        el.FundStandardName
          ? el.FundStandardName
          : el.Name,
      custodyFee: calculateIfNotNull(
        custodyFee,
        (custodyFee * weight * 100) / startingCapital
      )
    };

    return { currency, percent };
  });

  // Add 'Portfolio' row summary
  custodyFees.push({
    currency: getSumFunds(custodyFees, 'currency', i18n),
    percent: getSumFunds(custodyFees, 'percent', i18n)
  });

  const returnCommissionPaidToAdvisor = forecast.map(el => {
    const weight = weights[el.Ticker] / 100;

    const currency = {
      fund:
        fundNameForPresentation === FundNameForPresentation.fundStandardName &&
        el.FundStandardName
          ? el.FundStandardName
          : el.Name,
      fundReturnCommissionPaidToAdvisor: calculateIfNotNull(
        el.fund_return_commission_paid_to_advisor,
        weight * startingCapital * el.fund_return_commission_paid_to_advisor
      )
    };

    const percent = {
      fund:
        fundNameForPresentation === FundNameForPresentation.fundStandardName &&
        el.FundStandardName
          ? el.FundStandardName
          : el.Name,
      fundReturnCommissionPaidToAdvisor: calculateIfNotNull(
        el.fund_return_commission_paid_to_advisor,
        el.fund_return_commission_paid_to_advisor * 100
      )
    };

    return { currency, percent };
  });

  // Add 'Portfolio' row summary
  returnCommissionPaidToAdvisor.push({
    currency: getSumFunds(returnCommissionPaidToAdvisor, 'currency', i18n),
    percent: {
      fund: i18n ? i18n('portfolioChart.default.portfolio') : 'Portfolio',
      fundReturnCommissionPaidToAdvisor: calculateIfNotNull(
        portfolio.portfolio_commission_paid_to_advisor,
        portfolio.portfolio_commission_paid_to_advisor * 100
      )
    }
  });
  return {
    ongoingFees,
    oneTimeFees,
    custodyFees,
    returnCommissionPaidToAdvisor
  };
};

const getTableData = (
  forecast,
  startingCapital,
  summary,
  portfolio,
  goalsData,
  newGoalsData,
  fundNameForPresentation,
  i18n
) => {
  const forecastYearly = forecast.filter(
    (item, index) => (index + 1) % 12 === 0
  );
  const { instruments, portfolio: respPortfolio } = summary;
  const portfolioWeightMap = portfolio.reduce((map, obj) => {
    map[obj.id] = Number(obj.weight);
    return map;
  }, {});

  return {
    summary: getSummaryData(respPortfolio),
    firstYear: getDataForYear(forecastYearly, 0),
    lastYear: getDataForYear(
      forecastYearly,
      forecastYearly.length > 10 ? 10 : forecastYearly.length - 1
    ),
    fundDetails: getFundDetails(
      instruments,
      respPortfolio,
      startingCapital,
      portfolioWeightMap,
      goalsData,
      newGoalsData,
      fundNameForPresentation,
      i18n
    )
  };
};

const getDataForYear = (instruments, year) => {
  const aggregatedCostEffect = {
    deposits: instruments[year].deposit,
    valueBeforeCost: instruments[year].value_ex_cost,
    valueAfterCost: instruments[year].value,
    reductionInReturn: instruments[year].cost_reduction,
    reductionInReturnPercent: calculateIfNotNull(
      instruments[year].cost_reduction_percent,
      instruments[year].cost_reduction_percent * 100
    ),
    returnAfterCost: calculateIfNotNull(
      instruments[year].value || instruments[year].deposit,
      ((instruments[year].value - instruments[year].deposit) * 100) /
        instruments[year].deposit
    ),
    expectedReturn: calculateIfNotNull(
      instruments[year].deposit || instruments[year].value,
      instruments[year].value - instruments[year].deposit
    )
  };

  const aggregatedOngoingFees = {
    fundManagementFee: instruments[year].acc_portfolio_management_fee,
    fundReturnCommissionPaidToClient:
      instruments[year].acc_portfolio_commission_paid_to_client,
    fundTransactionCost: instruments[year].acc_portfolio_transaction_cost,
    performanceFee: instruments[year].acc_portfolio_performance_fee,
    platformFee: instruments[year].acc_platform_fee,
    insuranceCost: instruments[year].acc_portfolio_insurance_cost,
    custodyFee: instruments[year].acc_custody_fee,
    totalPortfolioFee: instruments[year].acc_total_cost
  };
  const aggregatedOngoingFeesPercent = {};

  Object.entries(aggregatedOngoingFees).forEach(([key, value]) => {
    aggregatedOngoingFeesPercent[key] = calculateIfNotNull(
      value,
      getVal(value, aggregatedCostEffect.valueBeforeCost) * 100
    );
  });

  const oneTimeFees = {
    fundPurchaseFee: instruments[year].portfolio_purchasing_fee || null,
    advisoryPurchaseFee:
      instruments[year].portfolio_advisory_purchasing_fee || null
  };
  const oneTimeFeesPercent = {};

  Object.entries(oneTimeFees).forEach(([key, value]) => {
    oneTimeFeesPercent[key] = calculateIfNotNull(
      value,
      getVal(value, aggregatedCostEffect.valueBeforeCost)
    );
  });

  const aggregatedReturnCommissionPaidToAdvisor = {
    returnCommissionPaidToAdvisor:
      instruments[year].acc_commission_paid_to_advisor
  };
  const aggregatedReturnCommissionPaidToAdvisorPercent = {};

  Object.entries(aggregatedReturnCommissionPaidToAdvisor).forEach(
    ([key, value]) => {
      aggregatedReturnCommissionPaidToAdvisorPercent[key] = calculateIfNotNull(
        value,
        getVal(value, aggregatedCostEffect.valueBeforeCost)
      );
    }
  );

  return {
    aggregatedCostEffect,
    aggregatedOngoingFees,
    aggregatedOngoingFeesPercent,
    oneTimeFees,
    oneTimeFeesPercent,
    aggregatedReturnCommissionPaidToAdvisor,
    aggregatedReturnCommissionPaidToAdvisorPercent
  };
};
