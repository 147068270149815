import create from 'zustand';
import * as R from 'ramda';

const defaultData = {
  portfolio: null,
  isReadPortfolioPending: false
};

export const usePageStore = create((set, get) => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  setPortfolio: portfolio => {
    set({ portfolio });
  },
  setIsReadPortfolioPending: isReadPortfolioPending => {
    set({ isReadPortfolioPending });
  },
  setInstrumentDetails: (portfolioItemId, instrumentDetails) => {
    const portfolioItemIndex = R.findIndex(
      i => i.id === portfolioItemId,
      R.path(['portfolio', 'items'], get())
    );

    if (portfolioItemIndex === -1) {
      return;
    }

    set(
      R.set(
        R.lensPath([
          'portfolio',
          'items',
          portfolioItemIndex,
          'instrumentDetails'
        ]),
        instrumentDetails
      ),
      true
    );
  },
  setIsReadInstrumentDetailsPending: (
    portfolioItemId,
    isReadInstrumentDetailsPending
  ) => {
    const portfolioItemIndex = R.findIndex(
      i => i.id === portfolioItemId,
      R.path(['portfolio', 'items'], get())
    );

    if (portfolioItemIndex === -1) {
      return;
    }

    set(
      R.set(
        R.lensPath([
          'portfolio',
          'items',
          portfolioItemIndex,
          'isReadInstrumentDetailsPending'
        ]),
        isReadInstrumentDetailsPending
      ),
      true
    );
  }
}));
