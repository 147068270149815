import create from 'zustand';

const defaultData = {
  expectedValue: null,
  averageTimeseries: null,
  deviationTimeseries: null,
  isReadExpectedReturnPending: false
};

export const usePageStore = create(set => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  setExpectedReturn: ({
    expectedValue,
    averageTimeseries,
    deviationTimeseries
  }) => {
    set({ expectedValue, averageTimeseries, deviationTimeseries });
  },
  setIsReadExpectedReturnPending: isReadExpectedReturnPending => {
    set({ isReadExpectedReturnPending });
  }
}));
