import React from 'react';

import { i18n } from 'i18n/index.js';
import Paragraph from '../../shared/components/paragraph';
import Heading1 from '../../shared/components/heading1';
import Section from '../../shared/components/section.js';
import { useSelector } from 'react-redux';
import sessionSelectors from '../../../shared/services/session/selectors';

const CompanyVest = () => {
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  const vestSectionTexts = [
    'report.finalDocument.vest.text1',
    'report.finalDocument.vest.text2',
    'report.finalDocument.vest.text3',
    'report.finalDocument.vest.text4'
  ];

  const investmentPhilosophyTexts = [
    'report.finalDocument.investmentPhilosophy.text1',
    'report.finalDocument.investmentPhilosophy.text2',
    'report.finalDocument.investmentPhilosophy.text3',
    'report.finalDocument.investmentPhilosophy.text4'
  ];

  const vestTextLi = [
    'report.finalDocument.vest.text5.p1',
    'report.finalDocument.vest.text5.p2',
    'report.finalDocument.vest.text5.p3',
    'report.finalDocument.vest.text5.p4'
  ];

  return (
    <>
      <Section>
        <Heading1>
          {i18n('report.finalDocument.vest.header', translationsConfig)}
        </Heading1>
        {vestSectionTexts.map((t, index) => (
          <Paragraph key={index}>{i18n(t, translationsConfig)}</Paragraph>
        ))}
        <Paragraph>
          <ul>
            {vestTextLi.map(key => (
              <li key={key}>{i18n(key, translationsConfig)}</li>
            ))}
          </ul>
        </Paragraph>
        <br />
        <Paragraph>
          {i18n('report.finalDocument.vest.text6', translationsConfig)}{' '}
          <a
            href={i18n(
              'report.finalDocument.vest.text6.link',
              translationsConfig
            )}
          >
            {i18n('report.finalDocument.vest.text6.link', translationsConfig)}
          </a>
        </Paragraph>
        <br />
        <Heading1>
          {i18n(
            'report.finalDocument.responsibleInvestments.header',
            translationsConfig
          )}
        </Heading1>
        <Paragraph>
          {i18n(
            'report.finalDocument.responsibleInvestments.text1',
            translationsConfig
          )}
        </Paragraph>
        <Paragraph>
          <a
            href={i18n(
              'report.finalDocument.responsibleInvestments.link',
              translationsConfig
            )}
          >
            {i18n(
              'report.finalDocument.responsibleInvestments.link',
              translationsConfig
            )}
          </a>
        </Paragraph>
        <br />
        <Heading1>
          {i18n(
            'report.finalDocument.investmentPhilosophy.header',
            translationsConfig
          )}
        </Heading1>
        {investmentPhilosophyTexts.map((t, index) => (
          <Paragraph key={t}>{i18n(t, translationsConfig)}</Paragraph>
        ))}
      </Section>
      <div style={{ pageBreakAfter: 'always' }} />
    </>
  );
};

export default CompanyVest;
