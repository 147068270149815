import * as R from 'ramda';

const getPortfolioItems = R.path(['portfolio', 'items']);

export const usePortfolioItems = ({
  stores: { usePageStore },
  hooks: { useMemo }
}) => {
  const portfolioItems = usePageStore(getPortfolioItems);

  return useMemo(
    () =>
      R.map(
        i => ({
          ...i,
          y: i.weight
        }),
        portfolioItems || []
      ),
    [portfolioItems]
  );
};
