import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import { i18n } from 'i18n/index.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import { creators as userOptionsCreator } from 'features/shared/services/userOptions/actions.js';
import Navigation from 'features/shared/components/screenLayout/navigation';
import FeesChart from 'features/shared/components/fees/index.js';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';

const useStyles = createUseStyles(theme => {
  return {
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '50px',
      paddingBottom: '11px',
      width: '100%'
    },
    subTitle: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.largePx,
      lineHeight: TextFontSizes.largePx,
      color: Colors.grayDark,
      paddingBottom: '40px'
    },

    legend: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '32px 20px',
      width: '100%',
      maxWidth: '600px'
    },
    legendItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '6px 8px 11px 0'
    },
    legendItemInfo: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '&:first-child': {
        paddingRight: '22px'
      }
    },
    legendItemValue: {
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      fontWeight: FontWeights.light,
      color: Colors.nightRiderColor
    },

    legendTotalItem: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '6px 0 11px 34px'
    },
    dot: {
      height: '24px',
      width: '24px',
      minHeight: '24px',
      minWidth: '24px',
      borderRadius: '50%',
      marginRight: '10px'
    },
    smallDot: {
      height: '12px',
      width: '12px',
      minHeight: '12px',
      minWidth: '12px',
      borderRadius: '50%',
      marginRight: '10px'
    },
    legendText: {
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      fontWeight: FontWeights.light,
      textAlign: 'center',
      color: Colors.nightRiderColor
    },

    divider: {
      height: '1px',
      width: '100%',
      backgroundColor: Colors.chardonColor
    },

    darkDivider: {
      height: '1px',
      width: '100%',
      backgroundColor: Colors.black
    },

    speechBubbleContainer: {
      width: '100%',
      padding: '20px 24px 0 24px'
    },

    speechBubble: {
      display: 'flex',
      flexDirection: 'column',
      position: 'relative',
      background: Colors.white,
      borderRadius: '12px',
      width: '100%',
      maxWidth: '600px',
      minHeight: '140px',
      margin: 'auto',
      padding: '20px',
      boxShadow:
        '0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 4px 3px ' +
        Colors.listShadowColor,

      '&::after': {
        content: '""',
        border: '20px solid transparent',
        borderTop: '0px',
        left: '16%',
        top: 0,
        width: 0,
        height: 0,
        position: 'absolute',
        marginLeft: '-20px',
        marginTop: '-20px',
        borderBottomColor: Colors.white
      }
    },
    speechBubbleTitle: {
      fontFamily: theme.headerFontFamily,
      fontSize: TextFontSizes.mediumPx,
      lineHeight: TextFontSizes.mediumLineHeightPx,
      fontWeight: FontWeights.semiBold,
      color: Colors.nightRiderColor,
      paddingBottom: '11px'
    },
    speechBubbleText: {
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.mediumPx,
      lineHeight: TextFontSizes.mediumLineHeightPx,
      color: Colors.matterhornColor
    },
    speechBubbleContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    },
    legendTotalItemWithSmallDots: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '6px 0 11px 22px'
    },
    legendItemWithSmallDots: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '6px 0 11px 0'
    },
    valueAfterFees: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      width: '100%',
      padding: '15px 0'
    },
    screenLayout: {
      minWidth: '100%',
      padding: '48px 0px 5px 0px'
    },
    progressBarContainer: {
      width: 'calc(100% - 48px)'
    }
  };
});

const FeesScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const feesData = useSelector(userOptionsSelectors.getFeesData);

  const dispatch = useDispatch();
  const getFeesData = () => {
    dispatch(userOptionsCreator.getFeesData());
  };

  useEffect(() => {
    getFeesData();
  }, []);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('counseling.fees.naviTitle', translations)}
      </Navigation>

      <Page
        className={classes.screenLayout}
        progressBarContainerClassName={classes.progressBarContainer}
      >
        <div className={classes.content}>
          <span className={classes.subTitle}>
            {i18n('counseling.fees.subTitle', translations)}
          </span>
          <FeesChart />

          <div className={classes.legend}>
            <div className={classes.legendItem}>
              <div className={classes.legendItemInfo}>
                <div
                  className={classes.dot}
                  style={{ backgroundColor: Colors.feesLightBlueGraphColor }}
                />
                <span className={classes.legendText}>
                  {i18n('counseling.fees.legend.ourFees', translations)}
                </span>
              </div>
              <span className={classes.legendItemValue}>0,49%</span>
            </div>

            <div className={classes.divider} />

            <div className={classes.legendItem}>
              <div className={classes.legendItemInfo}>
                <div
                  className={classes.dot}
                  style={{ backgroundColor: Colors.feesLightGreenGraphColor }}
                />
                <span className={classes.legendText}>
                  {i18n('counseling.fees.legend.feesFromFund', translations)}
                </span>
              </div>
              <span className={classes.legendItemValue}>0,20%</span>
            </div>

            <div className={classes.divider} />

            <div className={classes.legendTotalItem}>
              <span className={classes.legendText}>
                {i18n('counseling.fees.legend.totalFees', translations)}
              </span>

              <span className={classes.legendItemValue}>0,69%</span>
            </div>
          </div>

          <div className={classes.speechBubbleContainer}>
            <div className={classes.speechBubble}>
              <span className={classes.speechBubbleTitle}>
                {i18n('counseling.fees.example', translations)}
              </span>

              <div className={classes.speechBubbleContent}>
                <div className={classes.legendItemWithSmallDots}>
                  <div className={classes.legendItemInfo}>
                    <div
                      className={classes.smallDot}
                      style={{
                        backgroundColor: Colors.feesLightBlueGraphColor
                      }}
                    />
                    <span className={classes.legendText}>
                      {i18n('counseling.fees.legend.ourFees', translations)}
                    </span>
                  </div>
                  <span className={classes.legendItemValue}>247</span>
                </div>

                <div className={classes.legendItemWithSmallDots}>
                  <div className={classes.legendItemInfo}>
                    <div
                      className={classes.smallDot}
                      style={{
                        backgroundColor: Colors.feesLightGreenGraphColor
                      }}
                    />
                    <span className={classes.legendText}>
                      {i18n(
                        'counseling.fees.legend.feesFromFund',
                        translations
                      )}
                    </span>
                  </div>
                  <span className={classes.legendItemValue}>110</span>
                </div>

                <div className={classes.darkDivider} />

                <div className={classes.legendTotalItemWithSmallDots}>
                  <span className={classes.legendText}>
                    {i18n('counseling.fees.legend.totalFees', translations)}
                  </span>
                  <span className={classes.legendItemValue}>358</span>
                </div>

                <div className={classes.valueAfterFees}>
                  <div className={classes.legendItemInfo}>
                    <div
                      className={classes.smallDot}
                      style={{ backgroundColor: Colors.feesPureBlueGraphColor }}
                    />
                    <span className={classes.legendText}>
                      {i18n('counseling.fees.valueAfterFees', translations)}
                    </span>
                  </div>
                  <span className={classes.legendItemValue}>123456 </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <Footer>
          {nextRoute && (
            <Button
              type={ButtonType.primary}
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n('shared.continue', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default FeesScreen;
