import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { HistoricalReturnChart } from '@quantfoliorepo/ui-components';

import Paragraph from '../../../shared/components/paragraph';
import Section from 'features/report/shared/components/section';
import { i18n } from 'i18n';
import sessionSelectors from 'features/shared/services/session/selectors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import Card from 'features/report/shared/components/card';
import Heading1 from 'features/report/shared/components/heading1';
import { useReportStore } from 'features/report/shared/services/reportStore';
import HistoricalReturnTable from './table';
import I18n from '../../../../shared/components/i18n';

const useStyles = createUseStyles({
  root: {
    marginTop: '26px'
  },
  card: {
    margin: '26px 0'
  }
});

const HistoricalReturnSection = ({ chartWidth, showGoalIcons }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const cultureCode = useSelector(sessionSelectors.getCultureCode);

  const reportStore = useReportStore();
  const { userData } = reportStore;
  const reportConfig: any = useSelector(sessionSelectors.getReportConfig);

  React.useEffect(() => {
    if (R.isNil(userData?.historicalReturn.chartData)) {
      window.loadedCharts.push('historicalReturn');
    }
  }, [userData?.historicalReturn.chartData]);

  const getChartData = () => {
    const historicalReturn = userData?.historicalReturn.chartData;

    if (!historicalReturn) return [];

    return historicalReturn.map(data => ({
      ...data,
      portfolio: data.portfolio.map(data => ({
        x: new Date(data.date).getTime(),
        y: data.value
      }))
    }));
  };
  const chartData = getChartData();
  const expectedAnnualReturn =
    reportStore?.userData?.chartData[0]?.expectedAnnualReturn.toString();

  return (
    <Section>
      <Heading1>
        {i18n('report.historicalValue.header', translationsConfig)}
      </Heading1>
      {R.isNil(chartData) ? (
        <div>
          {i18n('historicalReturn.historyIsNotLongEnough', translationsConfig)}
        </div>
      ) : (
        <>
          {reportConfig.historicalReturnDescriptions.map(t => (
            <Paragraph key={t}>
              <I18n
                textClassName=""
                text={i18n(t, translationsConfig)}
                replaceParams={{
                  '{exceptedValue}': (
                    <b>{expectedAnnualReturn?.replace('.', ',')}</b>
                  )
                }}
              />
            </Paragraph>
          ))}
          <div>
            {userData?.historicalReturn.chartDescription}
            <br />
          </div>
          <Card className={classes.card}>
            {
              <HistoricalReturnChart
                chartData={chartData}
                cultureCode={cultureCode}
                isInteractive={false}
                onLoad={() => window.loadedCharts.push('historicalReturn')}
                width={chartWidth}
                customColors={userData?.chartColorsConfig.historicalReturn}
                benchmarkIcon={null}
              />
            }
          </Card>

          <HistoricalReturnTable
            goals={userData?.goals}
            keyStatistics={userData?.historicalReturn.keyStatistics}
            showGoalIcons={showGoalIcons}
          />
        </>
      )}
    </Section>
  );
};
export default HistoricalReturnSection;
