import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  head: {
    fill: theme.primaryColor
  }
}));

const Hello = () => {
  const classes = useStyles();

  return (
    <svg
      width="110"
      height="80"
      viewBox="33 0 110 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M110.909 54.1376C110.461 59.6449 106.78 55.1213 101.273 54.6738C95.7656 54.2263 90.5172 58.0244 90.9647 52.5171C91.4121 47.0097 96.2395 42.9079 101.747 43.3554C107.254 43.8029 111.356 48.6302 110.909 54.1376Z"
        fill="#132737"
      />
      <path
        d="M85.3641 52.5235C85.8082 58.0311 81.056 54.2099 75.5484 54.654C70.0407 55.098 65.8634 59.6392 65.4193 54.1316C64.9752 48.624 69.08 43.7992 74.5877 43.3551C80.0953 42.9111 84.9201 47.0159 85.3641 52.5235Z"
        fill="#132737"
      />
      <path
        d="M96.2232 32.7527C96.6005 30.9143 98.2469 29.6142 100.118 29.7533C102.047 29.8966 104.471 30.1397 106.323 30.5349C107.97 30.8863 110.014 31.5391 111.672 32.1144C113.444 32.729 114.441 34.5722 114.064 36.4089C113.636 38.4961 111.59 39.8349 109.596 39.0845C107.958 38.4683 106.521 37.8249 104.896 37.4913C103.202 37.1436 101.303 37.1323 99.2691 37.0001C97.1458 36.8621 95.7954 34.837 96.2232 32.7527Z"
        fill="#132737"
      />
      <path
        d="M79.7719 32.7527C79.3946 30.9143 77.7482 29.6142 75.8767 29.7533C73.9478 29.8966 71.5237 30.1397 69.6716 30.5349C68.0248 30.8863 65.9811 31.5391 64.3227 32.1144C62.5512 32.729 61.5541 34.5722 61.931 36.4089C62.3594 38.4961 64.405 39.8349 66.3991 39.0845C68.0367 38.4683 69.4737 37.8249 71.0993 37.4913C72.7934 37.1436 74.6924 37.1323 76.7261 37.0001C78.8493 36.8621 80.1997 34.837 79.7719 32.7527Z"
        fill="#132737"
      />
      <mask
        id="mask1"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="174"
        height="162"
      >
        <path
          d="M157.491 81.1315H138.719V64.7895V10.1812C138.719 7.48098 137.646 4.89135 135.737 2.98201C133.828 1.07266 131.238 0 128.538 0H47.0881C44.3879 0 41.7982 1.07266 39.8889 2.98201C37.9795 4.89135 36.9069 7.48098 36.9069 10.1812L36.9068 65.2234H24.8167C7.97697 65.2234 0 74.7911 0 91.6309V118.675H16.5444V91.6309C16.5444 86.021 21.1158 81.4496 26.7256 81.4496H36.9068V145.4H26.7256V161.627H61.7236V145.4H51.5423V115.811H124.083V145.4H113.902V161.627H148.9V145.4H138.719V85.5268V81.1315V64.7895H157.491C175.626 64.7895 173.717 72.4254 173.717 91.6309V120.15H157.491V91.6309V81.1315ZM51.5423 15.59H124.083V81.1315H51.5423V15.59Z"
          fill="#900032"
        />
      </mask>
      <g mask="url(#mask1)">
        <path
          d="M217.204 -55.9161C217.204 16.1143 158.812 74.5065 86.7819 74.5065C14.7515 74.5065 -43.6406 16.1143 -43.6406 -55.9161C-43.6406 -127.946 14.7515 -186.339 86.7819 -186.339C158.812 -186.339 217.204 -127.946 217.204 -55.9161Z"
          className={classes.head}
        />
      </g>
    </svg>
  );
};

export default Hello;
