import { takeLatest, put, call, select } from 'redux-saga/effects';
import * as R from 'ramda';

import { types } from './actions.js';
import userOptionSelectors from './selectors.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { creators as notificationActionCreators } from 'features/notification/services/actions.js';
import { NotificationTypes } from 'features/notification/constants/index.js';
import {
  getQAuthAccessToken,
  getRisk,
  getFees
} from 'features/shared/api/index.js';
import {
  startedCreator,
  succeedCreator,
  failedCreator
} from 'features/shared/utils/actions.js';

function* getUserRisk(action) {
  const { isInitialRiskScore } = action.payload;

  yield put(startedCreator(types.GET_USER_RISK));

  try {
    const state = yield select();
    const userOptions = userOptionSelectors.getUserOptions(state);
    const investmentKnowledge =
      R.length(userOptions.investmentKnowledges) > 0
        ? userOptions.investmentKnowledges[0]
        : null;
    const data = {
      horizon: userOptions.horizon,
      risk1: userOptions.risk,
      risk2: userOptions.return,
      drop_in_std: isInitialRiskScore ? null : userOptions.react,
      drop_in_percent: isInitialRiskScore ? null : userOptions.loss,
      experience: isInitialRiskScore ? null : userOptions.trading,
      education_work: isInitialRiskScore ? null : investmentKnowledge
    };
    const customerId = yield select(sessionSelectors.getCustomerId);
    const accessToken = yield call(getQAuthAccessToken, customerId);
    const result = yield call(getRisk, accessToken, undefined, data);
    const { risk } = result.data;

    yield put(
      succeedCreator(types.GET_USER_RISK, {
        riskScore: risk,
        isInitialRiskScore
      })
    );
  } catch (error) {
    yield put(failedCreator(types.GET_USER_RISK, { isInitialRiskScore }));

    yield put(
      notificationActionCreators.showNotification({
        message: 'shared.rickApiErrorMessage',
        type: NotificationTypes.error
      })
    );
  }
}

function* getFeesData() {
  yield put(startedCreator(types.GET_USER_FEES));

  try {
    const result = yield call(getFees);

    yield put(succeedCreator(types.GET_USER_FEES, result));
  } catch (error) {
    yield put(failedCreator(types.GET_USER_FEES));

    yield put(
      notificationActionCreators.showNotification({
        message: 'shared.getFeesDataErrorMessage',
        type: NotificationTypes.error
      })
    );
  }
}

export default function* () {
  yield takeLatest(types.GET_USER_RISK, getUserRisk);
  yield takeLatest(types.GET_USER_FEES, getFeesData);
}
