import * as R from 'ramda';

export const getYearOffset = (horizonsConfig, horizonCategory) => {
  const horizon = R.find(R.propEq('id', horizonCategory))(horizonsConfig);

  return !R.isNil(horizon) ? horizon.analysisProjectionYearsValue : null;
};

export const mapServerForecastToClient = (
  horizonsItems,
  forecast,
  horizon,
  currentDate
) => {
  const yearOffset = getYearOffset(horizonsItems, horizon);
  const monthsOffset = yearOffset * 12;
  const months = currentDate.getFullYear() * 12 + currentDate.getMonth();

  return R.pipe(
    R.map(i => {
      const date = new Date(i.date);

      return {
        value: i.value,
        months: date.getFullYear() * 12 + date.getMonth()
      };
    }),
    R.find(i => i.months - months === monthsOffset),
    R.prop('value')
  )(forecast);
};
