import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { Colors } from 'features/report/shared/constants/colors';
import { FontWeights } from 'features/shared/constants/fonts';
import { i18n } from 'i18n';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatNumber } from 'features/shared/utils/number.js';
import { ReactComponent as CheckIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';

const useStyles = createUseStyles({
  table: {
    borderCollapse: 'collapse',
    width: '100%'
  },
  headerRow: {
    backgroundColor: Colors.gray600
  },
  headerCell: {
    color: Colors.gray50,
    fontSize: 10,
    fontWeight: FontWeights.normal,
    lineHeight: '12px',
    padding: '13px 16px',
    textAlign: 'right',
    width: '33.3%',

    '&:first-child': {
      fontSize: 12,
      lineHeight: '14px',
      padding: '12px 16px',
      textAlign: 'left'
    },
    '&:last-child': {
      textAlign: 'center'
    }
  },
  row: {
    backgroundColor: Colors.gray600,

    '&:nth-child(odd)': {
      backgroundColor: Colors.gray500
    }
  },
  cell: {
    color: Colors.black,
    fontSize: 12,
    lineHeight: '14px',
    padding: '6.5px 16px',
    textAlign: 'right',

    '&:first-child': {
      textAlign: 'left'
    },
    '&:last-child': {
      textAlign: 'center'
    }
  },
  portfolioRow: {
    backgroundColor: '#E0E0ED',

    '& $cell': {
      fontWeight: FontWeights.semiBold,
      '&:first-child': {
        fontWeight: FontWeights.medium
      }
    }
  }
});

const AlignmentTableWeighted = ({
  cultureCode,
  fundAnalysisData,
  goals,
  sustainabilityData,
  totalValue
}) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  const portfolioScore = useMemo(() => {
    return sustainabilityData
      .map(({ data, goalId: id }) => {
        const goal = goals.find(({ goalId }) => goalId === id);
        const value = goal.data.firstDeposit || goal.data.monthlyDeposit || 0;
        return (
          data.portfolioAnalysis.portfolioWeightedSustainabilityScore *
          (value / totalValue)
        );
      })
      .reduce((acc, curr) => acc + curr, 0);
  }, [goals, sustainabilityData, totalValue]);

  const yourScore = useMemo(
    () =>
      sustainabilityData.length > 0
        ? (sustainabilityData[0].data.investorSustainabilityScore as number)
        : 0,
    [sustainabilityData]
  );

  return (
    <table className={classes.table}>
      <thead>
        <tr className={classes.headerRow}>
          <th className={classes.headerCell}>
            {i18n('report.sustainability.fund', translationsConfig)}
          </th>
          <th className={classes.headerCell}>
            {i18n('report.sustainability.score', translationsConfig)}
          </th>
          <th className={classes.headerCell}>
            {i18n('report.sustainability.alignment', translationsConfig)}
          </th>
        </tr>
      </thead>

      <tbody>
        {fundAnalysisData.map(
          ({
            fundStandardName,
            name,
            fundWeightedSustainabilityScore,
            fundAlignmentStatus,
            isin
          }) => (
            <tr className={classes.row} key={isin}>
              <td className={classes.cell}>{fundStandardName || name}</td>

              <td className={classes.cell}>
                {formatNumber(
                  cultureCode,
                  fundWeightedSustainabilityScore,
                  1,
                  1
                )}
              </td>

              <td className={classes.cell}>
                {fundAlignmentStatus ? <CheckIcon /> : <CrossIcon />}
              </td>
            </tr>
          )
        )}
        <tr className={classes.portfolioRow}>
          <td className={classes.cell}>
            {i18n('report.sustainability.portfolioScore', translationsConfig)}
          </td>

          <td className={classes.cell}>
            {formatNumber(cultureCode, portfolioScore, 1, 1)}
          </td>

          <td className={classes.cell}>
            {portfolioScore >= yourScore ? <CheckIcon /> : <CrossIcon />}
          </td>
        </tr>
      </tbody>
    </table>
  );
};

export default AlignmentTableWeighted;
