import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { add, equals, isNil, mergeWithKey, omit } from 'ramda';
import classNames from 'classnames';

import {
  OrderSummaryData,
  ProductAttribute,
  ProductAttributeType
} from 'features/report/shared/services/reportStore';
import sessionSelectors from '../../../../shared/services/session/selectors';
import {
  Table,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell
} from 'features/report/shared/components/table';
import Paragraph from '../../../shared/components/paragraph';
import Section from 'features/report/shared/components/section';
import { i18n } from 'i18n';
import { formatNumber } from 'features/shared/utils/number.js';
import useStyles from './styles';
import { Order } from './types';
import { ReactComponent as CheckIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';

type Props = {
  orderSummaryData: OrderSummaryData[];
  orderSummaryProductAttributes?: ProductAttribute[];
  isPercentColumnEnabled?: boolean;
};

const OneTimeOrders = ({
  orderSummaryData,
  isPercentColumnEnabled,
  orderSummaryProductAttributes
}: Props) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const cultureCode = useSelector(sessionSelectors.getCultureCode);

  const oneTimeOrdersData = useMemo(() => {
    const oneTimeOrdersForGoals = orderSummaryData.map(
      ({ data }) => data.oneTimeOrders
    );

    const buy = oneTimeOrdersForGoals
      .map(({ buy }) => buy)
      .flat()
      .reduce<Order[]>((acc, current) => {
        const index = acc.findIndex(({ id }) => id === current.id);
        if (
          index !== -1 &&
          equals(
            omit(['moneyAmount', 'weight'], acc[index]),
            omit(['moneyAmount', 'weight'], current)
          )
        ) {
          return acc.map((item, i) =>
            i === index
              ? mergeWithKey(
                  (k, l, r) => (k === 'moneyAmount' ? add(l, r) : r),
                  acc[index],
                  current
                )
              : item
          );
        }
        return [...acc, current];
      }, []);

    return { buy };
  }, [orderSummaryData]);

  const buyTotal = useMemo(
    () =>
      oneTimeOrdersData.buy.reduce((acc, curr) => acc + curr.moneyAmount, 0),
    [oneTimeOrdersData.buy]
  );

  return (
    <Section>
      <Paragraph className={classes.spaceBelow}>
        {i18n('report.orderSummary.buyOrder', translationsConfig)}
      </Paragraph>
      <Table className={classes.spaceBelow}>
        <TableHeader className={classes.header}>
          <TableHeaderRow>
            <TableHeaderCell>
              {i18n('costChart.fundsTable.fund', translationsConfig)}
            </TableHeaderCell>
            <TableHeaderCell>
              {i18n('portfolioChart.roboFront.isin', translationsConfig)}
            </TableHeaderCell>
            {orderSummaryProductAttributes?.map(({ label, name, type }) => (
              <TableHeaderCell
                key={name}
                className={classNames({
                  [classes.center]: type === ProductAttributeType.binary
                })}
              >
                {label}
              </TableHeaderCell>
            ))}
            <TableHeaderCell className={classes.alignRight}>
              {i18n('report.shared.amount', translationsConfig)}
            </TableHeaderCell>
            {isPercentColumnEnabled && (
              <TableHeaderCell className={classes.alignRight}>
                %
              </TableHeaderCell>
            )}
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {oneTimeOrdersData.buy.map(
            ({
              FundStandardName,
              name,
              isin,
              moneyAmount,
              id,
              ...orderData
            }) => (
              <TableBodyRow key={JSON.stringify({ id, orderData })}>
                <TableBodyCell>{FundStandardName || name}</TableBodyCell>
                <TableBodyCell>{isin}</TableBodyCell>

                {orderSummaryProductAttributes?.map(({ type, name }) => {
                  if (type === ProductAttributeType.text) {
                    return (
                      <TableBodyCell key={name}>
                        {orderData?.[name]}
                      </TableBodyCell>
                    );
                  }

                  if (type === ProductAttributeType.binary) {
                    return (
                      <TableBodyCell
                        className={classNames(classes.center)}
                        key={name}
                      >
                        {!isNil(orderData?.[name]) ? (
                          orderData[name] ? (
                            <CheckIcon className={classes.checkIcon} />
                          ) : (
                            <CrossIcon className={classes.crossIcon} />
                          )
                        ) : null}
                      </TableBodyCell>
                    );
                  }

                  return null;
                })}

                <TableBodyCell className={classes.alignRight}>
                  {formatNumber(cultureCode, moneyAmount, 0, 0)}
                </TableBodyCell>
                {isPercentColumnEnabled && (
                  <TableBodyCell className={classes.alignRight}>
                    {formatNumber(
                      cultureCode,
                      moneyAmount / (buyTotal / 100),
                      1,
                      1
                    )}
                    %
                  </TableBodyCell>
                )}
              </TableBodyRow>
            )
          )}
          {oneTimeOrdersData.buy.length > 1 && (
            <TableBodyRow>
              <TableBodyCell className={classes.portfolioRow}>
                {i18n('portfolioChart.default.portfolio', translationsConfig)}
              </TableBodyCell>
              <TableBodyCell className={classes.portfolioRow} />

              {orderSummaryProductAttributes?.map(({ name }) => (
                <TableBodyCell key={name} className={classes.portfolioRow} />
              ))}

              <TableBodyCell
                className={classNames(classes.alignRight, classes.portfolioRow)}
              >
                {formatNumber(cultureCode, buyTotal, 0, 0)}
              </TableBodyCell>
              {isPercentColumnEnabled && (
                <TableBodyCell className={classes.portfolioRow} />
              )}
            </TableBodyRow>
          )}
        </TableBody>
      </Table>
    </Section>
  );
};

export default OneTimeOrders;
