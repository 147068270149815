import React, { Fragment } from 'react';
import * as R from 'ramda';
import range from 'lodash.range';

const Chart = ({ className, scenarios, scenarioId, minY, maxY }) => {
  if (maxY < minY) {
    throw Error('maxY is less then minY');
  }
  if (minY > 0) {
    minY = 0;
  }
  if (maxY < 0) {
    maxY = 0;
  }

  const padding = 20;
  const ratio = 1.5;
  const height = padding * 2 + (maxY - minY) * ratio;
  const bandHeight = 17;
  const zeroYChart = padding + maxY * ratio;
  const zeroXChart = 17.7514;
  const zeroYLabel = zeroYChart + 1.4257;
  const zeroXLabel = 18.312;
  const positiveYLabels = range(0, maxY, 10);
  const negativeYLabels = range(0, minY, -10);
  const yLabels = R.uniq([...positiveYLabels, ...negativeYLabels]);

  return (
    <svg
      width="153"
      height={height}
      viewBox={`0 0 153 ${height}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      {R.map(
        i => (
          <rect
            key={i}
            y={bandHeight * i}
            width="153"
            height={bandHeight}
            fill={i % 2 === 0 ? 'white' : '#FAFAF8'}
          />
        ),
        range(0, height / bandHeight)
      )}
      {R.map(l => {
        let x;
        if (l >= 10) {
          x = '6.01974';
        } else if (l >= 0) {
          x = '8.14974';
        } else if (l > -10) {
          x = '6.01974';
        } else {
          x = '4.71974';
        }

        return (
          <text
            key={l}
            x={x}
            y={zeroYLabel - ratio * l}
            style={{ fontSize: '4px', fill: '#474747' }}
          >
            {l}
          </text>
        );
      }, yLabels)}
      {scenarios.map((s, index) => {
        const xOffset = 33;
        const opacity = scenarioId === s.id ? '1' : '0.3';
        const minAbs = Math.abs(s.min);
        const maxAbs = Math.abs(s.max);

        return (
          <Fragment key={s.id}>
            <text
              x={zeroXLabel + index * xOffset}
              y={zeroYChart - ratio * maxAbs - 2}
              opacity={opacity}
              style={{ fontSize: '6px', fill: '#9ECAF2' }}
            >
              {s.max}
            </text>
            <text
              x={zeroXLabel + index * xOffset}
              y={zeroYChart + ratio * minAbs + 7}
              opacity={opacity}
              style={{ fontSize: '6px', fill: '#3D709B' }}
            >
              {s.min}
            </text>
            <rect
              opacity={opacity}
              x={zeroXChart + index * xOffset}
              y={zeroYChart - ratio * maxAbs}
              width="27.0497"
              height={ratio * maxAbs}
              fill="#9ECAF2"
            />
            <rect
              opacity={opacity}
              x={zeroXChart + index * xOffset}
              y={zeroYChart}
              width="27.0497"
              height={ratio * minAbs}
              fill="#3D709B"
            />
          </Fragment>
        );
      })}
    </svg>
  );
};

export default Chart;
