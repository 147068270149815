import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { i18n } from 'i18n/index.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import CreditCardSlider from 'features/onboarding/stopScreen/components/creditCard/slider';
import Worried from 'assets/saving-robo-icons/worried';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute';
import sessionSelectors from 'features/shared/services/session/selectors';
import { useStyles } from 'features/onboarding/stopScreen/components/default/layout';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';

const CreditCardStopScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('creditCard.navTitle', translations)}
      </Navigation>

      <Page>
        <div className={classes.content}>
          <Worried />

          <span className={classes.title}>
            {i18n('creditCard.title', translations)}
          </span>
          <span className={classes.text}>
            {i18n('creditCard.text_2', translations)}
          </span>
        </div>
        <CreditCardSlider translations={translations} />

        <Footer>
          <div className={classes.buttons}>
            <Button
              className={classes.button}
              type={ButtonType.primary}
              isShowStartIcon={true}
              onClick={() => history.push(prevRoute.build(customerId))}
            >
              <span className={classes.buttonText}>
                {i18n('creditCard.adjustValue', translations)}
              </span>
            </Button>

            <Button
              type={ButtonType.primary}
              onClick={() => history.push(nextRoute.build(customerId))}
            >
              <span className={classes.buttonText}>
                {i18n('creditCard.continue', translations)}
              </span>
            </Button>
          </div>
        </Footer>
      </Page>
    </Layout>
  );
};

export default CreditCardStopScreen;
