import { takeEvery, put } from 'redux-saga/effects';

import { types as routerActionTypes } from 'framework/router/services/actions.js';

export function* onLocationChanged(action) {
  const { payload } = action;

  yield put({ type: routerActionTypes.LOCATION_CLEANUP, payload });
  yield put({ type: routerActionTypes.LOCATION_INIT, payload });
}

export default function*() {
  yield takeEvery(routerActionTypes.LOCATION_CHANGED, onLocationChanged);
}
