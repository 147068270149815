import * as R from 'ramda';
import { isPossiblePhoneNumber } from 'react-phone-number-input';

import { createForm as ffCreateForm } from 'features/sharedModules/finalForm/services/finalForm';

export const form = ffCreateForm({
  onSubmit: () => {},
  validate: values => {
    let errors = {};
    const { name, email, phoneNumber } = values;

    if (R.isNil(name) || R.isEmpty(R.trim(name))) {
      errors.name = 'counseling.leaveDetails.emptyNameValidationError';
    }

    if (R.isNil(email) || R.isEmpty(R.trim(email))) {
      errors.email = 'counseling.leaveDetails.emptyEmailValidationError';
    }

    if (R.isNil(phoneNumber) || R.isEmpty(R.trim(phoneNumber))) {
      errors.phoneNumber =
        'counseling.leaveDetails.emptyPhoneNumberValidationError';
    } else if (!isPossiblePhoneNumber(phoneNumber)) {
      errors.phoneNumber =
        'counseling.leaveDetails.invalidPhoneNumberValidationError';
    }

    return errors;
  }
});
