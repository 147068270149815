import { createSelector } from 'reselect';
import * as R from 'ramda';

import { i18n } from 'i18n/index.js';
import { globalizeSelectors } from 'framework/globalize/utils/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { riskScoreToNumber } from 'features/shared/utils/mapping.js';

export const getQuizResults = createSelector(
  state => sessionSelectors.getQuizResultConfig(state),
  state => userOptionSelectors.getKnowledges(state),
  state => sessionSelectors.getAffectFactorsConfig(state),
  state => sessionSelectors.getFundQuizConfig(state),
  (correctAnswers, knowledges, affectFactorsConfig, fundQuizConfig) => {
    return R.map(item => {
      let text;
      if (item.id === 'fundQuiz') {
        const configItem = R.find(R.propEq('id', knowledges.fundQuiz))(
          fundQuizConfig
        );
        text = configItem ? configItem.title : '';
      } else {
        const configItem = R.find(R.propEq('id', knowledges.affectFactors))(
          affectFactorsConfig
        );
        text = configItem ? configItem.title : '';
      }

      return {
        id: item.id,
        title: item.title,
        text: text,
        isCorrect: item.correctAnswerId === knowledges[item.id]
      };
    }, correctAnswers);
  }
);

export const getSavingGoal = createSelector(
  state => userOptionSelectors.getUserOptions(state),
  state => sessionSelectors.getGoalsConfig(state),
  state => sessionSelectors.getTranslationsConfig(state),
  (userOptions, goalsConfig, translations) => {
    return userOptions.goal.id === 'noSpecificGoal'
      ? userOptions.goal.title
      : R.pipe(R.find(R.propEq('id', userOptions.goal.id)), goalConfig =>
          i18n(R.prop('title', goalConfig), translations)
        )(goalsConfig);
  }
);

export const getSummary = createSelector(
  state => userOptionSelectors.getUserFinancialSituation(state),
  state => getSavingGoal(state),
  state => userOptionSelectors.getUserOptions(state),
  state => sessionSelectors.getConfig(state),
  state => getInitialMonthlyDeposit(state),
  (financialSituation, savingGoal, userOptions, config, monthlyDeposit) => {
    const horizon = R.find(R.propEq('id', userOptions.horizon))(
      config.horizons
    );

    return {
      goalTitle: savingGoal,
      countToSave: !R.isNil(monthlyDeposit)
        ? Math.max(0, monthlyDeposit - financialSituation.creditCard)
        : '',
      horizonTitle: !R.isNil(horizon) ? horizon.title : ''
    };
  }
);

export const getNumberOfYearsSaving = createSelector(
  state => userOptionSelectors.getUserOptions(state),
  state => sessionSelectors.getConfig(state),
  state => sessionSelectors.getTranslationsConfig(state),
  (userOptions, config, translations) => {
    const horizon = R.find(R.propEq('id', userOptions.horizon))(
      config.horizons
    );

    return !R.isNil(horizon)
      ? i18n(horizon.yearsNumberTitle, translations)
      : '';
  }
);

export const getDebtToAssetsRatio = createSelector(
  state => userOptionSelectors.getUserFinancialSituation(state),
  state => sessionSelectors.getFinancialSituationConfig(state),
  state => sessionSelectors.getTranslationsConfig(state),
  (financialSituation, financialSituationConfig, translations) => {
    const debtSum = R.pipe(
      R.props(R.map(R.prop('id'), R.prop('debt', financialSituationConfig))),
      R.reject(R.isNil),
      R.sum
    )(financialSituation.debt);
    const assetsSum = R.pipe(
      R.props(R.map(R.prop('id'), R.prop('assets', financialSituationConfig))),
      R.reject(R.isNil),
      R.sum
    )(financialSituation.assets);
    const ratio = R.divide(debtSum, assetsSum) * 100;

    if (ratio >= 90) {
      return {
        id: 'debtOver90',
        isStopped: true,
        text: i18n('stopScreen.debtOver90.text', translations)
      };
    } else if (ratio >= 70 && ratio < 90) {
      return {
        id: 'debt70to90',
        isStopped: false,
        text: i18n('stopScreen.debt70to90.text', translations)
      };
    } else {
      return {
        id: 'debtLess70',
        isStopped: false,
        text: i18n('stopScreen.debtLess70.text', translations)
      };
    }
  }
);

export const getCreditCardDebtCalc = createSelector(
  state => userOptionSelectors.getUserFinancialSituation(state),
  state => sessionSelectors.getTranslationsConfig(state),
  (financialSituation, translations) => {
    const debtCalc = R.divide(
      financialSituation.creditCard,
      financialSituation.left
    );

    if (financialSituation.buffer) {
      if (debtCalc >= 10) {
        return {
          isStopped: true,
          text: i18n('stopScreen.bufferCreditCardOver10.text', translations)
        };
      } else if (debtCalc >= 2 && debtCalc < 10) {
        return {
          isStopped: false,
          text: i18n('stopScreen.bufferCreditCard2to10.text', translations)
        };
      } else {
        return {
          isStopped: false,
          text: i18n('stopScreen.90ofX.text', translations)
        };
      }
    } else {
      if (debtCalc >= 10) {
        return {
          isStopped: true,
          text: i18n('stopScreen.creditCardOver10.text', translations)
        };
      } else if (debtCalc >= 2 && debtCalc < 10) {
        return {
          isStopped: false,
          text: i18n('stopScreen.creditCard2to10.text', translations)
        };
      } else {
        return {
          isStopped: false,
          text: i18n('stopScreen.90ofX.text', translations)
        };
      }
    }
  }
);

export const getInitialMonthlyDeposit = createSelector(
  state => userOptionSelectors.getUserFinancialSituation(state),
  state => sessionSelectors.getFinancialSituationConfig(state),
  (financialSituation, financialSituationConfig) => {
    const debtSum = R.pipe(
      R.props(R.map(R.prop('id'), R.prop('debt', financialSituationConfig))),
      R.reject(R.isNil),
      R.sum
    )(financialSituation.debt);
    const assetsSum = R.pipe(
      R.props(R.map(R.prop('id'), R.prop('assets', financialSituationConfig))),
      R.reject(R.isNil),
      R.sum
    )(financialSituation.assets);
    const ratio = R.divide(debtSum, assetsSum) * 100;

    if (ratio >= 70 && ratio < 90) {
      return Math.round(Number(financialSituation.left * 0.1));
    } else {
      return financialSituation.left;
    }
  }
);

export const getInvestmentFallPercentageByRisk = createSelector(
  state => sessionSelectors.getRiskScoreToInvestmentFallMap(state),
  state => userOptionSelectors.getInitialRiskCategory(state),
  (riskScoreToInvestmentFallMap, initialRiskCategory) => {
    return riskScoreToInvestmentFallMap[initialRiskCategory];
  }
);

const userOptionSelectors = globalizeSelectors(['user_options_state'], {
  getUserOptions: state => state,
  getRiskCategory: state => state.riskCategory,
  getInitialRiskCategory: state => state.initialRiskCategory,
  getUserGoal: state => state.goal,
  getUserFinancialSituation: state => state.financialSituation,
  getKnowledges: state => state.knowledges,
  getCounselingOptions: state => state.counseling,
  getHorizon: state => state.horizon,
  getHorizonYear: state => state.horizonYear,
  getRiskScore: state => {
    return riskScoreToNumber(state.riskCategory);
  },
  getExperienceAndKnowledgeScore: state => {
    return '';
  },
  getFeesData: state => state.feesData
});

export default userOptionSelectors;
