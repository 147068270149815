import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import classNames from 'classnames';

import { Colors } from 'features/shared/constants/colors.js';
import { TextFontSizes } from 'features/shared/constants/fonts.js';

const useStyles = createUseStyles(theme => ({
  root: {
    color: Colors.red50,
    marginTop: '8px',
    fontSize: TextFontSizes.xSmall,
    lineHeight: TextFontSizes.xSmallLineHeight,
    fontFamily: theme.textFontFamily
  }
}));

const ErrorMessage = ({ children, className }) => {
  const classes = useStyles();

  return <div className={classNames(classes.root, className)}>{children}</div>;
};

export default ErrorMessage;
