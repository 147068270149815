import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import PoweredBy from './poweredBy.js';

export const useStyles = createUseStyles({
  fullHeight: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  }
});

const ScreenLayout = ({ children, isPoweredByShown }) => {
  const classes = useStyles();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className={classes.fullHeight}>
      {children}
      {isPoweredByShown && <PoweredBy />}
    </div>
  );
};

ScreenLayout.defaultProps = {
  isPoweredByShown: true
};

export default ScreenLayout;
