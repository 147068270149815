import classNames from 'classnames';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { FontWeights, TextFontSizes } from '../constants/fonts.js';

const useStyles = createUseStyles(theme => ({
  root: {
    fontSize: TextFontSizes.x3,
    lineHeight: TextFontSizes.x3LineHeight,
    fontWeight: FontWeights.bold,
    marginBottom: '13px',
    color: theme.reportSectionTitleColor
  }
}));

const Heading1 = ({ children, className = '' }) => {
  const classes = useStyles();
  return <div className={classNames(classes.root, className)}>{children}</div>;
};

export default Heading1;
