import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { i18n } from 'i18n/index.js';
import SavingRoboAha from 'assets/saving-robo-icons/aha.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import Icon from 'features/shared/components/icon/index';
import { FontWeights, TextFontSizes } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';

const useStyles = createUseStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '50px',
    paddingBottom: '11px'
  },
  title: {
    fontFamily: theme.headerFontFamily,
    fontSize: TextFontSizes.xLargePx,
    lineHeight: TextFontSizes.xLargeLineHeightPx,
    textAlign: 'center',
    color: Colors.grayDark,
    paddingBottom: '15px'
  },
  subTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    fontWeight: FontWeights.bold,
    textAlign: 'center',
    color: Colors.grayDark,
    paddingBottom: '15px'
  },
  text: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    textAlign: 'center'
  },
  icon: {
    marginBottom: '17px'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '320px',
    width: '100%',
    minHeight: '263px',
    background: Colors.white,
    boxShadow:
      '0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 4px 3px ' +
      Colors.listShadowColor,
    borderRadius: '15px',
    margin: '14px auto'
  },
  cardTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    color: Colors.grayDark,
    paddingBottom: '20px'
  },
  cardText: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx
  }
}));

const WhyUse = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('whyUse.naviTitle', translations)}
      </Navigation>

      <Page>
        <div className={classes.content}>
          <SavingRoboAha />
          <span className={classes.title}>
            {i18n('whyUse.title', translations)}
          </span>
          <span className={classes.subTitle}>
            {i18n('whyUse.subTitle', translations)}
          </span>
          <span className={classes.text}>
            {i18n('whyUse.text', translations)}
          </span>
        </div>

        <div className={classes.card}>
          <Icon type="trees" className={classes.icon} />
          <span className={classes.cardTitle}>
            {i18n('whyUse.card.title', translations)}
          </span>
          <span className={classes.cardText}>
            {i18n('whyUse.card.text', translations)}
          </span>
        </div>

        <Footer>
          {nextRoute && (
            <Button
              type={ButtonType.primary}
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n('shared.tryItOut', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default WhyUse;
