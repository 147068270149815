import { isNil } from 'ramda';
import { useSelector } from 'react-redux';

import { FontWeights } from '../../shared/constants/fonts';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell
} from 'features/report/shared/components/table';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Section from '../../shared/components/section';
import Heading1 from '../../shared/components/heading1';
import { i18n } from '../../../../i18n';
import { formatNumber } from 'features/shared/utils/number';

const useStyles = createUseStyles({
  valueCell: {
    fontWeight: FontWeights.semiBold
  },
  answer: {
    '& + &': {
      marginTop: '10px'
    }
  },
  trainingDate: {
    color: '#90A0A6',
    marginTop: '5px'
  }
});

const LiquiditySection = ({ liquidity }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const reportConfig: any = useSelector(sessionSelectors.getReportConfig);

  const valueToDisplay = value => {
    const type = typeof value;

    if (type === 'number') {
      return formatNumber(cultureCode, value);
    }

    if (Array.isArray(value)) {
      return formatNumber(
        cultureCode,
        value.reduce((acc, curr) => acc + (curr.value || 0), 0)
      );
    }

    return value || '';
  };

  return (
    reportConfig.financialSituation?.liquidity.isEnabled && (
      <Section data-testid={'liquidity-section'}>
        <Heading1>
          {i18n('report.liquidity.header', translationsConfig)}
        </Heading1>

        <div className={classes.root}>
          <Table>
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell className={classes.companyTitleColumn}>
                  {i18n(
                    'report.financialSituation.liquidity',
                    translationsConfig
                  )}
                </TableHeaderCell>

                <TableHeaderCell className={classes.companyValueColumn}>
                  {i18n('report.shared.amount', translationsConfig)}
                </TableHeaderCell>
              </TableHeaderRow>
            </TableHeader>

            <TableBody>
              {liquidity.map((i, idx) => (
                <TableBodyRow key={idx}>
                  <TableBodyCell>{i.label}</TableBodyCell>

                  <TableBodyCell className={classes.valueCell}>
                    {valueToDisplay(i.value)}
                  </TableBodyCell>
                </TableBodyRow>
              ))}
            </TableBody>
          </Table>
        </div>
      </Section>
    )
  );
};

export default LiquiditySection;
