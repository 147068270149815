import { createStore, compose, applyMiddleware } from 'redux';
import deepFreeze from 'deep-freeze';
import thunk from 'redux-thunk';
import * as R from 'ramda';

import { reducers } from './reducers.js';
import { sagaMiddleware } from './sagaMiddleware.js';

const isDevelopment = process.env.NODE_ENV === 'development';

const composeEnhancers =
  isDevelopment && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        name: 'SAVING_ROBO',
        stateSanitizer: state =>
          R.isNil(state) ? state : state.__PRIVATE_INTERNAL_STATE__
      })
    : compose;

const rootReducer = (state, action) => {
  if (isDevelopment) {
    state = R.isNil(state) ? state : state.__PRIVATE_INTERNAL_STATE__;
  }

  let newState = reducers(state, action);

  if (isDevelopment) {
    newState = {
      __PRIVATE_INTERNAL_STATE__: newState
    };

    deepFreeze(newState);
  }

  return newState;
};

const middleware = [thunk, sagaMiddleware];
// if (isDevelopment) {
//   middleware = [logger, ...middleware];
// }

const store = createStore(
  rootReducer,
  composeEnhancers(applyMiddleware(...middleware))
);

export default store;
