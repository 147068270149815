import React from 'react';
import { useSelector } from 'react-redux';
import MuiDialog from '@material-ui/core/Dialog';
import create from 'zustand';

import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import DialogContent from 'features/sharedModules/dialog/components/content.js';
import DialogContentText from 'features/sharedModules/dialog/components/contentText.js';

export const useStore = create(set => ({
  isDialogOpen: false,
  openDialog: () => {
    set({
      isDialogOpen: true
    });
  },
  closeDialog: () => {
    set({
      isDialogOpen: false
    });
  }
}));

const Dialog = () => {
  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const store = useStore();

  return (
    <MuiDialog
      open={store.isDialogOpen}
      onClose={() => {
        store.closeDialog();
      }}
    >
      <DialogContent>
        <DialogContentText>
          {i18n('aml2.pepDescription', translations)}
        </DialogContentText>
      </DialogContent>
    </MuiDialog>
  );
};

export default Dialog;
