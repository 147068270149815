import React, { Fragment, useEffect } from 'react';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useHistory, useParams } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import classNames from 'classnames';
import * as R from 'ramda';
import axios from 'axios';
import moment from 'moment';

import { i18n } from 'i18n/index.js';
import { formatNumber } from 'features/shared/utils/number.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import { creators as notificationActionCreators } from 'features/notification/services/actions.js';
import { NotificationTypes } from 'features/notification/constants/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import Navigation from 'features/shared/components/screenLayout/navigation';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import { FontWeights, TextFontSizes } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import ProgressBar from 'features/shared/components/progressBar/index';
import {
  getQAuthAccessToken,
  readPortfolio,
  readSelection,
  readHistoricalReturn
} from 'features/shared/api/index.js';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { usePageStore } from 'features/counseling/backtest/services/pageStore.js';
import {
  mapServerHistoricalReturnToClient,
  mapServerPortfolioItemsToClient
} from '../services/mapping.js';
import BacktestChart from './backtestChart.js';

const useStyles = createUseStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '50px',
    paddingBottom: '11px',
    width: '100%'
  },
  subTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largePx,
    paddingBottom: '40px',
    color: Colors.grayDark
  },
  legend: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    paddingTop: '32px',
    paddingBottom: '32px',
    width: '100%'
  },
  legendItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '&:first-child': {
      paddingRight: '22px'
    }
  },
  firstLegendItem: {
    marginLeft: '17%'
  },
  lastLegendItem: {
    marginRight: '17%'
  },
  dot: {
    height: '24px',
    width: '24px',
    minHeight: '24px',
    minWidth: '24px',
    borderRadius: '50%',
    marginRight: '7px'
  },
  legendText: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: FontWeights.light,
    textAlign: 'center',
    color: Colors.nightRiderColor
  },
  speechBubble: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    background: Colors.white,
    borderRadius: '12px',
    minHeight: '100px',
    margin: '0 20px 0 20px',
    padding: '20px',
    boxShadow:
      '0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 4px 3px ' +
      Colors.listShadowColor,

    '&::after': {
      content: '""',
      border: '20px solid transparent',
      borderTop: '0px',
      left: '16%',
      top: 0,
      width: 0,
      height: 0,
      position: 'absolute',
      marginLeft: '-20px',
      marginTop: '-20px',
      borderBottomColor: Colors.white
    }
  },
  speechBubbleTitle: {
    fontFamily: theme.headerFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: FontWeights.medium,
    color: Colors.gray160,
    paddingBottom: '7px'
  },
  speechBubbleText: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: FontWeights.light,
    color: Colors.gray180
  },
  screenLayout: {
    minWidth: '100%',
    padding: '48px 0px 5px 0px'
  },
  progressBarContainer: {
    width: 'calc(100% - 48px)'
  }
}));

const Backtest = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();
  const { roboPortfolioPrecision } = useCustomerConfig();

  const pageStore = usePageStore();
  const reduxStore = useStore();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const dispatch = useDispatch();

  // TODO: wrap cancelTokenSourceRef, read, readDebounced into reusable hook, also zustand status state, notifications, error handling can be added
  const cancelTokenSourceRef = React.useRef(null);
  const getBacktestData = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const useOptions = userOptionsSelectors.getUserOptions(
      reduxStore.getState()
    );

    try {
      pageStore.setIsReadPortfolioDataPending(true);

      const accessToken = await getQAuthAccessToken(
        customerId,
        cancelTokenSource.token
      );

      const portfolioResponses = await Promise.all([
        readPortfolio(accessToken, cancelTokenSource.token, {
          risk_tolerance: R.prop('riskCategory', useOptions),
          optionals: R.path(['counseling', 'theme'], useOptions),
          precision: roboPortfolioPrecision
        }),
        readSelection(accessToken, cancelTokenSource.token)
      ]);

      let portfolioItems = mapServerPortfolioItemsToClient(
        portfolioResponses[0].data.Portfolio,
        portfolioResponses[1].data.Selection
      );

      if (
        R.length(portfolioItems) <
        R.length(portfolioResponses[0].data.Portfolio)
      ) {
        pageStore.setPortfolioData({
          portfolioTimeseries: null,
          portfolioDetails: null
        });
        pageStore.setIsReadPortfolioDataPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: 'counseling.backtest.portfolioInstrumentsAreMissing',
            type: NotificationTypes.error
          })
        );

        return;
      }

      const historicalReturnResponse = await readHistoricalReturn(
        accessToken,
        cancelTokenSource.token,
        {
          portfolio: portfolioItems
        }
      );

      const { portfolioTimeseries, portfolioDetails } =
        mapServerHistoricalReturnToClient(
          historicalReturnResponse.data.portfolio
        );

      pageStore.setPortfolioData({ portfolioTimeseries, portfolioDetails });
      pageStore.setIsReadPortfolioDataPending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        pageStore.setPortfolioData({
          portfolioTimeseries: null,
          portfolioDetails: null
        });
        pageStore.setIsReadPortfolioDataPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: 'shared.getBacktestDataErrorMessage',
            type: NotificationTypes.error
          })
        );
      }
    }
  };

  useEffect(() => {
    getBacktestData();
  }, []);

  const increase = R.isNil(pageStore.portfolioDetails)
    ? ''
    : `${formatNumber(
        cultureCode,
        pageStore.portfolioDetails.increase * 100
      )}%`;
  const startDate = R.isNil(pageStore.portfolioDetails)
    ? ''
    : moment.utc(pageStore.portfolioDetails.startDate).format('MMM YYYY');

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('counseling.backtest.naviTitle', translations)}
      </Navigation>

      <Page
        className={classes.screenLayout}
        progressBarContainerClassName={classes.progressBarContainer}
      >
        {pageStore.isReadPortfolioDataPending && <ProgressBar />}
        {!pageStore.isReadPortfolioDataPending && (
          <Fragment>
            <div className={classes.content}>
              <span className={classes.subTitle}>
                {i18n('counseling.backtest.subTitle', translations)}
              </span>
              <BacktestChart
                portfolioTimeseries={pageStore.portfolioTimeseries}
              />
              <div className={classes.legend}>
                <div
                  className={classNames(
                    classes.legendItem,
                    classes.firstLegendItem
                  )}
                >
                  <div
                    className={classes.dot}
                    style={{ backgroundColor: Colors.backtestGreenGraphColor }}
                  />
                  <span className={classes.legendText}>
                    {i18n(
                      'counseling.backtest.legend.yourPortfolio',
                      translations
                    )}
                  </span>
                </div>
              </div>
              <div className={classes.speechBubble}>
                <span className={classes.speechBubbleTitle}>
                  {i18n('counseling.backtest.info.title', translations)}
                </span>

                <span className={classes.speechBubbleText}>
                  {i18n('counseling.backtest.info.text', translations)
                    .replace('{0}', increase)
                    .replace('{1}', startDate)}
                </span>
              </div>
            </div>
            <Footer>
              {nextRoute && (
                <Button
                  type={ButtonType.primary}
                  onClick={() => {
                    history.push(nextRoute.build(customerId));
                  }}
                >
                  {i18n('shared.continue', translations)}
                </Button>
              )}
            </Footer>
          </Fragment>
        )}
      </Page>
    </Layout>
  );
};

export default Backtest;
