import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as R from 'ramda';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';

import { i18n } from 'i18n/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import ReportBody from 'features/report/body/components/index';

window.isReportGenerated = false;
window.imagesToLoad = [];
window.loadedImages = [];
window.fontsToLoad = [];
window.loadedFonts = [];
window.chartsToLoad = [];
window.loadedCharts = [];

window.getIsReportGenerated = () => {
  return (
    window.isReportGenerated &&
    window.imagesToLoad.every(
      href => window.loadedImages.indexOf(href) !== -1
    ) &&
    window.fontsToLoad.every(href => window.loadedFonts.indexOf(href) !== -1) &&
    window.chartsToLoad.every(href => window.loadedCharts.indexOf(href) !== -1)
  );
};

const Report = () => {
  const reportStyleSheets = useSelector(sessionSelectors.getReportStyleSheets);

  React.useEffect(() => {
    for (const s of reportStyleSheets) {
      window.fontsToLoad.push(s.href);
    }

    window.isReportGenerated = true;
  }, []);

  return (
    <Fragment>
      <Helmet>
        {R.map(
          s => (
            <link
              key={s.href}
              href={s.href}
              rel="stylesheet"
              onLoad={`loadedFonts.push("${s.href}")`}
            />
          ),
          reportStyleSheets
        )}
      </Helmet>
      <Switch>
        <Route path={routeTemplates.reportBody.config} component={ReportBody} />
      </Switch>
    </Fragment>
  );
};

export default Report;
