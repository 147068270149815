import React from 'react';
import * as R from 'ramda';
import MuiDialog from '@material-ui/core/Dialog';
import MuiInputBase from '@material-ui/core/InputBase';
import { StylesProvider } from '@material-ui/core/styles';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { useSelector } from 'react-redux';
import create from 'zustand';
import classNames from 'classnames';

import { i18n } from 'i18n/index.js';
import { Colors } from 'features/shared/constants/colors.js';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import DialogTitle from 'features/sharedModules/dialog/components/title.js';
import DialogContent from 'features/sharedModules/dialog/components/content.js';
import ErrorMessage from 'features/shared/components/errorMessage.js';

const useInputBaseStyles = createUseStyles(theme => ({
  root: {
    width: '100%'
  },
  input: {
    fontFamily: theme.textFontFamily,
    fontWeight: FontWeights.normal,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,

    '&$invalid': {
      color: Colors.red50
    }
  },
  invalid: {}
}));

const InputBase = ({ isInvalid, ...restProps }) => {
  const classes = R.pipe(
    classes =>
      R.assoc(
        'input',
        classNames(classes.input, { [classes.invalid]: isInvalid }),
        classes
      ),
    R.omit(['invalid'])
  )(useInputBaseStyles());

  return (
    <StylesProvider injectFirst>
      <MuiInputBase classes={classes} {...restProps} />
    </StylesProvider>
  );
};

const useStyles = createUseStyles(theme => ({
  content: {
    minHeight: '100px'
  },
  button: {
    minWidth: '0 !important',
    backgroundColor: `${Colors.white} !important`,
    margin: '0 auto 30px',
    width: '60%'
  }
}));

export const useStore = create((set, get) => ({
  isDialogOpen: false,
  dialogOption: null,
  value: '',
  field: null,
  openDialogForCreate: option => {
    set({ isDialogOpen: true, dialogOption: option, value: '', field: null });
  },
  openDialogForEdit: (option, initialOrigin) => {
    set({
      isDialogOpen: true,
      dialogOption: option,
      value: initialOrigin || '',
      field: null
    });
  },
  dialogWillEnter: () => {},
  dialogExited: () => {
    set({
      dialogOption: null,
      value: '',
      field: null
    });
  },
  inputValueChanged: value => {
    set({ value });
  },
  inputFocused: () => {},
  inputBlurred: () => {
    const { isDialogOpen } = get();

    if (!isDialogOpen) {
      return;
    }

    set({
      field: { touched: true }
    });
  },
  invalidFormSubmitted: () => {
    set({
      field: { touched: true }
    });
  },
  validFormSubmitted: () => {
    set({
      isDialogOpen: false
    });
  },
  formRejected: () => {
    set({
      isDialogOpen: false,
      field: null
    });
  }
}));

const getOriginValidationError = value => {
  if (R.isNil(value) || R.pipe(R.trim, R.isEmpty)(value)) {
    return 'moneyOrigins.other.emptyErrorMessage';
  }

  if (R.pipe(R.trim, R.length)(value) < 5) {
    return 'moneyOrigins.other.minLengthErrorMessage';
  }

  return null;
};

const getIsOriginValid = value => R.isNil(getOriginValidationError(value));

const Dialog = ({ onReject: onRejectProp, onSubmit: onSubmitProp }) => {
  const classes = useStyles();
  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const store = useStore();

  const originValidationError = getOriginValidationError(store.value);
  const originValidationErrorToShow =
    !R.isNil(originValidationError) && R.propOr(false, 'touched', store.field)
      ? i18n(originValidationError, translations)
      : null;

  const onDialogWillEnter = () => {};
  const onDialogExited = () => {
    store.dialogExited();
  };
  const onInputValueChanged = e => {
    const { value } = e.target;
    store.inputValueChanged(value);
  };
  const onInputFocused = () => {};
  const onInputBlurred = () => {
    setTimeout(() => {
      store.inputBlurred();
    }, 100);
  };
  const onFormSubmitted = () => {
    const { value } = useStore.getState();

    if (getIsOriginValid(value)) {
      store.validFormSubmitted();
      !R.isNil(onSubmitProp) && onSubmitProp(store.dialogOption, store.value);
    } else {
      store.invalidFormSubmitted();
    }
  };
  const onFormRejected = () => {
    store.formRejected();
    !R.isNil(onRejectProp) && onRejectProp();
  };

  return (
    <MuiDialog
      open={store.isDialogOpen}
      onEnter={onDialogWillEnter}
      onExited={onDialogExited}
      onClose={onFormRejected}
    >
      <DialogTitle>
        {i18n('aml1.pleaseDescribeTheOriginOfTheMoney', translations)}
      </DialogTitle>
      <DialogContent>
        <div className={classes.content}>
          <InputBase
            autoFocus
            multiline
            value={store.value}
            onChange={onInputValueChanged}
            onFocus={onInputFocused}
            onBlur={onInputBlurred}
            isInvalid={!R.isNil(originValidationErrorToShow)}
          />
          {!R.isNil(originValidationErrorToShow) && (
            <ErrorMessage>{`* ${originValidationErrorToShow}`}</ErrorMessage>
          )}
        </div>
      </DialogContent>

      <Button
        className={classes.button}
        type={ButtonType.primary}
        onClick={onFormSubmitted}
        doShowIcon={false}
      >
        {i18n('shared.ok', translations)}
      </Button>
    </MuiDialog>
  );
};

export default Dialog;
