import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import { i18n } from 'i18n/index.js';
import SavingRoboAha from 'assets/saving-robo-icons/aha.js';
import { getRouteById } from 'features/shared/services/router/selectors.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import Icon from 'features/shared/components/icon/index';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors';
import SlickCarousel from 'features/shared/components/slickCarousel/index.js';
import { FontWeights } from 'features/shared/constants/fonts';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';

const useStyles = createUseStyles(theme => ({
  subTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    textAlign: 'center',
    paddingTop: '15px',
    color: Colors.grayDark
  },
  text: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    textAlign: 'center',
    paddingTop: '19px',
    paddingBottom: '34px'
  },
  card: {
    maxWidth: '320px',
    minHeight: '360px',
    background: Colors.white,
    boxShadow:
      '0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 4px 3px ' +
      Colors.listShadowColor,
    borderRadius: '15px',
    margin: 'auto',
    height: '100%'
  },
  contentCard_1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '20px'
  },
  titleCard_1: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    textAlign: 'center',
    color: Colors.grayDark
  },
  listCard_1: {
    padding: '0',
    '& li': {
      listStyleType: 'none',
      display: 'flex',
      flexDirection: 'row',
      padding: '15px 0',

      '& div': {
        width: '70%',
        marginLeft: '25px',
        display: 'flex',
        flexDirection: 'column',

        '& :first-child': {
          fontFamily: theme.headerFontFamily,
          fontSize: TextFontSizes.smallPx,
          lineHeight: TextFontSizes.smallLineHeightPx,
          fontWeight: FontWeights.light
        },
        '& :last-child': {
          fontFamily: theme.textFontFamily,
          fontSize: TextFontSizes.xxSmallPx,
          lineHeight: TextFontSizes.xxSmallLineHeightPx,
          fontWeight: FontWeights.medium
        }
      }
    }
  },
  card_1_Header: {
    marginBottom: '3px'
  },
  cardContent_2: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px 25px'
  },
  titleCard_2: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    color: Colors.grayDark
  },
  textCard_2: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    paddingTop: '15px',
    fontWeight: FontWeights.light
  },
  cardContent_3: {
    display: 'flex',
    flexDirection: 'column',
    padding: '20px'
  },
  titleCard_3: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    color: Colors.grayDark
  },
  listCard_3: {
    padding: '0',

    '& li': {
      listStyleType: 'none',
      display: 'flex',
      flexDirection: 'row',
      padding: '15px 0',

      '& div': {
        display: 'flex',
        flexDirection: 'column',

        '& :first-child': {
          fontFamily: theme.headerFontFamily,
          fontSize: TextFontSizes.smallPx,
          lineHeight: TextFontSizes.smallLineHeightPx,
          fontWeight: FontWeights.medium
        },
        '& :last-child': {
          fontFamily: theme.textFontFamily,
          fontSize: TextFontSizes.smallPx,
          lineHeight: TextFontSizes.smallLineHeightPx,
          fontWeight: FontWeights.light
        }
      }
    }
  },
  card_3_Header: {
    marginBottom: '3px'
  },
  listItemNumber: {
    fontFamily: theme.headerFontFamily,
    fontSize: TextFontSizes.xLargePx,
    lineHeight: TextFontSizes.xLargeLineHeightPx,
    color: theme.imagePrimaryColor,
    flex: '0 0 25px',
    marginRight: '25px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '50px'
  },
  divider: {
    height: '1px',
    backgroundColor: theme.placeholderColor
  },
  cardContainer: {
    padding: '14px',
    height: '100%'
  },
  listIcon: {
    flex: '0 0 50px'
  }
}));

const WhatIsFundScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = useSelector(getRouteById);
  const translations = useSelector(sessionSelectors.getTranslationsConfig);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('whatIsFundScreen.naviTitle', translations)}
      </Navigation>

      <Page>
        <div className={classes.content}>
          <SavingRoboAha />
          <span className={classes.subTitle}>
            {i18n('whatIsFundScreen.subTitle', translations)}
          </span>
          <span className={classes.text}>
            {i18n('whatIsFundScreen.text', translations)}
          </span>
        </div>

        <SlickCarousel>
          <div>
            <div className={classes.cardContainer}>
              <div className={classes.card}>
                <div className={classes.contentCard_1}>
                  <span className={classes.titleCard_1}>
                    {i18n('whatIsFundScreen.card_1.title', translations)}
                  </span>
                  <ul className={classes.listCard_1}>
                    <li>
                      <Icon
                        className={classes.listIcon}
                        type="fundBankBuilding"
                      />
                      <div>
                        <span className={classes.card_1_Header}>
                          {i18n(
                            'whatIsFundScreen.card_1.item_1.title',
                            translations
                          )}
                        </span>
                        <span>
                          {i18n(
                            'whatIsFundScreen.card_1.item_1.text',
                            translations
                          )}
                        </span>
                      </div>
                    </li>
                    <div className={classes.divider} />
                    <li>
                      <Icon
                        className={classes.listIcon}
                        type="fundNewDocument"
                      />
                      <div>
                        <span className={classes.card_1_Header}>
                          {i18n(
                            'whatIsFundScreen.card_1.item_2.title',
                            translations
                          )}
                        </span>
                        <span>
                          {i18n(
                            'whatIsFundScreen.card_1.item_2.text',
                            translations
                          )}
                        </span>
                      </div>
                    </li>
                    <div className={classes.divider} />
                    <li>
                      <Icon className={classes.listIcon} type="fundRefund" />
                      <div>
                        <span className={classes.card_1_Header}>
                          {i18n(
                            'whatIsFundScreen.card_1.item_3.title',
                            translations
                          )}
                        </span>
                        <span>
                          {i18n(
                            'whatIsFundScreen.card_1.item_3.text',
                            translations
                          )}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.cardContainer}>
              <div className={classes.card}>
                <div className={classes.cardContent_2}>
                  <span className={classes.titleCard_2}>
                    {i18n('whatIsFundScreen.card_2.title', translations)}
                  </span>
                  <span className={classes.textCard_2}>
                    {i18n('whatIsFundScreen.card_2.text_1', translations)}
                  </span>
                  <span className={classes.textCard_2}>
                    {i18n('whatIsFundScreen.card_2.text_2', translations)}
                  </span>
                  <span className={classes.textCard_2}>
                    {i18n('whatIsFundScreen.card_2.text_3', translations)}
                  </span>
                </div>
              </div>
            </div>
          </div>
          <div>
            <div className={classes.cardContainer}>
              <div className={classes.card}>
                <div className={classes.cardContent_3}>
                  <span className={classes.titleCard_3}>
                    {i18n('whatIsFundScreen.card_3.title', translations)}
                  </span>
                  <ul className={classes.listCard_3}>
                    <li>
                      <span className={classes.listItemNumber}>1</span>
                      <div>
                        <span className={classes.card_3_Header}>
                          {i18n(
                            'whatIsFundScreen.card_3.item_1.title',
                            translations
                          )}
                        </span>
                        <span>
                          {i18n(
                            'whatIsFundScreen.card_3.item_1.text',
                            translations
                          )}
                        </span>
                      </div>
                    </li>
                    <div className={classes.divider} />
                    <li>
                      <span className={classes.listItemNumber}>2</span>
                      <div>
                        <span className={classes.card_3_Header}>
                          {i18n(
                            'whatIsFundScreen.card_3.item_2.title',
                            translations
                          )}
                        </span>
                        <span>
                          {i18n(
                            'whatIsFundScreen.card_3.item_2.text',
                            translations
                          )}
                        </span>
                      </div>
                    </li>
                    <div className={classes.divider} />
                    <li>
                      <span className={classes.listItemNumber}>3</span>
                      <div>
                        <span className={classes.card_3_Header}>
                          {i18n(
                            'whatIsFundScreen.card_3.item_3.title',
                            translations
                          )}
                        </span>
                        <span>
                          {i18n(
                            'whatIsFundScreen.card_3.item_3.text',
                            translations
                          )}
                        </span>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </SlickCarousel>

        <Footer>
          {prevRoute && (
            <Button
              type={ButtonType.primary}
              onClick={() => {
                history.push(prevRoute.build(customerId));
              }}
            >
              {i18n('shared.continue', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default WhatIsFundScreen;
