import t from 'tcomb';

import createStatusReducer, {
  Status,
  getStatus
} from 'features/shared/services/status/reducer.js';
import { createReducer } from 'framework/tcomb/utils.js';
import { types as userOptionsActionTypes } from 'features/shared/services/userOptions/actions.js';
import { types as sessionActionTypes } from 'features/shared/services/session/actions.js';

const StatusesState = t.interface(
  {
    riskScore_state: t.maybe(Status)
  },
  'StatusesState'
);

const Reducer = createReducer(StatusesState);

const reducer = Reducer.of(
  createStatusReducer({
    riskScore_state: getStatus(userOptionsActionTypes.GET_USER_RISK)
  })
);

export default reducer;
