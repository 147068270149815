import * as R from 'ramda';
import axios from 'axios';
import { v1 } from 'uuid';
import cookies from 'js-cookie';

import config from 'config/index.js';
import { EventTypes } from '../constants';
import { MetricsQueue } from './queue.js';
import { runMetricsQueueHandler as runMetricsQueueHandlerInternal } from './handler.js';

export const metricsQueue = new MetricsQueue();

export const createPageVisitedEvent = (customerId, pageId) => ({
  timestamp: new Date().toISOString(),
  customer_id: customerId,
  session_id: getSessionId(),
  type: EventTypes.pageVisited,
  page_id: pageId
});

export const createSessionCompletedEvent = customerId => ({
  timestamp: new Date().toISOString(),
  customer_id: customerId,
  session_id: getSessionId(),
  type: EventTypes.sessionCompleted
});

export const runMetricsQueueHandler = () => {
  runMetricsQueueHandlerInternal({
    queue: metricsQueue,
    batchInterval: 10000,
    sendMetrics: data => {
      const text = JSON.stringify(data);
      const url = `${config.REACT_APP_METRICS_API_URL}/client-events/`;

      try {
        if (!R.isNil(navigator.sendBeacon)) {
          navigator.sendBeacon(url, text);
        } else {
          axios.post(url, text, {
            headers: {
              'Content-Type': 'text/plain; charset=utf-8'
            }
          });
        }
      } catch (error) {}
    },
    onSessionEnd: callback => {
      document.addEventListener('visibilitychange', function () {
        if (document.visibilityState === 'hidden') {
          callback();
        }
      });

      window.addEventListener('pagehide', function () {
        callback();
      });
    }
  });
};

const getSessionId = () => {
  const sessionIdKey = 'metrics-session-id';
  let sessionIdValue = cookies.get(sessionIdKey);

  if (R.isNil(sessionIdValue)) {
    sessionIdValue = v1();
    cookies.set(sessionIdKey, sessionIdValue);
  }

  return sessionIdValue;
};
