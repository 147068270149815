import * as R from 'ramda';

export class MetricsQueue {
  constructor(settings = {}) {
    this.settings = settings;
    this.items = [];
  }

  enqueue(element) {
    this.items = R.append(element, this.items);
  }

  dequeue() {
    const head = R.head(this.items);
    this.items = R.tail(this.items);
    return head;
  }

  dequeueAll() {
    const items = this.items;
    this.items = [];
    return items;
  }
}
