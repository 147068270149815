import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { FontWeights, TextFontSizes } from 'features/shared/constants/fonts';

export const useStyles = createUseStyles(theme => {
  return {
    title: {
      fontFamily: theme.headerFontFamily,
      fontSize: TextFontSizes.xLargePx,
      lineHeight: TextFontSizes.xLargeLineHeightPx,
      textAlign: 'center',
      paddingTop: '20px'
    },
    subTitle: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.mediumPx,
      lineHeight: TextFontSizes.mediumLineHeightPx,
      fontWeight: FontWeights.semiBold,
      textAlign: 'center',
      paddingTop: '20px'
    },
    text: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      fontWeight: FontWeights.light,
      textAlign: 'center',
      paddingTop: '20px'
    },
    link: {
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.mediumPx,
      lineHeight: TextFontSizes.mediumLineHeightPx,
      fontWeight: FontWeights.semiBold,
      textAlign: 'center',
      padding: '13px 0'
    },
    orangeText: {
      color: theme.primaryColor
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1',
      paddingTop: '30px'
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center'
    },
    button: {
      marginBottom: '10px !important'
    }
  };
});
