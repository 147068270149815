import React from 'react';

import Section from 'features/report/shared/components/section';
import Heading1 from 'features/report/shared/components/heading1';
import Heading2 from 'features/report/shared/components/heading2';
import Paragraph from 'features/report/shared/components/paragraph';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { FontWeights } from 'features/report/shared/constants/fonts';

const useStyles = createUseStyles({
  sectionHeading: {
    fontWeight: FontWeights.bold
  }
});

const Attachment = ({ heading, sections }) => {
  const classes = useStyles();

  return (
    <>
      <div style={{ pageBreakAfter: 'always' }} />
      <Heading1>{heading}</Heading1>
      {sections.map(({ title, text }) => (
        <Section key={title}>
          <Heading2 className={classes.sectionHeading}>{title}</Heading2>
          <Paragraph>{text}</Paragraph>
        </Section>
      ))}
    </>
  );
};

export default Attachment;
