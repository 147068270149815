import config from 'config/index.js';
import { savingRoboApi } from 'features/shared/utils/axios.js';

export const createPdfReport = (
  accessToken,
  cancelToken,
  customerId,
  sourceParams
) => {
  return savingRoboApi.post(
    `/pdf-report/`,
    {
      pdf_report_config: {
        sandbox: `${config.REACT_APP_PDF_SHIDT_API_SANDBOX}` === 'on',
        landscape: false,
        format: 'A4',
        zoom: 1,
        margin: 0,
        use_print: true,
        wait_for: 'getIsReportGenerated'
      },
      pdf_report_settings: sourceParams,
      pdf_report_source: `${config.REACT_APP_REPORT_FRONT_URL}/report/${customerId}/body`,
      customer_id: customerId
    },
    {
      cancelToken,
      responseType: 'blob',
      headers: {
        Authorization: accessToken,
        'Content-Type': 'application/json'
      }
    }
  );
};
