import * as R from 'ramda';

// init.js must be first imported module in order to set up global configuration before other modules initialized
import './init.js';
import './polyfill.js';
import store from './store.js';
import { sagaMiddleware } from './sagaMiddleware.js';
import { sagas } from './sagas.js';
import { creators as applicationActionCreators } from 'features/shared/services/application/actions.js';
import { initAxios } from './axios.js';
import { getRouteBase } from 'features/shared/services/router/selectors.js';

const route = getRouteBase(window.location.pathname, false);

const customerId = R.isNil(route.route.id)
  ? null
  : route.match.params.customerId;

initAxios(store);

sagaMiddleware.run(sagas);

store.dispatch(applicationActionCreators.started(customerId));
