import React from 'react';
import { injectSheet } from 'features/sharedModules/styles/components/styles.js';

const styles = {
  page: {
    display: 'flex',
    justifyContent: 'center',
    padding: '4rem 0'
  }
};

const PageNotFound = ({ classes, text }) => {
  return (
    <div className={classes.page}>
      <span>{text}</span>
    </div>
  );
};

export default injectSheet(styles)(PageNotFound);
