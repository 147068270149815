import React from 'react';
import * as R from 'ramda';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import { useTheme } from 'features/sharedModules/styles/components/styles.js';
import { Colors } from 'features/shared/constants/colors.js';

const useStyles = createUseStyles({
  highcharts: {
    '& svg': {
      width: '100%'
    }
  }
});

const options = {
  credits: {
    enabled: false
  },
  chart: {
    type: 'spline',
    backgroundColor: 'transparent',
    plotBackgroundColor: null,
    plotBorderWidth: 0,
    plotShadow: false,
    width: '120',
    height: '30',
    spacingTop: 0,
    spacingBottom: 0,
    spacingLeft: 0,
    spacingRight: 0,
    animation: false
  },
  title: {
    text: null
  },
  legend: {
    enabled: false
  },
  xAxis: {
    labels: {
      enabled: false
    },
    tickWidth: 0,
    lineWidth: 0
  },
  yAxis: {
    labels: {
      enabled: false
    },
    title: {
      text: null
    }
  },
  tooltip: {
    enabled: false
  },
  plotOptions: {
    series: {
      enableMouseTracking: false,
      animation: false
    },
    spline: {
      lineWidth: 2,
      states: {
        hover: {
          lineWidth: 5
        }
      },
      marker: {
        enabled: false
      }
    }
  },
  series: []
};

const BasicLineChart = ({ data }) => {
  const classes = useStyles();
  const theme = useTheme();
  const chartOptions = R.set(
    R.lensPath(['series', 0]),
    { data, color: theme.primaryColor },
    options
  );

  return (
    <div className={classes.highcharts}>
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { width: '100%' } }}
        options={chartOptions}
      />
    </div>
  );
};

export default BasicLineChart;
