import React from 'react';
import { useSelector } from 'react-redux';

import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Paragraph from '../../shared/components/paragraph';
import Heading2 from '../../shared/components/heading2';
import Section from '../../shared/components/section.js';

const DisclamerSection = () => {
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  return (
    <Section>
      <Heading2>{i18n('report.disclamer.header', translationsConfig)}</Heading2>
      <Paragraph>
        {i18n('report.disclamer.text_1', translationsConfig)}
      </Paragraph>
      <Paragraph>
        {i18n('report.disclamer.text_2', translationsConfig)}
      </Paragraph>
      <Paragraph>
        {i18n('report.disclamer.text_3', translationsConfig)}
      </Paragraph>
      <Paragraph>
        {i18n('report.disclamer.text_4', translationsConfig)}
      </Paragraph>
    </Section>
  );
};

export default DisclamerSection;
