import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { useParams, useHistory } from 'react-router';
import * as R from 'ramda';
import InputBase from '@material-ui/core/InputBase';
import NumberFormat from 'react-number-format';

import { i18n } from 'i18n/index.js';
import { getNumberInputFormat } from 'features/shared/utils/number.js';
import { getCreditCardNextRouteConfig } from 'features/shared/services/router/selectors.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import { creators as userOptionsCreator } from 'features/shared/services/userOptions/actions.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import Slider from 'features/shared/components/slider/index.js';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import { FontWeights } from 'features/shared/constants/fonts';
import { Colors } from 'features/shared/constants/colors';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';

const useStyles = createUseStyles(theme => ({
  subTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    textAlign: 'center',
    paddingTop: '50px',
    paddingBottom: '20px',
    color: Colors.grayDark
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },
  inputContainer: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    width: '100%'
  },
  textField: {
    width: '100%',
    maxWidth: '400px',
    '& input': {
      fontWeight: FontWeights.semiBold,
      fontSize: TextFontSizes.xxLargePx,
      lineHeight: TextFontSizes.xxLargeLineHeightPx,
      textAlign: 'center',
      color: theme.primaryColor
    }
  }
}));

const CreditCard = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute(getCreditCardNextRouteConfig);

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const financialSituationConfig = useSelector(
    sessionSelectors.getFinancialSituationConfig
  );
  const userFinancialSituation = useSelector(
    userOptionsSelectors.getUserFinancialSituation
  );
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);
  const dispatch = useDispatch();
  const userFinancialSituationCreditCardChanged = (type, value) => {
    dispatch(
      userOptionsCreator.userFinancialSituationCreditCardChanged({
        type,
        value
      })
    );
  };

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('financialSituation.creditCard.naviTitle', translations)}
      </Navigation>

      <Page>
        <div className={classes.content}>
          <span className={classes.subTitle}>
            {i18n('financialSituation.creditCard.subTitle', translations)}
          </span>
        </div>

        <div className={classes.content}>
          <div className={classes.inputContainer}>
            <NumberFormat
              customInput={InputBase}
              value={
                R.isNil(userFinancialSituation.creditCard)
                  ? ''
                  : userFinancialSituation.creditCard
              }
              onValueChange={values => {
                const { min, max } = financialSituationConfig.creditCard;
                const value = R.isNil(values.floatValue)
                  ? values.floatValue
                  : R.pipe(v => Math.max(v, min))(values.floatValue);

                userFinancialSituationCreditCardChanged('left', value);
              }}
              thousandSeparator={thousandSeparator}
              decimalSeparator={decimalSeparator}
              allowedDecimalSeparators={[',', '.']}
              decimalScale={2}
              allowNegative={false}
              allowEmptyFormatting={true}
              className={classes.textField}
            />
          </div>

          <Slider
            options={financialSituationConfig.creditCard}
            userOption={userFinancialSituation.creditCard}
            type="creditCard"
            onChange={(type, value) => {
              userFinancialSituationCreditCardChanged(type, value);
            }}
            displayTitle={false}
            displayValue={false}
          />
        </div>

        <Footer>
          {nextRoute && (
            <Button
              disabled={R.isNil(userFinancialSituation.creditCard)}
              type={ButtonType.primary}
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n('shared.continue', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default CreditCard;
