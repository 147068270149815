import { savingRoboApi } from 'features/shared/utils/axios.js';

export const createSignicatSession = (accessToken, cancelToken, data) =>
  savingRoboApi.post('/signicat/sessions', data, {
    cancelToken,
    headers: { Authorization: accessToken }
  });

export const getSignicatSession = (accessToken, cancelToken, id) =>
  savingRoboApi.get(`/signicat/sessions/${id}`, {
    cancelToken,
    headers: { Authorization: accessToken }
  });
