import React from 'react';

import { buildPublicFileUrl } from 'features/shared/utils/axios.js';

const ExternalIcon = ({ rest, className, type }) => {
  return (
    <img
      {...rest}
      className={className}
      src={buildPublicFileUrl(type)}
      alt="icon"
    />
  );
};

export default ExternalIcon;
