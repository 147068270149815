import React from 'react';
import { injectSheet } from 'features/sharedModules/styles/components/styles.js';
import classNames from 'classnames';
import * as R from 'ramda';
import { connect } from 'react-redux';

import Checkmark from 'assets/icons/checkmark.js';
import { Colors } from 'features/shared/constants/colors.js';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import ExternalIcon from 'features/shared/components/icon/externalIcon.js';
import { FontWeights } from 'features/shared/constants/fonts';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { i18n } from 'i18n/index.js';

const styles = theme => {
  return {
    layoutColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },

    list: {
      display: 'grid',
      gridAutoRows: '1fr',
      gridTemplateColumns: 'repeat(2, 1fr)',
      gridGap: '20px'
    },

    card: {
      background: Colors.white,
      boxShadow:
        '0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 4px 3px ' +
        Colors.listShadowColor,
      borderRadius: '15px',
      position: 'relative'
    },

    cardMediumSize: {
      maxWidth: '150px',
      width: '145px',
      minHeight: '178px'
    },

    cardSmallSize: {
      maxWidth: '145px',
      width: '145px',
      minHeight: '130px'
    },

    iconContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center'
    },

    iconContainerSmallSize: {
      maxWidth: '145px',
      minHeight: '90px'
    },

    iconContainerMediumSize: {
      maxWidth: '150px',
      minHeight: '130px'
    },

    iconMediumSize: {
      borderRadius: '15px',
      width: '100%',
      height: 'auto'
    },

    iconSmallSize: {
      width: '45px',
      height: '45px'
    },

    cardText: {
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      textAlign: 'center',
      margin: '0 10px 5px 10px'
    },

    cardSmallTexts: {
      margin: '0 10px 10px 10px'
    },

    cardSmallText: {
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.xxSmallPx,
      lineHeight: TextFontSizes.xxSmallLineHeightPx,
      fontWeight: FontWeights.medium,
      textAlign: 'center'
    },

    checkmarkIcon: {
      position: 'absolute',
      right: '-5px',
      top: '-5px'
    }
  };
};

export const CardSize = {
  small: 'small',
  medium: 'medium',
  large: 'large'
};

const getCardSizeClassName = (size, classes) => {
  switch (size) {
    case CardSize.small:
      return classes.cardSmallSize;
    case CardSize.medium:
      return classes.cardMediumSize;
    default:
      return classes.cardMediumSize;
  }
};

const getIconContainerSizeClassName = (size, classes) => {
  switch (size) {
    case CardSize.small:
      return classes.iconContainerSmallSize;
    case CardSize.medium:
      return classes.iconContainerMediumSize;
    default:
      return classes.iconContainerMediumSize;
  }
};

const getIconSizeClassName = (size, classes) => {
  switch (size) {
    case CardSize.small:
      return classes.iconSmallSize;
    case CardSize.medium:
      return classes.iconMediumSize;
    default:
      return classes.iconMediumSize;
  }
};

const TileInput = ({
  className,
  classes,
  size,
  multiple,
  translations,
  options,
  selectedOption,
  onSelect,
  userOptionType
}) => {
  if (R.isNil(options) || R.isEmpty(options)) {
    return null;
  }

  return (
    <div className={classNames(classes.list, className)}>
      {R.map(i => {
        const itemComponent = i.component;

        return (
          <div
            key={i.id}
            className={classNames(
              classes.card,
              classes.layoutColumn,
              getCardSizeClassName(size, classes)
            )}
            onClick={() => onSelect(userOptionType, i.id)}
          >
            <div
              className={classNames(
                classes.iconContainer,
                getIconContainerSizeClassName(size, classes)
              )}
            >
              {i.icon && (
                <ExternalIcon
                  className={classNames(getIconSizeClassName(size, classes))}
                  type={i.icon}
                />
              )}
              {itemComponent && (
                <itemComponent.type
                  {...itemComponent.props}
                  className={classNames(
                    getIconSizeClassName(size, classes),
                    itemComponent.props.className
                  )}
                />
              )}
            </div>

            <div className={classes.cardText}>
              {i18n(i.title, translations)}
            </div>

            <div className={classes.cardSmallTexts}>
              {!R.isNil(i.texts) && !R.isEmpty(i.texts)
                ? i.texts.map((text, index) => {
                    return (
                      <div key={index} className={classes.cardSmallText}>
                        {i18n(text, translations)}
                      </div>
                    );
                  })
                : null}
            </div>

            {multiple ? (
              R.indexOf(i.id, selectedOption) !== -1 ? (
                <Checkmark className={classes.checkmarkIcon} />
              ) : null
            ) : i.id === selectedOption ? (
              <Checkmark className={classes.checkmarkIcon} />
            ) : null}
          </div>
        );
      }, options)}
    </div>
  );
};

export default R.compose(
  connect(state => {
    const translations = sessionSelectors.getTranslationsConfig(state);

    return {
      translations
    };
  }),
  injectSheet(styles)
)(TileInput);
