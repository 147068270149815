import React from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'ramda';

import sessionSelectors from 'features/shared/services/session/selectors';
import Heading2 from 'features/report/shared/components/heading2';
import ChartTable from '../../../../shared/components/chartTable';
import { i18n } from 'i18n';
import useStyles from './styles';

const CustodyFee = ({ data }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  const custodyFees = React.useMemo(() => {
    const currency = data.currency.custodyFees.map(el => ({
      fund: el.fund,
      currency: el.fundCustodyFee
    }));
    const percent = data.percent.custodyFees.map(el => ({
      fund: el.fund,
      percent: el.fundCustodyFee
    }));

    return currency.map(c => ({
      fund: c.fund,
      currency: c.currency,
      perc: percent.find(p => p.fund === c.fund)?.percent
    }));
  }, [data]);

  return (
    custodyFees
      .slice(0, -1)
      .every(item =>
        Object.entries(item).some(
          ([key, value]) => key !== 'fund' && !isNil(value)
        )
      ) && (
      <div className={classes.section}>
        <Heading2>
          {i18n('costChart.costTable.custodyFee', translationsConfig)}
        </Heading2>

        <ChartTable
          customClasses={{
            headerCell: classes.headerCell,
            cell: classes.cell
          }}
          headerTitles={[
            i18n('costChart.fundsTable.fund', translationsConfig),
            i18n('report.shared.amount', translationsConfig),
            '%'
          ]}
          data={custodyFees}
          dataKeys={['fund', 'currency', 'perc']}
          highlightLastRow
        />
      </div>
    )
  );
};

export default CustodyFee;
