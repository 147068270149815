import React from 'react';
import * as R from 'ramda';
import { useDispatch, useSelector, useStore } from 'react-redux';
import { useParams } from 'react-router';
import axios from 'axios';

import { throwSafeError } from 'features/shared/utils/throwSafeError.js';
import {
  getPortfolioDataFromServerDataByConfig,
  getPortfolioDataSourcesByConfig
} from 'features/sharedModules/portfolioFunds/services/selectors.js';
import { creators as notificationActionCreators } from 'features/notification/services/actions.js';
import { NotificationTypes } from 'features/notification/constants/index.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import {
  getQAuthAccessToken,
  readPortfolio,
  readSelection,
  readFunds
} from 'features/shared/api/index.js';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { usePageStore } from 'features/counseling/portfolioFunds/services/pageStore.js';
import { defaultTheme } from 'defaults/defaultTheme';

export function useReadPortfolios() {
  const { customerId } = useParams();
  const { roboPortfolioPrecision } = useCustomerConfig();

  const pageStore = usePageStore();
  const reduxStore = useStore();

  const config = useSelector(sessionSelectors.getConfig);
  const portfolioFundsConfig = useSelector(
    sessionSelectors.getPortfolioFundsConfig
  );
  const dispatch = useDispatch();

  // TODO: wrap cancelTokenSourceRef, read, readDebounced into reusable hook, also zustand status state, notifications, error handling can be added
  const cancelTokenSourceRef = React.useRef(null);
  const readPortfolios = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    const userOptions = userOptionsSelectors.getUserOptions(
      reduxStore.getState()
    );
    const dataSources = getPortfolioDataSourcesByConfig(portfolioFundsConfig);

    try {
      pageStore.setIsReadPortfolioPending(true);

      const accessToken = await getQAuthAccessToken(
        customerId,
        cancelTokenSource.token
      );
      const responses = await Promise.all([
        dataSources.portfolio
          ? readPortfolio(accessToken, cancelTokenSource.token, {
              risk_tolerance: R.prop('riskCategory', userOptions),
              optionals: R.path(['counseling', 'theme'], userOptions),
              precision: roboPortfolioPrecision
            })
          : null,
        dataSources.selection
          ? readSelection(accessToken, cancelTokenSource.token)
          : null,
        dataSources.funds
          ? readFunds(accessToken, cancelTokenSource.token)
          : null
      ]);

      const portfolio = getPortfolioDataFromServerDataByConfig(
        portfolioFundsConfig,
        R.path([0, 'data', 'Portfolio'], responses),
        R.path([1, 'data', 'Selection'], responses),
        R.path([2, 'data', 'funds'], responses),
        R.pathOr(
          defaultTheme.chartPortfolioColors,
          ['styles', 'chartPortfolioColors'],
          config
        )
      );

      pageStore.setPortfolio(portfolio);
      pageStore.setIsReadPortfolioPending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        pageStore.setPortfolio(null);
        pageStore.setIsReadPortfolioPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: 'shared.getPortfoliosDataErrorMessage',
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return readPortfolios;
}
