import classNames from 'classnames';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  root: {
    '& + &': {
      marginTop: '13px'
    }
  }
}));

const Paragraph = ({ children, className = {} }) => {
  const classes = useStyles();

  return <div className={classNames(classes.root, className)}>{children}</div>;
};

export default Paragraph;
