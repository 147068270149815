import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';
import * as R from 'ramda';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { getProgress } from 'features/shared/services/router/selectors';
import { Colors } from 'features/shared/constants/colors';

const useStyles = createUseStyles(theme => ({
  progressBarContainer: {
    width: '100%',
    height: '15px',
    borderRadius: '6px',
    backgroundColor: Colors.white,
    marginTop: '15px',
    boxShadow: '0px 2px 8px rgba(0, 0, 0, 0.08)',
    position: 'relative'
  },
  progressBarFilling: {
    height: '15px',
    backgroundColor: theme.primaryColor
  },
  progressBarValue: {
    fontFamily: 'Libre Franklin',
    fontSize: '12px',
    lineHeight: '15px',
    textAlign: 'center',
    color: Colors.grayDark,
    width: '34px',
    position: 'absolute',
    top: 0,
    left: 'calc(50% - 17px)'
  }
}));

const LinearProgressBar = ({ containerClassName }) => {
  const color = { backgroundColor: 'red' };
  const classes = useStyles(color);
  const progress = useSelector(getProgress);

  return (
    !R.isNil(progress) && (
      <div
        className={classNames(classes.progressBarContainer, containerClassName)}
      >
        <div
          className={classes.progressBarFilling}
          style={{
            width: progress,
            borderRadius: progress === '100%' ? '6px' : '6px 0px 0px 6px'
          }}
        />
        <div className={classes.progressBarValue}>{progress}</div>
      </div>
    )
  );
};

export default LinearProgressBar;
