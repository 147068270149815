import React from 'react';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';

const useStyles = createUseStyles(theme => ({
  title: {
    fontFamily: theme.headerFontFamily,
    fontWeight: FontWeights.semiBold,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx
  }
}));

const DialogTitle = ({ children, ...restProps }) => {
  const classes = useStyles();

  return (
    <MuiDialogTitle disableTypography={true} {...restProps}>
      <span className={classes.title}>{children}</span>
    </MuiDialogTitle>
  );
};

export default DialogTitle;
