import * as R from 'ramda';

import { types } from 'features/shared/services/session/actions.js';
import { succeedType } from 'features/shared/utils/actions.js';
import { mapManifests } from 'features/shared/utils/mapping.js';

const defaultState = {
  cultureCode: null,
  loadStatus: null,
  customerId: '',
  styles: {},
  manifest: [],
  manifestSpec: [],
  translations: {},
  uiComponenetsStyles: {},
  goals: [],
  routesFlow: [],
  logos: {},
  styleSheets: [],
  horizons: [],
  risks: [],
  returns: [],
  financialSituation: {
    left: {},
    debt: [],
    assets: [],
    creditCard: {}
  },
  reacts: [],
  losses: [],
  fundQuiz: [],
  affectFactors: [],
  quizResult: [],
  investmentsKnowledge: [],
  trading: [],
  themes: [],
  savingsPlan: {
    monthlyDeposit: {},
    firstDeposit: {}
  },
  stopScreen: {},
  moneyOrigins: [],
  introMessages: [],
  whatIsRisk: {},
  whatIsBuffer: {},
  howWork: {},
  successScreen: {},
  auth: {},
  riskClasses: [],
  optionalCategories: [],
  advisoryApiKey: '',
  riskScoreToInvestmentFallMap: {},
  portfolioFunds: {},
  report: {},
  reportStyleSheets: [],
  areButtonsColored: false,
  roboPortfolioPrecision: 1,
  expectedValue: null,
  timeHorizonConfig: {
    items: [],
    type: ''
  }
};

const reducer = (state = defaultState, action) => {
  switch (action.type) {
    case succeedType(types.INITIALIZE): {
      const config = action.payload;

      return {
        cultureCode: 'no-no',
        loadStatus: 200,
        customerId: config.customerId,
        styles: R.propOr({}, 'styles', config),
        manifest: mapManifests(R.propOr([], 'manifest', config)),
        manifestSpec: R.propOr([], 'manifestSpec', config),
        translations: R.propOr({}, 'translations', config),
        uiComponenetsStyles: R.propOr({}, 'uiComponenetsStyles', config),
        goals: R.propOr([], 'goals', config),
        routesFlow: R.propOr([], 'routesFlow', config),
        logos: R.propOr({}, 'logos', config),
        styleSheets: R.propOr([], 'styleSheets', config),
        horizons: R.propOr([], 'horizons', config),
        risks: R.propOr([], 'risks', config),
        returns: R.propOr([], 'returns', config),
        financialSituation: R.propOr({}, 'financialSituation', config),
        reacts: R.propOr([], 'reacts', config),
        losses: R.propOr([], 'losses', config),
        fundQuiz: R.propOr([], 'fundQuiz', config),
        affectFactors: R.propOr([], 'affectFactors', config),
        quizResult: R.propOr([], 'quizResult', config),
        investmentsKnowledge: R.propOr([], 'investmentsKnowledge', config),
        trading: R.propOr([], 'trading', config),
        themes: R.propOr([], 'themes', config),
        savingsPlan: R.propOr({}, 'savingsPlan', config),
        moneyOrigins: R.propOr([], 'moneyOrigins', config),
        introMessages: R.propOr([], 'introMessages', config),
        whatIsRisk: R.propOr({}, 'whatIsRisk', config),
        whatIsBuffer: R.propOr({}, 'whatIsBuffer', config),
        howWork: R.propOr({}, 'howWork', config),
        successScreen: R.propOr({}, 'successScreen', config),
        stopScreen: R.propOr({}, 'stopScreen', config),
        auth: R.propOr({}, 'auth', config),
        riskClasses: R.propOr([], 'riskClasses', config),
        optionalCategories: R.propOr([], 'optionalCategories', config),
        advisoryApiKey: R.prop('advisoryApiKey', config),
        riskScoreToInvestmentFallMap: R.propOr(
          {},
          'riskScoreToInvestmentFallMap',
          config
        ),
        portfolioFunds: R.propOr({}, 'portfolioFunds', config),
        report: R.propOr({}, 'report', config),
        reportStyleSheets: R.propOr([], 'reportStyleSheets', config),
        areButtonsColored: R.propOr(false, 'areButtonsColored', config),
        roboPortfolioPrecision: R.propOr(1, 'roboPortfolioPrecision', config),
        expectedValue: R.propOr(null, 'expectedValue', config),
        timeHorizonConfig: R.propOr({}, 'timeHorizonConfig', config)
      };
    }
    default:
      return state;
  }
};

export default reducer;
