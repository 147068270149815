import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import * as R from 'ramda';
import classNames from 'classnames';

import { i18n } from 'i18n/index.js';
import SavingRoboCelebrate from 'assets/saving-robo-icons/celebrate.js';
import SavingRoboAsking from 'assets/saving-robo-icons/asking.js';
import { getCurrentRouteConfig } from 'features/shared/services/router/selectors.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { getQuizResults } from 'features/shared/services/userOptions/selectors.js';
import { creators as userOptionsCreator } from 'features/shared/services/userOptions/actions.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import Icon from 'features/shared/components/icon/index';
import Link from 'features/shared/components/link/index';
import { Colors } from 'features/shared/constants/colors.js';
import { FontWeights } from 'features/shared/constants/fonts';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';

const useStyles = createUseStyles(theme => {
  return {
    title: {
      fontFamily: theme.headerFontFamily,
      fontSize: TextFontSizes.xLargePx,
      lineHeight: TextFontSizes.xLargeLineHeightPx,
      paddingBottom: '15px',
      paddingTop: '25px',
      color: Colors.grayDark
    },
    text: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      textAlign: 'center'
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    contentHeader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '45px',
      paddingTop: '50px'
    },
    link: {
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.mediumPx,
      lineHeight: TextFontSizes.mediumLineHeightPx,
      textAlign: 'center',
      padding: '13px 0 0 0'
    },
    list: {
      width: '100%',
      backgroundColor: Colors.white,
      boxShadow:
        '0px 4px 15px ' +
        Colors.listShadowColor +
        ', 0px 6px 15px ' +
        Colors.listShadowColor +
        ', 0px 8px 15px ' +
        Colors.listShadowColor +
        ', 0px 10px 15px ' +
        Colors.listShadowColor +
        ', 0px 12px 15px ' +
        Colors.listShadowColor +
        ', 0px 14px 35px ' +
        Colors.listShadowColor,
      padding: '0',
      borderRadius: '2px'
    },
    listContent: {
      listStyleType: 'none',
      minHeight: '60px',
      display: 'flex',
      alignItems: 'center',
      padding: '20px 25px',
      cursor: 'pointer',
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.mediumPx,
      lineHeight: TextFontSizes.mediumLineHeightPx,
      position: 'relative',
      justifyContent: 'space-between',
      '-webkit-tap-highlight-color': 'transparent',

      '& + &': {
        borderTop: `1px solid ${theme.placeholderColor}`
      }
    },
    listItem: {
      display: 'flex',
      flexDirection: 'column',
      '&:first-child': {
        width: '70%'
      },
      '&:last-child': {
        alignItems: 'center'
      }
    },
    listIcon: {
      marginBottom: '8px'
    },
    listTitle: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      fontWeight: FontWeights.medium,
      color: Colors.grayLight,
      paddingBottom: '11px'
    },
    listText: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      fontWeight: FontWeights.light
    },
    noAnswerText: {
      color: Colors.slickDotsColor
    },
    listLabel: {
      fontSize: TextFontSizes.mediumPx,
      lineHeight: TextFontSizes.mediumLineHeightPx,
      fontWeight: FontWeights.medium,
      marginBottom: '20px',
      alignSelf: 'flex-start',
      color: Colors.grayDark,
      fontFamily: theme.textFontFamily
    }
  };
});

const Result = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const currentRouteConfig = useSelector(getCurrentRouteConfig);
  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const quizResult = useSelector(getQuizResults);

  const isAllCorrect = R.all(i => i.isCorrect)(quizResult);

  const dispatch = useDispatch();
  const resetUserQuizAnswers = () => {
    dispatch(userOptionsCreator.resetUserQuizAnswers());
  };

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {isAllCorrect
          ? i18n('knowledge.result.correct.naviTitle', translations)
          : i18n('knowledge.result.incorrect.naviTitle', translations)}
      </Navigation>

      <Page>
        <div className={classes.content}>
          <div className={classes.contentHeader}>
            {isAllCorrect && <SavingRoboCelebrate />}
            {!isAllCorrect && <SavingRoboAsking />}
            <span className={classes.title}>
              {isAllCorrect
                ? i18n('knowledge.result.correct.title', translations)
                : i18n('knowledge.result.incorrect.title', translations)}
            </span>

            <span className={classes.text}>
              {isAllCorrect
                ? i18n('knowledge.result.correct.text', translations)
                : i18n('knowledge.result.incorrect.text', translations)}
            </span>

            <Link
              className={classes.link}
              to={routeTemplates.whatIsFund.build(
                customerId,
                currentRouteConfig.id
              )}
            >
              {isAllCorrect
                ? i18n('knowledge.result.correct.link.learnMore', translations)
                : i18n(
                    'knowledge.result.incorrect.link.learnMore',
                    translations
                  )}
              <Icon type="navigation" />
            </Link>
          </div>

          <div className={classes.listLabel}>
            {i18n('knowledge.result.yourAnswers', translations)}
          </div>

          <div className={classes.list}>
            {quizResult.map(item => {
              return (
                <div key={item.id} className={classes.listContent}>
                  <div className={classes.listItem}>
                    <span className={classes.listTitle}>
                      {i18n(item.title, translations)}
                    </span>
                    <span
                      className={classNames(
                        classes.listText,
                        !item.text ? classes.noAnswerText : null
                      )}
                    >
                      {item.text
                        ? i18n(item.text, translations)
                        : i18n('shared.noAnswer', translations)}
                    </span>
                  </div>
                  <div className={classes.listItem}>
                    <Icon
                      className={classes.listIcon}
                      type={item.isCorrect ? 'yes' : 'no'}
                    />
                    <span className={classes.listText}>
                      {item.isCorrect
                        ? i18n('shared.correct', translations)
                        : i18n('shared.wrong', translations)}
                    </span>
                  </div>
                </div>
              );
            })}
          </div>
        </div>

        <Footer>
          {isAllCorrect ? (
            <Button
              type={ButtonType.primary}
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n('shared.continue', translations)}
            </Button>
          ) : (
            <Button
              type={ButtonType.primary}
              onClick={() => {
                resetUserQuizAnswers();
                history.push(routeTemplates.fund.build(customerId));
              }}
            >
              {i18n('shared.redoTest', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default Result;
