import * as R from 'ramda';
import { format } from 'date-fns';

import { NodeType } from '../constants/i18n.js';

const reduceIndexed = R.addIndex(R.reduce);

export const defaultReplacements = {
  '{currentDateDDMMYYYYDot}': (
    <strong>{format(new Date(), 'DD.MM.YYYY')}</strong>
  ),
  '{currentDateDDMMYYYYDash}': (
    <strong>{format(new Date(), 'DD-MM-YYYY')}</strong>
  ),
  '{currentDateYYYYMMDDDot}': (
    <strong>{format(new Date(), 'YYYY.MM.DD')}</strong>
  ),
  '{currentDateYYYYMMDDDash}': (
    <strong>{format(new Date(), 'YYYY-MM-DD')}</strong>
  )
};

/**
 * Use this function to enable support for replacing multiple hits with a formatted element.
 *
 * Example
 *
 * {replaceTextWithFormatting(i18n('...', {'{customerName}': <strong>{"Ola Normann"}</strong>}))}
 *
 * @param string text
 * @param object replaceObj
 *
 * @returns array
 */
export const replaceTextWithFormatting = (text, replaceObj) => {
  if (text === null) {
    return text;
  }

  let parts = [text];

  for (const [key, value] of Object.entries(replaceObj)) {
    for (var i = 0; i < parts.length; ++i) {
      if (typeof parts[i] !== 'string') {
        continue;
      }

      const hasMatch = parts[i].indexOf(key);
      if (hasMatch !== -1) {
        const finds = parts[i].split(key);

        let newPart = [];
        for (let j = 0; j < finds.length; ++j) {
          newPart.push(finds[j]);

          if (j !== finds.length - 1) {
            newPart.push(value);
          }
        }

        parts[i] = newPart;

        parts = [].concat.apply([], parts);
      }
    }
  }

  return parts;
};

export const toAst = (replaceParams, text) => {
  let keysWithIndexes = R.pipe(
    R.keys,
    R.map(key => {
      return {
        key,
        value: replaceParams[key],
        index: text.indexOf(key)
      };
    }),
    R.filter(key => key.index !== -1),
    R.sortBy(key => key.index)
  )(replaceParams);
  if (keysWithIndexes.length === 0) {
    return [
      {
        type: NodeType.text,
        value: text
      }
    ];
  }
  let reducedKeys = reduceIndexed(
    (acc, key, index, list) => {
      let ast = acc.ast;
      if (acc.index < key.index) {
        ast = R.append(
          {
            type: NodeType.text,
            value: text.slice(acc.index, key.index)
          },
          ast
        );
      }
      ast = R.append(
        {
          type: NodeType.node,
          value: key.value
        },
        ast
      );
      let nextKey = key.index + key.key.length;
      if (index === list.length - 1 && nextKey < text.length) {
        ast = R.append(
          {
            type: NodeType.text,
            value: text.slice(nextKey)
          },
          ast
        );
        nextKey = text.length;
      }
      return {
        index: nextKey,
        ast
      };
    },
    {
      index: 0,
      ast: []
    },
    keysWithIndexes
  );
  return reducedKeys.ast;
};
