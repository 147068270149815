import React, { useMemo } from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import Slider from '@material-ui/core/Slider';
import { withStyles } from '@material-ui/core/styles';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import InputBase from '@material-ui/core/InputBase';
import NumberFormat from 'react-number-format';
import classNames from 'classnames';

import { getNumberInputFormat } from 'features/shared/utils/number.js';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import ExternalIcon from 'features/shared/components/icon/externalIcon.js';
import { Colors } from 'features/shared/constants/colors.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { i18n } from 'i18n/index.js';
import { formatNumber } from 'features/shared/utils/number.js';

const useStyles = createUseStyles(theme => ({
  sliderContainer: {
    width: '90%'
  },
  header: {
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end'
  },
  title: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    paddingBottom: '11px'
  },
  valueContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end'
  },
  value: {
    '& input': {
      fontFamily: theme.headerFontFamily,
      fontSize: TextFontSizes.xLargePx,
      lineHeight: TextFontSizes.xLargeLineHeightPx,
      textAlign: 'right',
      color: theme.primaryColor,
      minWidth: '190px'
    }
  },
  valuePerDay: {
    color: '#616161'
  },
  largeInput: {
    '& input': {
      minWidth: '190px',
      fontSize: TextFontSizes.largePx,
      lineHeight: TextFontSizes.largeLineHeightPx
    }
  },
  mediumInput: {
    '& input': {
      minWidth: '170px',
      fontSize: '30px',
      lineHeight: '34px'
    }
  },
  smallInput: {
    '& input': {
      minWidth: '150px'
    }
  },
  icon: {
    width: '30px',
    height: '30px'
  }
}));

const CustomSlider = withStyles({
  root: {
    color: Colors.sliderBackgroundColor,
    height: 11,
    padding: '24px 0'
  },
  thumb: {
    height: 44,
    width: 44,
    backgroundColor: Colors.white,
    marginTop: -8,
    marginLeft: -22,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit'
    },
    top: '14px',

    '&::after': {
      top: '0',
      bottom: '0',
      right: '0',
      left: '0'
    }
  },
  active: {},
  track: {
    height: 11,
    borderRadius: 30,
    background: 'linear-gradient(90deg, #4D2161 0%, #6F2A42 100%)'
  },
  rail: {
    opacity: 1,
    height: 11,
    borderRadius: 30
  }
})(Slider);

function getInputSize(value, classes) {
  const length = value ? value.toString().length : 1;
  if (length <= 6) {
    return classes.smallInput;
  } else if (length > 6 && length <= 8) {
    return classes.mediumInput;
  } else {
    return classes.largeInput;
  }
}

function ThumbComponent({ icon, iconClassName, ...props }) {
  return (
    <span {...props} data-id={'slider-thumb'}>
      {icon && <ExternalIcon className={iconClassName} type={icon} />}
    </span>
  );
}

function useCombinedRefs(...refs) {
  const targetRef = React.useRef();

  React.useEffect(() => {
    refs.forEach(ref => {
      if (!ref) return;

      if (typeof ref === 'function') {
        ref(targetRef.current);
      } else {
        ref.current = targetRef.current;
      }
    });
  }, [refs]);

  return targetRef;
}

const SliderInternalComponent = React.forwardRef(
  ({ onMouseDown, style, ...restProps }, ref) => {
    const innerRef = React.useRef();
    const combinedRef = useCombinedRefs(ref, innerRef);

    const isTouchScreen = React.useRef(false);

    React.useEffect(() => {
      const handleTouchStart = event => {
        isTouchScreen.current = true;

        if (event.target.getAttribute('data-id') !== 'slider-thumb') {
          event.stopImmediatePropagation();
        }
      };

      const { current: slider } = innerRef;

      slider.addEventListener('touchstart', handleTouchStart);

      return () => {
        slider.removeEventListener('touchstart', handleTouchStart);
      };
    }, []);

    return (
      <span
        {...restProps}
        onMouseDown={event => {
          if (isTouchScreen.current) {
            if (event.target.getAttribute('data-id') !== 'slider-thumb') {
              return;
            }
          }

          onMouseDown && onMouseDown(event);
        }}
        style={{ ...style, touchAction: 'pan-y' }}
        ref={combinedRef}
      />
    );
  }
);

const SliderComponent = ({
  options,
  onChange,
  userOption,
  type,
  displayTitle,
  displayValue,
  displayPerDay
}) => {
  const classes = useStyles();
  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const { thousandSeparator, decimalSeparator } =
    getNumberInputFormat(cultureCode);
  const MemoizedThumbComponent = useMemo(
    () => props =>
      (
        <ThumbComponent
          icon={options.icon}
          iconClassName={classes.icon}
          {...props}
        />
      ),
    [options.icon]
  );
  const sliderValue = R.isNil(userOption) ? 0 : userOption;
  const sliderStep = R.is(Number, options.step)
    ? options.step
    : R.find(s => R.isNil(s[0]) || sliderValue <= s[0], options.step)[1];
  const valuePerDay = R.isNil(userOption)
    ? 0
    : formatNumber(cultureCode, userOption / 30);
  return (
    <div className={classes.sliderContainer}>
      <div className={classes.header}>
        {displayTitle ? (
          <span className={classes.title}>
            {i18n(options.title, translations)}
          </span>
        ) : null}
        <div className={classes.valueContainer}>
          {displayValue ? (
            <NumberFormat
              customInput={InputBase}
              value={R.isNil(userOption) ? '' : userOption}
              onValueChange={values => {
                const { min, max } = options;
                const value = R.isNil(values.floatValue)
                  ? values.floatValue
                  : R.pipe(v => Math.max(v, min))(values.floatValue);

                onChange(type, value);
              }}
              thousandSeparator={thousandSeparator}
              decimalSeparator={decimalSeparator}
              allowedDecimalSeparators={[',', '.']}
              decimalScale={2}
              allowNegative={false}
              className={classNames(
                classes.value,
                getInputSize(userOption, classes)
              )}
            />
          ) : null}
          {displayPerDay ? (
            <span className={classes.valuePerDay}>
              {valuePerDay},-{' '}
              {i18n('counseling.savingsPlan.perDay', translations)}
            </span>
          ) : null}
        </div>
      </div>
      <CustomSlider
        min={options.min}
        max={options.max}
        step={sliderStep}
        value={sliderValue}
        onChange={(event, newValue) => {
          onChange(type, newValue);
        }}
        component={SliderInternalComponent}
        ThumbComponent={MemoizedThumbComponent}
        aria-label="slider"
      />
    </div>
  );
};

export default SliderComponent;
