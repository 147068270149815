import React from 'react';
import ReactDOM from 'react-dom';
import { Provider as ReactReduxProvider } from 'react-redux';
import { Route } from 'react-router-dom';

import store from './store.js';
import App from 'features/main/components/index.js';
import Report from 'features/main/components/report.js';
import OrderExecutionReport from 'features/main/components/orderExecutionReport.js';
import Router from 'features/shared/components/router/index.js';
import Confirmation from 'features/shared/components/confirmation/index.js';
import history, {
  setConfirmationComponent
} from 'features/shared/utils/history.js';
import config from 'config/index.js';
import MainLayout from 'features/shared/components/mainLayout/index.js';
import PageNotFound from 'features/shared/components/pageNotFound/index.js';
import { CustomerConfigProvider } from 'features/sharedModules/customerConfig/components/index.js';

setConfirmationComponent(Confirmation);

export const renderReport = () => {
  ReactDOM.render(
    <ReactReduxProvider store={store}>
      <Router history={history}>
        <CustomerConfigProvider>
          <Route component={Report} />
        </CustomerConfigProvider>
      </Router>
    </ReactReduxProvider>,
    document.getElementById(config.APP_ELEMENT_ID)
  );
};

export const renderOrderExecutionReport = () => {
  ReactDOM.render(
    <ReactReduxProvider store={store}>
      <Router history={history}>
        <CustomerConfigProvider>
          <Route component={OrderExecutionReport} />
        </CustomerConfigProvider>
      </Router>
    </ReactReduxProvider>,
    document.getElementById(config.APP_ELEMENT_ID)
  );
};

export const renderApplication = () => {
  ReactDOM.render(
    <ReactReduxProvider store={store}>
      <Router history={history}>
        <CustomerConfigProvider>
          <Route component={App} />
        </CustomerConfigProvider>
      </Router>
    </ReactReduxProvider>,
    document.getElementById(config.APP_ELEMENT_ID)
  );
};

export const renderErrorPage = errorType => {
  ReactDOM.render(
    errorType === 404 ? (
      <ReactReduxProvider store={store}>
        <CustomerConfigProvider>
          <MainLayout>
            <PageNotFound text="Customer not found" />
          </MainLayout>
        </CustomerConfigProvider>
      </ReactReduxProvider>
    ) : (
      <ReactReduxProvider store={store}>
        <CustomerConfigProvider>
          <MainLayout>
            <PageNotFound text="An error occurred. Try again later." />
          </MainLayout>
        </CustomerConfigProvider>
      </ReactReduxProvider>
    ),
    document.getElementById(config.APP_ELEMENT_ID)
  );
};
