import { ReactNode } from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import classNames from 'classnames';

import Link from 'features/shared/components/link/index';
import Icon from 'features/shared/components/icon/index';
import { FontWeights, TextFontSizes } from 'features/shared/constants/fonts.js';

const useStyles = createUseStyles(theme => ({
  header: {
    width: '100%',
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 53px',
    background: theme.headerBackgroundColor,
    color: theme.headerTextColor,
    fontFamily: theme.headerFontFamily,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    fontWeight: FontWeights.semiBold,
    position: 'fixed',
    zIndex: '99',

    '& svg': {
      '& path': {
        stroke: 'none',
        fill: theme.headerIconColor
      }
    }
  },
  backButton: {
    position: 'absolute',
    left: '20px'
  },
  title: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap'
  }
}));

const Navigation = ({ children, className, prevRoute, customerId }: {
  children?: ReactNode,
  className?: string,
  prevRoute?: { build: (customerId: string) => string },
  customerId?: string
}) => {
  const classes = useStyles();

  return (
    <div className={classNames(classes.header, className)}>
      {prevRoute && (
        <div className={classes.backButton}>
          <Link to={prevRoute.build(customerId || '')}>
            <Icon type="back" />
          </Link>
        </div>
      )}
      <div className={classes.title}>{children}</div>
    </div>
  );
};

export default Navigation;
