import { Fragment } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { FontWeights } from '../../shared/constants/fonts.js';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell
} from '../../shared/components/table';
import Heading1 from '../../shared/components/heading1';
import Section from '../../shared/components/section.js';
import { ClientTypes } from '../constants';
import LiquiditySection from './liquiditySection';

const useStyles = createUseStyles({
  root: {
    marginTop: '26px'
  },
  headerTextCell: {
    width: '40%'
  },
  headerNumberCell: {
    whiteSpace: 'nowrap',
    width: '10%',
    textAlign: 'right'
  },
  sumCell: {
    fontWeight: FontWeights.semiBold,
    textAlign: 'right',
    whiteSpace: 'nowrap'
  }
});

const FinancialSituationSection = ({ financialSituation }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  const {
    clientType,
    personFinancialSituation,
    companyFinancialSituation,
    companyFinancialSituationAccountingFigures,
    liquidity
  } = financialSituation;

  return clientType === ClientTypes.company ||
    clientType === ClientTypes.person ? (
    <>
      <Section data-testid={'financial-situation-section'}>
        <Heading1>
          {i18n('report.financialSituation.header', translationsConfig)}
        </Heading1>

        <div>{i18n('report.financialSituation.text', translationsConfig)}</div>

        <div className={classes.root}>
          <Table>
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell className={classes.headerTextCell}>
                  {i18n('report.financialSituation.assets', translationsConfig)}
                </TableHeaderCell>

                <TableHeaderCell className={classes.headerNumberCell}>
                  {i18n('report.financialSituation.sum', translationsConfig)}
                </TableHeaderCell>

                <TableHeaderCell className={classes.headerTextCell}>
                  {i18n('report.financialSituation.debt', translationsConfig)}
                </TableHeaderCell>

                <TableHeaderCell className={classes.headerNumberCell}>
                  {i18n('report.financialSituation.sum', translationsConfig)}
                </TableHeaderCell>
              </TableHeaderRow>
            </TableHeader>

            <TableBody>
              {clientType === ClientTypes.person &&
                personFinancialSituation.map(
                  ({ asset, assetSum, debt, debtSum }, index) => (
                    <TableBodyRow key={index}>
                      <TableBodyCell>{asset}</TableBodyCell>

                      <TableBodyCell className={classes.sumCell}>
                        {assetSum}
                      </TableBodyCell>

                      <TableBodyCell>{debt}</TableBodyCell>

                      <TableBodyCell className={classes.sumCell}>
                        {debtSum}
                      </TableBodyCell>
                    </TableBodyRow>
                  )
                )}

              {clientType === ClientTypes.company &&
                companyFinancialSituation.map((assetsAndDebt, index) => (
                  <TableBodyRow key={index}>
                    {assetsAndDebt.map(({ title, value }, index) => (
                      <Fragment key={index}>
                        <TableBodyCell>{title}</TableBodyCell>

                        <TableBodyCell className={classes.sumCell}>
                          {value}
                        </TableBodyCell>
                      </Fragment>
                    ))}
                  </TableBodyRow>
                ))}
            </TableBody>
          </Table>
        </div>
      </Section>

      <LiquiditySection liquidity={liquidity} />

      {clientType === ClientTypes.company &&
        !R.isEmpty(companyFinancialSituationAccountingFigures) && (
          <Section>
            <Heading1>
              {i18n(
                'report.financialSituation.accountingFigures',
                translationsConfig
              )}
            </Heading1>

            <Table>
              <TableHeader>
                <TableHeaderRow>
                  <TableHeaderCell>
                    {i18n(
                      'report.financialSituation.accountingFigures',
                      translationsConfig
                    )}
                  </TableHeaderCell>

                  <TableHeaderCell className={classes.headerNumberCell}>
                    {i18n('report.shared.amount', translationsConfig)}
                  </TableHeaderCell>
                </TableHeaderRow>
              </TableHeader>

              <TableBody>
                {companyFinancialSituationAccountingFigures.map(
                  ({ title, value }, index) => (
                    <TableBodyRow key={index}>
                      <TableBodyCell>{title}</TableBodyCell>

                      <TableBodyCell className={classes.sumCell}>
                        {value}
                      </TableBodyCell>
                    </TableBodyRow>
                  )
                )}
              </TableBody>
            </Table>
          </Section>
        )}
    </>
  ) : null;
};

export default FinancialSituationSection;
