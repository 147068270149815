import { useSelector } from 'react-redux';
import classNames from 'classnames';
import { find, propEq } from 'ramda';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { i18n } from 'i18n/index.js';
import I18n from 'features/shared/components/i18n/index.js';
import { FontWeights } from '../../shared/constants/fonts.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Heading1 from '../../shared/components/heading1';
import Heading2 from '../../shared/components/heading2';
import Section from '../../shared/components/section.js';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell
} from '../../shared/components/table';
import { riskScoreToNumber } from 'features/shared/utils/mapping.js';
import { TimeHorizonTypes } from '../constants';
import GoalTableIcon from 'features/report/shared/components/goalTableIcon';

const useStyles = createUseStyles(theme => ({
  boldTableData: {
    fontWeight: FontWeights.bold
  },
  maximumRiskScore: {
    marginBottom: '30px'
  },
  goal: {
    marginBottom: '20px'
  },
  goalIcon: {
    fill: theme.primaryColor,
    width: 30
  },
  notIconHeader: {
    width: '33%'
  },
  purposeSection: {
    '& > *': {
      marginBottom: '26px'
    }
  },
  numberCell: {
    textAlign: 'right'
  }
}));

const PurposeSection = ({
  goals,
  purposeAndRisk,
  timeHorizonConfig,
  showGoalIcons
}) => {
  const classes = useStyles();

  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  const getHorizonYearsDisplayValue = ({
    timeHorizonConfig,
    timeHorizon,
    i18n
  }) =>
    timeHorizonConfig.type === TimeHorizonTypes.slider
      ? i18n('shared.yearsTemplate', translationsConfig).replace(
          '{0}',
          timeHorizon
        )
      : i18n(
          find(propEq('activeValue', timeHorizon))(timeHorizonConfig.items)
            ?.labelKey,
          translationsConfig
        );

  return (
    <Section data-testid={'purpose-section'} className={classes.purposeSection}>
      <Heading1>{i18n('report.purpose.header', translationsConfig)}</Heading1>
      <div>{i18n('report.purpose.text', translationsConfig)}</div>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            {showGoalIcons && <TableHeaderCell />}
            <TableHeaderCell className={classes.notIconHeader}>
              {i18n('report.purpose.goal', translationsConfig)}
            </TableHeaderCell>

            <TableHeaderCell className={classes.notIconHeader}>
              {i18n('report.purpose.years', translationsConfig)}
            </TableHeaderCell>
            <TableHeaderCell
              className={classNames(classes.notIconHeader, classes.numberCell)}
            >
              {i18n('report.purpose.riskScore', translationsConfig)}
            </TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {goals &&
            goals.map(goal => (
              <TableBodyRow key={goal.goalId}>
                {showGoalIcons && (
                  <TableBodyCell className={classes.goalIcon}>
                    <GoalTableIcon name={goal.name} icon={goal.icon} />
                  </TableBodyCell>
                )}
                <TableBodyCell>{goal.name}</TableBodyCell>
                <TableBodyCell>
                  {getHorizonYearsDisplayValue({
                    timeHorizonConfig,
                    timeHorizon: goal.data.timeHorizon,
                    i18n
                  })}
                </TableBodyCell>
                <TableBodyCell
                  className={classNames(
                    classes.boldTableData,
                    classes.numberCell
                  )}
                >
                  {riskScoreToNumber(goal.data.riskScore)}
                </TableBodyCell>
              </TableBodyRow>
            ))}
        </TableBody>
      </Table>
      <div className={classes.maximumRiskScore}>
        <I18n
          text={i18n('report.purpose.maximumRiskScore', translationsConfig)}
          replaceParams={{
            '{0}': <span>{purposeAndRisk.maxRiskScore}</span>
          }}
        />
      </div>
      <div>
        {goals &&
          goals.map(goal => (
            <div key={goal.goalId} className={classes.goal}>
              <Heading2>
                <I18n
                  text={i18n('report.purpose.goalWithName', translationsConfig)}
                  replaceParams={{
                    '{0}': <span>{goal.name}</span>
                  }}
                />
              </Heading2>
              {goal.description}
            </div>
          ))}
      </div>
    </Section>
  );
};

export default PurposeSection;
