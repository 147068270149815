import * as R from 'ramda';

const DefaultCultureCode = 'no-no';

export const formatNumber = (
  cultureCode,
  number,
  minimumFractionDigits = 0,
  maximumFractionDigits = 0
) => {
  if (R.isNil(number)) {
    return null;
  }

  if (R.isNil(cultureCode)) {
    cultureCode = DefaultCultureCode;
  }

  return new Intl.NumberFormat(cultureCode, {
    minimumFractionDigits,
    maximumFractionDigits
  }).format(number);
};

export const getNumberInputFormat = cultureCode => {
  if (R.isNil(cultureCode)) {
    cultureCode = DefaultCultureCode;
  }

  return {
    thousandSeparator: getThousandSeparator(cultureCode),
    decimalSeparator: getDecimalSeparator(cultureCode)
  };
};

function getThousandSeparator(cultureCode) {
  return Intl.NumberFormat(cultureCode)
    .format(1111)
    .replace(/1/g, '');
}

function getDecimalSeparator(cultureCode) {
  return Intl.NumberFormat(cultureCode)
    .format(1.1)
    .replace(/1/g, '');
}
