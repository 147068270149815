export const Colors = {
  black: '#000000',
  white: '#ffffff',
  blue25: '#164152',
  blue50: '#1a242a',
  blue50_07: 'rgba(26, 36, 42, 0.7)',
  blue75: '#1e2a30',
  blue100: '#233037',
  blue150: '#0bb3f3',
  blue150_05: 'rgba(11, 179, 243, 0.5)',
  blue200: '#2a3840',
  blue250: '#222e35',
  blue300: '#28363d',
  blue350: '#3c4a54',
  blue400: '#04739e',
  blue500: '#173845',
  gray50: '#6a8595',
  gray50_06: 'rgba(106, 133, 149, 0.6)',
  gray100: '#707070',
  gray150: '#2b373f',
  gray155: '#32374B',
  gray160: '#333333',
  gray170: '#383838',
  gray180: '#4f4f4f',
  gray200: '#526874',
  gray500: '#78909C',
  red50: '#f25b5b',
  red50_05: 'rgba(242, 91, 91, 0.5)',
  red100: '#863f42',
  green50: '#41b851',
  green50_05: 'rgba(65, 184, 81, 0.5)',
  green100: '#41bf50',
  yellow50: '#ffb01c',
  yellow50_05: 'rgba(255, 176, 28, 0.5)',
  yellow100: '#8d6a23',

  orange: '#FF7E20',
  backgroundColor: '#F3F3F3',
  listShadowColor: 'rgba(0, 0, 0, 0.01)',
  slickDotsColor: '#A5A5A5',
  grayDark: '#474747',
  sliderBackgroundColor: '#E5E2D3',
  grayLight: '#231F20',
  disabledButtonColor: 'rgba(0, 0, 0, 0.26)',
  infoRedColor: '#900032',
  nightRiderColor: '#333333',
  nomadColor: '#949391',
  matterhornColor: '#4F4F4F',
  chardonColor: '#F5ECE4',

  //Graph colors
  darkGreenGraphColor: '#444444',

  //Backtest
  backtestGreenGraphColor: '#62796C',
  backtestLightGreenGraphColor: '#DAE0DC',

  //Montecarlo
  montecarloOrangeColor: '#F6922E',
  montecarloGraphColor: '#82a03f',

  //Fees
  feesPureBlueGraphColor: '#3D709B',
  feesLightBlueGraphColor: '#9ECAF2',
  feesLightGreenGraphColor: '#82C698',
  feesGridLineColor: '#C9C9C9',

  // Grid
  gridLineColor: '#FAFAF8',
  gridLabelColor: '#62796C',

  negativeValue: '#EB5757',
  successIconColor: '#56AD61'
};
