import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { FontWeights, TextFontSizes } from '../../shared/constants/fonts.js';
import Heading2 from '../../shared/components/heading2';
import Heading1 from '../../shared/components/heading1';
import Paragraph from '../../shared/components/paragraph';
import Section from '../../shared/components/section.js';
import { ClientTypes } from '../constants';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow
} from 'features/report/shared/components/table';
import { getAutomaticClientClassification } from '../services/reportSelectors';
import { useReportStore } from 'features/report/shared/services/reportStore';
import AdvisorNotesSection from './advisorNotesSection.js';

const useStyles = createUseStyles({
  subHeader: {
    fontWeight: FontWeights.semiBold,
    margin: '23px 0'
  },
  cell: {
    wordBreak: 'break-all'
  },
  answer: {
    whiteSpace: 'pre-line',
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx
  },
  bold: {
    fontWeight: FontWeights.semiBold
  },
  table: {
    marginBottom: 32
  },
  description: {
    marginBottom: 32
  },
  documentationHeader: {
    fontWeight: FontWeights.semiBold
  },
  halfTable: {
    width: '50%'
  }
});

const NONE_OF_THE_ABOVE_ID = 'none-of-the-above';

const GeneralInformationSection = ({ clientInformation }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const reportStore = useReportStore();
  const automaticClientClassification =
    getAutomaticClientClassification(reportStore);

  const clientType = clientInformation?.clientType;
  const clientFields = clientInformation?.fields;

  const clientInformationFields = clientFields
    .filter(
      row =>
        row.name !== 'clientInformation.additionalData.title' &&
        row.name !== 'clientInformation.country'
    )
    .sort((a, b) => a.order - b.order);

  return (
    <>
      {clientFields?.length && (
        <Section data-testid={'general-information-section'}>
          <Heading2 className={classes.subHeader}>
            {i18n('report.generalInformation.header', translationsConfig)}
          </Heading2>
          <Table>
            <TableBody>
              {clientInformationFields.map((i, iIndex) => (
                <TableBodyRow key={iIndex}>
                  <TableBodyCell>{i.title}</TableBodyCell>
                  <TableBodyCell>{i.value}</TableBodyCell>
                </TableBodyRow>
              ))}
            </TableBody>
          </Table>
        </Section>
      )}
      {clientType === ClientTypes.company &&
        clientInformation.isContactListEnabled &&
        clientInformation.contacts?.length && (
          <Section data-testid={'general-information-section'}>
            <Heading2 className={classes.subHeader}>
              {i18n(
                'report.generalInformation.contactPerson',
                translationsConfig
              )}
            </Heading2>
            <Table>
              <TableBody>
                <TableBodyRow>
                  <TableBodyCell>
                    {i18n('report.generalInformation.name', translationsConfig)}
                  </TableBodyCell>
                  <TableBodyCell>
                    {i18n(
                      'report.generalInformation.email',
                      translationsConfig
                    )}
                  </TableBodyCell>
                  <TableBodyCell>
                    {i18n(
                      'report.generalInformation.phoneNumber',
                      translationsConfig
                    )}
                  </TableBodyCell>
                  <TableBodyCell>
                    {i18n('report.generalInformation.role', translationsConfig)}
                  </TableBodyCell>
                  {clientInformation.isSignatureRightsEnabled && (
                    <TableBodyCell>
                      {i18n(
                        'report.generalInformation.signatureRights',
                        translationsConfig
                      )}
                    </TableBodyCell>
                  )}
                  {clientInformation.isUBOEnabled && (
                    <TableBodyCell>
                      {i18n(
                        'report.generalInformation.ubo',
                        translationsConfig
                      )}
                    </TableBodyCell>
                  )}
                </TableBodyRow>
                {clientInformation.contacts?.map((i, iIndex) => (
                  <TableBodyRow key={iIndex}>
                    <TableBodyCell>{i.name}</TableBodyCell>
                    <TableBodyCell className={classes.cell}>
                      {i.email}
                    </TableBodyCell>
                    <TableBodyCell>{i.phone}</TableBodyCell>
                    <TableBodyCell className={classes.cell}>
                      {i.role}
                    </TableBodyCell>
                    {clientInformation.isSignatureRightsEnabled && (
                      <TableBodyCell>
                        {i18n(
                          i.signRight ? 'shared.yes' : 'shared.no',
                          translationsConfig
                        )}
                      </TableBodyCell>
                    )}
                    {clientInformation.isUBOEnabled && (
                      <TableBodyCell>
                        {i18n(
                          i.ubo ? 'shared.yes' : 'shared.no',
                          translationsConfig
                        )}
                      </TableBodyCell>
                    )}
                  </TableBodyRow>
                ))}
              </TableBody>
            </Table>
          </Section>
        )}

      {automaticClientClassification && (
        <Section>
          <Heading1>
            {i18n(
              'report.generalInformation.clientClassification',
              translationsConfig
            )}
          </Heading1>
          <Table className={classes.table}>
            <TableBody>
              {automaticClientClassification.clientClassification !==
                NONE_OF_THE_ABOVE_ID && (
                <>
                  <TableBodyRow>
                    <TableBodyCell className={classes.halfTable}>
                      {i18n(
                        'report.generalInformation.investorType',
                        translationsConfig
                      )}
                    </TableBodyCell>
                    <TableBodyCell className={classes.bold}>
                      {automaticClientClassification.clientClassification}
                    </TableBodyCell>
                  </TableBodyRow>
                  <TableBodyRow>
                    <TableBodyCell
                      className={classes.halfTable}
                    ></TableBodyCell>
                    <TableBodyCell
                      className={classNames(classes.answer, classes.bold)}
                    >
                      {Array.isArray(automaticClientClassification.answer)
                        ? automaticClientClassification.answer?.map(answer =>
                            answer.concat('\n')
                          )
                        : automaticClientClassification.answer}
                    </TableBodyCell>
                  </TableBodyRow>
                </>
              )}
              <TableBodyRow>
                <TableBodyCell className={classes.halfTable}>
                  {i18n(
                    'report.generalInformation.clientClassification',
                    translationsConfig
                  )}
                </TableBodyCell>
                <TableBodyCell className={classes.bold}>
                  {automaticClientClassification.investorType}
                </TableBodyCell>
              </TableBodyRow>
            </TableBody>
          </Table>

          {automaticClientClassification.isDecisionOverwritten &&
            automaticClientClassification.originalInvestorType && (
              <>
                <Paragraph className={classes.description}>
                  {i18n(
                    'report.generalInformation.reClassificationRequestDocumentation.description',
                    translationsConfig
                  )
                    .replace(
                      '{0}',
                      automaticClientClassification.originalInvestorType
                    )
                    .replace('{1}', automaticClientClassification.investorType)}
                </Paragraph>
                {automaticClientClassification.reclassificationRequestDocumentation && (
                  <>
                    <Heading2 className={classes.documentationHeader}>
                      {i18n(
                        'report.generalInformation.reClassificationRequestDocumentation.header',
                        translationsConfig
                      )}
                      {automaticClientClassification.isCommentRequired && ' *'}
                    </Heading2>
                    <AdvisorNotesSection
                      header={''}
                      text={
                        automaticClientClassification.reclassificationRequestDocumentation
                      }
                    />
                  </>
                )}
              </>
            )}
        </Section>
      )}
    </>
  );
};

export default GeneralInformationSection;
