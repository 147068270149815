import * as R from 'ramda';
import { createSelector } from 'reselect';

import { globalizeSelectors } from 'framework/globalize/utils/index.js';
import { riskScoreToNumber } from 'features/shared/utils/mapping.js';

export default globalizeSelectors(['config_state'], {
  getCustomerId: state => state.customerId,
  getCultureCode: state => state.cultureCode,
  getConfig: state => state,
  getTranslationsConfig: state => state.translations,
  getUiComponenetsStylesConfig: state => state.uiComponenetsStyles,
  getAuthConfig: state => state.auth,
  getGoalsConfig: state => state.goals,
  getStopScreenConfig: state => state.stopScreen,
  getStylesConfig: state => state.styles,
  getManifestConfig: state => state.manifest,
  getManifestSpecConfig: state => state.manifestSpec,
  getRoutesFlowConfig: state => state.routesFlow,
  getLogosConfig: state => state.logos,
  getStyleSheets: state => state.styleSheets,
  getHorizonsConfig: state => state.timeHorizonConfig,
  getRisksConfig: state => state.risks,
  getReturnsConfig: state => state.returns,
  getFinancialSituationConfig: state => state.financialSituation,
  getReactsConfig: state => state.reacts,
  getLossesConfig: state => state.losses,
  getFundQuizConfig: state => state.fundQuiz,
  getAffectFactorsConfig: state => state.affectFactors,
  getQuizResultConfig: state => state.quizResult,
  getInvestmentsKnowledgeConfig: state => state.investmentsKnowledge,
  getTradingConfig: state => state.trading,
  getThemesConfig: createSelector(
    state => state.themes,
    state => state.optionalCategories,
    (themes, optionalCategories) => {
      if (R.isNil(themes) || R.isNil(optionalCategories)) {
        return null;
      }

      return R.filter(t => R.includes(t.id, optionalCategories), themes);
    }
  ),
  getSavingsPlanConfig: state => state.savingsPlan,
  getMoneyOriginsConfig: state => state.moneyOrigins,
  getIntroMessages: state => state.introMessages,
  getMaxRiskScore: state => {
    const maxRiskClass = R.last(state.riskClasses);

    return riskScoreToNumber(maxRiskClass);
  },
  getAdvisoryApiKey: state => state.advisoryApiKey,
  getRiskScoreToInvestmentFallMap: state => state.riskScoreToInvestmentFallMap,
  getPortfolioFundsConfig: state => state.portfolioFunds,
  getReportConfig: state => state.report,
  getReportStyleSheets: state => state.reportStyleSheets,
  getRoboPortfolioPrecisionConfig: state => state.roboPortfolioPrecision,
  getExpectedValueConfig: state => state.expectedValue
});
