import React from 'react';
import { useSelector } from 'react-redux';
import { ExpectedPathChart } from '@quantfoliorepo/ui-components';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { useReportStore } from 'features/report/shared/services/reportStore';
import Section from '../../shared/components/section.js';
import Heading1 from '../../shared/components/heading1';
import Card from '../../shared/components/card.js';
import ChartTable from '../../shared/components/chartTable.js';
import { formatNumber } from 'features/shared/utils/number.js';
import Paragraph from '../../shared/components/paragraph';

const useStyles = createUseStyles({
  card: {
    margin: '26px 0'
  }
});

const ExpectedPathSection = ({ chartWidth }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const reportStore = useReportStore();
  const expectedPathChartData = reportStore.userData?.expectedPathChartData;

  const formattedTableData = React.useMemo(() => {
    return expectedPathChartData?.expectedPathTable &&
      expectedPathChartData?.expectedPathTable.length
      ? expectedPathChartData.expectedPathTable.map(
          ({ year, expectedReturn, lowerBound, upperBound }) => ({
            year,
            expectedReturn: formatNumber(cultureCode, expectedReturn, 0, 0),
            lowerBound: formatNumber(cultureCode, lowerBound, 0, 0),
            upperBound: formatNumber(cultureCode, upperBound, 0, 0)
          })
        )
      : [];
  }, [expectedPathChartData?.expectedPathTable]);

  return (
    <Section>
      <Heading1>
        {i18n('report.expectedPath.header', translationsConfig)}
      </Heading1>
      <div>
        {i18n('report.expectedPath.text', translationsConfig)}
        <Card className={classes.card}>
          <ExpectedPathChart
            cultureCode={cultureCode}
            chartData={{
              expectedReturn: expectedPathChartData?.expectedReturn,
              bounds: expectedPathChartData?.bounds
            }}
            isInteractive={false}
            onLoad={() => window.loadedCharts.push('expectedPath')}
            width={chartWidth}
            customColors={
              reportStore.userData?.chartColorsConfig.expectedPath as any
            }
          />
        </Card>
      </div>
      <br />
      <Paragraph>
        {i18n('report.expectedPath.tableTitle', translationsConfig)}
      </Paragraph>
      {!!formattedTableData.length && (
        <ChartTable
          headerTitles={[
            i18n('roboAdvice.proposal.year', translationsConfig),
            i18n('roboAdvice.proposal.expectedValue', translationsConfig),
            i18n('roboAdvice.proposal.lowerBound', translationsConfig),
            i18n('roboAdvice.proposal.upperBound', translationsConfig)
          ]}
          dataKeys={['year', 'expectedReturn', 'lowerBound', 'upperBound']}
          data={formattedTableData}
          customClasses={undefined}
          asterisk={undefined}
        />
      )}
    </Section>
  );
};

export default ExpectedPathSection;
