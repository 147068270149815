import React from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';

const useStyles = createUseStyles({
  root: {
    flex: '0 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  label: {
    fontSize: TextFontSizes.xxSmallLineHeightPx,
    color: '#D8D4D4',
    fontWeight: FontWeights.normal,
    marginBottom: '12px'
  }
});

const PoweredBy = () => {
  const classes = useStyles();
  const translations = useSelector(sessionSelectors.getTranslationsConfig);

  return (
    <div className={classes.root}>
      <div className={classes.label}>
        {`${i18n('shared.poweredBy', translations)} Quantfolio `}
        <b>&reg;</b>
      </div>
    </div>
  );
};

export default PoweredBy;
