import React, { Fragment, useState, ReactNode } from 'react';
import Drawer from '@material-ui/core/Drawer';
import IconButton from '@material-ui/core/IconButton';
import classNames from 'classnames';

import { Colors } from 'features/shared/constants/colors.js';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import Icon from 'features/shared/components/icon/index';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  paper: {
    minHeight: '300px',
    borderTopLeftRadius: '20px',
    borderTopRightRadius: '20px',
    padding: '20px'
  },
  paperAnchorDockedBottom: {
    border: '0px !important'
  },

  closeIconButton: {
    position: 'absolute !important',
    right: '23px',
    top: '23px',
    padding: '0px !important'
  },

  sheetContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },

  sheetContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  },

  title: {
    fontFamily: theme.headerFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    textAlign: 'center',
    padding: '0 33px 40px 33px',
    color: Colors.nomadColor
  },

  black: {
    color: Colors.black
  },
  grey: {
    color: Colors.grayDark
  },

  button: {
    width: '100%'
  }
}));

const BottomSheetComponent = ({
  children,
  title,
  button,
  disabled
}: {
  children?: ReactNode;
  title?: string;
  button?: ReactNode;
  disabled?: boolean;
}) => {
  const [state, setState] = useState(false);
  const classes = useStyles();

  const toggleDrawer = open => () => {
    setState(open);
  };

  return (
    <Fragment>
      <div
        className={classes.button}
        onClick={disabled ? undefined : toggleDrawer(true)}
      >
        {button}
      </div>

      <Drawer
        classes={{
          paper: classes.paper,
          paperAnchorDockedBottom: classes.paperAnchorDockedBottom
        }}
        anchor={'bottom'}
        open={state}
        onClose={toggleDrawer(false)}
      >
        <div className={classes.sheetContainer}>
          <IconButton
            className={classes.closeIconButton}
            onClick={toggleDrawer(false)}
          >
            <Icon type="close" />
          </IconButton>
          <span className={classNames(classes.title, classes.grey)}>
            {title}
          </span>
          <div className={classes.sheetContent}>{children}</div>
        </div>
      </Drawer>
    </Fragment>
  );
};

export default BottomSheetComponent;
