import React from 'react';
import { injectSheet } from 'features/sharedModules/styles/components/styles.js';
import classNames from 'classnames';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { Colors } from 'features/shared/constants/colors.js';
import { TextFontSizes } from 'features/shared/constants/fonts';

const styles = theme => {
  return {
    container: {
      width: '100%',
      maxWidth: '600px',
      margin: 'auto',
      paddingBottom: '25px'
    },
    slickDots: {
      '& li button:before': {
        color: Colors.slickDotsColor,
        fontSize: TextFontSizes.xxSmallPx
      },
      '& li.slick-active button:before': {
        color: theme.slickDotsFocusedColor
      }
    },
    slider: {
      '& .slick-track': {
        display: 'flex !important',

        '& .slick-slide': {
          height: 'auto',
          display: 'flex',

          '& > div': {
            display: 'flex',
            justifyContent: 'center',
            flex: 1,
            height: '100%',
            margin: '0 5px'
          }
        }
      }
    }
  };
};

const SlickCarousel = ({ children, classes }) => {
  const settings = {
    dots: true,
    className: classes.slider,
    dotsClass: classNames('slick-dots', classes.slickDots),
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    arrows: false
  };

  return (
    <div className={classes.container}>
      <Slider {...settings}>{children}</Slider>
    </div>
  );
};

export default injectSheet(styles)(SlickCarousel);
