import * as R from 'ramda';

import { utcNow } from 'features/shared/utils/dateTime.js';

const createSetAccessToken = key => (accessToken, expiresTime) => {
  localStorage.setItem(
    key,
    JSON.stringify({
      accessToken,
      expiresTime
    })
  );
};

const createRemoveAccessToken = key => () => {
  localStorage.removeItem(key);
};

const createGetAccessToken = key => () => {
  return R.pipe(
    key => localStorage.getItem(key),
    item => {
      if (R.isNil(item)) {
        return null;
      }

      const tokenData = JSON.parse(item);
      const currentTime = utcNow().getTime();

      return currentTime > tokenData.expiresTime ? null : tokenData.accessToken;
    }
  )(key);
};

const qAuthApiAccessTokenKey = 'qAuthApi_accessToken';

export const setQAuthApiAccessToken = createSetAccessToken(
  qAuthApiAccessTokenKey
);

export const removeQAuthApiAccessToken = createRemoveAccessToken(
  qAuthApiAccessTokenKey
);

export const getQAuthApiAccessToken = createGetAccessToken(
  qAuthApiAccessTokenKey
);
