import { useSelector } from 'react-redux';
import { isNil } from 'ramda';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { i18n } from '../../../../i18n';
import Heading1 from '../../shared/components/heading1';
import Paragraph from '../../shared/components/paragraph';
import sessionSelectors from '../../../shared/services/session/selectors';
import Section from 'features/report/shared/components/section';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell
} from '../../shared/components/table';
import { useReportStore } from '../../shared/services/reportStore';

const useStyles = createUseStyles({
  section: {
    marginBottom: '50px'
  },
  table: {
    marginBottom: '50px'
  },
  answerCell: {
    textAlign: 'right',
    whiteSpace: 'pre-line',
    maxWidth: '400px'
  }
});

const ProductPlatform = () => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const reportStore = useReportStore();
  const data = reportStore.userData?.productPlatformData;

  if (isNil(data)) {
    return null;
  }

  return (
    <Section>
      <div className={classes.section}>
        <Heading1>
          {i18n('report.productPlatform.header', translationsConfig)}
        </Heading1>
        <Paragraph>
          {i18n('report.productPlatform.description', translationsConfig)}
        </Paragraph>
      </div>
      {data.map(goal => (
        <div key={goal.name}>
          <Heading1>{goal.name}</Heading1>
          <Table className={classes.table}>
            <TableHeader>
              <TableHeaderRow>
                <TableHeaderCell>
                  {i18n('report.productPlatform.input', translationsConfig)}
                </TableHeaderCell>
                <TableHeaderCell className={classes.answerCell}>
                  {i18n('report.productPlatform.answer', translationsConfig)}
                </TableHeaderCell>
              </TableHeaderRow>
            </TableHeader>
            <TableBody>
              {goal.productPlatformQuestions?.map(question => (
                <TableBodyRow key={question.label}>
                  <TableBodyCell>{question.label}</TableBodyCell>
                  <TableBodyCell className={classes.answerCell}>
                    {question.answer}
                  </TableBodyCell>
                </TableBodyRow>
              ))}
              {goal.productPlatformNamespace && (
                <TableBodyRow>
                  <TableBodyCell>
                    {i18n(
                      'report.productPlatform.selectedProductPlatform',
                      translationsConfig
                    )}
                  </TableBodyCell>
                  <TableBodyCell className={classes.answerCell}>
                    {goal.productPlatformNamespace}
                  </TableBodyCell>
                </TableBodyRow>
              )}
              {goal.productPlatformReasoning && (
                <TableBodyRow>
                  <TableBodyCell>
                    {i18n('report.productPlatform.comment', translationsConfig)}
                  </TableBodyCell>
                  <TableBodyCell className={classes.answerCell}>
                    {goal.productPlatformReasoning}
                  </TableBodyCell>
                </TableBodyRow>
              )}
            </TableBody>
          </Table>
        </div>
      ))}
      <div style={{ pageBreakAfter: 'always' }} />
    </Section>
  );
};

export default ProductPlatform;
