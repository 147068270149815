import React, { Fragment } from 'react';
import { injectSheet } from 'features/sharedModules/styles/components/styles.js';
import classNames from 'classnames';
import { connect } from 'react-redux';
import * as R from 'ramda';

import Checkmark from 'assets/icons/checkmark.js';
import { Colors } from 'features/shared/constants/colors.js';
import { buildPublicFileUrl } from 'features/shared/utils/axios.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { i18n } from 'i18n/index.js';
import { FontWeights, TextFontSizes } from 'features/shared/constants/fonts';

const styles = theme => {
  return {
    list: {
      width: '100%',
      backgroundColor: Colors.white,
      boxShadow:
        '0px 4px 15px ' +
        Colors.listShadowColor +
        ', 0px 6px 15px ' +
        Colors.listShadowColor +
        ', 0px 8px 15px ' +
        Colors.listShadowColor +
        ', 0px 10px 15px ' +
        Colors.listShadowColor +
        ', 0px 12px 15px ' +
        Colors.listShadowColor +
        ', 0px 14px 35px ' +
        Colors.listShadowColor,
      padding: '0',
      borderRadius: '2px',
      margin: '0'
    },
    listItem: {
      listStyleType: 'none',
      minHeight: '60px',
      display: 'flex',
      alignItems: 'center',
      padding: '15px 25px',
      cursor: 'pointer',
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.mediumPx,
      lineHeight: TextFontSizes.mediumLineHeightPx,
      position: 'relative',
      '-webkit-tap-highlight-color': 'transparent',

      '& svg': {
        position: 'absolute',
        right: '-20px'
      },

      '& + &': {
        borderTop: `1px solid ${theme.placeholderColor}`
      }
    },
    imgSize: {
      marginRight: '19px',
      height: '40px',
      width: '40px'
    },
    multipleLineContainer: {
      display: 'flex',
      flexDirection: 'column'
    },
    multipleLineTitle: {
      fontFamily: theme.headerFontFamily,
      fontSize: TextFontSizes.mediumPx,
      lineHeight: TextFontSizes.mediumLineHeightPx,
      fontWeight: FontWeights.semiBold
    },
    multipleLineText: {
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      fontWeight: FontWeights.light
    }
  };
};

const List = ({
  className,
  classes,
  options,
  translations,
  selectedOption,
  onSelect,
  isMultipleLine,
  userOptionType,
  showIcons = true
}) => {
  if (!R.isNil(options) && !R.isEmpty(options)) {
    return (
      <ul className={classNames(classes.list, className)}>
        {options.map(item => {
          return (
            <Fragment key={item.id}>
              <li
                className={classes.listItem}
                onClick={() => onSelect(userOptionType, item)}
              >
                {showIcons && !R.isNil(item.icon) ? (
                  <img
                    alt="img"
                    src={buildPublicFileUrl(item.icon)}
                    className={classes.imgSize}
                  />
                ) : null}
                {isMultipleLine ? (
                  <div className={classes.multipleLineContainer}>
                    <span className={classes.multipleLineTitle}>
                      {i18n(item.title, translations)}
                    </span>
                    <span className={classes.multipleLineText}>
                      {i18n(item.text, translations)}
                    </span>
                  </div>
                ) : (
                  <span>{i18n(item.title, translations)}</span>
                )}

                {item.id === selectedOption ? <Checkmark /> : null}
              </li>
            </Fragment>
          );
        })}
      </ul>
    );
  } else {
    return null;
  }
};

export default R.compose(
  connect(state => {
    const translations = sessionSelectors.getTranslationsConfig(state);

    return {
      translations
    };
  }),
  injectSheet(styles)
)(List);
