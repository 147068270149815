import t from 'tcomb';

import createFormReducer from 'features/shared/services/form/reducer.js';
import { createReducer } from 'framework/tcomb/utils.js';

const FormsState = t.interface({}, 'FormsState');

const Reducer = createReducer(FormsState);

export default Reducer.of(createFormReducer({}));
