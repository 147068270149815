import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import * as R from 'ramda';

import { i18n } from 'i18n/index.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import { creators as userOptionsCreator } from 'features/shared/services/userOptions/actions.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import Slider from 'features/shared/components/slider/index.js';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import { Colors } from 'features/shared/constants/colors';

const useStyles = createUseStyles(theme => ({
  subTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    textAlign: 'center',
    paddingTop: '50px',
    paddingBottom: '20px',
    color: Colors.grayDark
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  }
}));

const Debt = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const financialSituationConfig = useSelector(
    sessionSelectors.getFinancialSituationConfig
  );
  const userFinancialSituation = useSelector(
    userOptionsSelectors.getUserFinancialSituation
  );

  const dispatch = useDispatch();
  const userFinancialSituationDebtChanged = (type, value) => {
    dispatch(
      userOptionsCreator.userFinancialSituationDebtChanged({
        type,
        value
      })
    );
  };

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('financialSituation.debt.naviTitle', translations)}
      </Navigation>

      <Page>
        <div className={classes.content}>
          <span className={classes.subTitle}>
            {i18n('financialSituation.debt.subTitle', translations)}
          </span>
          {R.map(
            d => (
              <Slider
                key={d.id}
                options={d}
                userOption={R.prop(d.id, userFinancialSituation.debt)}
                onChange={(type, value) => {
                  userFinancialSituationDebtChanged(d.id, value);
                }}
                displayTitle={true}
                displayValue={true}
              />
            ),
            financialSituationConfig.debt
          )}
        </div>

        <Footer>
          {nextRoute && (
            <Button
              disabled={R.pipe(
                R.map(d => R.prop(d.id, userFinancialSituation.debt)),
                R.any(R.isNil)
              )(financialSituationConfig.debt)}
              type={ButtonType.primary}
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n('shared.continue', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default Debt;
