import React from 'react';
import { injectSheet } from 'features/sharedModules/styles/components/styles.js';
import classNames from 'classnames';
import { Link as ReactRouterLink } from 'react-router-dom';

const styles = theme => {
  return {
    link: {
      color: theme.linkColor,
      textDecoration: 'none',
      cursor: 'pointer',
      display: 'flex',
      alignItems: 'center'
    }
  };
};

const Link = ({ children, className, classes, to, ...rest }: {
  children?: any,
  className?: string | undefined,
  classes?: { [key: string]: string, link: string },
  to?: string
}) => {
  return (
    <ReactRouterLink
      {...rest}
      to={to || ''}
      className={classNames(classes?.link , className)}
    >
      {children}
    </ReactRouterLink>
  );
};

export default injectSheet(styles)(Link);
