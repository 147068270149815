export const types = {
  USER_OPTION_CHANGED: 'USER_OPTIONS/USER_OPTION_CHANGED',
  SAVING_GOAL_OPTION_CHANGED: 'USER_OPTIONS/SAVING_GOAL_OPTION_CHANGED',
  USER_LIST_OPTION_CHANGED: 'USER_OPTIONS/USER_LIST_OPTION_CHANGED',
  USER_NO_SPECIFIC_GOAL_CHANGED: 'USER_OPTIONS/USER_NO_SPECIFIC_GOAL_CHANGED',
  USER_FINANCIAL_SITUATION_LEFT_CHANGED:
    'USER_OPTIONS/USER_FINANCIAL_SITUATION_LEFT_CHANGED',
  USER_FINANCIAL_SITUATION_DEBT_CHANGED:
    'USER_OPTIONS/USER_FINANCIAL_SITUATION_DEBT_CHANGED',
  USER_FINANCIAL_SITUATION_ASSETS_CHANGED:
    'USER_OPTIONS/USER_FINANCIAL_SITUATION_ASSETS_CHANGED',
  USER_FINANCIAL_SITUATION_BUFFER_CHANGED:
    'USER_OPTIONS/USER_FINANCIAL_SITUATION_BUFFER_CHANGED',
  USER_FINANCIAL_SITUATION_CREDITCARD_CHANGED:
    'USER_OPTIONS/USER_FINANCIAL_SITUATION_CREDITCARD_CHANGED',
  RESET_USER_OPTIONS: 'USER_OPTIONS/RESET_USER_OPTIONS',
  RESET_USER_QUIZ_ANSWERS: 'USER_OPTIONS/RESET_USER_QUIZ_ANSWERS',
  USER_KNOWLEDGES_CHANGED: 'USER_OPTIONS/USER_KNOWLEDGES_CHANGED',
  USER_INVESTMENT_KNOWLEDGES_CHANGED:
    'USER_OPTIONS/USER_INVESTMENT_KNOWLEDGES_CHANGED',
  USER_COUNSELING_CHANGED: 'USER_OPTIONS/USER_COUNSELING_CHANGED',
  USER_COUNSELING_THEMES_CHANGED: 'USER_OPTIONS/USER_COUNSELING_THEMES_CHANGED',
  USER_COUNSELING_SAVINGS_PLAN_CHANGED:
    'USER_OPTIONS/USER_COUNSELING_SAVINGS_PLAN_CHANGED',
  GET_USER_RISK: 'USER_OPTIONS/GET_USER_RISK',
  GET_USER_FEES: 'USER_OPTIONS/GET_USER_FEES',
  SET_MONTHLY_DEPOSIT: 'USER_OPTIONS/SET_MONTHLY_DEPOSIT'
};

export const creators = {
  userOptionChanged: data => ({
    type: types.USER_OPTION_CHANGED,
    payload: data
  }),
  savingGoalOptionChanged: data => ({
    type: types.SAVING_GOAL_OPTION_CHANGED,
    payload: data
  }),
  userListOptionChanged: data => ({
    type: types.USER_LIST_OPTION_CHANGED,
    payload: data
  }),
  userNoSpecificGoalChanged: data => ({
    type: types.USER_NO_SPECIFIC_GOAL_CHANGED,
    payload: data
  }),
  userFinancialSituationLeftChanged: data => ({
    type: types.USER_FINANCIAL_SITUATION_LEFT_CHANGED,
    payload: data
  }),
  userFinancialSituationDebtChanged: data => ({
    type: types.USER_FINANCIAL_SITUATION_DEBT_CHANGED,
    payload: data
  }),
  userFinancialSituationAssetsChanged: data => ({
    type: types.USER_FINANCIAL_SITUATION_ASSETS_CHANGED,
    payload: data
  }),
  userFinancialSituationBufferChanged: data => ({
    type: types.USER_FINANCIAL_SITUATION_BUFFER_CHANGED,
    payload: data
  }),
  userFinancialSituationCreditCardChanged: data => ({
    type: types.USER_FINANCIAL_SITUATION_CREDITCARD_CHANGED,
    payload: data
  }),
  userKnowledgesChanged: data => ({
    type: types.USER_KNOWLEDGES_CHANGED,
    payload: data
  }),
  userInvestmentKnowledgesChanged: data => ({
    type: types.USER_INVESTMENT_KNOWLEDGES_CHANGED,
    payload: data
  }),
  userCounselingChanged: data => ({
    type: types.USER_COUNSELING_CHANGED,
    payload: data
  }),
  userCounselingThemesChanged: data => ({
    type: types.USER_COUNSELING_THEMES_CHANGED,
    payload: data
  }),
  userCounselingSavingsPlanChanged: data => ({
    type: types.USER_COUNSELING_SAVINGS_PLAN_CHANGED,
    payload: data
  }),
  getUserRisk: isInitialRiskScore => ({
    type: types.GET_USER_RISK,
    payload: { isInitialRiskScore }
  }),
  getFeesData: () => ({
    type: types.GET_USER_FEES
  }),
  setMonthlyDeposit: data => ({
    type: types.SET_MONTHLY_DEPOSIT,
    payload: data
  }),
  resetUserOptions: () => ({
    type: types.RESET_USER_OPTIONS
  }),
  resetUserQuizAnswers: () => ({
    type: types.RESET_USER_QUIZ_ANSWERS
  })
};
