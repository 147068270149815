import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { i18n } from 'i18n/index.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors';
import SlickCarousel from 'features/shared/components/slickCarousel';
import {
  getReturnPreferenceNextRouteConfig,
  getDisconnectedAnswersType
} from 'features/shared/services/router/selectors.js';
import {
  disconnectedAnswersTypes,
  disconnectedAnswersCarouselCards
} from './helpers';
import Worried from 'assets/saving-robo-icons/worried';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';

const useStyles = createUseStyles(theme => {
  return {
    title: {
      fontFamily: theme.headerFontFamily,
      fontSize: TextFontSizes.xLargePx,
      lineHeight: TextFontSizes.xLargeLineHeightPx,
      textAlign: 'center',
      paddingTop: '20px'
    },
    subTitle: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      fontWeight: FontWeights.light,
      textAlign: 'center',
      paddingTop: '14px'
    },
    text: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      fontWeight: FontWeights.light,
      textAlign: 'center',
      padding: '14px 0 30px'
    },
    card: {
      maxWidth: '320px',
      minHeight: '263px',
      borderRadius: '15px',
      background: Colors.white,
      boxShadow:
        '0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 4px 3px ' +
        Colors.listShadowColor,
      display: 'flex !important',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'space-around',
      padding: '0 12px'
    },
    cardTitle: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: '20px',
      lineHeight: '24px',
      color: Colors.grayDark
    },
    cardDescription: {
      fontFamily: theme.subTitleFontFamily,
      fontWeight: FontWeights.light,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      color: Colors.grayLight,
      textAlign: 'center'
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      paddingTop: '60px',
      width: '100%'
    },
    buttons: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: '-15px'
    },
    button: {
      marginBottom: '10px'
    }
  };
});

const DisconnectedAnswers = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();
  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute(getReturnPreferenceNextRouteConfig);
  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const disconnectedAnswersType = useSelector(getDisconnectedAnswersType);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('disconnectedAnswers.navTitle', translations)}
      </Navigation>

      <Page showProgressBar={false}>
        <div className={classes.content}>
          <Worried />

          <span className={classes.title}>
            {i18n('disconnectedAnswers.title', translations)}
          </span>

          <span className={classes.subTitle}>
            {i18n('disconnectedAnswers.subTitle', translations)}
          </span>

          <span className={classes.text}>
            {i18n(
              disconnectedAnswersType ===
                disconnectedAnswersTypes.lowRiskHighReturn
                ? 'disconnectedAnswers.lowRiskHighReturnText'
                : 'disconnectedAnswers.highRiskLowReturnText',
              translations
            )}
          </span>
        </div>

        <SlickCarousel>
          {disconnectedAnswersCarouselCards
            .filter(
              ({ hideCondition }) => hideCondition !== disconnectedAnswersType
            )
            .map(({ title, icon, description }, i) => (
              <div key={i} className={classes.card}>
                <div className={classes.cardTitle}>
                  {i18n(title, translations)}
                </div>
                {icon}
                <div className={classes.cardDescription}>
                  {i18n(description, translations)}
                </div>
              </div>
            ))}
        </SlickCarousel>

        <Footer>
          <div className={classes.buttons}>
            <Button
              className={classes.button}
              type={ButtonType.primary}
              onClick={() => history.push(nextRoute.build(customerId))}
            >
              <span className={classes.buttonText}>
                {i18n('disconnectedAnswers.continueProcess', translations)}
              </span>
            </Button>

            <Button
              type={ButtonType.primary}
              onClick={() => {
                history.push(prevRoute.build(customerId));
              }}
            >
              <span className={classes.buttonText}>
                {i18n('disconnectedAnswers.adjustValue', translations)}
              </span>
            </Button>
          </div>
        </Footer>
      </Page>
    </Layout>
  );
};

export default DisconnectedAnswers;
