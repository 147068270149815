import React from 'react';
import { useSelector } from 'react-redux';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Section from '../../shared/components/section.js';
import { formatDate } from 'features/shared/utils/dateTime';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { i18n } from 'i18n/index.js';

const useStyles = createUseStyles(theme => ({
  grid: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: '200px'
  },
  gridColumn: {
    flex: '1 1 auto'
  },
  date: {
    marginLeft: '20px',
    borderBottom: '1px solid',
    width: '300px',
    textAlign: 'center'
  },
  dateFooter: {
    marginLeft: '20px',
    width: '300px',
    paddingTop: '5px',
    textAlign: 'center',
    fontWeight: 'bold'
  }
}));

const SignatureSection = ({ name }) => {
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const classes = useStyles();

  return (
    <Section>
      <div className={classes.grid}>
        <div className={classes.gridColumn}>
          <div className={classes.date}>
            {i18n('report.footer.dateText', translationsConfig).replace(
              '{date}',
              formatDate(cultureCode, new Date())
            )}
          </div>
          <div className={classes.dateFooter}>
            {i18n('report.footer.dateFooter', translationsConfig)}
          </div>
        </div>
        <div className={classes.gridColumn}>
          <div className={classes.date}>
            <br />
          </div>
          <div className={classes.dateFooter}>{name}</div>
        </div>
      </div>
    </Section>
  );
};

export default SignatureSection;
