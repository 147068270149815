import { createSelector } from 'reselect';
import * as R from 'ramda';
import { matchPath } from 'react-router';
import queryString from 'query-string';

import flatRouteTemplates from 'features/shared/utils/routeTemplates.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { routeTemplates } from 'features/shared/utils/routeTemplates.js';
import { getLocation } from 'framework/router/services/selectors.js';
import { globalizeSelectors } from 'framework/globalize/utils/index.js';
import userOptionsSelectors, {
  getDebtToAssetsRatio,
  getCreditCardDebtCalc
} from 'features/shared/services/userOptions/selectors.js';
import { disconnectedAnswersTypes } from 'features/onboarding/riskAssessment/disconnectedAnswers/components/helpers';

const getRouteData = (pathname, routeData, routeTemplates, isExactRequired) => {
  if (routeData.isCompleted) {
    return;
  }

  R.forEach(rt => {
    if (routeData.isCompleted) {
      return;
    }

    let match = matchPath(pathname, { path: rt.config, exact: false });

    if (match != null) {
      if (match.isExact || !isExactRequired) {
        routeData.route = rt;
        routeData.match = match;
        routeData.isCompleted = true;

        return;
      } else if (rt.children != null) {
        getRouteData(pathname, routeData, rt.children, isExactRequired);
      }
    }
  }, routeTemplates);
};

export const getRouteBase = (pathname, search, isExactRequired) => {
  let routeData = {
    route: {},
    match: {
      params: {}
    }
  };

  if (pathname == null) {
    return routeData;
  }

  getRouteData(pathname, routeData, routeTemplates, isExactRequired);

  routeData = {
    ...routeData,
    query: R.isNil(search) || R.isEmpty(search) ? {} : queryString.parse(search)
  };

  return routeData;
};

export const getRoute = createSelector(
  state => (state.location != null ? state.location.pathname : null),
  state => (state.location != null ? state.location.search : null),
  (pathname, search) => getRouteBase(pathname, search, true)
);

export const getPrevRoute = createSelector(
  state => (state.prevLocation != null ? state.prevLocation.pathname : null),
  state => (state.location != null ? state.location.search : null),
  (pathname, search) => getRouteBase(pathname, search, true)
);

export const isRouteFound = state => !R.isNil(getRoute(state).route.id);

export const getFirstRoute = createSelector(
  state => sessionSelectors.getRoutesFlowConfig(state),
  routesFlow => {
    const routeFlow = R.find(R.propEq('id', 'intro'))(routesFlow);

    return flatRouteTemplates[routeFlow.id];
  }
);

export const getNextRouteConfig = createSelector(
  state => sessionSelectors.getRoutesFlowConfig(state),
  state => routerSelectors.getRoute(state),
  (routesFlow, route) => {
    const currentRouteId = route.route.id;

    if (R.isNil(currentRouteId)) {
      return null;
    }

    const routeFlow = R.find(R.propEq('id', currentRouteId))(routesFlow);

    return R.isNil(routeFlow) || R.isNil(routeFlow.nextId)
      ? null
      : flatRouteTemplates[routeFlow.nextId];
  }
);

export const getPrevRouteConfig = createSelector(
  state => sessionSelectors.getRoutesFlowConfig(state),
  state => routerSelectors.getRoute(state),
  (routesFlow, route) => {
    const currentRouteId = route.route.id;

    if (R.isNil(currentRouteId)) {
      return null;
    }

    const routeFlow = R.find(R.propEq('id', currentRouteId))(routesFlow);

    return R.isNil(routeFlow) || R.isNil(routeFlow.prevId)
      ? null
      : flatRouteTemplates[routeFlow.prevId];
  }
);

export const getCurrentRouteConfig = createSelector(
  state => sessionSelectors.getRoutesFlowConfig(state),
  state => routerSelectors.getRoute(state),
  (routesFlow, route) => {
    const currentRouteId = route.route.id;

    if (R.isNil(currentRouteId)) {
      return null;
    }

    return R.find(R.propEq('id', currentRouteId))(routesFlow);
  }
);

export const getRouteById = createSelector(
  state => routerSelectors.getRoute(state),
  state => getPrevRouteConfig(state),
  (route, prevRoute) => {
    const prevId = route.query.prevId;

    if (R.isNil(prevId)) {
      return prevRoute;
    }

    return flatRouteTemplates[prevId] ? flatRouteTemplates[prevId] : prevRoute;
  }
);

export const getFirstNotComplitedRoute = createSelector(
  state => sessionSelectors.getRoutesFlowConfig(state),
  routesFlow => {
    const routeFlow = R.find(R.propEq('id', 'intro'))(routesFlow);

    return flatRouteTemplates[routeFlow.id];
  }
);

export const getStopScreenText = createSelector(
  state => getDebtToAssetsRatio(state),
  state => getCreditCardDebtCalc(state),
  (debtToAssetsRatio, creditCardDebtCalc) => {
    if (debtToAssetsRatio.isStopped) {
      return { ...debtToAssetsRatio, type: 'debtToAssetsRatio' };
    } else if (creditCardDebtCalc.isStopped) {
      return { ...creditCardDebtCalc, type: 'creditCardDebt' };
    } else {
      return { text: '', type: '' };
    }
  }
);

export const getSavingGoalsNextRouteConfig = createSelector(
  state => sessionSelectors.getRoutesFlowConfig(state),
  state => routerSelectors.getRoute(state),
  state => userOptionsSelectors.getUserGoal(state),
  (routesFlow, route, goal) => {
    const currentRouteId = route.route.id;

    if (R.isNil(currentRouteId)) {
      return null;
    }

    const routeFlow = R.find(R.propEq('id', currentRouteId))(routesFlow);

    if (R.isNil(routeFlow)) {
      return null;
    }

    return R.pipe(
      R.propOr([], 'nextIdByCondition'),
      R.find(i => doesGoalConditionMatch(i.condition, goal.id)),
      R.propOr(routeFlow.nextId, 'id'),
      id => flatRouteTemplates[id]
    )(routeFlow);
  }
);

export const getDisconnectedAnswersType = createSelector(
  state => userOptionsSelectors.getUserOptions(state),
  state => sessionSelectors.getRisksConfig(state),
  state => sessionSelectors.getReturnsConfig(state),
  (userOptions, risks, returns) => {
    const minRisk = Math.min(...risks.map(({ id }) => id));
    const maxRisk = Math.max(...risks.map(({ id }) => id));
    const minReturn = Math.min(...returns.map(({ id }) => id));
    const maxReturn = Math.max(...returns.map(({ id }) => id));

    if (userOptions.risk === minRisk && userOptions.return === maxReturn) {
      return disconnectedAnswersTypes.lowRiskHighReturn;
    }

    if (userOptions.risk === maxRisk && userOptions.return === minReturn) {
      return disconnectedAnswersTypes.highRiskLowReturn;
    }

    return null;
  }
);

export const getReturnPreferenceNextRouteConfig = createSelector(
  state => sessionSelectors.getRoutesFlowConfig(state),
  state => routerSelectors.getRoute(state),
  state => userOptionsSelectors.getRiskScore(state),
  state => getDisconnectedAnswersType(state),
  (routesFlow, route, riskScore, disconnectedAnswersType) => {
    const currentRouteId = route.route.id;

    if (R.isNil(currentRouteId)) {
      return null;
    }

    const routeFlow = R.find(R.propEq('id', currentRouteId))(routesFlow);

    if (R.isNil(routeFlow)) {
      return null;
    }

    const disconnectedAnswersId = 'disconnectedAnswers';
    if (
      currentRouteId !== disconnectedAnswersId &&
      R.includes(
        disconnectedAnswersId,
        routesFlow.map(({ id }) => id)
      ) &&
      disconnectedAnswersType
    ) {
      return flatRouteTemplates[disconnectedAnswersId];
    }

    return R.pipe(
      R.propOr([], 'nextIdByCondition'),
      R.find(i => doesRiskScoreConditionMatch(i.condition, riskScore)),
      R.propOr(routeFlow.nextId, 'id'),
      id => flatRouteTemplates[id]
    )(routeFlow);
  }
);

export const getAssetsNextRouteConfig = createSelector(
  state => sessionSelectors.getRoutesFlowConfig(state),
  state => routerSelectors.getRoute(state),
  state => getDebtToAssetsRatio(state),
  (routesFlow, route, debtToAssetsRatio) => {
    const currentRouteId = route.route.id;

    if (R.isNil(currentRouteId)) {
      return null;
    }

    const routeFlow = R.find(R.propEq('id', currentRouteId))(routesFlow);

    if (R.isNil(routeFlow)) {
      return null;
    }

    return R.pipe(
      R.propOr([], 'nextIdByCondition'),
      R.find(i =>
        doesIsStoppedConditionMatch(i.condition, debtToAssetsRatio.isStopped)
      ),
      R.propOr(routeFlow.nextId, 'id'),
      id => flatRouteTemplates[id]
    )(routeFlow);
  }
);

export const getCreditCardNextRouteConfig = createSelector(
  state => sessionSelectors.getRoutesFlowConfig(state),
  state => routerSelectors.getRoute(state),
  state => getCreditCardDebtCalc(state),
  state => userOptionsSelectors.getUserFinancialSituation(state),
  (routesFlow, route, creditCardDebtCalc, userFinancialSituation) => {
    const currentRouteId = route.route.id;

    if (R.isNil(currentRouteId)) {
      return null;
    }

    const routeFlow = R.find(R.propEq('id', currentRouteId))(routesFlow);

    if (R.isNil(routeFlow)) {
      return null;
    }

    const isStopped =
      routeFlow.isStoppedMethod === 'creditCardDebtCalc'
        ? creditCardDebtCalc.isStopped
        : !!userFinancialSituation.creditCard;

    return R.pipe(
      R.propOr([], 'nextIdByCondition'),
      R.find(i => doesIsStoppedConditionMatch(i.condition, isStopped)),
      R.propOr(routeFlow.nextId, 'id'),
      id => flatRouteTemplates[id]
    )(routeFlow);
  }
);

export const getSavingHorizonPrevRouteConfig = createSelector(
  state => sessionSelectors.getRoutesFlowConfig(state),
  state => routerSelectors.getRoute(state),
  state => userOptionsSelectors.getUserGoal(state),
  (routesFlow, route, goal) => {
    const currentRouteId = route.route.id;

    if (R.isNil(currentRouteId)) {
      return null;
    }

    const routeFlow = R.find(R.propEq('id', currentRouteId))(routesFlow);

    if (R.isNil(routeFlow)) {
      return null;
    }

    return R.pipe(
      R.propOr([], 'prevIdByCondition'),
      R.find(i => doesGoalConditionMatch(i.condition, goal.id)),
      R.propOr(routeFlow.prevId, 'id'),
      id => flatRouteTemplates[id]
    )(routeFlow);
  }
);

export const getProgress = createSelector(
  state => sessionSelectors.getRoutesFlowConfig(state),
  state => routerSelectors.getRoute(state),
  (routesFlow, { route: { id } }) => {
    if (R.isNil(id)) {
      return null;
    }

    const routeFlow = R.find(R.propEq('id', id))(routesFlow);

    if (R.isNil(routeFlow)) {
      return null;
    }

    const progressFlow = routesFlow.filter(
      ({ excludeFromProgress }) => !excludeFromProgress
    );
    const index = routeFlow.excludeFromProgress
      ? R.findIndex(R.propEq('id', routeFlow.prevId))(progressFlow)
      : R.findIndex(R.propEq('id', id))(progressFlow);
    return `${(((index + 1) / progressFlow.length) * 100).toFixed(0)}%`;
  }
);

export const getRiskAssessmentPrevRouteConfig = createSelector(
  state => sessionSelectors.getRoutesFlowConfig(state),
  state => routerSelectors.getRoute(state),
  state => userOptionsSelectors.getUserGoal(state),
  (routesFlow, route, goal) => {
    const currentRouteId = route.route.id;

    if (R.isNil(currentRouteId)) {
      return null;
    }

    const routeFlow = R.find(R.propEq('id', currentRouteId))(routesFlow);

    if (R.isNil(routeFlow)) {
      return null;
    }

    return R.pipe(
      R.propOr([], 'prevIdByCondition'),
      R.find(i => doesGoalConditionMatch(i.condition, goal.id)),
      R.propOr(routeFlow.prevId, 'id'),
      id => flatRouteTemplates[id]
    )(routeFlow);
  }
);

export const getLeftPrevRouteConfig = createSelector(
  state => sessionSelectors.getRoutesFlowConfig(state),
  state => routerSelectors.getRoute(state),
  state => userOptionsSelectors.getUserGoal(state),
  state => userOptionsSelectors.getRiskScore(state),
  (routesFlow, route, goal, riskScore) => {
    const currentRouteId = route.route.id;

    if (R.isNil(currentRouteId)) {
      return null;
    }

    const routeFlow = R.find(R.propEq('id', currentRouteId))(routesFlow);

    if (R.isNil(routeFlow)) {
      return null;
    }

    return R.pipe(
      R.propOr([], 'prevIdByCondition'),
      R.find(
        i =>
          doesGoalConditionMatch(i.condition, goal.id) ||
          doesRiskScoreConditionMatch(i.condition, riskScore)
      ),
      R.propOr(routeFlow.prevId, 'id'),
      id => flatRouteTemplates[id]
    )(routeFlow);
  }
);

const routerSelectors = globalizeSelectors(['router_state'], {
  getLocation,
  getRoute,
  getPrevRoute,
  isRouteFound
});

export default routerSelectors;

const doesGoalConditionMatch = (condition, goalId) =>
  !R.isNil(goalId) && !R.isNil(condition.goalId) && goalId === condition.goalId;

const doesRiskScoreConditionMatch = (condition, riskScoreNumber) =>
  !R.isNil(riskScoreNumber) &&
  !R.isNil(condition.riskScore) &&
  riskScoreNumber <= condition.riskScore;

const doesIsStoppedConditionMatch = (condition, isStopped) =>
  !R.isNil(isStopped) &&
  !R.isNil(condition.isStopped) &&
  isStopped === condition.isStopped;
