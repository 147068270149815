import React, { Component } from 'react';
import { ReactReduxContext } from 'react-redux';
import { Router as RouterRR } from 'react-router';
import { propTypes, ReactNode } from 'tcomb-react';
import t from 'tcomb';

const PropTypes = t.interface({
  getLocation: t.Function,
  history: t.Object,
  onLocationChanged: t.Function,
  children: t.maybe(ReactNode)
});

const isDevelopment = process.env.NODE_ENV === 'development';

class Router extends Component {
  componentDidMount() {
    this.inTimeTravelling = false;

    if (isDevelopment) {
      // Subscribe to store changes
      this.unsubscribe = this.context.store.subscribe(() => {
        // Extract store's location
        const {
          pathname: pathnameInStore,
          search: searchInStore,
          hash: hashInStore
        } = this.props.getLocation(this.context.store.getState());
        // Extract history's location
        const {
          pathname: pathnameInHistory,
          search: searchInHistory,
          hash: hashInHistory
        } = this.props.history.location;

        // If we do time travelling, the location in store is changed but location in history is not changed
        if (
          pathnameInHistory !== pathnameInStore ||
          searchInHistory !== searchInStore ||
          hashInHistory !== hashInStore
        ) {
          this.inTimeTravelling = true;
          // Update history's location to match store's location
          this.props.history.push({
            pathname: pathnameInStore,
            search: searchInStore,
            hash: hashInStore
          });
        }
      });
    }

    // Listen to history changes
    this.unlisten = this.props.history.listen((location, action) => {
      // Dispatch onLocationChanged except when we're in time travelling
      if (!this.inTimeTravelling) {
        this.props.onLocationChanged(location, action);
      } else {
        this.inTimeTravelling = false;
      }
    });

    this.props.onLocationChanged(
      this.props.history.location,
      this.props.history.action
    );
  }

  componentWillUnmount() {
    if (isDevelopment) {
      this.unsubscribe();
    }

    this.unlisten();
  }

  render() {
    let { history, children } = this.props;

    return <RouterRR history={history}>{children}</RouterRR>;
  }
}

Router.contextType = ReactReduxContext;

Router.propTypes = propTypes(PropTypes);

export default Router;
