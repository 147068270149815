import React from 'react';
import hoistStatics from 'hoist-non-react-statics';

export default staticProps => InnerComponent => {
  const WithStaticProps = props => (
    <InnerComponent {...staticProps} {...props} />
  );

  return hoistStatics(WithStaticProps, InnerComponent);
};
