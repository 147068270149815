import React, { Fragment, useEffect } from 'react';
import * as R from 'ramda';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import { i18n } from 'i18n/index.js';
import SavingRoboCelebrate from 'assets/saving-robo-icons/celebrate.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import statusSelectors from 'features/shared/services/status/selectors.js';
import { getSummary } from 'features/shared/services/userOptions/selectors.js';
import { creators as userOptionsCreator } from 'features/shared/services/userOptions/actions.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import I18n from 'features/shared/components/i18n/index.js';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import ProgressBar from 'features/shared/components/progressBar/index';

const useStyles = createUseStyles(theme => ({
  title: {
    fontFamily: theme.headerFontFamily,
    fontSize: TextFontSizes.xLargePx,
    lineHeight: TextFontSizes.xLargeLineHeightPx,
    textAlign: 'center',
    color: Colors.grayDark
  },
  subTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    fontWeight: FontWeights.light,
    textAlign: 'center',
    paddingBottom: '45px',
    paddingTop: '20px',
    color: Colors.grayDark
  },
  text: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: FontWeights.light,
    textAlign: 'center',
    paddingBottom: '20px'
  },
  link: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    fontWeight: FontWeights.semiBold,
    textAlign: 'center',
    padding: '13px 0'
  },
  orangeText: {
    color: theme.imagePrimaryColor
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    flex: '1',
    paddingTop: '30px'
  },
  runAgainButton: {
    fontWeight: FontWeights.medium,
    color: `${theme.textColor} !important`
  }
}));

const Summary = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const summary = useSelector(getSummary);
  const riskScore = useSelector(userOptionsSelectors.getRiskScore);
  const maxRiskScore = useSelector(sessionSelectors.getMaxRiskScore);
  const riskScoreStatus = useSelector(
    statusSelectors.getStatusOrDefault('riskScore_state')
  );
  const dispatch = useDispatch();
  const resetUserOptions = () => {
    dispatch(userOptionsCreator.resetUserOptions());
  };
  const getUserRisk = () => {
    dispatch(userOptionsCreator.getUserRisk(false));
  };

  useEffect(() => {
    getUserRisk();
  }, []);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('summary.naviTitle', translations)}
      </Navigation>

      <Page>
        {riskScoreStatus.isPending && <ProgressBar />}
        {!riskScoreStatus.isPending && (
          <Fragment>
            <div className={classes.content}>
              <SavingRoboCelebrate />

              <div className={classes.subTitle}>
                <I18n
                  text={i18n('summary.title', translations)}
                  replaceParams={{
                    '{0}': (
                      <span className={classes.orangeText}>
                        {summary.countToSave}
                      </span>
                    )
                  }}
                />
              </div>

              <div className={classes.text}>
                <I18n
                  text={i18n('summary.text_1', translations)}
                  replaceParams={{
                    '{0}': (
                      <span className={classes.orangeText}>
                        {summary.goalTitle
                          ? R.toLower(summary.goalTitle)
                          : i18n('shared.noValueText', translations)}
                      </span>
                    ),
                    '{1}': (
                      <span className={classes.orangeText}>
                        {summary.horizonTitle
                          ? i18n(summary.horizonTitle, translations)
                          : i18n('shared.noValueText', translations)}
                      </span>
                    )
                  }}
                />
              </div>

              <div className={classes.text}>
                <I18n
                  text={i18n('summary.text_2', translations)}
                  replaceParams={{
                    '{0}': (
                      <span className={classes.orangeText}>
                        {!R.isNil(riskScore)
                          ? `${riskScore}/${maxRiskScore}`
                          : i18n('shared.noValueText', translations)}
                      </span>
                    )
                  }}
                />
              </div>

              <Button
                type={ButtonType.secondary}
                onClick={() => {
                  history.push(routeTemplates.savingGoals.build(customerId));
                }}
                className={classes.runAgainButton}
              >
                {i18n('summary.runAgain', translations)}
              </Button>
            </div>

            <Footer>
              {nextRoute && (
                <Button
                  type={ButtonType.primary}
                  onClick={() => {
                    history.push(nextRoute.build(customerId));
                  }}
                >
                  {i18n('shared.continue', translations)}
                </Button>
              )}
            </Footer>
          </Fragment>
        )}
      </Page>
    </Layout>
  );
};

export default Summary;
