import * as R from 'ramda';

export const mapServerInstrumentDetailsToClient = (
  historicalReturn,
  fundMeta,
  currentDate
) => {
  const fiveYears = 5 * 12;
  const months = currentDate.getFullYear() * 12 + currentDate.getMonth();
  const last5YearsTrend = R.pipe(
    R.map(i => {
      const date = new Date(i.date);

      return {
        value: i.value,
        months: date.getFullYear() * 12 + date.getMonth()
      };
    }),
    R.filter(i => months - i.months <= fiveYears),
    R.map(i => i.value)
  )(historicalReturn);

  return {
    last5YearsTrend,
    currency: R.prop('Currency', fundMeta)
  };
};
