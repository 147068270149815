export const types = {
  STARTED: 'APPLICATION/STARTED'
};

export const creators = {
  started: customerId => ({
    type: types.STARTED,
    payload: { customerId }
  })
};
