import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import * as R from 'ramda';
import create from 'zustand';

import { i18n } from 'i18n/index.js';
import { FontWeights } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Header from 'features/shared/components/screenLayout/header.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import Navigation from 'features/shared/components/screenLayout/navigation';
import Button, { ButtonType } from 'features/shared/components/button/index';
import MultipleSelectList, {
  SelectModes
} from 'features/shared/components/list/multipleSelectList.js';
import SpeechBubble from 'features/shared/components/speechBubble.js';
import Dialog, { useStore as useDialogStore } from './dialog.js';

const useStyles = createUseStyles(theme => ({
  multipleSelectList: {
    margin: '0'
  },
  sectionHeader: {
    padding: '20px 20px 25px 20px',
    textAlign: 'center',
    fontFamily: theme.subTitleFontFamily,
    fontWeight: FontWeights.semiBold,
    color: Colors.grayDark
  }
}));

const useStore = create((set, get) => ({
  moneyOrigins: [],
  moneyOriginOther: null,
  changeMoneyOrigins: (option, isSelected, moneyOriginOther) => {
    const { moneyOrigins } = get();

    if (option.isDialogOption) {
      if (isSelected) {
        set({
          moneyOriginOther
        });
      } else {
        set({
          moneyOriginOther: null
        });
      }
    }

    const isOptionSelected = R.includes(option.id, moneyOrigins);

    if (isSelected === isOptionSelected) {
      return;
    }

    if (isSelected) {
      set({
        moneyOrigins: R.append(option.id, moneyOrigins)
      });
    } else {
      set({
        moneyOrigins: R.reject(R.equals(option.id), moneyOrigins)
      });
    }
  }
}));

const getIsMoneyOriginsValid = value => !R.isNil(value) && !R.isEmpty(value);

const Screen = () => {
  const classes = useStyles();
  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const { customerId } = useParams();
  const history = useHistory();
  const store = useStore();
  const dialogStoreState = useDialogStore.getState();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const moneyOriginsConfig = useSelector(
    sessionSelectors.getMoneyOriginsConfig
  );
  const moneyOriginOptions = React.useMemo(
    () =>
      R.map(
        R.pipe(
          i => ({
            id: i.id,
            title: i.title
          }),
          o =>
            o.id === 6
              ? {
                  ...o,
                  isDialogOption: true,
                  showIcon: R.includes(6, store.moneyOrigins),
                  iconType: 'edit',
                  onIconClick: () => {
                    dialogStoreState.openDialogForEdit(
                      {
                        id: 6,
                        isDialogOption: true
                      },
                      store.moneyOriginOther
                    );
                  }
                }
              : o
        ),
        moneyOriginsConfig
      ),
    [
      moneyOriginsConfig,
      translations,
      store.moneyOrigins,
      store.moneyOriginOther
    ]
  );

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('aml1.naviTitle', translations)}
      </Navigation>

      <Page showProgressBar={false}>
        <Dialog
          onSubmit={(option, origin) => {
            store.changeMoneyOrigins(option, true, origin);
          }}
        />
        <Header>{i18n('aml1.subTitle', translations)}</Header>
        <div>
          <SpeechBubble
            title={i18n('aml1.weTakeComplianceSeriouslyTitle', translations)}
            message={i18n(
              'aml1.weTakeComplianceSeriouslyMessage',
              translations
            )}
          />
          <div className={classes.sectionHeader}>
            {i18n('aml1.whatIsTheOriginOfTheMoney', translations)}
          </div>
          <MultipleSelectList
            className={classes.multipleSelectList}
            selectMode={SelectModes.checkbox}
            options={moneyOriginOptions}
            selectedOption={store.moneyOrigins}
            onSelect={(userOptionType, optionId, isSelected, option) => {
              if (R.length(store.moneyOrigins) === 5 && isSelected) {
                return;
              }

              if (option.isDialogOption && isSelected) {
                dialogStoreState.openDialogForCreate(option);
              } else {
                store.changeMoneyOrigins(option, isSelected);
              }
            }}
          />
        </div>

        <Footer>
          {nextRoute && (
            <Button
              disabled={!getIsMoneyOriginsValid(store.moneyOrigins)}
              type={ButtonType.primary}
              onClick={() => {
                const { moneyOrigins } = useStore.getState();

                if (getIsMoneyOriginsValid(moneyOrigins)) {
                  history.push(nextRoute.build(customerId));
                }
              }}
            >
              {i18n('shared.continue', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default Screen;
