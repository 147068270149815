import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Heading1 from '../../shared/components/heading1';
import Heading2 from '../../shared/components/heading2';
import { FontWeights } from '../../shared/constants/fonts.js';
import Section from '../../shared/components/section.js';
import { formatNumber } from 'features/shared/utils/number';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell
} from '../../shared/components/table';
import { riskScoreToNumber } from 'features/shared/utils/mapping.js';
import Paragraph from '../../shared/components/paragraph';
import GoalTableIcon from 'features/report/shared/components/goalTableIcon';

const useStyles = createUseStyles(theme => ({
  title: {
    width: '210px'
  },
  boldTableData: {
    fontWeight: FontWeights.bold
  },
  aggregatedRow: {
    height: '50px'
  },
  smallHeader: {
    margin: '30px 0'
  },
  goalIcon: {
    fill: theme.primaryColor,
    width: 30
  },
  footer: {
    marginTop: '40px'
  },
  numberCell: {
    textAlign: 'right'
  }
}));

const defaultInvestmentPlan = {
  goalsTableColumns: {
    goalName: true,
    expectedAnnualReturn: true,
    expectedVolatility: true,
    expectedValueInYears: true,
    equityPortion: true
  }
};

const InvestmentPlanSection = ({ goals, chartData, showGoalIcons }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const { investmentPlan: { goalsTableColumns } = defaultInvestmentPlan } =
    useSelector(sessionSelectors.getReportConfig);

  return (
    <Section data-testid={'investment-plan-section'}>
      <Heading1>
        {i18n('report.investmentPlan.header', translationsConfig)}
      </Heading1>
      <Heading2 className={classes.smallHeader}>
        {i18n('report.investmentPlan.details', translationsConfig)}
      </Heading2>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            {showGoalIcons && <TableHeaderCell />}
            <TableHeaderCell>
              {i18n('report.purpose.goal', translationsConfig)}
            </TableHeaderCell>
            <TableHeaderCell className={classes.numberCell}>
              {i18n('report.purpose.riskScore', translationsConfig)}
            </TableHeaderCell>
            <TableHeaderCell className={classes.numberCell}>
              {i18n('report.investmentPlan.initialDeposit', translationsConfig)}
            </TableHeaderCell>
            <TableHeaderCell className={classes.numberCell}>
              {i18n('report.investmentPlan.monthlySaving', translationsConfig)}
            </TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {goals &&
            goals.map(goal => (
              <TableBodyRow key={goal.goalId} className={classes.row}>
                {showGoalIcons && (
                  <TableBodyCell className={classes.goalIcon}>
                    <GoalTableIcon name={goal.name} icon={goal.icon} />
                  </TableBodyCell>
                )}
                <TableBodyCell>{goal.name}</TableBodyCell>
                <TableBodyCell
                  className={classNames(
                    classes.boldTableData,
                    classes.numberCell
                  )}
                >
                  {riskScoreToNumber(goal.data.riskScore)}
                </TableBodyCell>
                <TableBodyCell
                  className={classNames(
                    classes.boldTableData,
                    classes.numberCell
                  )}
                >
                  {formatNumber(cultureCode, goal.data?.firstDeposit || 0)}
                </TableBodyCell>
                <TableBodyCell
                  className={classNames(
                    classes.boldTableData,
                    classes.numberCell
                  )}
                >
                  {formatNumber(cultureCode, goal.data?.monthlyDeposit || 0)}
                </TableBodyCell>
              </TableBodyRow>
            ))}
          {goals && goals.length > 1 ? (
            <TableBodyRow className={classes.aggregatedRow}>
              {showGoalIcons && <TableBodyCell />}
              <TableBodyCell>
                {i18n('report.investmentPlan.aggregated', translationsConfig)}
              </TableBodyCell>
              <TableBodyCell />
              <TableBodyCell
                className={classNames(
                  classes.boldTableData,
                  classes.numberCell
                )}
              >
                {formatNumber(
                  cultureCode,
                  goals.reduce(
                    (prev, curr) => prev + (curr.data?.firstDeposit || 0),
                    0
                  )
                )}
              </TableBodyCell>
              <TableBodyCell
                className={classNames(
                  classes.boldTableData,
                  classes.numberCell
                )}
              >
                {formatNumber(
                  cultureCode,
                  goals.reduce(
                    (prev, curr) => prev + (curr.data?.monthlyDeposit || 0),
                    0
                  )
                )}
              </TableBodyCell>
            </TableBodyRow>
          ) : null}
        </TableBody>
      </Table>
      <Heading2 className={classes.smallHeader}>
        {i18n('report.investmentPlan.keyStatistics', translationsConfig)}
      </Heading2>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            {showGoalIcons && <TableHeaderCell />}
            {goalsTableColumns?.goalName && (
              <TableHeaderCell>
                {i18n('report.purpose.goal', translationsConfig)}
              </TableHeaderCell>
            )}
            {goalsTableColumns?.expectedAnnualReturn && (
              <TableHeaderCell className={classes.numberCell}>
                {i18n(
                  'report.investmentPlan.expectedAnnualReturn',
                  translationsConfig
                )}
              </TableHeaderCell>
            )}
            {goalsTableColumns?.expectedVolatility && (
              <TableHeaderCell className={classes.numberCell}>
                {i18n(
                  'report.investmentPlan.expectedVolatility',
                  translationsConfig
                )}
              </TableHeaderCell>
            )}
            {goalsTableColumns?.expectedValueInYears && (
              <TableHeaderCell className={classes.numberCell}>
                {i18n(
                  'report.investmentPlan.expectedValue',
                  translationsConfig
                ).replace(
                  '{0}',
                  i18n(
                    'report.shared.amount',
                    translationsConfig
                  )?.toLowerCase()
                )}
              </TableHeaderCell>
            )}
            {goalsTableColumns?.equityPortion && (
              <TableHeaderCell className={classes.numberCell}>
                {i18n(
                  'report.investmentPlan.equityPortion',
                  translationsConfig
                )}
              </TableHeaderCell>
            )}
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {goals &&
            goals.map(goal => {
              const goalChartData = chartData.find(
                c => c.goalId === goal.goalId
              );

              return (
                <TableBodyRow key={goal.goalId} className={classes.row}>
                  {showGoalIcons && (
                    <TableBodyCell className={classes.goalIcon}>
                      <GoalTableIcon name={goal.name} icon={goal.icon} />
                    </TableBodyCell>
                  )}
                  {goalsTableColumns?.goalName && (
                    <TableBodyCell>{goal.name}</TableBodyCell>
                  )}
                  {goalsTableColumns?.expectedAnnualReturn && (
                    <TableBodyCell
                      className={classNames(
                        classes.boldTableData,
                        classes.numberCell
                      )}
                    >
                      {formatNumber(
                        cultureCode,
                        goalChartData.expectedAnnualReturn,
                        0,
                        1
                      )}{' '}
                      %
                    </TableBodyCell>
                  )}
                  {goalsTableColumns?.expectedVolatility && (
                    <TableBodyCell
                      className={classNames(
                        classes.boldTableData,
                        classes.numberCell
                      )}
                    >
                      {formatNumber(
                        cultureCode,
                        goalChartData.expectedVolatility,
                        0,
                        1
                      )}{' '}
                      %
                    </TableBodyCell>
                  )}
                  {goalsTableColumns?.expectedValueInYears && (
                    <TableBodyCell
                      className={classNames(
                        classes.boldTableData,
                        classes.numberCell
                      )}
                    >
                      {formatNumber(cultureCode, goal?.expectedValue, 0, 0)}
                    </TableBodyCell>
                  )}
                  {goalsTableColumns?.equityPortion && (
                    <TableBodyCell
                      className={classNames(
                        classes.boldTableData,
                        classes.numberCell
                      )}
                    >
                      {formatNumber(
                        cultureCode,
                        goalChartData.equityPortion,
                        0,
                        1
                      )}{' '}
                      %
                    </TableBodyCell>
                  )}
                </TableBodyRow>
              );
            })}
        </TableBody>
      </Table>
      <div className={classes.footer}>
        <Paragraph>
          {i18n('report.investmentPlan.footerText', translationsConfig)}
        </Paragraph>
      </div>
      <div style={{ pageBreakAfter: 'always' }} />
    </Section>
  );
};

export default InvestmentPlanSection;
