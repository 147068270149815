import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import { i18n } from 'i18n/index.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import { creators as userOptionsCreator } from 'features/shared/services/userOptions/actions.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import TileInput, {
  CardSize
} from 'features/shared/components/tileInput/index.js';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import PoweredBy from 'features/shared/components/screenLayout/poweredBy.js';

const useStyles = createUseStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '50px',
    paddingBottom: '20px'
  },
  subTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largePx,
    textAlign: 'center',
    color: Colors.grayDark,
    padding: '15px 0'
  },
  text: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    textAlign: 'center',
    paddingBottom: '20px'
  },
  icon: {
    marginBottom: '17px'
  },
  page: {
    paddingBottom: '42px'
  },
  footer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'fixed',
    bottom: '0',
    left: '0',
    width: '100%',
    background:
      'linear-gradient(to bottom, rgba(255,255,255,0) 0%,rgba(243, 243, 243,1) 100%)'
  },
  actionButtons: {
    paddingBottom: '5px',
    display: 'flex',
    alignItems: 'center'
  }
}));

const Themes = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const [isButtonColored, setIsButtonColored] = useState(false);

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const themes = useSelector(sessionSelectors.getThemesConfig);
  const userCounselingOptions = useSelector(
    userOptionsSelectors.getCounselingOptions
  );
  const dispatch = useDispatch();
  const userCounselingThemesChanged = value => {
    dispatch(userOptionsCreator.userCounselingThemesChanged({ value }));
  };

  useEffect(() => {
    const handleScroll = () => {
      const pageHeight = document.getElementById('page').offsetHeight;
      const windowHeight = window.innerHeight;
      const offset = pageHeight - windowHeight - 25;

      if (window.scrollY < offset) {
        setIsButtonColored(true);
      } else {
        setIsButtonColored(false);
      }
    };

    handleScroll();

    document.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      document.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [themes]);

  return (
    <Layout isPoweredByShown={false}>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('counseling.themes.naviTitle', translations)}
      </Navigation>

      <Page className={classes.page}>
        <div className={classes.content}>
          <span className={classes.subTitle}>
            {i18n('counseling.themes.subTitle', translations)}
          </span>
          <span className={classes.text}>
            {i18n('counseling.themes.text_1', translations)}
          </span>
          <span className={classes.text}>
            {i18n('counseling.themes.text_2', translations)}
          </span>
          <TileInput
            size={CardSize.small}
            multiple
            options={themes}
            selectedOption={userCounselingOptions.theme}
            onSelect={(type, value) => {
              userCounselingThemesChanged(value);
            }}
          />
        </div>

        <Footer>
          <div className={classes.footer}>
            <div className={classes.actionButtons}>
              {nextRoute && (
                <Button
                  id="continueButton"
                  type={ButtonType.primary}
                  isColoredButton={isButtonColored}
                  onClick={() => {
                    history.push(nextRoute.build(customerId));
                  }}
                >
                  {i18n('shared.continue', translations)}
                </Button>
              )}
            </div>
            <PoweredBy />
          </div>
        </Footer>
      </Page>
    </Layout>
  );
};

export default Themes;
