import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import Section from '../../shared/components/section.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import {
  Table,
  TableBody,
  TableBodyRow,
  TableBodyCell,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell
} from '../../shared/components/table';
import { i18n } from 'i18n/index.js';
import { formatNumber } from 'features/shared/utils/number.js';

const useStyles = createUseStyles(theme => ({
  positiveNumber: {
    color: theme.successColor
  },
  negativeNumber: {
    color: theme.primaryColor
  },
  numberCell: {
    textAlign: 'right'
  }
}));

const CashflowForecastTable = ({ cashflowTableData }) => {
  const classes = useStyles();
  const cultureCode = useSelector(sessionSelectors.getCultureCode);

  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  return (
    <Section data-testid={'cashflow-table-section'}>
      <Table>
        <TableHeader>
          <TableHeaderRow>
            <TableHeaderCell>
              {i18n('report.cashflow.year', translationsConfig)}
            </TableHeaderCell>
            <TableHeaderCell className={classes.numberCell}>
              {i18n('report.cashflow.deposits', translationsConfig)}
            </TableHeaderCell>
            <TableHeaderCell className={classes.numberCell}>
              {i18n('report.cashflow.withdrawals', translationsConfig)}
            </TableHeaderCell>
            <TableHeaderCell className={classes.numberCell}>
              {i18n('report.shared.value', translationsConfig)}
            </TableHeaderCell>
          </TableHeaderRow>
        </TableHeader>
        <TableBody>
          {cashflowTableData.map(({ amount, date, deposits, withdrawals }) => (
            <TableBodyRow key={date}>
              <TableBodyCell>{date.slice(0, 4)}</TableBodyCell>
              <TableBodyCell
                className={classNames(classes.numberCell, {
                  [classes.positiveNumber]: deposits > 0
                })}
              >
                {formatNumber(cultureCode, deposits || 0, 0, 0)}
              </TableBodyCell>
              <TableBodyCell
                className={classNames(classes.numberCell, {
                  [classes.negativeNumber]: withdrawals > 0
                })}
              >
                {formatNumber(cultureCode, withdrawals || 0, 0, 0)}
              </TableBodyCell>
              <TableBodyCell
                className={classNames(classes.numberCell, {
                  [classes.negativeNumber]: amount < 0,
                  [classes.positiveNumber]: amount > 0
                })}
              >
                {formatNumber(cultureCode, amount, 0, 0)}
              </TableBodyCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>
    </Section>
  );
};

export default CashflowForecastTable;
