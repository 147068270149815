import React from 'react';
import * as R from 'ramda';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import { i18n } from 'i18n/index.js';
import { getReturnPreferenceNextRouteConfig } from 'features/shared/services/router/selectors.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import statusSelectors from 'features/shared/services/status/selectors.js';
import { creators as userOptionsCreator } from 'features/shared/services/userOptions/actions.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import TileInput from 'features/shared/components/tileInput/index.js';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import { Colors } from 'features/shared/constants/colors';
import Chart from './chart.js';

const useStyles = createUseStyles(theme => ({
  subTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    textAlign: 'center',
    color: Colors.grayDark
  },
  text: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: FontWeights.medium,
    textAlign: 'center',
    padding: '13px 0 34px 0',
    color: Colors.grayDark
  },
  legendText: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: FontWeights.light,
    textAlign: 'center'
  },
  link: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    textAlign: 'center',
    padding: '13px 0'
  },
  gainDot: {
    height: '18px',
    width: '18px',
    backgroundColor: theme.lightBlueGraphColor,
    borderRadius: '50%',
    marginRight: '5px'
  },
  lossDot: {
    height: '18px',
    width: '18px',
    backgroundColor: theme.pureBlueGraphColor,
    borderRadius: '50%',
    marginRight: '5px'
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '50px'
  },
  legends: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingBottom: '32px'
  },
  legendItem: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',

    '&:first-child': {
      paddingRight: '22px'
    }
  }
}));

const ReturnPreference = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute(getReturnPreferenceNextRouteConfig);

  const returnsConfig = useSelector(sessionSelectors.getReturnsConfig);
  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const userOptions = useSelector(userOptionsSelectors.getUserOptions);
  const riskScoreStatus = useSelector(
    statusSelectors.getStatusOrDefault('riskScore_state')
  );

  const dispatch = useDispatch();
  const userOptionsChanged = (type, value) => {
    dispatch(userOptionsCreator.userOptionChanged({ type, value }));
    dispatch(userOptionsCreator.getUserRisk(true));
  };
  const getUserRisk = () => {
    dispatch(userOptionsCreator.getUserRisk(true));
  };

  const scenarios = React.useMemo(
    () =>
      R.map(
        r => ({
          id: r.scenarioId,
          max: r.max,
          min: r.min
        }),
        returnsConfig
      ),
    [returnsConfig]
  );
  const minY = React.useMemo(
    () =>
      R.pipe(R.map(R.prop('min')), values => Math.min(...values))(
        returnsConfig
      ),
    [returnsConfig]
  );
  const maxY = React.useMemo(
    () =>
      R.pipe(R.map(R.prop('max')), values => Math.max(...values))(
        returnsConfig
      ),
    [returnsConfig]
  );

  const returns = React.useMemo(
    () =>
      R.map(
        r => ({
          ...r,
          component: (
            <Chart
              scenarios={scenarios}
              minY={minY}
              maxY={maxY}
              scenarioId={r.scenarioId}
            />
          )
        }),
        returnsConfig
      ),
    [returnsConfig]
  );

  React.useEffect(() => {
    getUserRisk();
  }, []);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('returnPreference.naviTitle', translations)}
      </Navigation>

      <Page>
        <div className={classes.content}>
          <span className={classes.subTitle}>
            {i18n('returnPreference.subTitle', translations)}
          </span>

          <span className={classes.text}>
            {i18n('returnPreference.text', translations)}
          </span>

          <div className={classes.legends}>
            <div className={classes.legendItem}>
              <div className={classes.gainDot} />
              <span className={classes.legendText}>
                {i18n('returnPreference.maximumGain', translations)}
              </span>
            </div>

            <div className={classes.legendItem}>
              <div className={classes.lossDot} />
              <span className={classes.legendText}>
                {i18n('returnPreference.maximumLoss', translations)}
              </span>
            </div>
          </div>

          <TileInput
            options={returns}
            selectedOption={userOptions.return}
            userOptionType="return"
            onSelect={(type, value) => {
              userOptionsChanged(type, value);
              window.scrollTo(0, document.body.scrollHeight);
            }}
          />
        </div>

        <Footer>
          {nextRoute && (
            <Button
              type={ButtonType.primary}
              disabled={
                R.isNil(userOptions.return) || riskScoreStatus.isPending
              }
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n('shared.continue', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default ReturnPreference;
