import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  head: {
    fill: theme.primaryColor
  }
}));

const Celebrate = () => {
  const classes = useStyles();

  return (
    <svg
      width="190"
      height="80"
      viewBox="50 35 143 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M144.51 91.6467C144.061 97.1798 140.69 90.4682 135.157 90.0186C129.624 89.5691 124.024 95.5518 124.473 90.0186C124.923 84.4855 129.773 80.3645 135.306 80.814C140.839 81.2636 144.96 86.1136 144.51 91.6467Z"
        fill="#132737"
      />
      <path
        d="M118.846 90.0251C119.292 95.5585 114.518 89.9697 108.984 90.4158C103.451 90.862 99.254 97.1741 98.8078 91.6407C98.3617 86.1073 102.486 81.2599 108.019 80.8138C113.553 80.3676 118.4 84.4917 118.846 90.0251Z"
        fill="#132737"
      />
      <path
        d="M129.865 70.5405C130.24 68.7123 131.877 67.4194 133.738 67.5576C135.657 67.7001 138.067 67.9419 139.909 68.3349C141.547 68.6844 143.579 69.3336 145.229 69.9058C146.99 70.5169 147.982 72.35 147.607 74.1766C147.181 76.2522 145.147 77.5836 143.164 76.8373C141.535 76.2245 140.106 75.5847 138.489 75.2529C136.805 74.9071 134.916 74.8959 132.894 74.7645C130.782 74.6272 129.439 72.6134 129.865 70.5405Z"
        fill="#132737"
      />
      <path
        d="M113.504 70.5405C113.128 68.7123 111.491 67.4194 109.63 67.5576C107.712 67.7001 105.301 67.9419 103.459 68.3349C101.821 68.6844 99.7889 69.3336 98.1396 69.9058C96.378 70.5169 95.3863 72.35 95.7612 74.1766C96.1872 76.2522 98.2215 77.5836 100.205 76.8373C101.833 76.2245 103.262 75.5847 104.879 75.2529C106.564 74.9071 108.452 74.8959 110.475 74.7645C112.586 74.6272 113.929 72.6134 113.504 70.5405Z"
        fill="#132737"
      />
      <mask
        id="mask4"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="34"
        y="41"
        width="173"
        height="162"
      >
        <path
          d="M190.793 106.629H172.125V51.8906C172.125 49.2053 171.059 46.63 169.16 44.7312C167.261 42.8324 164.686 41.7656 162 41.7656H81.0003C78.315 41.7656 75.7397 42.8324 73.8409 44.7312C71.9421 46.63 70.8753 49.2053 70.8753 51.8906L70.8753 122.766H50.6252C33.8785 122.766 34.1722 113.251 34.1722 96.5039V69.6094H50.6253V96.5039C50.6253 102.083 50.6253 106.629 50.6253 106.629H70.8753L70.8753 186.363H60.7503V202.5H95.555V186.363H85.43V156.938H157.571V186.363H147.446V202.5H182.25V186.363H172.125V122.766H190.793C208.828 122.766 206.93 115.172 206.93 96.0724V67.711H190.793V96.0724V106.629ZM85.43 57.2696H157.571V122.766H85.43V57.2696Z"
          fill="#900032"
        />
      </mask>
      <g mask="url(#mask4)">
        <circle cx="122" cy="-14" r="130" className={classes.head} />
      </g>
    </svg>
  );
};

export default Celebrate;
