import { useSelector } from 'react-redux';
import { isNil, isEmpty } from 'ramda';

import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Paragraph from '../../shared/components/paragraph';
import Heading1 from '../../shared/components/heading1';
import Section from '../../shared/components/section.js';
import { createUseStyles } from '../../../sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  text: {
    whiteSpace: 'pre-line'
  }
}));

const AdvisorNotesSection = ({
  header = null,
  text = '',
  className = '',
  headerClassName = ''
} = {}) => {
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const classes = useStyles();

  return isNil(text) || isEmpty(text) ? null : (
    <Section className={className}>
      <Heading1 className={headerClassName}>
        {!isNil(header)
          ? header
          : i18n('report.shared.advisorNotes', translationsConfig)}
      </Heading1>
      <Paragraph className={classes.text}>{text}</Paragraph>
    </Section>
  );
};

export default AdvisorNotesSection;
