import { connect } from 'react-redux';
import * as R from 'ramda';

import Router from './router.js';
import withStaticProps from 'framework/withStaticProps/components/index.js';

export default ({ getLocation, onLocationChanged }) =>
  R.compose(
    withStaticProps({
      getLocation
    }),
    connect(
      state => {
        return {};
      },
      dispatch => {
        return {
          onLocationChanged: onLocationChanged(dispatch)
        };
      }
    )
  )(Router);
