import { useSelector } from 'react-redux';

import Heading1 from 'features/report/shared/components/heading1';
import sessionSelectors from 'features/shared/services/session/selectors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { i18n } from 'i18n';
import { FontWeights } from 'features/report/shared/constants/fonts';
import { TextFontSizes } from 'features/shared/constants/fonts';
import ChartTable from '../../../shared/components/chartTable';
import { useReportStore } from 'features/report/shared/services/reportStore';
import AdvisorNotesSection from '../advisorNotesSection';

const useStyles = createUseStyles(theme => ({
  root: {
    marginTop: '26px',
    breakInside: 'avoid-page'
  },
  subheader: {
    fontWeight: FontWeights.medium,
    fontSize: 18,
    lineHeight: '22px',
    margin: '16px 0'
  },
  headerCell: {
    color: theme.secondaryColor,
    fontWeight: FontWeights.normal,
    fontSize: 12,
    lineHeight: '150%',
    height: 27
  },
  cell: {
    overflowWrap: 'break-word',
    fontSize: TextFontSizes.xSmall
  },
  advisorNotes: {
    paddingTop: 16
  }
}));

const SummaryTables = ({ data }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const reportConfig = useSelector(sessionSelectors.getReportConfig);
  const { userData } = useReportStore();

  const overrideSummaryAnnualOngoingFeesAdvisorNotes =
    userData?.cost?.overrideSummaryAnnualOngoingFeesAdvisorNotes;
  const overrideSummaryCustodyFeesAdvisorNotes =
    userData?.cost?.overrideSummaryCustodyFeesAdvisorNotes;
  const overrideSummaryOneTimeFeesAdvisorNotes =
    userData?.cost?.overrideSummaryOneTimeFeesAdvisorNotes;

  return (
    <div className={classes.root}>
      <Heading1>
        {i18n('costChart.summaryTable.title', translationsConfig)}
      </Heading1>
      {reportConfig.summaryTableItems.ongoingFees &&
        data.annualOngoingFees.length > 0 && (
          <>
            <div className={classes.subheader}>
              {i18n('costChart.costTable.ongoingFees', translationsConfig)}
            </div>

            <ChartTable
              customClasses={{
                headerCell: classes.headerCell,
                cell: classes.cell
              }}
              headerTitles={['', '', '%']}
              data={data.annualOngoingFees}
              dataKeys={['icon', 'field', 'percent']}
              withIcon
              highlightLastRow
            />

            {overrideSummaryAnnualOngoingFeesAdvisorNotes && (
              <AdvisorNotesSection
                className={classes.advisorNotes}
                headerClassName={classes.subheader}
                text={overrideSummaryAnnualOngoingFeesAdvisorNotes}
              />
            )}
          </>
        )}

      {reportConfig.summaryTableItems.custodyFees &&
        data.custodyFees.length > 0 && (
          <>
            <div className={classes.subheader}>
              {i18n('costChart.costTable.custodyFee', translationsConfig)}
            </div>

            <ChartTable
              customClasses={{
                headerCell: classes.headerCell,
                cell: classes.cell
              }}
              headerTitles={[
                '',
                i18n('report.shared.amount', translationsConfig)
              ]}
              data={data.custodyFees}
              dataKeys={['field', 'value']}
            />

            {overrideSummaryCustodyFeesAdvisorNotes && (
              <AdvisorNotesSection
                className={classes.advisorNotes}
                headerClassName={classes.subheader}
                text={overrideSummaryCustodyFeesAdvisorNotes}
              />
            )}
          </>
        )}

      {data.oneTimeFees.length > 0 &&
        reportConfig.summaryTableItems.oneTimeFee && (
          <>
            <div className={classes.subheader}>
              {i18n('costChart.costTable.oneTimeFee', translationsConfig)}
            </div>

            <ChartTable
              customClasses={{
                headerCell: classes.headerCell,
                cell: classes.cell
              }}
              headerTitles={['', '%']}
              data={data.oneTimeFees}
              dataKeys={['field', 'percent']}
            />

            {overrideSummaryOneTimeFeesAdvisorNotes && (
              <AdvisorNotesSection
                className={classes.advisorNotes}
                headerClassName={classes.subheader}
                text={overrideSummaryOneTimeFeesAdvisorNotes}
              />
            )}
          </>
        )}

      {data.annualReturnCommissionPaidToAdvisor.length > 0 &&
        reportConfig.summaryTableItems.returnCommissionPaidToAdvisor && (
          <>
            <div className={classes.subheader}>
              {i18n(
                'costChart.costTable.returnCommissionPaidToAdvisor',
                translationsConfig
              )}
            </div>

            <ChartTable
              customClasses={{
                headerCell: classes.headerCell,
                cell: classes.cell
              }}
              headerTitles={['', '%']}
              data={data.annualReturnCommissionPaidToAdvisor}
              dataKeys={['field', 'percent']}
            />
          </>
        )}
    </div>
  );
};

export default SummaryTables;
