import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  counter: {
    fill: theme.imageSecondaryColor
  },
  item: {
    fill: theme.imagePrimaryColor
  }
}));

const Abacus = () => {
  const classes = useStyles();

  return (
    <svg
      width="104"
      height="88"
      viewBox="0 0 104 88"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M0 0V88H104V0H0ZM13 10H91V22H85.5359C84.6828 18.5156 81.6969 16 78 16C74.3031 16 71.3172 18.5156 70.4641 22H51.7359C50.8828 18.5156 47.8969 16 44.2 16C40.5031 16 37.5172 18.5156 36.6641 22H33.5359C32.6828 18.5156 29.6969 16 26 16C22.3031 16 19.3172 18.5156 18.4641 22H13V10ZM13 26H18.4641C19.3172 29.4844 22.3031 32 26 32C29.6969 32 32.6828 29.4844 33.5359 26H36.6641C37.5172 29.4844 40.5031 32 44.2 32C47.8969 32 50.8828 29.4844 51.7359 26H70.4641C71.3172 29.4844 74.3031 32 78 32C81.6969 32 84.6828 29.4844 85.5359 26H91V42H69.9359C69.0828 38.5156 66.0969 36 62.4 36C58.7031 36 55.7172 38.5156 54.8641 42H51.7359C50.8828 38.5156 47.8969 36 44.2 36C40.5031 36 37.5172 38.5156 36.6641 42H33.5359C32.6828 38.5156 29.6969 36 26 36C22.3031 36 19.3172 38.5156 18.4641 42H13V26ZM13 46H18.4641C19.3172 49.4844 22.3031 52 26 52C29.6969 52 32.6828 49.4844 33.5359 46H36.6641C37.5172 49.4844 40.5031 52 44.2 52C47.8969 52 50.8828 49.4844 51.7359 46H54.8641C55.7172 49.4844 58.7031 52 62.4 52C66.0969 52 69.0828 49.4844 69.9359 46H91V62H85.5359C84.6828 58.5156 81.6969 56 78 56C74.3031 56 71.3172 58.5156 70.4641 62H67.3359C66.4828 58.5156 63.4969 56 59.8 56C56.1031 56 53.1172 58.5156 52.2641 62H33.5359C32.6828 58.5156 29.6969 56 26 56C22.3031 56 19.3172 58.5156 18.4641 62H13V46ZM13 66H18.4641C19.3172 69.4844 22.3031 72 26 72C29.6969 72 32.6828 69.4844 33.5359 66H52.2641C53.1172 69.4844 56.1031 72 59.8 72C63.4969 72 66.4828 69.4844 67.3359 66H70.4641C71.3172 69.4844 74.3031 72 78 72C81.6969 72 84.6828 69.4844 85.5359 66H91V78H13V66Z"
        className={classes.counter}
      />
      <circle cx="78" cy="24" r="8" className={classes.item} />
    </svg>
  );
};

export default Abacus;
