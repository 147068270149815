import {
  FontWeights,
  TextFontSizes
} from 'features/report/shared/constants/fonts';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  backAccountCell: {
    width: '50%'
  },
  spaceBelow: {
    marginBottom: '30px'
  },
  alignRight: {
    textAlign: 'right'
  },
  portfolioRow: {
    backgroundColor: `${theme.reportHighlightColor} !important`,
    fontWeight: FontWeights.bold
  },
  header: {
    fontSize: TextFontSizes.x05,
    lineHeight: TextFontSizes.x05LineHeight
  },
  center: {
    textAlign: 'center'
  }
}));

export default useStyles;
