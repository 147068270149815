import { takeLatest, put, call } from 'redux-saga/effects';
import * as R from 'ramda';
import { matchPath } from 'react-router';
import queryString from 'query-string';

import { defaultTranslations } from 'defaults/defaultTranslations.js';
import { defaultTheme } from 'defaults/defaultTheme';
import { types } from './actions.js';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { types as applicationActionTypes } from 'features/shared/services/application/actions.js';
import { getCustomer, readPortfolioMeta } from 'features/shared/api/index.js';
import {
  startedCreator,
  succeedCreator,
  failedCreator
} from 'features/shared/utils/actions.js';
import { buildPublicFileUrl } from 'features/shared/utils/axios.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import { getReport } from 'features/shared/api/index.js';
import { useReportStore } from 'features/report/shared/services/reportStore';

export const getLocation = () => window.location;

function* initializeSession(action) {
  const { customerId } = action.payload;

  yield put(startedCreator(types.INITIALIZE));

  try {
    const accessToken = yield call(getQAuthAccessToken, customerId);
    const { pathname, search } = yield call(getLocation);
    const reportMatch = yield call(matchPath, pathname, {
      path: routeTemplates.report.config,
      exact: false
    });
    const orderExecutionReportMatch = yield call(matchPath, pathname, {
      path: routeTemplates.orderExecutionReportBody.config,
      exact: false
    });
    let language;

    if (!R.isNil(reportMatch) || !R.isNil(orderExecutionReportMatch)) {
      const reportStoreState = useReportStore.getState();

      const { report_id } =
        R.isNil(search) || R.isEmpty(search) ? {} : queryString.parse(search);

      const getReportResponse = yield call(
        getReport,
        accessToken,
        undefined,
        report_id
      );
      const report = getReportResponse.data.report;
      language = report.settings.language;
      reportStoreState.setUserData(report.settings);
    }

    const customerResponse = yield call(
      getCustomer,
      accessToken,
      undefined,
      customerId,
      { language }
    );
    const customer = customerResponse.data.customer;

    const portfolioMetaResponse = yield call(readPortfolioMeta, accessToken);
    const riskClasses = portfolioMetaResponse.data['allowed options'].risk;
    const optionalCategories =
      portfolioMetaResponse.data['allowed options'].optionals;

    const customerSettings = R.pipe(
      s => ({ ...s, customerId: customer.id, riskClasses, optionalCategories }),
      s => {
        const manifestJson = R.pipe(
          R.set(
            R.lensProp('start_url'),
            `${window.location.origin}/${customerId}`
          ),
          R.over(
            R.lensProp('icons'),
            R.map(i => ({ ...i, src: buildPublicFileUrl(i.src) }))
          )
        )(s.manifest_json);
        const stringManifest = JSON.stringify(manifestJson);
        const blob = new Blob([stringManifest], { type: 'application/json' });

        return {
          ...s,
          manifestSpec: [{ rel: 'manifest', href: URL.createObjectURL(blob) }]
        };
      },
      R.over(R.lensProp('translations'), R.mergeRight(defaultTranslations)),
      R.over(R.lensProp('styles'), R.mergeDeepRight(defaultTheme))
    )(customer.settings.saving_robo);

    yield put(succeedCreator(types.INITIALIZE, customerSettings));
  } catch (error) {
    let errorType;
    if (
      (customerId && error.response && error.response.status) ||
      customerId === undefined
    ) {
      errorType = error.response.status;
    } else {
      errorType = null;
    }

    yield put(failedCreator(types.INITIALIZE, { errorType }));
  }
}

export default function* () {
  yield takeLatest(applicationActionTypes.STARTED, initializeSession);
}
