import React from 'react';
import * as R from 'ramda';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { useSelector } from 'react-redux';

import { i18n } from 'i18n/index.js';
import { formatDate } from 'features/shared/utils/dateTime.js';
import { buildPublicFileUrl } from 'features/shared/utils/axios.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { FontWeights, TextFontSizes } from '../../shared/constants/fonts.js';

const useStyles = createUseStyles(theme => ({
  root: {
    height: '100%',
    pageBreakAfter: 'always',
    backgroundColor: theme.reportCoverBackgroundColor,
    position: 'relative'
  },
  backgroundImage: {
    width: '100%',
    position: 'absolute',
    bottom: '0'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '25px'
  },
  headerText: {
    fontSize: TextFontSizes.x05,
    lineHeight: TextFontSizes.x05LineHeight,
    color: theme.reportCoverHeaderColor
  },
  logo: {
    width: '268px',
    marginTop: '176px'
  },
  headerLeftLogo: {
    height: '40px'
  },
  headerRightLogo: {
    height: '40px'
  },
  body: {
    padding: '25px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative'
  },
  title: {
    fontSize: TextFontSizes.x10,
    lineHeight: TextFontSizes.x10LineHeight,
    fontWeight: FontWeights.extraBold,
    color: theme.reportCoverTitleColor,
    textAlign: 'center',
    marginTop: '15px',
    zIndex: '1'
  },
  subTitle: {
    fontSize: TextFontSizes.x5,
    lineHeight: TextFontSizes.x5LineHeight,
    fontWeight: FontWeights.semiBold,
    color: theme.reportCoverTitleColor,
    marginTop: '157px',
    zIndex: '1'
  },
  advisorName: {
    fontSize: TextFontSizes.x3,
    color: theme.reportCoverTitleColor,
    zIndex: '1',
    marginTop: '20px'
  },
  details: {
    fontSize: TextFontSizes.x3,
    color: theme.reportCoverTitleColor,
    zIndex: '1',
    marginTop: '60px'
  }
}));

const Cover = ({ generalInformation, advisorName }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const reportConfig = useSelector(sessionSelectors.getReportConfig);
  const cultureCode = useSelector(sessionSelectors.getCultureCode);

  React.useEffect(() => {
    if (!R.isNil(reportConfig.headerLeftLogo)) {
      window.imagesToLoad.push('headerLeftLogo');
    }
    if (!R.isNil(reportConfig.coverBackground)) {
      window.imagesToLoad.push('coverBackground');
    }
    if (!R.isNil(reportConfig.headerRightLogo)) {
      window.imagesToLoad.push('headerRightLogo');
    }
    if (!R.isNil(reportConfig.coverLogo)) {
      window.imagesToLoad.push('coverLogo');
    }
  }, []);

  const HeaderLeft = () => {
    if (!R.isNil(reportConfig.headerLeftLogo)) {
      return (
        <img
          className={classes.headerLeftLogo}
          src={buildPublicFileUrl(reportConfig.headerLeftLogo)}
          onLoad={() => {
            window.loadedImages.push('headerLeftLogo');
          }}
        />
      );
    } else {
      return (
        <div className={classes.headerText}>
          <div>{i18n('report.header.text_1', translationsConfig)}</div>
          <div>{i18n('report.header.text_2', translationsConfig)}</div>
        </div>
      );
    }
  };
  return (
    <div className={classes.root}>
      {!R.isNil(reportConfig.coverBackground) && (
        <img
          src={buildPublicFileUrl(reportConfig.coverBackground)}
          className={classes.backgroundImage}
          onLoad={() => {
            window.loadedImages.push('coverBackground');
          }}
        />
      )}
      <div className={classes.header}>
        {HeaderLeft()}
        <div>
          {!R.isNil(reportConfig.headerRightLogo) && (
            <img
              className={classes.headerRightLogo}
              src={buildPublicFileUrl(reportConfig.headerRightLogo)}
              onLoad={() => {
                window.loadedImages.push('headerRightLogo');
              }}
            />
          )}
        </div>
      </div>
      <div className={classes.body}>
        {!R.isNil(reportConfig.coverLogo) && (
          <img
            className={classes.logo}
            src={buildPublicFileUrl(reportConfig.coverLogo)}
            onLoad={() => {
              window.loadedImages.push('coverLogo');
            }}
          />
        )}
        <div className={classes.subTitle}>
          {i18n('report.cover.subTitle', translationsConfig)}
        </div>
        <div data-testid={'report-title'} className={classes.title}>
          {generalInformation.name}
        </div>
        <div className={classes.details}>{advisorName}</div>
        <div className={classes.advisorName}>
          {formatDate(cultureCode, new Date())}
        </div>
      </div>
    </div>
  );
};

export default Cover;
