import React from 'react';
import classNames from 'classnames';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { TextFontSizes, FontWeights } from '../constants/fonts.js';

const useStyles = createUseStyles(theme => ({
  root: {
    fontSize: TextFontSizes.x2,
    lineHeight: TextFontSizes.x2LineHeight,
    fontWeight: FontWeights.medium,
    marginBottom: '13px'
  }
}));

const Heading2 = ({ children, className = {} }) => {
  const classes = useStyles();

  return <div className={classNames(classes.root, className)}>{children}</div>;
};

export default Heading2;
