import React from 'react';
import { useSelector } from 'react-redux';

import sessionSelectors from 'features/shared/services/session/selectors';
import Heading2 from 'features/report/shared/components/heading2';
import ChartTable from '../../../../shared/components/chartTable';
import { i18n } from 'i18n';
import useStyles from './styles';

const ReturnCommissionPaidToAdvisor = ({ data }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const reportConfig = useSelector(sessionSelectors.getReportConfig);

  const returnCommissionPaidToAdvisorPercentData = React.useMemo(() => {
    if (reportConfig.isFundDetailsCommissionPaidToAdvisorTableFlipped) {
      const result = [
        data.percent.returnCommissionPaidToAdvisor.reduce((acc, el) => {
          acc[el.fund] = el.fundReturnCommissionPaidToAdvisor;
          return acc;
        }, {})
      ];
      result[0].title = i18n(
        'costChart.costTable.fundReturnCommissionPaidToAdvisor',
        translationsConfig
      );

      return result;
    }

    return data.percent.returnCommissionPaidToAdvisor;
  }, [
    data.percent.returnCommissionPaidToAdvisor,
    reportConfig.isFundDetailsCommissionPaidToAdvisorTableFlipped,
    translationsConfig
  ]);

  const returnCommissionPaidToAdvisorHeaderTitles = React.useMemo(() => {
    if (reportConfig.isFundDetailsCommissionPaidToAdvisorTableFlipped) {
      return [
        '',
        ...data.percent.returnCommissionPaidToAdvisor.map(el => el.fund)
      ];
    }

    return [
      i18n('costChart.fundsTable.fund', translationsConfig),
      i18n(
        'costChart.costTable.fundReturnCommissionPaidToAdvisor',
        translationsConfig
      )
    ];
  }, [
    data.percent.returnCommissionPaidToAdvisor,
    reportConfig.isFundDetailsCommissionPaidToAdvisorTableFlipped,
    translationsConfig
  ]);

  const returnCommissionPaidToAdvisorDataKeys = React.useMemo(() => {
    if (reportConfig.isFundDetailsCommissionPaidToAdvisorTableFlipped) {
      return [
        'title',
        ...data.percent.returnCommissionPaidToAdvisor.map(el => el.fund)
      ];
    }

    return ['fund', 'fundReturnCommissionPaidToAdvisor'];
  }, [
    data.percent.returnCommissionPaidToAdvisor,
    reportConfig.isFundDetailsCommissionPaidToAdvisorTableFlipped
  ]);

  const returnCommissionPaidToAdvisorCurrencyData = React.useMemo(() => {
    if (reportConfig.isFundDetailsCommissionPaidToAdvisorTableFlipped) {
      const result = [
        data.currency.returnCommissionPaidToAdvisor.reduce((acc, el) => {
          acc[el.fund] = el.fundReturnCommissionPaidToAdvisor;
          return acc;
        }, {})
      ];
      result[0].title = i18n(
        'costChart.costTable.fundReturnCommissionPaidToAdvisor',
        translationsConfig
      );

      return result;
    }

    return data.currency.returnCommissionPaidToAdvisor;
  }, [
    data.currency.returnCommissionPaidToAdvisor,
    reportConfig.isFundDetailsCommissionPaidToAdvisorTableFlipped,
    translationsConfig
  ]);

  return (
    data.length > 0 && (
      <>
        <div className={classes.section}>
          <Heading2>
            {i18n(
              'costChart.costTable.returnCommissionPaidToAdvisor',
              translationsConfig
            )}
          </Heading2>
          <ChartTable
            customClasses={{
              headerCell: classes.headerCell,
              cell: classes.cell
            }}
            headerTitles={returnCommissionPaidToAdvisorHeaderTitles}
            data={returnCommissionPaidToAdvisorPercentData}
            dataKeys={returnCommissionPaidToAdvisorDataKeys}
            addPercentToAllValues
          />
        </div>
        <div className={classes.section}>
          <ChartTable
            customClasses={{
              headerCell: classes.headerCell,
              cell: classes.cell
            }}
            headerTitles={returnCommissionPaidToAdvisorHeaderTitles}
            data={returnCommissionPaidToAdvisorCurrencyData}
            dataKeys={returnCommissionPaidToAdvisorDataKeys}
          />
        </div>
        <div style={{ pageBreakAfter: 'always' }} />
      </>
    )
  );
};

export default ReturnCommissionPaidToAdvisor;
