import { useSelector } from 'react-redux';

import sessionSelectors from 'features/shared/services/session/selectors';
import { i18n } from 'i18n';
import Heading1 from 'features/report/shared/components/heading1';
import OngoingFees from './ongoingFees';
import OneTimeFee from './oneTimeFee';
import CustodyFee from './custodyFee';
import ReturnCommissionPaidToAdvisor from './returnCommissionPaidToAdvisor';

const FundsTable = ({ data }) => {
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const reportConfig = useSelector(sessionSelectors.getReportConfig);

  return (
    <>
      <Heading1>
        {i18n('costChart.fundDetails.title', translationsConfig)}
      </Heading1>
      {reportConfig.fundDetailsTableItems?.ongoingFees && (
        <OngoingFees data={data} />
      )}
      {reportConfig.fundDetailsTableItems?.oneTimeFee && (
        <OneTimeFee data={data} />
      )}
      {reportConfig.fundDetailsTableItems?.custodyFees && (
        <CustodyFee data={data} />
      )}
      {reportConfig.fundDetailsTableItems?.returnCommissionPaidToAdvisor && (
        <ReturnCommissionPaidToAdvisor data={data} />
      )}
    </>
  );
};

export default FundsTable;
