import React from 'react';
import { useSelector } from 'react-redux';

import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow
} from 'features/report/shared/components/table';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { FontWeights } from 'features/report/shared/constants/fonts';
import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';

const useStyles = createUseStyles(theme => ({
  valueCell: {
    fontWeight: FontWeights.semiBold
  },
  answer: {
    '& + &': {
      marginTop: '10px'
    }
  },
  trainingDate: {
    color: '#90A0A6',
    marginTop: '5px'
  }
}));

const RiskQuestions = ({ riskQuestions }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  return (
    <Table>
      <TableBody>
        {riskQuestions.map((i, iIndex) => (
          <TableBodyRow key={iIndex}>
            <TableBodyCell>{i.question}</TableBodyCell>
            <TableBodyCell className={classes.valueCell}>
              {i.answers.map((a, aIndex) => (
                <div key={aIndex} className={classes.answer}>
                  {a}
                </div>
              ))}
              {i.trainingDate && (
                <div className={classes.trainingDate}>
                  {i18n(
                    'roboAdvice.knowledgeAndExperience.quiz.trainingPerformed',
                    translationsConfig
                  )}{' '}
                  {i.trainingDate}
                </div>
              )}
            </TableBodyCell>
          </TableBodyRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default RiskQuestions;
