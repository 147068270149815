import create from 'zustand';

const defaultData = {
  session: null,
  identity: null,
  createSessionStatus: {
    pending: false,
    failed: false
  },
  completeSessionStatus: {
    pending: false,
    failed: false
  }
};

export const usePageStore = create(set => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  setSession: session => {
    set({ session });
  },
  setIdentity: identity => {
    set({ identity });
  },

  resetCreateSessionStatus: () => {
    set({ createSessionStatus: { pending: false, failed: false } });
  },
  setCreateSessionStarted: () => {
    set({ createSessionStatus: { pending: true, failed: false } });
  },
  setCreateSessionSucceed: () => {
    set({ createSessionStatus: { pending: false, failed: false } });
  },
  setCreateSessionFailed: () => {
    set({ createSessionStatus: { pending: false, failed: true } });
  },

  resetCompleteSessionStatus: () => {
    set({ completeSessionStatus: { pending: false, failed: false } });
  },
  setCompleteSessionStarted: () => {
    set({ completeSessionStatus: { pending: true, failed: false } });
  },
  setCompleteSessionSucceed: () => {
    set({ completeSessionStatus: { pending: false, failed: false } });
  },
  setCompleteSessionFailed: () => {
    set({ completeSessionStatus: { pending: false, failed: true } });
  }
}));
