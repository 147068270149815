import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import { i18n } from 'i18n/index.js';
import { formatNumber } from 'features/shared/utils/number.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import Navigation from 'features/shared/components/screenLayout/navigation';
import { Colors } from 'features/shared/constants/colors.js';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import Icon from 'features/shared/components/icon/index';
import BottomSheet from 'features/shared/components/bottomSheet/index';
import Button, { ButtonType } from 'features/shared/components/button/index';
import ProgressBar from 'features/shared/components/progressBar/index';
import { usePageStore } from 'features/counseling/montecarlo/services/pageStore.js';
import { getYearOffset } from '../services/mapping.js';
import MontecarloChart from './montecarloChart.js';
import { useInitPage } from './useInitPage';
import { RoboFrontParams } from 'features/shared/utils/types';
import { TimeHorizonConfigType } from 'features/report/body/constants';

const useStyles = createUseStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '50px',
    paddingBottom: '11px',
    width: '100%'
  },
  subTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largePx,
    color: Colors.grayDark,
    paddingBottom: '50px'
  },
  speechBubble: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    background: Colors.white,
    borderRadius: '12px',
    minHeight: '100px',
    margin: '0 20px 60px 20px',
    padding: '20px',
    boxShadow:
      '0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 4px 3px ' +
      Colors.listShadowColor,

    '&::after': {
      content: '""',
      border: '20px solid transparent',
      borderTop: '20px solid green',
      left: '15%',
      bottom: 0,
      position: 'absolute',
      marginBottom: '-38px',
      borderTopColor: Colors.white
    }
  },
  speechBubbleTitle: {
    fontFamily: theme.headerFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: FontWeights.medium,
    color: Colors.gray160,
    paddingBottom: '7px'
  },
  speechBubbleText: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: FontWeights.light,
    color: Colors.gray180
  },
  questionText: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    fontWeight: FontWeights.semiBold,
    padding: '40px 0 10px 0'
  },
  info: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    display: 'flex',
    alignItems: 'center'
  },
  sheetContent: {
    display: 'flex',
    flexDirection: 'column',
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: FontWeights.light,
    padding: '0 20px',

    '& span': {
      paddingBottom: '20px'
    }
  },
  screenLayout: {
    minWidth: '100%',
    padding: '48px 0px 5px 0px'
  },
  reamMoreContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%'
  },
  progressBarContainer: {
    width: 'calc(100% - 48px)'
  }
}));

const BottomSheetButton = () => {
  const classes = useStyles();
  const translations = useSelector(sessionSelectors.getTranslationsConfig);

  return (
    <div className={classes.reamMoreContainer}>
      <span className={classes.info}>
        {i18n('counseling.montecarlo.link.readMore', translations)}
        <Icon type="navigation" />
      </span>
    </div>
  );
};

const MontecarloScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const pageStore = usePageStore();
  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();
  const { customerId } = useParams<RoboFrontParams>();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const horizonsConfig: TimeHorizonConfigType = useSelector(
    sessionSelectors.getHorizonsConfig
  );

  const userCounselingOptions: {
    savingsPlan: {
      monthlyDeposit: number | string;
      firstDeposit: number | string;
    };
  } = useSelector(userOptionsSelectors.getCounselingOptions);
  const horizon = useSelector(userOptionsSelectors.getHorizon);
  const horizonsYear = useSelector(userOptionsSelectors.getHorizonYear);
  const cultureCode = useSelector(sessionSelectors.getCultureCode);

  useInitPage();

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('counseling.montecarlo.naviTitle', translations)}
      </Navigation>

      <Page
        className={classes.screenLayout}
        progressBarContainerClassName={classes.progressBarContainer}
      >
        {pageStore.isReadExpectedReturnPending && <ProgressBar />}
        {!pageStore.isReadExpectedReturnPending && (
          <Fragment>
            <div className={classes.content}>
              <span className={classes.subTitle}>
                {i18n('counseling.montecarlo.subTitle', translations)}
              </span>
              <div className={classes.speechBubble}>
                <span className={classes.speechBubbleTitle}>
                  {i18n('counseling.montecarlo.details.title', translations)}
                </span>
                <span className={classes.speechBubbleText}>
                  {i18n('counseling.montecarlo.details.text', translations)
                    .replace(
                      '{0}',
                      formatNumber(
                        cultureCode,
                        userCounselingOptions.savingsPlan.monthlyDeposit
                      )
                    )
                    .replace(
                      '{1}',
                      formatNumber(
                        cultureCode,
                        userCounselingOptions.savingsPlan.firstDeposit
                      )
                    )
                    .replace(
                      '{2}',
                      formatNumber(cultureCode, pageStore.expectedValue)
                    )
                    .replace(
                      '{3}',
                      horizonsYear ||
                        getYearOffset(horizonsConfig.items, horizon)
                    )}
                </span>
              </div>
              <MontecarloChart
                averageTimeseries={pageStore.averageTimeseries}
                deviationTimeseries={pageStore.deviationTimeseries}
              />
              <span className={classes.questionText}>
                {i18n('counseling.montecarlo.text', translations)}
              </span>
              <BottomSheet
                title={i18n('counseling.montecarlo.info.title', translations)}
                button={<BottomSheetButton />}
              >
                <div className={classes.sheetContent}>
                  <span>
                    {i18n('counseling.montecarlo.info.text_1', translations)}
                  </span>
                  <span>
                    {i18n('counseling.montecarlo.info.text_2', translations)}
                  </span>
                </div>
              </BottomSheet>
            </div>
            <Footer>
              {nextRoute && (
                <Button
                  type={ButtonType.primary}
                  onClick={() => {
                    history.push(nextRoute.build(customerId));
                  }}
                >
                  {i18n('shared.continue', translations)}
                </Button>
              )}
            </Footer>
          </Fragment>
        )}
      </Page>
    </Layout>
  );
};

export default MontecarloScreen;
