import React from 'react';
import { injectSheet } from 'features/sharedModules/styles/components/styles.js';

import {
  FontFamilies,
  FontWeights,
  TextFontSizes
} from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';

const styles = theme => {
  return {
    '@global': {
      html: {
        height: '100%'
      },
      body: {
        height: 'inherit',
        color: theme.textColor,
        backgroundColor: theme.backgroundColor
          ? theme.backgroundColor
          : Colors.backgroundColor,
        margin: 0,
        padding: 0,
        fontSize: TextFontSizes.mediumPx,
        lineHeight: TextFontSizes.mediumLineHeightPx,
        fontFamily: FontFamilies.roboto,
        fontWeight: FontWeights.normal,
        '-webkit-font-smoothing': 'antialiased',
        '-moz-osx-font-smoothing': 'grayscale'
      },
      '*': {
        boxSizing: 'border-box'
      }
    },
    pageContainer: {
      height: 'inherit',
      margin: 'auto',
      minHeight: '100%'
    }
  };
};

const MainLayout = ({ children, classes }) => {
  return <div className={classes.pageContainer}>{children}</div>;
};

export default injectSheet(styles)(MainLayout);
