import FileSaver from 'file-saver';

import { getYearOffset } from 'features/counseling/montecarlo/services/mapping.js';

export const saveFile = (data, filename, options) => {
  FileSaver.saveAs(data, filename, options);
};

export const getExpectedChartGoalsData = (goals, horizonsConfig) =>
  goals.map(goal => ({
    ...goal,
    data: {
      ...goal?.data,
      timeHorizon: getYearOffset(horizonsConfig, goal?.data?.timeHorizon)
    }
  }));
