import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import classNames from 'classnames';

import { Colors } from '../constants/colors.js';
import { WithChildren } from 'features/report/shared/utils/typeUtils';

const useStyles = createUseStyles(theme => ({
  table: {
    width: '100%',
    borderCollapse: 'collapse'
  },
  header: {},
  headerRow: {},
  headerCell: {
    padding: '11px 13px',
    color: Colors.gray400,
    backgroundColor: Colors.gray500
  },
  body: {},
  bodyRow: {
    '&:nth-of-type(even) $bodyCell': {
      backgroundColor: Colors.gray500
    },
    '&:nth-of-type(odd) $bodyCell': {
      backgroundColor: Colors.gray600
    }
  },
  bodyCell: {
    padding: '11px 13px',
    verticalAlign: 'middle'
  }
}));

type Props = WithChildren<{
  className?: string | {};
}>;

export const Table = ({ className = {}, children }: Props) => {
  const classes = useStyles();

  return (
    <table className={classNames(classes.table, className)}>{children}</table>
  );
};

export const TableHeader = ({ className = {}, children }: Props) => {
  const classes = useStyles();

  return (
    <thead className={classNames(classes.header, className)}>{children}</thead>
  );
};

export const TableHeaderRow = ({ className = {}, children }: Props) => {
  const classes = useStyles();

  return (
    <tr className={classNames(classes.headerRow, className)}>{children}</tr>
  );
};

export const TableHeaderCell = ({
  className = {},
  children,
  ...restProps
}: Props) => {
  const classes = useStyles();

  return (
    <td className={classNames(classes.headerCell, className)} {...restProps}>
      {children}
    </td>
  );
};

export const TableBody = ({ className = {}, children }: Props) => {
  const classes = useStyles();

  return (
    <tbody className={classNames(classes.body, className)}>{children}</tbody>
  );
};

export const TableBodyRow = ({ className = {}, children }: Props) => {
  const classes = useStyles();

  return <tr className={classNames(classes.bodyRow, className)}>{children}</tr>;
};

export const TableBodyCell = ({
  className = {},
  children,
  ...restProps
}: Props) => {
  const classes = useStyles();

  return (
    <td className={classNames(classes.bodyCell, className)} {...restProps}>
      {children}
    </td>
  );
};
