import React from 'react';
import * as R from 'ramda';
import Highcharts from 'highcharts/highstock';
import HighchartsReact from 'highcharts-react-official';
import { useDispatch, useSelector, useStore } from 'react-redux';

import { formatNumber } from 'features/shared/utils/number.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';

const options = {
  credits: {
    enabled: false
  },
  chart: {
    backgroundColor: null,
    plotBackgroundColor: null,
    plotBorderWidth: 0,
    plotShadow: false,
    width: '150',
    height: '150',
    animation: false
  },
  plotOptions: {
    series: {
      name: 'Portfolio',
      animation: false
    },
    pie: {
      dataLabels: {
        enabled: false
      },
      states: {
        enabled: false,
        hover: {
          lineWidthPlus: 100
        }
      }
    }
  },
  series: [
    {
      type: 'pie',
      innerSize: '65%'
    }
  ],
  title: {
    text: null
  }
};

const PieChart = ({ data }) => {
  const cultureCode = useSelector(sessionSelectors.getCultureCode);

  let plotOptions = R.pipe(
    R.set(R.lensPath(['series', 0, 'data']), data),
    R.set(R.lensPath(['tooltip', 'pointFormatter']), function() {
      return `<span style="color:${
        this.color
      }">●</span> ${this.series.name} <b>${formatNumber(cultureCode, this.y, 0, 2)}</b>%<br/>`;
    })
  )(options);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      constructorType={'chart'}
      options={plotOptions}
    />
  );
};

export default PieChart;
