import * as R from 'ramda';

import { savingRoboApi } from 'features/shared/utils/axios.js';

const setNoCacheHeaders = R.over(
  R.lensProp('headers'),
  R.merge(R.__, {
    'Cache-Control': 'no-cache, no-store',
    Pragma: 'no-cache',
    Expires: '0'
  })
);

export const initAxios = store => {
  savingRoboApi.interceptors.request.use(setNoCacheHeaders, error => {
    return Promise.reject(error);
  });
};
