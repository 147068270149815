import React, { useEffect, useRef } from 'react';
import * as R from 'ramda';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import { useSelector } from 'react-redux';

import { i18n } from 'i18n/index.js';
import { Colors } from 'features/shared/constants/colors.js';
import {
  getHighchartsDayFormatKey,
  getHighchartsMonthFormatKey,
  getHighchartsYearFormatKey
} from 'features/shared/utils/dateTime.js';
import { formatNumber } from 'features/shared/utils/number.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';

const useStyles = createUseStyles(theme => ({
  highcharts: {
    flex: '1 1 auto',
    width: '100%',
    '& svg': {
      width: '100%'
    },
    '& .highcharts-container': {
      width: '100% !important'
    }
  },
  headerFormat: {
    fontFamily: theme.textFontFamily,
    fontSize: '12px',
    lineHeight: '14.54px',
    color: Colors.gray500
  },
  tooltipFirstLine: {
    fontFamily: theme.textFontFamily,
    fontSize: '12px',
    lineHeight: '14.54px',
    color: Colors.blue300,
    display: 'inline-block',
    marginTop: '10px'
  },
  tooltipLastLine: {
    fontFamily: theme.textFontFamily,
    fontSize: '12px',
    lineHeight: '14.54px',
    color: Colors.gray155
  }
}));

const getOptions = (cultureCode, classes) => {
  const highchartsDayFormatKey = getHighchartsDayFormatKey(cultureCode);
  const highchartsMonthFormatKey = getHighchartsMonthFormatKey();
  const highchartsYearFormatKey = getHighchartsYearFormatKey();

  const dateTimeLabelFormats = {
    millisecond: highchartsDayFormatKey,
    second: highchartsDayFormatKey,
    minute: highchartsDayFormatKey,
    hour: highchartsDayFormatKey,
    day: highchartsDayFormatKey,
    week: highchartsDayFormatKey,
    month: highchartsMonthFormatKey,
    year: highchartsYearFormatKey
  };

  return {
    credits: {
      enabled: false
    },
    chart: {
      animation: false,
      spacingTop: 0,
      spacingRight: 0
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    xAxis: {
      tickWidth: 0,
      lineWidth: 0,
      type: 'datetime',
      labels: {
        format: '{value: %Y}'
      },
      crosshair: true
    },
    yAxis: {
      alternateGridColor: Colors.gridLineColor,
      title: {
        text: null
      },
      gridLineColor: Colors.white,
      labels: {
        style: {
          color: Colors.gridLabelColor
        }
      },
      showLastLabel: false
    },
    tooltip: {
      xDateFormat: highchartsMonthFormatKey,
      dateTimeLabelFormats,
      headerFormat: `<span class='${classes.headerFormat}'>{point.key}</span><br>`,
      pointFormatter: function() {
        const value = formatNumber(cultureCode, this.y, 0, 0);

        return `<span class='${classes.tooltipFirstLine}'>${this.series.name}</span>: <span class='${classes.tooltipLastLine}'>${value}</span>`;
      },
      useHTML: true
    },
    plotOptions: {
      series: {
        animation: false,
        marker: {
          enabled: false
        }
      }
    },
    series: []
  };
};

const Backtest = ({ portfolioTimeseries }) => {
  const classes = useStyles();
  const chartComponent = useRef();
  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const cultureCode = useSelector(sessionSelectors.getCultureCode);

  const chartOptions = R.pipe(
    R.over(
      R.lensProp('series'),
      R.append({
        name: i18n('counseling.backtest.legend.yourPortfolio', translations),
        color: Colors.backtestGreenGraphColor,
        data: portfolioTimeseries
      })
    )
  )(getOptions(cultureCode, classes));

  useEffect(() => {
    const reflow = () => {
      chartComponent.current.chart.reflow();
    };

    reflow();
    window.addEventListener('resize', reflow);
    return () => {
      window.removeEventListener('resize', reflow);
    };
  }, []);

  return (
    <div className={classes.highcharts}>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        containerProps={{ style: { width: '100%' } }}
        options={chartOptions}
      />
    </div>
  );
};

export default Backtest;
