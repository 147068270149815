import React from 'react';

import { Logos } from 'features/shared/constants/logos.js';
import { buildPublicFileUrl } from 'features/shared/utils/axios.js';

const Logo = ({
  forwardedRef,
  className,
  classes,
  type,
  defaultType,
  ...rest
}) => {
  if (defaultType) {
    const Icon = Logos[type];
    return <Icon {...rest} />;
  } else {
    return <img src={buildPublicFileUrl(type)} alt="logo" />;
  }
};

export default Logo;
