import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { useSelector } from 'react-redux';
import * as R from 'ramda';
import { i18n } from 'i18n/index.js';
import { buildPublicFileUrl } from 'features/shared/utils/axios.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { Colors } from '../../shared/constants/colors.js';

const useStyles = createUseStyles(theme => ({
  root: {
    width: '100%',
    borderCollapse: 'collapse',
    tableLayout: 'fixed'
  },
  tHeader: {
    position: 'relative',
    zIndex: '1'
  },
  headerCell: {
    backgroundColor: Colors.white
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '25px 55px 15px 55px',
    borderBottom: `1px solid ${Colors.gray200}`,
    marginBottom: '35px'
  },
  headerLogo: {
    width: '168px'
  },
  body: {
    padding: '0 55px'
  },
  tFooter: {
    position: 'relative',
    zIndex: '1'
  },
  footerCell: {
    backgroundColor: Colors.white
  },
  footer: {
    height: '35px'
  }
}));

const Page = ({ children }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const reportConfig = useSelector(sessionSelectors.getReportConfig);

  const hasLogoHeaderCustomStyles =
    reportConfig.pageHeaderLogoStyle?.width ||
    reportConfig.pageHeaderLogoStyle?.height;

  return (
    <table className={classes.root}>
      <thead className={classes.tHeader}>
        <tr>
          <td className={classes.headerCell}>
            <div className={classes.header}>
              <div>
                <div>{i18n('report.header.text_1', translationsConfig)}</div>
                <div>{i18n('report.header.text_2', translationsConfig)}</div>
              </div>
              {!R.isNil(reportConfig.pageHeaderLogo) &&
              hasLogoHeaderCustomStyles ? (
                <img
                  style={reportConfig.pageHeaderLogoStyle}
                  src={buildPublicFileUrl(reportConfig.pageHeaderLogo)}
                  alt={'Logo'}
                />
              ) : (
                <img
                  className={classes.headerLogo}
                  src={buildPublicFileUrl(reportConfig.pageHeaderLogo)}
                  alt={reportConfig.pageHeaderLogo && 'Logo'}
                />
              )}
            </div>
          </td>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td>
            <div className={classes.body}>{children}</div>
            {/* Tag below is used to prevent empty page at the beginning of the document. */}
            {/* 
                Empty page at the end of the document is not fixed yet. It appeart if height of page content equal to height of page (1123px for A4). 
                Or if there is padding/margin at the end of page (event nested one) and height of page content is inside range [height of page, height of page + padding/margin].
            */}
            <div
              dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
              style={{
                fontSize: '1px',
                lineHeight: '1px',
                height: '0px',
                overflow: 'hidden'
              }}
            />
          </td>
        </tr>
      </tbody>
      <tfoot className={classes.tFooter}>
        <tr>
          <td className={classes.footerCell}>
            <div className={classes.footer}></div>
          </td>
        </tr>
      </tfoot>
    </table>
  );
};

export default Page;
