import { ReactComponent as back } from 'assets/icons/back.svg';
import { ReactComponent as arrow_forward } from 'assets/icons/arrow_forward.svg';
import { ReactComponent as arrow_back } from 'assets/icons/arrow_back.svg';
import { ReactComponent as navigation } from 'assets/icons/navigation.svg';
import { ReactComponent as yes } from 'assets/icons/yes.svg';
import { ReactComponent as no } from 'assets/icons/no.svg';
import { ReactComponent as templeMark } from 'assets/icons/temple_mark.svg';
import { ReactComponent as vegetarianMark } from 'assets/icons/vegetarian_mark.svg';
import { ReactComponent as windTurbineMark } from 'assets/icons/wind_turbine_mark.svg';
import { ReactComponent as dots } from 'assets/icons/dots.svg';
import { ReactComponent as graphLine } from 'assets/icons/graph_line.svg';
import { ReactComponent as trees } from 'assets/icons/trees.svg';
import { ReactComponent as money } from 'assets/icons/money.svg';
import { ReactComponent as status } from 'assets/icons/status.svg';
import { ReactComponent as status_failed } from 'assets/icons/status_failed.svg';

import { ReactComponent as forward } from 'assets/icons/forward.svg';

import { ReactComponent as fundBankBuilding } from 'assets/icons/bank_building.svg';
import { ReactComponent as fundNewDocument } from 'assets/icons/new_document.svg';
import { ReactComponent as fundRefund } from 'assets/icons/refund.svg';

import { ReactComponent as riskScore } from 'assets/counseling-icons/risk_score.svg';
import { ReactComponent as experienceAndKnowledge } from 'assets/counseling-icons/experience_and_knowledge.svg';
import { ReactComponent as yearsSaving } from 'assets/counseling-icons/years_saving.svg';

import { ReactComponent as close } from 'assets/icons/close.svg';

import { ReactComponent as dots2 } from 'assets/icons/dots_2.svg';
import { ReactComponent as graphLine2 } from 'assets/icons/graph_line_2.svg';

import { ReactComponent as expensiveLoans } from 'assets/icons/expensiveLoans.svg';
import { ReactComponent as roomEconomy } from 'assets/icons/roomEconomy.svg';
import { ReactComponent as unpaidDebt } from 'assets/icons/unpaidDebt.svg';

import { ReactComponent as radio } from 'assets/icons/radio.svg';
import { ReactComponent as radioSelected } from 'assets/icons/radio_selected.svg';
import { ReactComponent as training } from 'assets/icons/training.svg';

export const Icons = {
  back: back,
  arrow_forward: arrow_forward,
  arrow_back: arrow_back,
  templeMark: templeMark,
  yes: yes,
  no: no,
  vegetarianMark: vegetarianMark,
  windTurbineMark: windTurbineMark,
  dots: dots,
  graphLine: graphLine,
  trees: trees,
  navigation: navigation,
  money: money,
  fundBankBuilding: fundBankBuilding,
  fundNewDocument: fundNewDocument,
  fundRefund: fundRefund,
  status: status,
  statusFailed: status_failed,

  riskScore: riskScore,
  experienceAndKnowledge: experienceAndKnowledge,
  yearsSaving: yearsSaving,

  forward: forward,
  close: close,
  dots2: dots2,
  graphLine2: graphLine2,

  expensiveLoans: expensiveLoans,
  roomEconomy: roomEconomy,
  unpaidDebt: unpaidDebt,
  radio: radio,
  radioSelected: radioSelected,
  training: training
};
