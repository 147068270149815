import { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { ReportPortfolioChart as PortfolioChart } from '@quantfoliorepo/ui-components';
import classNames from 'classnames';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import {
  AnalyticsComponents,
  ChartData,
  useReportStore
} from 'features/report/shared/services/reportStore';
import Card from '../../shared/components/card.js';
import Heading2 from 'features/report/shared/components/heading2';
import Paragraph from 'features/report/shared/components/paragraph.js';

const useStyles = createUseStyles(theme => ({
  card: {
    margin: '25px 0'
  },
  assetTitle: {
    marginTop: '25px'
  },
  assetAvoidPageBreak: {
    breakInside: 'avoid'
  },
  fundAllocationAdvisorNotes: {
    marginBottom: 25
  },
  advisorNotes: {
    whiteSpace: 'pre-line'
  }
}));

const GoalPortfolioSection = ({
  analyticsComponents,
  assetClassAllocationAdvisorNotes,
  fundAllocationAdvisorNotes,
  goalId,
  goalName,
  isPortfolioCustom
}: {
  analyticsComponents: AnalyticsComponents;
  assetClassAllocationAdvisorNotes?: string | false;
  fundAllocationAdvisorNotes?: string | false;
  goalId: string;
  goalName: string;
  isPortfolioCustom: boolean;
}) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const reportStore = useReportStore();

  const { userData } = reportStore;

  let chartData: ChartData | undefined;
  if (userData?.chartData) {
    chartData = userData.chartData.find(c => c.goalId === goalId);
  }

  const charts = [
    {
      title: 'roboAdvice.proposal.broadAssetAllocation',
      subTitle: 'report.portfolio.broadAssetAllocationText',
      chartName: 'portfolioBroadAssetAllocation',
      visible: analyticsComponents.portfolio.broadAssetAllocation,
      data: chartData?.categoryMainAssetClassPortfolio.filter(
        ({ weight }) => weight > 0
      )
    },
    {
      title: 'roboAdvice.proposal.assetAllocation',
      subTitle: 'report.portfolio.assetAllocationText',
      chartName: 'portfolioAssetAllocation',
      visible: analyticsComponents.portfolio.assetAllocation,
      data: chartData?.categorySubAssetClassPortfolio.filter(
        ({ weight }) => weight > 0
      ),
      advisorNotesComponent: isPortfolioCustom &&
        assetClassAllocationAdvisorNotes && (
          <>
            <Heading2 className={classes.assetTitle}>
              {i18n('report.shared.advisorNotes', translationsConfig)}
            </Heading2>
            <Paragraph className={classes.advisorNotes}>
              {assetClassAllocationAdvisorNotes}
            </Paragraph>
          </>
        )
    },
    {
      title: 'roboAdvice.proposal.fundAllocation',
      subTitle: 'report.portfolio.fundAllocationText',
      chartName: 'portfolioFundAllocation',
      visible: analyticsComponents.portfolio.fundAllocation,
      data: chartData?.categorySelectionPortfolio.filter(
        ({ weight }) => weight > 0
      ),
      advisorNotesComponent: isPortfolioCustom &&
        fundAllocationAdvisorNotes && (
          <>
            <Heading2 className={classes.assetTitle}>
              {i18n('report.shared.advisorNotes', translationsConfig)}
            </Heading2>
            <Paragraph
              className={classNames(
                classes.fundAllocationAdvisorNotes,
                classes.advisorNotes
              )}
            >
              {fundAllocationAdvisorNotes}
            </Paragraph>
          </>
        )
    }
  ];

  const onPortfolioLoad = (chartName, e) => {
    if (e.target.container) {
      e.target.container.style.overflow = 'visible';
      e.target.container.parentElement.style.overflow = 'visible';
      window.loadedCharts.push(chartName);
    }
  };

  return (
    <>
      <Heading2>
        <b>{goalName}</b>
      </Heading2>
      {charts.map(
        (el, index) =>
          el.visible && (
            <Fragment key={index}>
              <div className={classes.assetAvoidPageBreak}>
                <Heading2 className={classes.assetTitle}>
                  {i18n(el.title, translationsConfig)}
                </Heading2>
                {i18n(el.subTitle, translationsConfig) && (
                  <Paragraph>{i18n(el.subTitle, translationsConfig)}</Paragraph>
                )}
                <Card className={classes.card}>
                  <PortfolioChart
                    cultureCode={cultureCode}
                    isIsinVisible
                    isFundsAndWeightVisible={false}
                    isHorizontalReversed
                    onLoad={e => onPortfolioLoad(el.chartName, e)}
                    chartData={el.data}
                  />
                </Card>
              </div>
              {el.advisorNotesComponent}
            </Fragment>
          )
      )}
    </>
  );
};

export default GoalPortfolioSection;
