import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import { Colors } from 'features/shared/constants/colors.js';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';

const useStyles = createUseStyles(theme => ({
  speechBubble: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    background: Colors.white,
    borderRadius: '12px',
    width: '100%',
    marginBottom: '20px',
    padding: '20px',
    boxShadow:
      '0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 4px 3px ' +
      Colors.listShadowColor,

    '&::after': {
      content: '""',
      border: '20px solid transparent',
      borderTop: '20px solid green',
      left: '83%',
      bottom: 0,

      position: 'absolute',
      marginBottom: '-38px',
      borderTopColor: Colors.white
    }
  },
  subTitle: {
    fontFamily: theme.headerFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: FontWeights.medium,
    color: Colors.gray160,
    paddingBottom: '7px'
  },
  text: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: FontWeights.light,
    color: Colors.gray180
  }
}));

const SpeechBubble = ({ title, message }) => {
  const classes = useStyles();

  return (
    <div className={classes.speechBubble}>
      <span className={classes.subTitle}>{title}</span>
      <span className={classes.text}>{message}</span>
    </div>
  );
};

export default SpeechBubble;
