import React from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import Icon from '@material-ui/core/Icon';
import create from 'zustand';

import { i18n } from 'i18n/index.js';
import { FontWeights } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Header from 'features/shared/components/screenLayout/header.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import Navigation from 'features/shared/components/screenLayout/navigation';
import Button, { ButtonType } from 'features/shared/components/button/index';
import YesNoInput from 'features/shared/components/yesNoInput/index.js';
import Dialog, { useStore as useDialogStore } from './dialog.js';

const useStyles = createUseStyles(theme => ({
  body: {
    alignSelf: 'stretch'
  },
  section: {
    '& + &': {
      marginTop: '40px'
    }
  },
  sectionHeader: {
    padding: '0 20px 25px 20px',
    textAlign: 'center',
    fontFamily: theme.subTitleFontFamily,
    fontWeight: FontWeights.semiBold,
    color: Colors.grayDark
  },
  icon: {
    verticalAlign: 'top',
    position: 'relative',
    top: '-2px',
    marginLeft: '5px'
  }
}));

const useStore = create(set => ({
  isTaxableOutsideOfNorway: null,
  isPep: null,
  setIsTaxableOutsideOfNorway: value => {
    set({
      isTaxableOutsideOfNorway: value
    });
  },
  setIsPep: value => {
    set({
      isPep: value
    });
  }
}));

const getIsIsTaxableOutsideOfNorwayValid = value => !R.isNil(value);
const getIsIsPepValid = value => !R.isNil(value);
const getIsFormValid = form =>
  getIsIsTaxableOutsideOfNorwayValid(form.isTaxableOutsideOfNorway) &&
  getIsIsPepValid(form.isPep);

const Screen = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);

  const store = useStore();
  const dialogStoreState = useDialogStore.getState();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('aml2.naviTitle', translations)}
      </Navigation>

      <Page showProgressBar={false}>
        <Dialog />
        <Header>{i18n('aml2.subTitle', translations)}</Header>
        <div className={classes.body}>
          <div className={classes.section}>
            <div className={classes.sectionHeader}>
              {i18n('aml2.taxableOutsideOfNorwayQuestion', translations)}
            </div>
            <YesNoInput
              onSelect={value => {
                store.setIsTaxableOutsideOfNorway(value);
              }}
              selectedOption={store.isTaxableOutsideOfNorway}
            />
          </div>
          <div className={classes.section}>
            <div className={classes.sectionHeader}>
              {i18n('aml2.areYouPepQuestion', translations)}
              <Icon
                className={classes.icon}
                onClick={() => {
                  dialogStoreState.openDialog();
                }}
              >
                info
              </Icon>
            </div>
            <YesNoInput
              onSelect={value => {
                store.setIsPep(value);
              }}
              selectedOption={store.isPep}
            />
          </div>
        </div>
        <Footer>
          {nextRoute && (
            <Button
              disabled={!getIsFormValid(store)}
              type={ButtonType.primary}
              onClick={() => {
                const state = useStore.getState();

                if (getIsFormValid(state)) {
                  history.push(nextRoute.build(customerId));
                }
              }}
            >
              {i18n('shared.continue', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default Screen;
