import { useMemo } from 'react';
import { useSelector } from 'react-redux';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { Colors } from 'features/report/shared/constants/colors';
import { i18n } from 'i18n';
import sessionSelectors from 'features/shared/services/session/selectors';
import { ReactComponent as CheckIcon } from 'assets/icons/checkmark.svg';
import { ReactComponent as CrossIcon } from 'assets/icons/cross.svg';

const useStyles = createUseStyles({
  preferenceCriteriaTable: {
    borderCollapse: 'collapse',
    width: '100%'
  },
  headerRow: {
    backgroundColor: Colors.gray600
  },
  headerCell: {
    fontSize: 12,
    lineHeight: '14px',
    color: Colors.gray50,
    padding: 16,
    fontWeight: 400,

    '&:first-child': {
      textAlign: 'left'
    }
  },
  tableIcon: {
    maxHeight: 24,
    maxWidth: 24
  },
  row: {
    backgroundColor: Colors.gray600,
    textAlign: 'left',

    '&:nth-child(odd)': {
      backgroundColor: Colors.gray500
    }
  },
  cell: {
    color: Colors.black,
    fontSize: 12,
    lineHeight: '14px',
    padding: '6.5px 16px',

    '&:not(:first-child)': {
      textAlign: 'center'
    }
  },
  iconsContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: '10px 0',
    marginTop: 20,
    width: '100%'
  },
  iconContainer: {
    color: Colors.gray50,
    display: 'flex',
    fontSize: 10,
    lineHeight: '12px',
    width: '33.3%'
  },
  icon: {
    maxHeight: 15,
    maxWidth: 15,
    marginRight: 8
  }
});

const PreferenceCriteria = ({
  fundAnalysisData,
  preferenceCriteria,
  sustainabilityPreference
}) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  const selectedSustainabilityPreference = useMemo(
    () =>
      sustainabilityPreference.filter(({ id }) =>
        preferenceCriteria?.includes(id)
      ),
    [preferenceCriteria, sustainabilityPreference]
  );

  return (
    <>
      <table className={classes.preferenceCriteriaTable}>
        <thead>
          <tr className={classes.headerRow}>
            <th className={classes.headerCell}>
              {i18n('report.sustainability.fund', translationsConfig)}
            </th>

            {selectedSustainabilityPreference.map(({ icon, id, title }) => (
              <th key={id} className={classes.headerCell}>
                {icon ? (
                  <img
                    className={classes.tableIcon}
                    src={icon}
                    alt={title}
                    onLoad={() => window.loadedImages.push(icon)}
                  />
                ) : (
                  title
                )}
              </th>
            ))}
          </tr>
        </thead>

        <tbody>
          {fundAnalysisData.map(({ name, preferenceValues }) => (
            <tr key={name} className={classes.row}>
              <td className={classes.cell}>{name}</td>
              {selectedSustainabilityPreference.map(({ id }) => (
                <td key={id} className={classes.cell}>
                  {preferenceValues[id] !== null ? (
                    preferenceValues[id] ? (
                      <CheckIcon />
                    ) : (
                      <CrossIcon />
                    )
                  ) : null}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </table>

      <div className={classes.iconsContainer}>
        {selectedSustainabilityPreference.map(
          ({ icon, id, title }) =>
            icon && (
              <div className={classes.iconContainer} key={id}>
                <img className={classes.icon} src={icon} alt={title} />

                <div>{title}</div>
              </div>
            )
        )}
      </div>
    </>
  );
};

export default PreferenceCriteria;
