import classNames from 'classnames';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';

import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow,
  TableHeader,
  TableHeaderRow,
  TableHeaderCell
} from 'features/report/shared/components/table';
import {
  createUseStyles,
  useTheme
} from 'features/sharedModules/styles/components/styles.js';
import { FontWeights, TextFontSizes } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';
import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';

const useStyles = createUseStyles(theme => ({
  root: {
    breakInside: 'avoid-page'
  },
  title: {
    fontWeight: FontWeights.medium,
    color: theme.reportTextColor
  },
  leftAligned: {
    textAlign: 'left'
  },
  rightAligned: {
    textAlign: 'right',
    fontWeight: FontWeights.medium
  },
  headerCell: {
    fontWeight: FontWeights.medium,
    color: theme.reportTextColor
  },
  gridColumnItem: {
    '& + &': {
      marginTop: '25px'
    }
  },
  asterisk: {
    color: Colors.gray50,
    fontSize: TextFontSizes.xSmall,
    lineHeight: '14px',
    marginTop: '12px'
  },
  withIcon: {
    '&:first-child': {
      width: 60
    },

    '&:nth-child(2)': {
      textAlign: 'left',
      fontWeight: FontWeights.normal
    }
  },
  secondRowActive: {
    textAlign: 'center'
  }
}));

const ChartTable = ({
  headerTitles,
  secondHeaderTitles = [],
  dataKeys,
  data,
  customClasses,
  asterisk,
  rowClassName = '',
  highlightLastRow = false,
  withIcon = false,
  addPercentToAllValues = false
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  return (
    <div className={classes.root}>
      <Table className={classes.gridColumnItem}>
        <TableHeader>
          <TableHeaderRow>
            {headerTitles
              .filter((_, index) =>
                data.some(
                  row =>
                    !isNil(row[dataKeys[index]]) &&
                    row.fund !==
                      i18n(
                        'portfolioChart.default.portfolio',
                        translationsConfig
                      ) &&
                    row.fund !== 'Portfolio'
                )
              )
              .map((el, idx) => (
                <TableHeaderCell
                  className={classNames(
                    classes.title,
                    idx === 0 ? classes.leftAligned : classes.rightAligned,
                    customClasses?.headerCell,
                    {
                      [classes.secondRowActive]: secondHeaderTitles.length > 0
                    }
                  )}
                  key={idx}
                  colSpan={idx > 0 && secondHeaderTitles.length > 0 ? 2 : 0}
                >
                  {el}
                </TableHeaderCell>
              ))}
          </TableHeaderRow>
          {secondHeaderTitles.length > 0 && (
            <TableHeaderRow>
              {secondHeaderTitles.map((el, idx) => (
                <TableHeaderCell
                  className={classNames(
                    classes.title,
                    idx === 0 ? classes.leftAligned : classes.rightAligned,
                    customClasses?.headerCell
                  )}
                  key={idx}
                >
                  {el}
                </TableHeaderCell>
              ))}
            </TableHeaderRow>
          )}
        </TableHeader>
        <TableBody>
          {data.map((val, idx) => (
            <TableBodyRow className={rowClassName} key={idx}>
              {dataKeys
                .filter(dataKey =>
                  data.some(
                    row =>
                      !isNil(row[dataKey]) &&
                      row.fund !==
                        i18n(
                          'portfolioChart.default.portfolio',
                          translationsConfig
                        ) &&
                      row.fund !== 'Portfolio'
                  )
                )
                .map((k, index) => (
                  <TableBodyCell
                    className={classNames(
                      index === 0 ? classes.leftAligned : classes.rightAligned,
                      customClasses?.cell,
                      {
                        [classes.withIcon]: withIcon
                      }
                    )}
                    key={index}
                    style={
                      highlightLastRow && idx === data.length - 1
                        ? {
                            backgroundColor: theme.reportHighlightColor,
                            fontWeight: 'bold'
                          }
                        : {}
                    }
                  >
                    {val[k]}
                    {k === 'percent' ? '%' : null}
                    {addPercentToAllValues &&
                    !['fund', 'field', 'Name'].includes(k)
                      ? '%'
                      : null}
                  </TableBodyCell>
                ))}
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>

      {asterisk && <div className={classes.asterisk}>{asterisk}</div>}
    </div>
  );
};

export default ChartTable;
