import { useSelector } from 'react-redux';
import { isNil } from 'ramda';

import sessionSelectors from 'features/shared/services/session/selectors';
import Heading2 from 'features/report/shared/components/heading2';
import ChartTable from '../../../../shared/components/chartTable';
import { i18n } from 'i18n';
import useStyles from './styles';
import { useReportStore } from 'features/report/shared/services/reportStore';
import AdvisorNotesSection from '../../advisorNotesSection';

const OneTimeFee = ({ data }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const { userData } = useReportStore();
  const overrideFundOneTimeFeesAdvisorNotes =
    userData?.cost?.overrideFundOneTimeFeesAdvisorNotes;

  return (
    <>
      {data.percent.oneTimeFees
        .slice(0, -1)
        .every(item =>
          Object.entries(item).some(
            ([key, value]) => key !== 'fund' && !isNil(value)
          )
        ) && (
        <>
          <div className={classes.section}>
            <Heading2>
              {i18n('costChart.costTable.oneTimeFee', translationsConfig)}
            </Heading2>
            <ChartTable
              customClasses={{
                headerCell: classes.headerCell,
                cell: classes.cell
              }}
              headerTitles={[
                i18n('costChart.fundsTable.fund', translationsConfig),
                i18n('costChart.costTable.fundPurchaseFee', translationsConfig),
                i18n(
                  'costChart.costTable.advisoryPurchaseFee',
                  translationsConfig
                ),
                i18n(
                  'costChart.costTable.advisoryOnboardingFee',
                  translationsConfig
                )
              ]}
              data={data.percent.oneTimeFees}
              dataKeys={[
                'fund',
                'fundPurchaseFee',
                'advisoryPurchaseFee',
                'advisoryOnboardingFee'
              ]}
              highlightLastRow
              addPercentToAllValues
            />
          </div>

          {overrideFundOneTimeFeesAdvisorNotes && (
            <AdvisorNotesSection
              className={classes.advisorNotes}
              header={i18n(
                'costChart.fundsTable.reasoningForOversteeredCost',
                translationsConfig
              )}
              text={overrideFundOneTimeFeesAdvisorNotes}
            />
          )}
        </>
      )}

      {data.percent.oneTimeFees
        .slice(0, -1)
        .every(item =>
          Object.entries(item).some(
            ([key, value]) => key !== 'fund' && !isNil(value)
          )
        ) && (
        <div className={classes.section}>
          <ChartTable
            customClasses={{
              headerCell: classes.headerCell,
              cell: classes.cell
            }}
            headerTitles={[
              i18n('costChart.fundsTable.fund', translationsConfig),
              i18n('costChart.costTable.fundPurchaseFee', translationsConfig),
              i18n(
                'costChart.costTable.advisoryPurchaseFee',
                translationsConfig
              ),
              i18n(
                'costChart.costTable.advisoryOnboardingFee',
                translationsConfig
              )
            ]}
            data={data.currency.oneTimeFees}
            dataKeys={[
              'fund',
              'fundPurchaseFee',
              'advisoryPurchaseFee',
              'advisoryOnboardingFee'
            ]}
            highlightLastRow
          />
        </div>
      )}
    </>
  );
};

export default OneTimeFee;
