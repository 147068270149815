import React, { Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import * as R from 'ramda';

import { i18n } from 'i18n/index.js';
import KnowledgeGraph from 'assets/knowledge-icons/graph.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors, {
  getInvestmentFallPercentageByRisk
} from 'features/shared/services/userOptions/selectors.js';
import statusSelectors from 'features/shared/services/status/selectors.js';
import { creators as userOptionsCreator } from 'features/shared/services/userOptions/actions.js';
import List from 'features/shared/components/list/index.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import I18n from 'features/shared/components/i18n/index.js';
import ProgressBar from 'features/shared/components/progressBar/index';
import { Colors } from 'features/shared/constants/colors';
import { formatNumber } from 'features/shared/utils/number.js';

const useStyles = createUseStyles(theme => {
  return {
    subTitle: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.largePx,
      lineHeight: TextFontSizes.largeLineHeightPx,
      textAlign: 'center',
      paddingTop: '40px',
      color: Colors.grayDark
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    contentHeader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '40px',
      paddingTop: '50px'
    }
  };
});

const ExperienceAssessment = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const reacts = useSelector(sessionSelectors.getReactsConfig);
  const userOptions = useSelector(userOptionsSelectors.getUserOptions);
  const percentByRisk = useSelector(getInvestmentFallPercentageByRisk);
  const riskScoreStatus = useSelector(
    statusSelectors.getStatusOrDefault('riskScore_state')
  );

  const dispatch = useDispatch();
  const userOptionsChanged = (type, value) => {
    dispatch(userOptionsCreator.userOptionChanged({ type, value }));
  };

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('knowledge.experienceAssessment.naviTitle', translations)}
      </Navigation>

      <Page>
        {riskScoreStatus.isPending && <ProgressBar />}
        {!riskScoreStatus.isPending && (
          <Fragment>
            <div className={classes.content}>
              <div className={classes.contentHeader}>
                <KnowledgeGraph />
                <div className={classes.subTitle}>
                  <I18n
                    text={i18n(
                      'knowledge.experienceAssessment.subTitle',
                      translations
                    )}
                    replaceParams={{
                      '{0}': (
                        <span>
                          {R.isNil(percentByRisk)
                            ? i18n('shared.noValueText', translations)
                            : formatNumber(cultureCode, percentByRisk, 0, 2)}
                        </span>
                      )
                    }}
                  />
                </div>
              </div>

              <List
                options={reacts}
                selectedOption={userOptions.react}
                userOptionType="react"
                onSelect={(type, item) => {
                  userOptionsChanged(type, item.id);
                  window.scrollTo(0, document.body.scrollHeight);
                }}
              />
            </div>

            <Footer>
              {nextRoute && (
                <Button
                  type={ButtonType.primary}
                  disabled={R.isNil(userOptions.react)}
                  onClick={() => {
                    history.push(nextRoute.build(customerId));
                  }}
                >
                  {i18n('shared.continue', translations)}
                </Button>
              )}
            </Footer>
          </Fragment>
        )}
      </Page>
    </Layout>
  );
};

export default ExperienceAssessment;
