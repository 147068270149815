import * as R from 'ramda';
import {
  createForm as ffCreateForm,
  Config,
  ValidationErrors,
  FormApi as FfFormApi
} from 'final-form';

import shallowEqual from 'features/shared/utils/shallowEqual.js';
import { runValidation } from './mutators';

type Override<T1, T2> = Omit<T1, keyof T2> & T2;

export type FormApi<
  FormValues = Record<string, any>,
  InitialFormValues = Partial<FormValues>
> = FfFormApi<FormValues, InitialFormValues> & {
  restartToInitialValues: () => void;
};

export function createForm<FormValues>(
  config: Override<
    Config<FormValues>,
    {
      validationDependencies?: { [key: string]: any };
      validate?: (
        values: FormValues,
        dependencies?: any
      ) => ValidationErrors | Promise<ValidationErrors>;
    }
  > = {
    onSubmit: () => {}
  }
): FormApi<FormValues> {
  const {
    validationDependencies,
    validate: originalValidate,
    ...restConfig
  } = config;

  const getDependencies = () =>
    R.pipe(
      R.keys,
      R.reduce(
        (acc, key) => ({
          ...acc,
          [key]: validationDependencies?.[key][0]()
        }),
        {}
      )
    )(validationDependencies);

  let validate = originalValidate;
  if (!R.isNil(originalValidate) && !R.isNil(validationDependencies)) {
    validate = values => {
      const dependencies = getDependencies();

      return originalValidate!(values, dependencies);
    };
  }

  const formApi = ffCreateForm({
    ...restConfig,
    mutators: {
      ...restConfig?.mutators,
      runValidation
    },
    validate
  });

  if (!R.isNil(originalValidate) && !R.isNil(validationDependencies)) {
    const subscriptions = R.pipe<{ [key: string]: any }, any[], any[]>(
      R.values,
      R.map(d => d[1])
    )(validationDependencies);
    let lastDependencies = {};

    for (const s of subscriptions) {
      s(() => {
        const dependencies = getDependencies();

        if (!shallowEqual(dependencies, lastDependencies)) {
          lastDependencies = dependencies;
          formApi.mutators.runValidation();
        } else {
          lastDependencies = dependencies;
        }
      });
    }
  }

  const initialValues: any = restConfig?.initialValues ?? {};

  return {
    ...formApi,
    restartToInitialValues: () => {
      formApi.restart(initialValues);
    }
  };
}
