import * as R from 'ramda';
import moment from 'moment';
import qs from 'qs';

import {
  advisoryRoboApi,
  advisoryRoboApiV2,
  clientsApiUrl,
  qAuthApi,
  savingCalculatorApi,
  savingRoboApi
} from 'features/shared/utils/axios.js';
import {
  getQAuthApiAccessToken,
  setQAuthApiAccessToken
} from 'features/shared/services/session/storage.js';

export const getQAuthAccessToken = async (customerId, cancelToken) => {
  const accessToken = getQAuthApiAccessToken();

  if (!R.isNil(accessToken) && accessToken.customerId === customerId) {
    return accessToken.qAuthAccessToken;
  }

  const auth = await qAuthApi.post(
    '/auth/token/open',
    {
      customer_id: customerId,
      application: 'robo-front'
    },
    {
      cancelToken
    }
  );

  setQAuthApiAccessToken(
    {
      qAuthAccessToken: auth.data.token,
      customerId
    },
    moment.utc(auth.data.expire_utc).toDate().getTime()
  );

  return auth.data.token;
};

export const getCustomer = (accessToken, cancelToken, customerId, params) => {
  return clientsApiUrl.get(`/${customerId}`, {
    cancelToken,
    headers: { Authorization: `Bearer ${accessToken}` },
    params
  });
};

export const getReport = (accessToken, cancelToken, id) => {
  return savingRoboApi.get(`/pdf-report/${id}`, {
    cancelToken,
    headers: { Authorization: accessToken }
  });
};

export const getRisk = (accessToken, cancelToken, params) => {
  return advisoryRoboApi.get(`/risk/generic/`, {
    params,
    cancelToken,
    headers: { Authorization: accessToken }
  });
};

export const getBacktest = () => {
  return { id: '1', data: {} };
};

export const getFees = () => {
  return { id: '1', data: {} };
};

export const readPortfolio = (accessToken, cancelToken, params) => {
  return advisoryRoboApi.get('/robo/portfolio', {
    cancelToken,
    headers: { Authorization: accessToken },
    params,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
  });
};

export const readPortfolioMeta = (accessToken, cancelToken) => {
  return advisoryRoboApi.get('/robo/portfolio/meta', {
    cancelToken,
    headers: { Authorization: accessToken }
  });
};

export const readSelection = (accessToken, cancelToken) => {
  return advisoryRoboApi.get('/robo/selection', {
    cancelToken,
    headers: { Authorization: accessToken },
    params: { no_instruments: 1 }
  });
};

export const readHistoricalReturn = (accessToken, cancelToken, data) => {
  return advisoryRoboApi.post('analyze/historical-return', data, {
    cancelToken,
    headers: { Authorization: accessToken }
  });
};

export const createAnalyzeForecast = (accessToken, cancelToken, data) => {
  return advisoryRoboApiV2.post(`/analyze/forecast`, data, {
    cancelToken,
    headers: { Authorization: accessToken }
  });
};

export const createRiskReturn = (accessToken, cancelToken, data) =>
  advisoryRoboApi.post('/analyze/risk-return', data, {
    cancelToken,
    headers: { Authorization: accessToken }
  });

export const readForecast = (accessToken, cancelToken, params) => {
  return advisoryRoboApi.get(`/robo/forecast`, {
    cancelToken,
    headers: { Authorization: accessToken },
    params,
    paramsSerializer: params => qs.stringify(params, { arrayFormat: 'repeat' })
  });
};

export const readFunds = (accessToken, cancelToken) => {
  return advisoryRoboApi.get('/fund/list/', {
    cancelToken,
    headers: { Authorization: accessToken },
    params: { filter: 0 }
  });
};

export const readFundMeta = (accessToken, cancelToken, ticker) => {
  return advisoryRoboApi.get(`/fund/meta/${ticker}`, {
    cancelToken,
    headers: { Authorization: accessToken }
  });
};

export const readCalculatorSimple = (accessToken, cancelToken, data) => {
  return savingCalculatorApi.post(
    `/calculator/simple/`,
    { input: data },
    {
      headers: {
        Authorization: accessToken
      },
      cancelToken
    }
  );
};
