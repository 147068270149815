import React from 'react';
import { injectSheet } from 'features/sharedModules/styles/components/styles.js';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';

import { Colors } from 'features/shared/constants/colors.js';

const styles = theme => {
  return {
    highcharts: {
      flex: '1 1 auto',
      width: '100%',
      '& svg': {
        width: '100%'
      },
      '& .highcharts-container': {
        width: '100% !important'
      }
    }
  };
};

const options = {
  credits: {
    enabled: false
  },
  chart: {
    type: 'column',
    backgroundColor: 'transparent',
    events: {
      render: function() {
        let chart = this,
          ticks = Object.values(chart.yAxis[0].ticks);
        ticks.forEach(function(tick, i) {
          if (ticks.length - 1 === i) {
            tick.gridLine.element.remove();
          }
        });
      }
    },
    animation: false
  },
  title: {
    text: null
  },
  legend: {
    enabled: false
  },
  xAxis: {
    gridLineColor: Colors.feesGridLineColor,
    gridLineWidth: 1,
    tickmarkPlacement: 'on',
    categories: ['2010', '', '2012', '', '2014', '', '2016', '', '2018', '']
  },
  yAxis: {
    gridLineColor: Colors.feesGridLineColor,
    gridLineWidth: 1,
    tickmarkPlacement: 'on',
    title: {
      text: null
    }
  },
  tooltip: {
    enabled: false
  },
  plotOptions: {
    series: {
      groupPadding: 0,
      borderWidth: 0,
      enableMouseTracking: false,
      animation: false
    },
    column: {
      stacking: 'normal',
      dataLabels: {
        enabled: false
      }
    }
  },
  series: [
    {
      color: Colors.feesLightBlueGraphColor,
      data: [1, 1, 1, 1, 2, 2, 2, 2, 2, 2]
    },
    {
      color: Colors.feesLightGreenGraphColor,
      data: [1, 1, 1, 1, 2, 2, 2, 2, 2, 2]
    },
    {
      color: Colors.feesPureBlueGraphColor,
      data: [10, 11, 12, 13, 14, 15, 16, 17, 18, 19]
    }
  ]
};

const Fees = ({ classes }) => {
  return (
    <div className={classes.highcharts}>
      <HighchartsReact
        highcharts={Highcharts}
        containerProps={{ style: { width: '100%' } }}
        options={options}
      />
    </div>
  );
};

export default injectSheet(styles)(Fees);
