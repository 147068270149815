import React from 'react';
import { isNil } from 'ramda';
import { useSelector } from 'react-redux';
import {
  CostChart,
  costTableDataMapping,
  costIcons
} from '@quantfoliorepo/ui-components';

import Heading1 from 'features/report/shared/components/heading1';
import { i18n } from 'i18n';
import sessionSelectors from 'features/shared/services/session/selectors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import Card from 'features/report/shared/components/card';
import { useReportStore } from 'features/report/shared/services/reportStore';
import YearsTable from './yearsTable';
import FundsTable from './fundsTable';
import { FontWeights } from 'features/report/shared/constants/fonts';
import Paragraph from '../../../shared/components/paragraph';
import SummaryTable from './summaryTable';
import ExplanationOfTerms from './explanationOfTerms';
import Section from 'features/report/shared/components/section';

const useStyles = createUseStyles({
  root: {
    marginTop: '26px'
  },
  card: {
    margin: '26px 0'
  },
  summaryValue: {
    fontWeight: FontWeights.bold
  },
  summaryField: {
    marginBottom: '10px'
  },
  summary: {
    display: 'flex',
    columnGap: 10,
    justifyContent: 'space-between'
  },
  advisorNotes: {
    whiteSpace: 'pre-line'
  }
});

const CostSection = ({ chartWidth }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const reportStore = useReportStore();
  const reportConfig: any = useSelector(sessionSelectors.getReportConfig);

  const { userData } = reportStore;
  const costTableData = userData?.cost.tableData;
  const startYear = React.useMemo(() => new Date().getUTCFullYear() + 1, []);

  const yearsData = React.useMemo(() => {
    if (!costTableData) return null;
    const { firstYear, lastYear } = costTableData;

    return {
      firstYear: {
        aggregatedCostEffect: costTableDataMapping
          .getForYearAggregatedCostEffect(firstYear, cultureCode)
          .filter(el => !isNil(el.value) || !isNil(el.percent))
          .map(el => ({ ...el, field: i18n(el.field, translationsConfig) })),
        aggregatedOngoingFees: costTableDataMapping
          .getForYearAggregatedOngoingFees(firstYear, cultureCode)
          .filter(el => !isNil(el.value) && !isNil(el.percent))
          .map(el => ({ ...el, field: i18n(el.field, translationsConfig) })),
        oneTimeFees: costTableDataMapping
          .getForYearOneTimeFees(firstYear, cultureCode)
          .map(el => ({ ...el, field: i18n(el.field, translationsConfig) })),
        aggregatedReturnCommissionPaidToAdvisor: costTableDataMapping
          .getForYearAggregatedReturnCommissionPaidToAdvisor(
            firstYear,
            cultureCode
          )
          .map(el => ({ ...el, field: i18n(el.field, translationsConfig) }))
      },
      lastYear: {
        aggregatedCostEffect: costTableDataMapping
          .getForYearAggregatedCostEffect(lastYear, cultureCode)
          .filter(el => !isNil(el.value) || !isNil(el.percent))
          .map(el => ({ ...el, field: i18n(el.field, translationsConfig) })),
        aggregatedOngoingFees: costTableDataMapping
          .getForYearAggregatedOngoingFees(lastYear, cultureCode)
          .filter(el => !isNil(el.value) && !isNil(el.percent))
          .map(el => ({ ...el, field: i18n(el.field, translationsConfig) })),
        oneTimeFees: costTableDataMapping
          .getForYearOneTimeFees(lastYear, cultureCode)
          .filter(el => !isNil(el.value) && !isNil(el.percent))
          .map(el => ({ ...el, field: i18n(el.field, translationsConfig) })),
        aggregatedReturnCommissionPaidToAdvisor: costTableDataMapping
          .getForYearAggregatedReturnCommissionPaidToAdvisor(
            lastYear,
            cultureCode
          )
          .filter(el => !isNil(el.value) && !isNil(el.percent))
          .map(el => ({ ...el, field: i18n(el.field, translationsConfig) }))
      }
    };
  }, [cultureCode, translationsConfig, costTableData]);

  const tableData = React.useMemo(() => {
    if (!costTableData || !yearsData) return null;
    const { summary, fundDetails } = costTableData;

    return {
      summary: {
        annualOngoingFees: costTableDataMapping
          .getSummaryAnnualOngoingFees(summary, cultureCode)
          .filter(el => !isNil(el.percent))
          .map(el => ({
            ...el,
            icon: costIcons[el.icon],
            field: i18n(el.field, translationsConfig)
          })),
        oneTimeFees: costTableDataMapping
          .getSummaryOneTimeFees(summary, cultureCode)
          .filter(el => !isNil(el.percent))
          .map(el => ({ ...el, field: i18n(el.field, translationsConfig) })),
        annualReturnCommissionPaidToAdvisor: costTableDataMapping
          .getSummaryAnnualReturnCommissionPaidToAdvisor(summary, cultureCode)
          .filter(el => !isNil(el.percent))
          .map(el => ({ ...el, field: i18n(el.field, translationsConfig) })),
        custodyFees: costTableDataMapping
          .getSummaryCustodyFees(summary, cultureCode)
          .filter(el => !isNil(el.value))
          .map(el => ({ ...el, field: i18n(el.field, translationsConfig) }))
      },
      years: {
        aggregatedCostEffect: yearsData.firstYear.aggregatedCostEffect.map(
          el => {
            const indexLastYear =
              yearsData.lastYear.aggregatedCostEffect.findIndex(
                elLast => el.field === elLast.field
              );

            const yearData: any = {
              field: el.field,
              firstYear: el.value || `${el.percent}%`
            };
            if (indexLastYear !== -1) {
              yearData.lastYear =
                yearsData.lastYear.aggregatedCostEffect[indexLastYear].value ||
                `${yearsData.lastYear.aggregatedCostEffect[indexLastYear].percent}%`;
            }

            return yearData;
          }
        ),
        aggregatedOngoingFees: yearsData.firstYear.aggregatedOngoingFees.map(
          el => {
            const indexLastYear =
              yearsData.lastYear.aggregatedOngoingFees.findIndex(
                elLast => el.field === elLast.field
              );

            const yearData: any = {
              field: el.field,
              firstYearValue: el.value,
              firstYearPercent: `${el.percent}%`
            };
            if (indexLastYear !== -1) {
              yearData.lastYearValue =
                yearsData.lastYear.aggregatedOngoingFees[indexLastYear].value;
              yearData.lastYearPercent = `${yearsData.lastYear.aggregatedOngoingFees[indexLastYear].percent}%`;
            }

            return yearData;
          }
        ),
        aggregatedOneTimeFee: yearsData.lastYear.oneTimeFees,
        aggregatedReturnCommissionPaidToAdvisor:
          yearsData.lastYear.aggregatedReturnCommissionPaidToAdvisor
      },
      fundDetails: {
        percent: {
          ongoingFees: fundDetails.ongoingFees
            .map(el => el.percent)
            .map(el =>
              costTableDataMapping.getFundOngoingFees(el, cultureCode)
            ),
          oneTimeFees: fundDetails.oneTimeFees
            .map(el => el.percent)
            .map(el =>
              costTableDataMapping.getFundOneTimeFees(el, cultureCode)
            ),
          custodyFees: fundDetails.custodyFees
            .map(el => el.percent)
            .map(el =>
              costTableDataMapping.getFundCustodyFees(el, cultureCode)
            ),
          returnCommissionPaidToAdvisor:
            fundDetails.returnCommissionPaidToAdvisor
              .map(el => el.percent)
              .filter(el => el.fundReturnCommissionPaidToAdvisor)
              .map(el =>
                costTableDataMapping.getFundReturnCommissionPaidToAdvisor(
                  el,
                  cultureCode
                )
              )
        },
        currency: {
          ongoingFees: fundDetails.ongoingFees
            .map(el => el.currency)
            .map(el =>
              costTableDataMapping.getFundOngoingFees(el, cultureCode, 0)
            ),
          oneTimeFees: fundDetails.oneTimeFees
            .map(el => el.currency)
            .map(el =>
              costTableDataMapping.getFundOneTimeFees(el, cultureCode)
            ),
          custodyFees: fundDetails.custodyFees
            .map(el => el.currency)
            .map(el =>
              costTableDataMapping.getFundCustodyFees(el, cultureCode)
            ),
          returnCommissionPaidToAdvisor:
            fundDetails.returnCommissionPaidToAdvisor
              .map(el => el.currency)
              .filter(el => el.fundReturnCommissionPaidToAdvisor)
              .map(el =>
                costTableDataMapping.getFundReturnCommissionPaidToAdvisor(
                  el,
                  cultureCode
                )
              )
        }
      }
    };
  }, []);
  const chartData = userData?.cost.chartData;

  const costParagraphs = [
    i18n('report.cost.text1', translationsConfig).replace(
      '{year}',
      isNil(chartData) ? 0 : chartData.length
    ),
    i18n('report.cost.text2', translationsConfig),
    i18n('report.cost.text3', translationsConfig),
    i18n('report.cost.text4', translationsConfig)
  ];

  return (
    <Section>
      <Section>
        <Heading1>{i18n('report.cost.header', translationsConfig)}</Heading1>
        <div>
          {costParagraphs.map((t, index) => (
            <Paragraph key={index}>{t}</Paragraph>
          ))}
        </div>
        <Card className={classes.card}>
          <CostChart
            cultureCode={cultureCode}
            items={chartData}
            startYear={startYear}
            width={chartWidth}
            onLoad={() => window.loadedCharts.push('cost')}
            showLegend
            customColors={userData?.chartColorsConfig.cost as any}
          />
        </Card>
        <SummaryTable data={tableData?.summary} />
      </Section>

      <Section className={classes.root}>
        {chartData && (
          <YearsTable
            data={tableData?.years}
            maxYears={chartData.length > 10 ? 10 : chartData.length}
          />
        )}
        {(reportConfig.fundDetailsTableItems?.ongoingFees ||
          reportConfig.fundDetailsTableItems?.oneTimeFee ||
          reportConfig.fundDetailsTableItems
            ?.returnCommissionPaidToAdvisor) && (
          <FundsTable data={tableData?.fundDetails} />
        )}
        {reportConfig.costExplanationOfTerms &&
          reportConfig.costExplanationOfTerms.length > 0 && (
            <ExplanationOfTerms
              costExplanationOfTerms={reportConfig.costExplanationOfTerms}
            />
          )}
      </Section>
    </Section>
  );
};
export default CostSection;
