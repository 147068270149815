import routerReducer from 'features/shared/services/router/reducer.js';
import formReducer from 'features/main/services/form/reducer.js';
import sessionReducer from 'features/shared/services/session/reducer.js';
import userOptionsReducer from 'features/shared/services/userOptions/reducer.js';
import notificationsReducer from 'features/notification/services/reducer.js';
import statusReducer from 'features/main/services/status/reducer.js';

export const reducers = (state = {}, action) => {
  return {
    router_state: routerReducer(state.router_state, action),
    form_state: formReducer(state.form_state, action),
    config_state: sessionReducer(state.config_state, action),
    user_options_state: userOptionsReducer(state.user_options_state, action),
    notification_state: notificationsReducer(state.notification_state, action),
    status_state: statusReducer(state.status_state, action)
  };
};
