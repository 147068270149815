import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import classNames from 'classnames';

import { FontWeights } from '../../shared/constants/fonts.js';

const useStyles = createUseStyles(theme => ({
  summary: {},
  row: {
    display: 'flex',
    alignItems: 'baseline',

    '& + &': {
      marginTop: '14px'
    }
  },
  title: {
    width: '280px'
  },
  value: {
    fontWeight: FontWeights.semiBold
  }
}));

const Summary = ({ items, titleClassName, valueClassName }) => {
  const classes = useStyles();

  return (
    <div className={classes.summary}>
      {items.map((i, index) => (
        <div key={index} className={classes.row}>
          <div className={classNames(classes.title, titleClassName)}>
            {i.title}
          </div>
          <div className={classNames(classes.value, valueClassName)}>
            {i.value}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Summary;
