import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isNil } from 'ramda';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { useReportStore } from 'features/report/shared/services/reportStore';
import { FontWeights, TextFontSizes } from '../../shared/constants/fonts.js';
import {
  getClientInformation,
  getGeneralInformation,
  getOrderInformation
} from '../services/reportSelectors';
import Cover from './cover.js';
import Page from './page.js';
import GeneralInformationSection from './generalInformationSection.js';
import BackgroundSection from './backgroundSection.js';
import AboutCustomerSection from './aboutCustomerSection.js';
import OrderInformationSection from './orderInformationSection.js';
import AcceptanceSection from './acceptanceSection.js';
import OrderExecutionSummarySection from './orderExecutionSummarySection.js';
import OrderExecutionCostSection from './orderExecution/costSection/index';
import OrderExecutionExpectedValueSection from './orderExecutionExpectedValueSection';

declare global {
  interface Window {
    chartsToLoad: string[];
    loadedCharts: string[];
  }
}

const CHART_WIDTH = 630;

// No margin-bottom is allowed for top level page components (sections particularly) in order to make 1px offset fix work.
// We can only do calculation based on sections, because we can not predict how section itself will be split.

const useStyles = createUseStyles(theme => ({
  '@global': {
    html: {
      height: '100%'
    },
    body: {
      height: '100%',
      margin: 0,
      padding: 0,
      fontFamily: theme.reportFontFamily,
      fontSize: TextFontSizes.x1,
      lineHeight: TextFontSizes.x1LineHeight,
      fontWeight: FontWeights.normal,
      color: theme.reportTextColor
    },
    '*': {
      boxSizing: 'border-box'
    }
  }
}));

const Body = () => {
  const classes = useStyles();
  const reportStore = useReportStore();
  const clientInformation = getClientInformation(reportStore);
  const orderInformation = getOrderInformation(reportStore);
  const generalInformation = getGeneralInformation(reportStore);
  const advisorName = reportStore?.userData?.advisorName;
  const bankAccountNumber =
    reportStore?.userData?.clientInformation?.accountNumber;
  const orderSummary = reportStore?.userData?.orderSummary;
  const reportConfig: any = useSelector(sessionSelectors.getReportConfig);
  const analyticsComponents = reportStore.userData?.analyticsComponents;
  const costData = reportStore.userData?.costData;
  const expectedValueData = reportStore.userData?.expectedValueData;
  const isExpectedValueEmpty = isNil(expectedValueData) || isEmpty(expectedValueData);
  const advisorNotes = reportStore.userData?.advisorNotes;
  const productAttributes = reportStore.userData?.productAttributes;

  useEffect(() => {
    if (!isExpectedValueEmpty && analyticsComponents?.expectedValue) {
      window.chartsToLoad.push('expectedValue');
    }
  }, []);

  return (
    <>
      <Cover
        generalInformation={generalInformation}
        advisorName={advisorName}
      />
      <Page>
        {reportConfig.background && (
          <BackgroundSection
            generalInformation={generalInformation}
            backgroundTexts={reportConfig.orderExecutionBackgroundTexts}
            advisorName={advisorName}
          />
        )}
        {reportConfig.aboutCustomer && <AboutCustomerSection />}
        {reportConfig.generalInformation && (
          <GeneralInformationSection clientInformation={clientInformation} />
        )}
        {reportConfig.orderInformation && (
          <OrderInformationSection
            orderInformation={orderInformation}
            advisorNotes={advisorNotes?.orderInformationAdvisorNotes}
          />
        )}
        {reportConfig.orderExecutionSummarySection && (
          <OrderExecutionSummarySection
            orderSummary={orderSummary}
            productAttributes={productAttributes}
            advisorNotes={advisorNotes?.instrumentsAdvisorNotes}
          />
        )}

        {analyticsComponents?.cost && costData && (
          <OrderExecutionCostSection
            costData={costData}
            advisorNotes={advisorNotes?.proposalAdvisorNotes}
          />
        )}

        {analyticsComponents?.expectedValue && !isExpectedValueEmpty && (
          <OrderExecutionExpectedValueSection
            chartWidth={CHART_WIDTH}
            expectedValueData={expectedValueData}
          />
        )}

        {reportConfig.acceptanceSection && (
          <AcceptanceSection
            bankAccountNumber={bankAccountNumber}
            generalInformation={generalInformation}
            advisorName={advisorName}
          />
        )}
      </Page>
    </>
  );
};
export default Body;
