import * as R from 'ramda';
import { useSelector } from 'react-redux';

import routeTemplates from 'features/shared/utils/routeTemplates.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { getPrevRouteConfig } from 'features/shared/services/router/selectors.js';
import { usePageStore } from 'features/counseling/signing/services/pageStore.js';

const getIdentity = R.prop('identity');

export function usePrevRoute(routeSelector = getPrevRouteConfig) {
  const identity = usePageStore(getIdentity);

  const prevRoute = useSelector(routeSelector);
  const routesFlow = useSelector(sessionSelectors.getRoutesFlowConfig);
  const authConfig = useSelector(sessionSelectors.getAuthConfig);

  if (
    R.prop('id', prevRoute) === routeTemplates.signing.id &&
    (!R.prop('isRequired', authConfig) || !R.isNil(identity))
  ) {
    const signingRouteFlow = R.find(R.propEq('id', routeTemplates.signing.id))(
      routesFlow
    );

    return R.isNil(signingRouteFlow) || R.isNil(signingRouteFlow.prevId)
      ? null
      : routeTemplates[signingRouteFlow.prevId];
  }

  return prevRoute;
}
