import { ExpectedValueChart } from '@quantfoliorepo/ui-components';
import { useSelector } from 'react-redux';

import sessionSelectors from 'features/shared/services/session/selectors';
import { useReportStore } from 'features/report/shared/services/reportStore';
import Section from 'features/report/shared/components/section';
import Heading1 from 'features/report/shared/components/heading1';
import { i18n } from 'i18n';

type Props = {
  chartWidth: number;
  expectedValueData: { x: number; y: number }[];
};

const OrderExecutionExpectedValueSection = ({
  chartWidth,
  expectedValueData
}: Props) => {
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const reportStore = useReportStore();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  return (
    <>
      <Section>
        <Heading1>
          {i18n(
            'report.orderExecution.expectedValue.header',
            translationsConfig
          )}
        </Heading1>

        <div>
          {i18n('report.orderExecution.expectedValue.text', translationsConfig)}
        </div>

        <ExpectedValueChart
          chartData={{ savingPlan: expectedValueData }}
          cultureCode={cultureCode}
          customColors={
            reportStore.userData?.chartColorsConfig.expectedValue as any
          }
          isInteractive={false}
          onLoad={() => {
            window.loadedCharts.push('expectedValue');
          }}
          savingPlanOnly
          width={chartWidth}
          yMinValue={expectedValueData[0]?.y || 0}
        />
      </Section>
    </>
  );
};

export default OrderExecutionExpectedValueSection;
