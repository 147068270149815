import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles({
  headerCell: {
    overflowWrap: 'break-word',
    padding: '9px 3px',
    fontSize: '12px',

    '&:first-of-type': {
      paddingLeft: '13px',
      paddingRight: 0
    },

    '&:last-of-type': {
      paddingRight: '13px',
      paddingLeft: 0
    }
  },
  cell: {
    overflowWrap: 'break-word',
    maxWidth: '150px',
    fontSize: '12px'
  },
  section: {
    marginTop: 20,
    breakInside: 'avoid-page'
  },
  advisorNotes: {
    padding: '32px 0 12px'
  }
});

export default useStyles;
