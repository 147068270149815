import React, { Fragment } from 'react';
import ReactDOM from 'react-dom';
import { createBrowserHistory } from 'history';
import * as R from 'ramda';

import config from 'config/index.js';
import { i18n } from 'i18n/index.js';

let _confirmationComponent = () => null;
export const setConfirmationComponent = component => {
  _confirmationComponent = component;
};
export const getConfirmationComponent = () => {
  return _confirmationComponent;
};

const history = createBrowserHistory({
  getUserConfirmation: (message, callback) => {
    const Confirmation = getConfirmationComponent();

    ReactDOM.render(
      <Confirmation
        modalProps={{
          isOpen: true
        }}
        onDecline={() => {
          ReactDOM.unmountComponentAtNode(
            document.getElementById(config.CONFIRMATION_ELEMENT_ID)
          );
          callback(false);
        }}
        onConfirm={() => {
          ReactDOM.unmountComponentAtNode(
            document.getElementById(config.CONFIRMATION_ELEMENT_ID)
          );
          callback(true);
        }}
        content={
          <Fragment>
            <div>{i18n('shared.youHaveUnsavedChanges_1')}</div>
            <div>{i18n('shared.youHaveUnsavedChanges_2')}</div>
          </Fragment>
        }
        confirmButtonText={i18n('shared.yes')}
        declineButtonText={i18n('shared.no')}
      />,
      document.getElementById(config.CONFIRMATION_ELEMENT_ID)
    );
  }
});

export const getHistoryUrl = (queryString, pathName) => {
  const baseUrl = `${window.location.protocol}//${window.location.hostname}${
    window.location.port ? `:${window.location.port}` : ''
  }`;

  return `${baseUrl}${R.isNil(queryString) ? '' : `?${queryString}`}${
    R.isNil(pathName) ? '' : `${pathName}`
  }`;
};

export default history;
