import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import { i18n } from 'i18n/index.js';
import SavingRoboAha from 'assets/saving-robo-icons/aha.js';
import { getRiskAssessmentPrevRouteConfig } from 'features/shared/services/router/selectors.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Link from 'features/shared/components/link/index';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import Icon from 'features/shared/components/icon/index';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors';
import Layout from 'features/shared/components/screenLayout/layout';
import Page from 'features/shared/components/screenLayout/page';
import Footer from 'features/shared/components/screenLayout/footer';

const useStyles = createUseStyles(theme => {
  return {
    subTitle: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.largePx,
      lineHeight: TextFontSizes.largeLineHeightPx,
      textAlign: 'center',
      paddingBottom: '15px',
      color: Colors.grayDark
    },
    text: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      textAlign: 'center'
    },
    link: {
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.mediumPx,
      lineHeight: TextFontSizes.mediumLineHeightPx,
      textAlign: 'center',
      padding: '13px 0'
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      flex: '1',
      paddingTop: '30px'
    }
  };
});

const RiskAssessment = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute(getRiskAssessmentPrevRouteConfig);
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('riskAssessment.naviTitle', translations)}
      </Navigation>

      <Page>
        <div className={classes.content}>
          <SavingRoboAha />

          <span className={classes.subTitle}>
            {i18n('riskAssessment.subTitle', translations)}
          </span>

          <span className={classes.text}>
            {i18n('riskAssessment.text', translations)}
          </span>

          <Link
            to={routeTemplates.whatIsRisk.build(customerId)}
            className={classes.link}
          >
            {i18n('riskAssessment.link.whatIsRisk', translations)}
            <Icon type="navigation" />
          </Link>
        </div>

        <Footer>
          {nextRoute && (
            <Button
              type={ButtonType.primary}
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n('shared.continue', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default RiskAssessment;
