import { useSelector } from 'react-redux';
import { isNil } from 'ramda';

import Heading1 from 'features/report/shared/components/heading1';
import Section from 'features/report/shared/components/section';
import {
  Table,
  TableBody,
  TableBodyRow,
  TableBodyCell
} from 'features/report/shared/components/table';
import { i18n } from 'i18n';
import sessionSelectors from '../../../shared/services/session/selectors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import AdvisorNotesSection from './advisorNotesSection.js';

const useStyles = createUseStyles({
  tableCell: {
    width: '50%'
  },
  advisorNotes: {
    marginTop: 32
  }
});

const OrderInformationSection = ({ orderInformation, advisorNotes = '' }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  if (isNil(orderInformation) || orderInformation.length === 0) {
    return null;
  }
  return (
    <Section>
      <Heading1>
        {i18n('report.orderInformation.header', translationsConfig)}
      </Heading1>

      <Table>
        <TableBody>
          {orderInformation
            .sort((a, b) => a.order - b.order)
            .map(({ label, name, value }) => (
              <TableBodyRow key={name}>
                <TableBodyCell className={classes.tableCell}>
                  {label}
                </TableBodyCell>
                <TableBodyCell className={classes.tableCell}>
                  {value}
                </TableBodyCell>
              </TableBodyRow>
            ))}
        </TableBody>
      </Table>

      {advisorNotes && (
        <AdvisorNotesSection
          text={advisorNotes}
          className={classes.advisorNotes}
        />
      )}
    </Section>
  );
};

export default OrderInformationSection;
