import React from 'react';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  head: {
    fill: theme.primaryColor
  }
}));

const Worried = () => {
  const classes = useStyles();

  return (
    <svg
      width="173"
      height="110"
      viewBox="0 0 173 110"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="101" cy="89" r="10" fill="#132737" />
      <circle cx="75" cy="89" r="10" fill="#132737" />
      <path
        d="M102.128 66.4547C103.045 64.7366 105.182 64.0874 106.9 65.0047L111.781 67.6108L116.662 70.2169C118.381 71.1342 119.03 73.2707 118.112 74.9889C117.195 76.707 115.058 77.3561 113.34 76.4387C111.592 75.5052 110.025 74.669 108.459 73.8328C106.893 72.9966 105.327 72.1604 103.578 71.2269C101.86 70.3096 101.211 68.1729 102.128 66.4547Z"
        fill="#132737"
      />
      <path
        d="M105.863 23.477C105.158 22.915 105.042 21.8878 105.603 21.1827L110.233 15.3743L114.862 9.56597C115.423 8.86082 116.451 8.74476 117.156 9.30673C117.861 9.8687 117.977 10.896 117.415 11.6012C115.68 13.7782 114.233 15.5938 112.786 17.4094C111.339 19.225 109.892 21.0406 108.157 23.2177C107.595 23.9228 106.568 24.039 105.863 23.477Z"
        fill="#132737"
      />
      <path
        d="M69.6949 23.477C70.4 22.915 70.5161 21.8878 69.9541 21.1827L65.3251 15.3743L60.6961 9.56597C60.1341 8.86082 59.1069 8.74476 58.4018 9.30673C57.6966 9.8687 57.5807 10.896 58.1426 11.6012C59.8776 13.7782 61.3246 15.5938 62.7716 17.4094C64.2185 19.225 65.6655 21.0406 67.4005 23.2177C67.9624 23.9228 68.9898 24.039 69.6949 23.477Z"
        fill="#132737"
      />
      <path
        d="M87.2342 18.8154C86.3325 18.8154 85.6016 18.0845 85.6016 17.1828L85.6016 9.75546L85.6016 2.32815C85.6016 1.42646 86.3325 0.695498 87.2342 0.695498C88.1359 0.695498 88.8669 1.42662 88.8669 2.32831C88.8669 5.11213 88.8669 7.4338 88.8669 9.75546C88.8669 12.0771 88.8669 14.3988 88.8669 17.1826C88.8669 18.0843 88.1359 18.8154 87.2342 18.8154Z"
        fill="#132737"
      />
      <path
        d="M72.8719 66.4547C71.9546 64.7366 69.8181 64.0874 68.0999 65.0047L63.2187 67.6108L58.3376 70.2169C56.6194 71.1342 55.9702 73.2707 56.8875 74.9889C57.8049 76.707 59.9417 77.3561 61.6598 76.4387C63.4083 75.5052 64.9745 74.669 66.5407 73.8328C68.1069 72.9966 69.6731 72.1604 71.4216 71.2269C73.1397 70.3096 73.7892 68.1729 72.8719 66.4547Z"
        fill="#132737"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="36"
        width="173"
        height="162"
      >
        <path
          d="M156.793 101.629H138.125V46.8906C138.125 44.2053 137.058 41.63 135.16 39.7312C133.261 37.8324 130.685 36.7656 128 36.7656H47C44.3147 36.7656 41.7394 37.8324 39.8405 39.7312C37.9417 41.63 36.875 44.2053 36.875 46.8906L36.875 101.629H24.8516C8.10481 101.629 0.171875 111.144 0.171875 127.891V154.785H16.625V127.891C16.625 122.312 21.1711 117.766 26.75 117.766H36.875V181.363H26.75V197.5H61.5547V181.363H51.4297V151.938H123.57V181.363H113.445V197.5H148.25V181.363H138.125V117.766H156.793C174.828 117.766 172.93 110.172 172.93 91.0724V62.711H156.793V91.0724V101.629ZM51.4297 52.2696H123.57V117.766H51.4297V52.2696Z"
          fill="#900032"
        />
      </mask>
      <g mask="url(#mask0)">
        <circle cx="88" cy="-19" r="130" className={classes.head} />
      </g>
    </svg>
  );
};

export default Worried;
