import { ReactComponent as PalmOfHand } from 'assets/disconnected-answers-icons/palm-of-hand.svg';
import { ReactComponent as LowRisk } from 'assets/disconnected-answers-icons/low.svg';
import { ReactComponent as HighRisk } from 'assets/disconnected-answers-icons/high.svg';
import { ReactComponent as Balance } from 'assets/disconnected-answers-icons/balance.svg';
import { ReactComponent as TakeRisk } from 'assets/disconnected-answers-icons/take-risk.svg';

export const disconnectedAnswersTypes = {
  lowRiskHighReturn: 'lowRiskHighReturn',
  highRiskLowReturn: 'highRiskLowReturn'
};

export const disconnectedAnswersCarouselCards = [
  {
    title: '',
    icon: <PalmOfHand />,
    description: 'disconnectedAnswers.reduceRiskDescription',
    hideCondition: disconnectedAnswersTypes.lowRiskHighReturn
  },
  {
    title: '',
    icon: <TakeRisk />,
    description: 'disconnectedAnswers.takeRiskDescription',
    hideCondition: disconnectedAnswersTypes.highRiskLowReturn
  },
  {
    title: 'disconnectedAnswers.cardTitle',
    icon: <LowRisk />,
    description: 'disconnectedAnswers.lowRiskDescription'
  },
  {
    title: 'disconnectedAnswers.cardTitle',
    icon: <HighRisk />,
    description: 'disconnectedAnswers.highRiskDescription'
  },
  {
    title: 'disconnectedAnswers.cardTitle',
    icon: <Balance />,
    description: 'disconnectedAnswers.balanceDescription'
  }
];
