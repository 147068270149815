export const TextFontSizes = {
  x05: '13px',
  x05LineHeight: '16px',

  x1: '16px',
  x1LineHeight: '20px',

  x2: '19px',
  x2LineHeight: '23px',

  x3: '21px',
  x3LineHeight: '26px',

  x5: '32px',
  x5LineHeight: '39px',

  x10: '50px',
  x10LineHeight: '60px'
};

// https://github.com/jonathantneal/css-font-weight-names
export const FontWeights = {
  light: '300',
  normal: '400',
  medium: '500',
  semiBold: '600',
  bold: '700',
  extraBold: '800'
};

export const FontFamilies = {};
