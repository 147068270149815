import { take, select, call, all, takeEvery } from 'redux-saga/effects';
import { matchPath } from 'react-router';
import { isNil } from 'ramda';

import { types as routerActionTypes } from 'framework/router/services/actions.js';
import history from 'features/shared/utils/history.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import { getFirstNotComplitedRoute } from 'features/shared/services/router/selectors.js';
import { types as sessionActionTypes } from 'features/shared/services/session/actions.js';
import { succeedType, failedType } from 'features/shared/utils/actions.js';
import {
  metricsQueue,
  createPageVisitedEvent,
  runMetricsQueueHandler
} from 'features/sharedModules/metrics/services/index.js';
import { getCurrentRoute } from './selectors.js';

export const getLocation = () => window.location;

export function* watchApplicationInitialization({
  renderReport,
  renderOrderExecutionReport,
  renderApplication,
  renderErrorPage
}) {
  const action = yield take([
    succeedType(sessionActionTypes.INITIALIZE),
    failedType(sessionActionTypes.INITIALIZE)
  ]);

  if (action.type === succeedType(sessionActionTypes.INITIALIZE)) {
    const { pathname } = yield call(getLocation);

    const reportMatch = yield call(matchPath, pathname, {
      path: routeTemplates.report.config,
      exact: false
    });

    if (!isNil(reportMatch)) {
      yield call(renderReport);
      return;
    }

    const orderExecutionReportMatch = yield call(matchPath, pathname, {
      path: routeTemplates.orderExecutionReportBody.config,
      exact: false
    });

    if (!isNil(orderExecutionReportMatch)) {
      yield call(renderOrderExecutionReport);
      return;
    }

    const homeMatch = yield call(matchPath, pathname, {
      path: routeTemplates.home.config,
      exact: false
    });

    if (!isNil(homeMatch)) {
      const route = yield select(getFirstNotComplitedRoute);
      if (!isNil(route)) {
        yield call(history.replace, route.build(homeMatch.params.customerId));
      }
    }

    yield call(runMetricsQueueHandler);

    yield call(renderApplication);
  }

  if (action.type === failedType(sessionActionTypes.INITIALIZE)) {
    yield call(renderErrorPage, action.payload.errorType);
  }
}

function* onLocationInit() {
  const route = yield select(getCurrentRoute);

  if (isNil(route)) {
    return;
  }

  metricsQueue.enqueue(createPageVisitedEvent(route.customerId, route.routeId));
}

function* watchLocationInit() {
  yield takeEvery(routerActionTypes.LOCATION_INIT, onLocationInit);
}

export default function* ({
  renderReport,
  renderOrderExecutionReport,
  renderApplication,
  renderErrorPage
}) {
  yield all([
    watchApplicationInitialization({
      renderReport,
      renderOrderExecutionReport,
      renderApplication,
      renderErrorPage
    }),
    watchLocationInit()
  ]);
}
