import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import * as R from 'ramda';

import { i18n } from 'i18n/index.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import { creators as userOptionsCreator } from 'features/shared/services/userOptions/actions.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import TileInput from 'features/shared/components/tileInput/index.js';
import { Colors } from 'features/shared/constants/colors';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';

const useStyles = createUseStyles(theme => {
  return {
    subTitle: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.largePx,
      lineHeight: TextFontSizes.largeLineHeightPx,
      textAlign: 'center',
      color: Colors.grayDark
    },
    link: {
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.mediumPx,
      lineHeight: TextFontSizes.mediumLineHeightPx,
      textAlign: 'center',
      padding: '13px 0'
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '50px',
      paddingBottom: '30px'
    }
  };
});

const RiskPreference = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const risks = useSelector(sessionSelectors.getRisksConfig);
  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const userOptions = useSelector(userOptionsSelectors.getUserOptions);

  const dispatch = useDispatch();
  const userOptionsChanged = (type, value) => {
    dispatch(userOptionsCreator.userOptionChanged({ type, value }));
  };

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('riskPreference.naviTitle', translations)}
      </Navigation>

      <Page>
        <div className={classes.content}>
          <span className={classes.subTitle}>
            {i18n('riskPreference.subTitle', translations)}
          </span>
        </div>

        <TileInput
          options={risks}
          selectedOption={userOptions.risk}
          userOptionType="risk"
          onSelect={(type, value) => {
            userOptionsChanged(type, value);
            window.scrollTo(0, document.body.scrollHeight);
          }}
        />

        <Footer>
          {nextRoute && (
            <Button
              type={ButtonType.primary}
              disabled={R.isNil(userOptions.risk)}
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n('shared.continue', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default RiskPreference;
