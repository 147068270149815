import React from 'react';
import MuiDialogContent from '@material-ui/core/DialogContent';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';

const useStyles = createUseStyles(theme => ({
  root: {
    fontFamily: theme.textFontFamily,
    fontWeight: FontWeights.normal,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx
  }
}));

const DialogContent = ({ children, ...restProps }) => {
  const classes = useStyles();

  return (
    <MuiDialogContent classes={classes} {...restProps}>
      {children}
    </MuiDialogContent>
  );
};

export default DialogContent;
