import React, { Fragment } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import * as R from 'ramda';
import Helmet from 'react-helmet';
import { useSelector } from 'react-redux';

import { i18n } from 'i18n/index.js';
import PageNotFound from 'features/shared/components/pageNotFound/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import routerSelectors from 'features/shared/services/router/selectors.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { getFirstRoute } from 'features/shared/services/router/selectors.js';
import Onboarding from 'features/onboarding/main/components/index.js';
import Counseling from 'features/counseling/main/components/index.js';
import MainLayout from 'features/shared/components/mainLayout/index.js';
import Notifications from 'features/notification/components/index.js';

const App = () => {
  const isPageFound = useSelector(routerSelectors.isRouteFound);
  const styleSheets = useSelector(sessionSelectors.getStyleSheets);
  const manifest = useSelector(sessionSelectors.getManifestConfig);
  const manifestSpec = useSelector(sessionSelectors.getManifestSpecConfig);
  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const firstRoute = useSelector(getFirstRoute);

  if (!isPageFound) {
    return (
      <Fragment>
        <Notifications />
        <MainLayout>
          <PageNotFound text={i18n('shared.pageNotFound', translations)} />
        </MainLayout>
      </Fragment>
    );
  } else {
    return (
      <Fragment>
        <Helmet>
          <meta name="mobile-web-app-capable" content="yes" />
          <meta name="apple-touch-fullscreen" content="yes" />
          <meta name="apple-mobile-web-app-title" content="Saving Robo" />
          <meta name="apple-mobile-web-app-capable" content="yes" />
          <meta name="apple-mobile-web-app-status-bar-style" content="black" />
        </Helmet>
        <Helmet link={styleSheets} />
        <Helmet link={manifestSpec} />
        <Helmet link={manifest} />
        <Helmet title={i18n('global.appTitle', translations)} />
        <Notifications />
        <Switch>
          <Route
            exact
            path={routeTemplates.home.config}
            render={({ match }) => {
              const { customerId } = match.params;

              return <Redirect to={firstRoute.build(customerId)} />;
            }}
          />
          <Route
            path={routeTemplates.onboarding.config}
            component={Onboarding}
          />
          <Route
            path={routeTemplates.counseling.config}
            component={Counseling}
          />
        </Switch>
      </Fragment>
    );
  }
};

export default App;
