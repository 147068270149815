import { useRef } from 'react';
import axios, { CancelTokenSource } from 'axios';
import { isNil } from 'ramda';
import { useParams } from 'react-router';
import { calculatorSimpleMapping } from '@quantfoliorepo/ui-components';

import { getQAuthAccessToken, readCalculatorSimple } from 'features/shared/api';
import { useExpectedValueChartStore } from '../services/expectedValueChart';
import { usePortfolioChartStore } from '../services/portfolioChart';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { RoboFrontParams } from 'features/shared/utils/types';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

export function useReadExpectedValueChartData() {
  const { customerId } = useParams<RoboFrontParams>();
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const expectedValueChartStoreState = useExpectedValueChartStore.getState();
  const {
    expectedValue: { bankReturn }
  } = useCustomerConfig();

  const readExpectedValueChartData = async goals => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      const { chartData } = usePortfolioChartStore.getState();
      const currentDate = new Date();
      const qAuthAccessToken = await getQAuthAccessToken(
        customerId,
        cancelTokenSource.token
      );

      for (const goal of goals) {
        const goalChartData = chartData.find(
          ({ goalId }) => goalId === goal.goalId
        );

        const analyzeRiskReturn = {
          risk: goalChartData.expectedVolatility,
          return: goalChartData.expectedAnnualReturn
        };

        const timeHorizon = goal.data.timeHorizon;

        const readCalculatorSimpleResponse = await readCalculatorSimple(
          qAuthAccessToken,
          cancelTokenSource.token,
          {
            expected_return: calculatorSimpleMapping.getReturn({
              dataSources: {
                analyzeRiskReturn
              }
            }),
            expected_return_bank: bankReturn,
            initial_deposit: goal.data.firstDeposit,
            monthly_deposit: goal.data.monthlyDeposit,
            time_horizon: timeHorizon
          }
        );

        const forecast = readCalculatorSimpleResponse.data.forecast;

        const data = {
          forecast,
          currentDate,
          timeHorizon,
          firstDeposit: goal.data.firstDeposit,
          goalId: goal.goalId
        };

        expectedValueChartStoreState.addGoalData(data);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        expectedValueChartStoreState.resetChartData();
        throwSafeError(error);
      }
    }
  };

  return readExpectedValueChartData;
}
