import { useCallback, useRef } from 'react';
import { isNil } from 'ramda';
import { useDispatch, useSelector, useStore } from 'react-redux';
import axios, { CancelTokenSource } from 'axios';
import { useParams } from 'react-router';

import { utcNow } from 'features/shared/utils/dateTime';
import { creators as notificationActionCreators } from 'features/notification/services/actions.js';
import { NotificationTypes } from 'features/notification/constants/index.js';
import {
  getQAuthAccessToken,
  readCalculatorSimple
} from 'features/shared/api/index.js';
import {
  mapServerForecastToClient,
  getYearOffset
} from '../services/mapping.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import { usePageStore } from '../services/pageStore';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { PageStatuses } from 'features/shared/constants/statuses';
import { RoboFrontParams } from 'features/shared/utils/types.js';
import { TimeHorizonConfigType } from 'features/report/body/constants';

export const useReadExpectedValue = () => {
  const cancelTokenSourceRef = useRef<CancelTokenSource>();
  const { customerId } = useParams<RoboFrontParams>();

  const reduxStore = useStore();
  const dispatch = useDispatch();
  const pageStore = usePageStore();

  const horizonsConfig: TimeHorizonConfigType = useSelector(
    sessionSelectors.getHorizonsConfig
  );
  const horizonItems = horizonsConfig.items;
  const horizon = useSelector(userOptionsSelectors.getHorizon);

  const readExpectedValue = useCallback(async () => {
    const useOptions = userOptionsSelectors.getUserOptions(
      reduxStore.getState()
    );
    const startingCapital = useOptions?.counseling?.savingsPlan?.firstDeposit;
    const monthlySavings = useOptions?.counseling?.savingsPlan?.monthlyDeposit;
    const isAnyParameterInvalid =
      isNil(startingCapital) || isNil(monthlySavings);
    if (isAnyParameterInvalid) {
      return;
    }
    const pageStoreState = usePageStore.getState();
    if (isNil(pageStoreState.returnValue)) {
      return;
    }

    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      pageStore.setReadExpectedValueStatus(PageStatuses.pending);

      const accessToken = await getQAuthAccessToken(
        customerId,
        cancelTokenSource.token
      );

      const response = await readCalculatorSimple(
        accessToken,
        cancelTokenSource.token,
        {
          expected_return: pageStoreState.returnValue / 100,
          initial_deposit: startingCapital,
          monthly_deposit: monthlySavings,
          time_horizon: getYearOffset(horizonItems, horizon)
        }
      );

      const expectedValue = mapServerForecastToClient(
        horizonItems,
        response.data?.forecast,
        useOptions?.horizon,
        utcNow()
      );

      pageStore.setExpectedValue(expectedValue);
      pageStore.setReadExpectedValueStatus(PageStatuses.succeed);
    } catch (error) {
      if (!axios.isCancel(error)) {
        pageStore.setExpectedValue(null);
        pageStore.setReadExpectedValueStatus(PageStatuses.failed);

        dispatch(
          notificationActionCreators.showNotification({
            message: 'shared.getForecastErrorMessage',
            type: NotificationTypes.error
          })
        );
      }
    }
  }, []);

  return readExpectedValue;
};
