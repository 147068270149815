import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { isNil } from 'ramda';

import { createUseStyles, useTheme } from 'modules/styles/components/styles.js';
import { FontFamilies, TextFontSizes } from 'modules/shared/constants/fonts.js';
import { formatNumber } from 'modules/shared/utils/number.js';
import { useSetHighchartsLocaleMonths } from 'modules/shared/utils/months';

HighchartsMore(Highcharts);

const useStyles = createUseStyles(theme => ({
  root: {},
  chart: {
    height: '300px'
  },
  tooltipHeader: {
    fontFamily: FontFamilies.roboto,
    fontSize: TextFontSizes.xxSmall,
    lineHeight: TextFontSizes.xxSmallLineHeight
  },
  tooltipFirstLine: {
    fontFamily: FontFamilies.roboto,
    fontSize: TextFontSizes.xSmall,
    lineHeight: TextFontSizes.xSmallLineHeight,
    marginTop: '4px'
  },
  tooltipLastLine: {
    fontFamily: FontFamilies.roboto,
    fontSize: TextFontSizes.xSmall,
    lineHeight: TextFontSizes.xSmallLineHeight,
    marginTop: '2px'
  },
  legend: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    width: '100%',
    padding: 0
  },
  goal: {
    display: 'inline',
    color: theme.secondaryTextColor,
    fontSize: TextFontSizes.xSmall,
    lineHeight: '1.4rem',
    margin: '0 2rem'
  },
  dot: {
    display: 'inline-block',
    height: '16px',
    width: '16px',
    borderRadius: '50%',
    marginRight: '8px',
    verticalAlign: 'text-top'
  }
}));

const ExpectedValuePortfoliosChart = ({
  cultureCode,
  chartData,
  isInteractive,
  onLoad,
  width,
  yMinValue = 0
}) => {
  const classes = useStyles();
  const chartComponent = React.useRef();
  const theme = useTheme();
  useSetHighchartsLocaleMonths();

  const series = chartData
    .sort(
      (a, b) =>
        b.portfolio[b.portfolio.length - 1].y -
        a.portfolio[a.portfolio.length - 1].y
    )
    .map(({ goalId: id, color, portfolio: data }) => ({
      id,
      type: 'area',
      color,
      fillColor: color,
      lineColor: color,
      lineWidth: 0,
      states: {
        hover: {
          lineWidthPlus: 0
        },
        select: {
          lineWidthPlus: 0
        }
      },
      marker: {
        enabled: false,
        states: {
          hover: {
            enabled: false,
            radiusPlus: 0
          }
        }
      },
      data
    }));

  const legendData = chartData
    .map(({ goalId, goalName, color }) => ({ goalId, goalName, color }))
    .sort((a, b) => +a.goalId - +b.goalId);

  React.useEffect(() => {
    const reflow = () => {
      chartComponent.current.chart.reflow();
    };

    window.addEventListener('resize', reflow);

    reflow();

    return () => {
      window.removeEventListener('resize', reflow);
    };
  }, []);

  const chartOptions = {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    chart: {
      backgroundColor: 'transparent',
      marginTop: 20,
      marginRight: 0,
      animation: false,
      events: {
        load: onLoad
      }
    },
    title: {
      text: null
    },
    xAxis: {
      tickmarkPlacement: 'on',
      crosshair: {
        color: theme.secondaryColor,
        zIndex: 5
      },
      lineWidth: 0,
      tickWidth: 0,
      type: 'datetime',
      labels: {
        format: '{value: %Y}',
        style: {
          fontSize: TextFontSizes.xxSmall,
          lineHeight: TextFontSizes.xxSmallLineHeight,
          fontFamily: FontFamilies.roboto
        }
      }
    },
    yAxis: {
      min: yMinValue,
      gridLineWidth: 0,
      title: {
        enabled: false
      },
      crosshair: {
        color: theme.secondaryColor,
        zIndex: 5
      },
      labels: {
        formatter: function () {
          return this.value
            .toString()
            .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
        },
        style: {
          fontSize: TextFontSizes.xxSmall,
          lineHeight: TextFontSizes.xxSmallLineHeight,
          fontFamily: FontFamilies.roboto
        }
      }
    },
    tooltip: {
      enabled: isInteractive,
      xDateFormat: '%b %Y',
      useHTML: true,
      headerFormat: `<span class='${classes.tooltipHeader}'>{point.key}</span><br>`,
      pointFormatter: function () {
        const totalValue = formatNumber(cultureCode, this.y, 0, 2);

        return `
        <div class='${classes.tooltipLastLine}'>${totalValue}</div>
        `;
      }
    },
    plotOptions: {
      series: {
        enableMouseTracking: isInteractive,
        animation: false,
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    series
  };

  return (
    <div className={classes.root}>
      <div className={classes.chart}>
        <HighchartsReact
          ref={chartComponent}
          highcharts={Highcharts}
          containerProps={{
            style: {
              width: isNil(width) ? 'auto' : `${width}px`,
              height: '300px'
            }
          }}
          options={chartOptions}
        />
      </div>
      <ul className={classes.legend}>
        {legendData.map(({ goalId, goalName, color }) => (
          <li key={goalId} className={classes.goal}>
            <span className={classes.dot} style={{ background: color }} />
            <span>{goalName} </span>
          </li>
        ))}
      </ul>
    </div>
  );
};

ExpectedValuePortfoliosChart.defaultProps = {
  isInteractive: true
};

export default ExpectedValuePortfoliosChart;
