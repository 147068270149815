import { useSelector } from 'react-redux';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { FontWeights } from 'features/shared/constants/fonts';
import Section from 'features/report/shared/components/section';
import Heading1 from 'features/report/shared/components/heading1';
import Paragraph from 'features/report/shared/components/paragraph';
import Heading2 from 'features/report/shared/components/heading2';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableBodyRow
} from 'features/report/shared/components/table';
import { AdvancedSuitability as AdvancedSuitabilityType } from 'features/report/shared/services/reportStore';

const useStyles = createUseStyles({
  section: {
    breakInside: 'avoid'
  },
  tableHeader: {
    margin: '32px 0 16px'
  },
  valueCell: {
    fontWeight: FontWeights.semiBold
  }
});

type Props = {
  advancedSuitability: AdvancedSuitabilityType;
};

const AdvancedSuitability = ({ advancedSuitability }: Props) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  return (
    <Section data-testid={'suitability-assessment-section'}>
      <Heading1>
        {i18n('report.advancedSuitability.header', translationsConfig)}
      </Heading1>
      <Paragraph>
        {i18n('report.advancedSuitability.introtext', translationsConfig)}
      </Paragraph>

      {Object.entries(advancedSuitability || {}).map(
        ([tableHeader, tableValues]) => {
          return (
            <div className={classes.section} key={tableHeader}>
              <Heading2 className={classes.tableHeader}>{tableHeader}</Heading2>

              <Table>
                <TableBody>
                  {tableValues?.map(item => {
                    return (
                      <TableBodyRow
                        key={`${tableHeader}-${item.trainingContentValue}`}
                      >
                        <TableBodyCell>{item.label}</TableBodyCell>
                        <TableBodyCell className={classes.valueCell}>
                          {item.trainingContentValue}
                        </TableBodyCell>
                      </TableBodyRow>
                    );
                  })}
                </TableBody>
              </Table>
            </div>
          );
        }
      )}
    </Section>
  );
};

export default AdvancedSuitability;
