import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import * as R from 'ramda';
import debounce from 'lodash.debounce';
import classNames from 'classnames';

import { i18n } from 'i18n/index.js';
import { formatNumber } from 'features/shared/utils/number.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors, {
  getSummary
} from 'features/shared/services/userOptions/selectors.js';
import { creators as userOptionsCreator } from 'features/shared/services/userOptions/actions.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import Slider from 'features/shared/components/slider/index.js';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import I18n from 'features/shared/components/i18n/index.js';
import { usePageStore } from 'features/counseling/savingsPlan/services/pageStore';
import { getYearOffset } from '../services/mapping.js';
import { useReadExpectedValue } from './useReadExpectedValue';
import { useInitPage } from './useInitPage';
import { PageStatuses } from 'features/shared/constants/statuses';

const useStyles = createUseStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '50px',
    width: '100%'
  },
  subTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: FontWeights.medium,
    paddingBottom: '7px',
    color: Colors.gray160
  },
  text: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: FontWeights.light,
    color: Colors.gray180
  },
  icon: {
    marginBottom: '17px'
  },
  speechBubble: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    background: Colors.white,
    borderRadius: '12px',
    width: '100%',
    minHeight: '130px',
    marginBottom: '20px',
    padding: '20px',
    boxShadow:
      '0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 4px 3px ' +
      Colors.listShadowColor,

    '&::after': {
      content: '""',
      border: '20px solid transparent',
      borderTop: '20px solid green',
      left: '83%',
      bottom: 0,

      position: 'absolute',
      marginBottom: '-38px',
      borderTopColor: Colors.white
    }
  },
  expectedContent: {
    display: 'flex',
    flexDirection: 'column',
    paddingTop: '30px',
    width: '100%'
  },
  expectedValueTitle: {
    fontFamily: theme.headerFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    textAlign: 'center',
    paddingBottom: '5px',
    color: Colors.grayDark
  },
  expectedValueText: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    textAlign: 'center',
    paddingBottom: '5px'
  },
  expectedValue: {
    fontWeight: FontWeights.semiBold,
    textAlign: 'center',
    color: theme.primaryColor,
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',

    '&$loading': {
      opacity: '0.3'
    }
  },
  expectedValueSize_1: {
    fontSize: '58px',
    lineHeight: '76px'
  },
  expectedValueSize_2: {
    fontSize: '52px',
    lineHeight: '68px'
  },
  expectedValueSize_3: {
    fontSize: '45px',
    lineHeight: '59px'
  },
  loading: {}
}));

const SavingsPlan = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const pageStore = usePageStore();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const horizonsConfig = useSelector(sessionSelectors.getHorizonsConfig);
  const savingsPlan = useSelector(sessionSelectors.getSavingsPlanConfig);
  const userCounselingOptions = useSelector(
    userOptionsSelectors.getCounselingOptions
  );
  const horizon = useSelector(userOptionsSelectors.getHorizon);
  const horizonYear = useSelector(userOptionsSelectors.getHorizonYear);
  const summary = useSelector(getSummary);
  const cultureCode = useSelector(sessionSelectors.getCultureCode);

  const dispatch = useDispatch();
  const userCounselingSavingsPlanChanged = (type, value) => {
    dispatch(
      userOptionsCreator.userCounselingSavingsPlanChanged({ type, value })
    );
  };

  const readForecast = useReadExpectedValue();
  const readForecastDebounced = React.useCallback(
    debounce(() => readForecast(), 300),
    []
  );

  useInitPage();

  const expectedValue = R.isNil(pageStore.expectedValue)
    ? null
    : formatNumber(cultureCode, pageStore.expectedValue);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('counseling.savingsPlan.naviTitle', translations)}
      </Navigation>

      <Page>
        <div className={classes.content}>
          <div className={classes.speechBubble}>
            <span className={classes.subTitle}>
              {i18n('counseling.savingsPlan.message.title', translations)}
            </span>

            <span className={classes.text}>
              <I18n
                text={i18n('counseling.savingsPlan.message.text', translations)}
                replaceParams={{
                  '{0}': <span>{summary.countToSave},-</span>
                }}
              />
            </span>
          </div>

          <Slider
            options={savingsPlan.monthlyDeposit}
            userOption={userCounselingOptions.savingsPlan.monthlyDeposit}
            onChange={(type, value) => {
              userCounselingSavingsPlanChanged('monthlyDeposit', value);

              // Fast fix for issue that NumberInput onValueChange is called not on input event only, but on any value chnage.
              if (value !== userCounselingOptions.savingsPlan.monthlyDeposit) {
                readForecastDebounced();
              }
            }}
            displayTitle={true}
            displayValue={true}
            displayPerDay
          />

          <Slider
            options={savingsPlan.firstDeposit}
            userOption={userCounselingOptions.savingsPlan.firstDeposit}
            onChange={(type, value) => {
              userCounselingSavingsPlanChanged('firstDeposit', value);

              // Fast fix for issue that NumberInput onValueChange is called not on input event only, but on any value chnage.
              if (value !== userCounselingOptions.savingsPlan.firstDeposit) {
                readForecastDebounced();
              }
            }}
            displayTitle={true}
            displayValue={true}
          />
          <div className={classes.expectedContent}>
            <span className={classes.expectedValueTitle}>
              {i18n('counseling.savingsPlan.expectedValue', translations)}
            </span>
            <span className={classes.expectedValueText}>
              <I18n
                text={i18n('counseling.savingsPlan.afterXYears', translations)}
                replaceParams={{
                  '{0}': (
                    <span>
                      {horizonYear ||
                        getYearOffset(horizonsConfig?.items, horizon)}
                    </span>
                  )
                }}
              />
            </span>
            {R.isNil(pageStore.expectedValue) &&
              pageStore.readExpectedValueStatus !== PageStatuses.pending && (
                <span
                  className={classNames(
                    classes.expectedValue,
                    classes.expectedValueSize_1
                  )}
                  dangerouslySetInnerHTML={{ __html: '&nbsp;' }}
                />
              )}
            {R.isNil(pageStore.expectedValue) &&
              pageStore.readExpectedValueStatus === PageStatuses.pending && (
                <span
                  className={classNames(
                    classes.expectedValue,
                    classes.expectedValueSize_1,
                    classes.loading
                  )}
                >
                  {`${i18n('shared.loading', translations)}`}
                </span>
              )}
            {!R.isNil(pageStore.expectedValue) && (
              <span
                title={expectedValue}
                className={classNames(classes.expectedValue, {
                  [classes.expectedValueSize_1]:
                    pageStore.expectedValue < 100000000,
                  [classes.expectedValueSize_2]:
                    pageStore.expectedValue >= 100000000 &&
                    pageStore.expectedValue < 1000000000,
                  [classes.expectedValueSize_3]:
                    pageStore.expectedValue >= 1000000000,
                  [classes.loading]:
                    pageStore.readExpectedValueStatus === PageStatuses.pending
                })}
              >
                {expectedValue}
              </span>
            )}
          </div>
        </div>

        <Footer>
          {nextRoute && (
            <Button
              disabled={
                R.isNil(userCounselingOptions.savingsPlan.monthlyDeposit) ||
                R.isNil(userCounselingOptions.savingsPlan.firstDeposit)
              }
              type={ButtonType.primary}
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n('shared.continue', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default SavingsPlan;
