import React, { Fragment } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import IconButton from '@material-ui/core/IconButton';
import * as R from 'ramda';
import axios from 'axios';
import { useParams } from 'react-router';

import { utcNow } from 'features/shared/utils/dateTime';
import { Colors } from 'features/shared/constants/colors.js';
import { creators as notificationActionCreators } from 'features/notification/services/actions.js';
import { NotificationTypes } from 'features/notification/constants/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { i18n } from 'i18n/index.js';
import { formatNumber } from 'features/shared/utils/number.js';
import { FontWeights, TextFontSizes } from 'features/shared/constants/fonts.js';
import BottomSheet from 'features/shared/components/bottomSheet/index';
import Icon from 'features/shared/components/icon/index';
import BasicLineChart from 'features/shared/components/basicLineChart/index.js';
import ProgressBar from 'features/shared/components/progressBar/index';
import {
  getQAuthAccessToken,
  readFundMeta,
  readHistoricalReturn
} from 'features/shared/api/index.js';
import { usePageStore } from 'features/counseling/portfolioFunds/services/pageStore.js';
import { mapServerInstrumentDetailsToClient } from '../services/mapping.js';
import MiniPieChart from './miniPieChart.js';

const useStyles = createUseStyles(theme => ({
  list: {
    width: '100%',
    backgroundColor: Colors.white,
    boxShadow:
      '0px 4px 15px ' +
      Colors.listShadowColor +
      ', 0px 6px 15px ' +
      Colors.listShadowColor +
      ', 0px 8px 15px ' +
      Colors.listShadowColor +
      ', 0px 10px 15px ' +
      Colors.listShadowColor +
      ', 0px 12px 15px ' +
      Colors.listShadowColor +
      ', 0px 14px 35px ' +
      Colors.listShadowColor,
    padding: '0',
    borderRadius: '2px',
    margin: '0'
  },
  listItem: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    listStyleType: 'none',
    minHeight: '44px',
    padding: '10px 12px',
    cursor: 'pointer',
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    color: Colors.nightRiderColor,
    '-webkit-tap-highlight-color': 'transparent',

    '&$disabled': {
      cursor: 'default'
    },

    '& + &': {
      borderTop: `1px solid ${theme.placeholderColor}`
    }
  },
  disabled: {},
  listItemContainer: {
    display: 'flex',
    alignItems: 'center',
    width: 'calc(100% - 28px)'
  },
  text: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    fontWeight: FontWeights.light
  },
  coloredCircle: {
    borderRadius: '50%',
    height: '24px',
    width: '24px',
    minHeight: '24px',
    minWidth: '24px',
    marginRight: '10px'
  },
  sheetContent: {
    display: 'flex',
    flexDirection: 'row',
    overflowX: 'auto',
    maxHeight: '300px',
    width: '100%',
    marginBottom: '20px'
  },
  sheetColumn: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%'
  },
  sheetColumnItem: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '60px',
    marginBottom: '15px'
  },
  sheetLegend: {
    fontFamily: theme.headerFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: FontWeights.light,
    color: Colors.darkGreenGraphColor,
    paddingBottom: '5px'
  },
  sheetValue: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    fontWeight: '900',
    color: theme.primaryColor
  },
  iconButton: {
    padding: '0px !important'
  },
  row: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%'
  },
  contentPlaceholder: {
    height: '364px',
    display: 'flex',
    flexDirection: 'column'
  }
}));

export const PortfolioItemDetails = ({ portfolioItem }) => {
  const classes = useStyles();
  const { customerId } = useParams();

  const pageStore = usePageStore();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const cultureCode = useSelector(sessionSelectors.getCultureCode);

  const dispatch = useDispatch();
  const { instrument, instrumentDetails, isReadInstrumentDetailsPending } =
    portfolioItem;
  const doesInstrumentExist = !R.isNil(instrument);
  const doInstrumentDetailsExist = !R.isNil(instrumentDetails);

  // TODO: wrap cancelTokenSourceRef, read, readDebounced into reusable hook, also zustand status state, notifications, error handling can be added
  const cancelTokenSourceRef = React.useRef(null);
  const cancelReadPortfolioItemDetails = () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
      pageStore.setIsReadInstrumentDetailsPending(portfolioItem.id, false);
    }
  };
  const readPortfolioItemDetails = async () => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      pageStore.setIsReadInstrumentDetailsPending(portfolioItem.id, true);

      const accessToken = await getQAuthAccessToken(
        customerId,
        cancelTokenSource.token
      );
      const responses = await Promise.all([
        readHistoricalReturn(accessToken, cancelTokenSource.token, {
          portfolio: [
            {
              id: R.path(['instrument', 'id'], portfolioItem),
              weight: 100
            }
          ]
        }),
        readFundMeta(
          accessToken,
          cancelTokenSource.token,
          R.path(['instrument', 'id'], portfolioItem)
        )
      ]);

      const instrumentDetails = mapServerInstrumentDetailsToClient(
        responses[0].data.portfolio,
        responses[1].data.meta,
        utcNow()
      );

      pageStore.setInstrumentDetails(portfolioItem.id, instrumentDetails);
      pageStore.setIsReadInstrumentDetailsPending(portfolioItem.id, false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        pageStore.setInstrumentDetails(portfolioItem.id, null);
        pageStore.setIsReadInstrumentDetailsPending(portfolioItem.id, false);

        dispatch(
          notificationActionCreators.showNotification({
            message: 'shared.getPortfolioItemDetailsDataErrorMessage',
            type: NotificationTypes.error
          })
        );
      }
    }
  };

  React.useEffect(() => {
    if (doesInstrumentExist && !doInstrumentDetailsExist) {
      readPortfolioItemDetails();
    }

    return () => {
      cancelReadPortfolioItemDetails();
    };
  }, []);

  return (
    <Fragment>
      {isReadInstrumentDetailsPending && (
        <div className={classes.contentPlaceholder}>
          <ProgressBar />
        </div>
      )}
      {!isReadInstrumentDetailsPending && (
        <Fragment>
          <div className={classes.sheetContent}>
            <div className={classes.sheetColumn}>
              <div className={classes.sheetColumnItem}>
                <span className={classes.sheetLegend}>
                  {`${formatNumber(
                    cultureCode,
                    portfolioItem.weight,
                    2,
                    2
                  )}% ${i18n(
                    'counseling.portfolioFunds.info.percentOfPortfolio',
                    translations
                  )}`}
                </span>
                <MiniPieChart percent={portfolioItem.weight} />
              </div>
              <div className={classes.sheetColumnItem}>
                <span className={classes.sheetLegend}>
                  {i18n(
                    'counseling.portfolioFunds.info.currency',
                    translations
                  )}
                </span>
                {doesInstrumentExist && (
                  <span className={classes.sheetValue}>
                    {R.prop('currency', instrumentDetails)}
                  </span>
                )}
                {!doesInstrumentExist && (
                  <span className={classes.sheetValue}>
                    {i18n('shared.noValueText', translations)}
                  </span>
                )}
              </div>
            </div>
            <div className={classes.sheetColumn}>
              <div className={classes.sheetColumnItem}>
                <span className={classes.sheetLegend}>
                  {i18n(
                    'counseling.portfolioFunds.info.trendLast5Years',
                    translations
                  )}
                </span>
                {doesInstrumentExist && (
                  <BasicLineChart
                    data={R.propOr([], 'last5YearsTrend', instrumentDetails)}
                  />
                )}
                {!doesInstrumentExist && (
                  <span className={classes.sheetValue}>
                    {i18n('shared.noValueText', translations)}
                  </span>
                )}
              </div>
            </div>
          </div>
        </Fragment>
      )}
    </Fragment>
  );
};

const PortfolioItemsList = ({ className, options }) => {
  const classes = useStyles();

  if (R.isNil(options) || R.isEmpty(options)) {
    return null;
  }

  return (
    <ul className={classNames(classes.list, className)}>
      {options.map(item => {
        const disabled = !item.doesHaveDetails;

        return (
          <Fragment key={item.id}>
            <li
              className={classNames(classes.listItem, {
                [classes.disabled]: disabled
              })}
            >
              <BottomSheet
                title={item.name}
                disabled={disabled}
                button={
                  <div className={classes.row}>
                    <div className={classes.listItemContainer}>
                      <div
                        className={classes.coloredCircle}
                        style={{ backgroundColor: item.color }}
                      />
                      <span className={classes.text}>{item.name}</span>
                    </div>
                    {!disabled && (
                      <IconButton className={classes.iconButton}>
                        <Icon type="forward" />
                      </IconButton>
                    )}
                  </div>
                }
              >
                <PortfolioItemDetails portfolioItem={item} />
              </BottomSheet>
            </li>
          </Fragment>
        );
      })}
    </ul>
  );
};

export default PortfolioItemsList;
