import create from 'zustand';
import { HistoricalReturnTimeRangeChartData } from '@quantfoliorepo/ui-components';

type ClientInformation = {
  clientType: string;
  fields: {
    value?: string | null;
    title: string;
    order: number;
    name?: string;
  }[];
  accountNumber?: string;
  isContactListEnabled?: boolean;
  isSignatureRightsEnabled?: boolean;
  isUBOEnabled?: boolean;
  contacts?: {
    name: string;
    role: string;
    email: string;
    phone: string;
  }[];
};

type KnowledgeAndExperience = {
  question: string;
  answers: string[];
  trainingDate: string | null;
}[];

type PersonFinancialSituation = Partial<{
  asset: string;
  assetSum: string | null;
  debt: string;
  debtSum: string | null;
}>[];

type CompanyFinancialSituation = {
  investableAssets: { title: string; value: string | null }[];
  accountingFigures: { title: string; value: string | null }[];
  debt: { title: string; value: string | null }[];
  liquidity: { title: string; value: string | null }[];
};

type CostYearData = {
  aggregatedCostEffect: Record<string, any>;
  aggregatedOngoingFees: Record<string, any>;
  aggregatedOngoingFeesPercent: Record<string, any>;
  oneTimeFees: Record<string, any>;
  oneTimeFeesPercent: Record<string, any>;
  aggregatedReturnCommissionPaidToAdvisor: Record<string, any>;
  aggregatedReturnCommissionPaidToAdvisorPercent: Record<string, any>;
};

type Cost = {
  chartData: {
    valueAfterCost: number;
    valueBeforeCost: number;
    deposits: number;
  }[];
  tableData: {
    summary: {
      annualOngoingFees: Record<string, any>;
      oneTimeFees: Record<string, any>;
      annualReturnCommissionPaidToAdvisor: Record<string, any>;
    };
    firstYear: CostYearData;
    lastYear: CostYearData;
    fundDetails: {
      ongoingFees: {
        currency: {
          fund: string;
          fundManagementFee: string | number;
          fundTransactionCost: string | number;
          fundReturnCommissionPaidToClient: string | number;
          performanceFee: string | number;
          platformFee: string | number;
          insuranceCost: string | number;
          custodyFee: string | number;
          totalExpenseRatio: string | number;
        };
        percent: {
          fund: string;
          fundManagementFee: string | number;
          fundTransactionCost: string | number;
          fundReturnCommissionPaidToClient: string | number;
          performanceFee: string | number;
          platformFee: string | number;
          insuranceCost: string | number;
          custodyFee: string | number;
          totalExpenseRatio: string | number;
        };
      }[];
      oneTimeFees: {
        currency: {
          fund: string;
          fundPurchaseFee: string | number;
          advisoryPurchaseFee: string | number;
          advisoryOnboardingFee: string | number;
        };
        percent: {
          fund: string;
          fundPurchaseFee: string | number;
          advisoryPurchaseFee: string | number;
          advisoryOnboardingFee: string | number;
        };
      }[];
      custodyFees: {
        currency: {
          fund: string;
          custodyFee: string | number;
        };
        percent: {
          fund: string;
          custodyFee: string | number;
        };
      }[];
      returnCommissionPaidToAdvisor: {
        currency: {
          fund: string;
          fundReturnCommissionPaidToAdvisor: string | number;
        };
        percent: {
          fund: string;
          fundReturnCommissionPaidToAdvisor: string | number;
        };
      }[];
    };
  };
  overrideFundOngoingFeesAdvisorNotes?: string;
  overrideFundOneTimeFeesAdvisorNotes?: string;
  overrideSummaryAnnualOngoingFeesAdvisorNotes?: string;
  overrideSummaryCustodyFeesAdvisorNotes?: string;
  overrideSummaryOneTimeFeesAdvisorNotes?: string;
};

type ExpectedPathChartData = {
  expectedPathTable: {
    year: number;
    expectedReturn: number;
    lowerBound: number;
    upperBound: number;
  }[];
  expectedReturn: [number, number][];
  bounds: [number, number, number][];
};

type ExpectedValueChartData = {
  expectedValueTable: {
    year: number;
    accDeposit: number;
    accReturn: number;
    value: number;
  }[];
  savingPlan: {
    x: number;
    y: number;
    custom: {
      accReturn: number;
    };
  }[];
  savingInBankAccount: {
    x: number;
    y: number;
    custom: {
      accReturn: number;
    };
  }[];
};

type HistoricalReturnKeyStatistics = Record<
  string,
  {
    goalId: string;
    growth: number;
  }[]
>;

type HistoricalReturn = {
  chartData: HistoricalReturnTimeRangeChartData[];
  chartDescription: string;
  keyStatistics: HistoricalReturnKeyStatistics;
};

export type OrderSummaryData = {
  goalId: string;
  data: {
    monthlyOrders: {
      buy: {
        FundStandardName: string;
        currency: string;
        id: string;
        isin: string;
        moneyAmount: number;
        name: string;
        type: string;
        weight: number;
      }[];
    };
    oneTimeOrders: {
      buy: {
        FundStandardName: string;
        currency: string;
        id: string;
        isin: string;
        moneyAmount: number;
        name: string;
        type: string;
        weight: number;
      }[];
    };
  };
};

export const ProductAttributeType = {
  text: 'text',
  binary: 'binary'
} as const;

export type OrderExecutionSummaryData = {
  fund: string;
  initialDeposit: number;
  monthlySaving: number;
  isin: string;
  productAttributeBinary: {
    productAttributeBinary1?: boolean | null;
    productAttributeBinary2?: boolean | null;
    productAttributeBinary3?: boolean | null;
    productAttributeBinary4?: boolean | null;
    productAttributeBinary5?: boolean | null;
  };
  productAttributeText: {
    productAttributeTxt1?: string | null;
    productAttributeTxt2?: string | null;
    productAttributeTxt3?: string | null;
    productAttributeTxt4?: string | null;
    productAttributeTxt5?: string | null;
  };
};

export type AnalyticsComponents = {
  investmentPlan: boolean;
  portfolio: {
    broadAssetAllocation: boolean;
    assetAllocation: boolean;
    fundAllocation: boolean;
  };
  expectations: boolean;
  expectedPath: boolean;
  expectedValue: boolean;
  costValue: boolean;
  historicalReturn: boolean;
  orderSummary: boolean;
  sustainability: boolean;
  cashflow: boolean;
  cost?: boolean;
};

export type GoalData = {
  timeHorizon: number;
  firstDeposit?: number;
  monthlyDeposit?: number;
  portfolio?: string;
  customPortfolio?: {
    id?: string;
    portfolio?: string;
    title?: string;
    existingPortfolio?: {
      assetClasses: {
        id: string;
        title: string;
        mainAssetClass: string;
        subAssetClass: string;
        instruments: {
          id: string;
          isin: string;
          title: string;
          weight: number;
        }[];
        availableInstruments: {
          id: string;
          isin: string;
          title: string;
        }[];
        weight: number;
        instrumentsSelection?: string;
      }[];
    };
  };
  isPortfolioCustom?: boolean;
  themes?: string[];
};

export type Goal = {
  goalId: string;
  name: string;
  sessionId: string;
  userId: number;
  description?: string;
  icon?: string;
  isGoalSelected: boolean;
  expectedValue?: number;
  purposeAndRiskScore?: string | null;
  riskScore?: string | null;
  data: Partial<GoalData>;
};

export type CashflowGoal = {
  goalId: string;
  icon?: string;
  name: string;
  withdrawal?: string | null;
  capitalNeed: number | string;
  frequency: string;
  year: number | string;
};

export type CashflowGoalChartData = {
  chartData: { estimatedGoalAchievement: number; capitalNeed: number };
  goalIcon?: string;
  goalId: string;
  goalName: string;
};

export type CashflowForecastChartData = {
  ranges: [number, number, number][];
  savingPlan: [number, number][];
  savingPlanWithNoReturn: [number, number][];
};

export type CashflowTableData = {
  amount: number;
  date: string;
  deposits: number;
  withdrawals: number;
};

export type ChartData = {
  expectedAnnualReturn: number;
  expectedVolatility: number;
  equityPortion: number;
  goalId: string;
  goalName: string;
  goalIcon: string;
  categoryMainAssetClassPortfolio: {
    id: string;
    isin: string | null;
    name: string;
    weight: number;
  }[];
  categorySubAssetClassPortfolio: {
    id: string;
    isin: string | null;
    name: string;
    weight: number;
  }[];
  categorySelectionPortfolio: {
    id: string;
    isin: string | null;
    name: string;
    weight: number;
  }[];
};

type SustainabilityResponseData = {
  investorSustainabilityScore: number;
  fundAnalysis: FundAnalysis[];
  portfolioAnalysis: PortfolioAnalysis;
};

export type AlignmentCriteriaKey = `alignmentCriteria${1 | 2 | 3 | 4 | 5}`;
export type PreferenceCriteriaKey = `preferenceCriteria${number}`;

export type FundAnalysis = {
  name: string;
  fundStandardName: string;
  isin: string;
  ticker: string;
  alignmentCriteria: Record<AlignmentCriteriaKey, number | null>;
  alignmentCriteriaStatus: Record<AlignmentCriteriaKey, boolean | null>;
  preferenceValues: Record<PreferenceCriteriaKey, number | null>;
  preferenceStatus: Record<PreferenceCriteriaKey, boolean | null>;
  fundWeightedSustainabilityScore: number;
  fundAlignmentStatus: boolean;
};

type PortfolioAnalysis = {
  alignmentValuesWeighted: Record<AlignmentCriteriaKey, number | null>;
  alignmentStatus: Record<AlignmentCriteriaKey, boolean | null>;
  portfolioWeightedSustainabilityScore: number;
  portfolioWeightedStatus: boolean;
};

export type SustainabilityData = {
  goalId: string;
  data: SustainabilityResponseData;
};

export const sustainabilityTableType = {
  standard: 'tableStandard',
  weighted: 'tableWeighted'
} as const;

export type SustainabilityDataReport = {
  sustainabilityAssessment: {
    header: string;
    body: string;
    answer: number;
    advisorNote: string;
  }[];
  preferenceCriteria: PreferenceCriteriaKey[];
  preferenceCriteriaAdvisorNotes?: string;
  sustainabilityPreference: {
    icon: string;
    id: PreferenceCriteriaKey;
    title: string;
  }[];
  genericAssessment?: {
    enabled?: boolean;
    header?: string;
    description?: string;
    answerText?: string;
    answer?: boolean;
    comment?: string;
  };
  data: SustainabilityData[];
  tableType: typeof sustainabilityTableType[keyof typeof sustainabilityTableType];
};

export type CostData = {
  Category: string;
  FundStandardName?: string | null;
  ISIN: string;
  Name: string;
  Ticker: string;
  fund_advisory_onboarding_fee: null | number;
  fund_advisory_onboarding_fee_amount: null | number;
  fund_advisory_purchasing_fee: null | number;
  fund_advisory_purchasing_fee_amount: null | number;
  fund_report_commission_paid_to_advisor: null | number;
  fund_report_commission_paid_to_client: null | number;
  fund_custody_fee: null | number;
  fund_custody_fee_amount: null | number;
  fund_insurance_cost: null | number;
  fund_insurance_cost_amount: null | number;
  fund_management_fee: null | number;
  fund_performance_fee: null | number;
  fund_platform_fee: null | number;
  fund_platform_fee_amount: null | number;
  fund_purchasing_fee: null | number;
  fund_total_ongoing_fees: null | number;
  fund_transaction_cost: null | number;
};

export type ProductAttribute = {
  label: string;
  name: string;
  type: keyof typeof ProductAttributeType;
};

type InformationField = {
  label: string;
  name: string;
  order: number;
  value: string;
};

export type AdvancedSuitability =
  | {
      [key: string]: {
        label: string;
        trainingContentValue: string;
        order: number;
      }[];
    }
  | undefined
  | null;

type AutomaticClientClassification = {
  answer?: string | string[];
  clientClassification: string;
  investorType?: string;
  originalInvestorType?: string;
  isDecisionOverwritten?: boolean;
  reclassificationRequestDocumentation?: string;
  isCommentRequired?: boolean;
};

export type PdfReportPayload = {
  advancedSuitability: AdvancedSuitability;
  adviceInformation?: InformationField[];
  orderInformation?: InformationField[];
  chartColorsConfig: {
    expectedPath: {
      expectedReturnColor: string;
      boundsColor: string;
      labelsColor: string;
    };
    expectedValue: {
      savingPlanColor: string;
      savingInBankAccountColor: string;
      labelsColor: string;
    };
    cost: {
      costColor: string;
      valueAfterCostColor: string;
      labelsColor: string;
    };
    historicalReturn: {
      labelsColor: string;
      errorNegativeColor: string;
      successColor: string;
    };
  };
  clientInformation: ClientInformation;
  generalInformation: {
    name: string;
  };
  purposeAndRisk: {
    maxRiskScore: number;
    expectationOfRisk: number;
    riskStrategy: number;
  };
  purposeAndRiskAdvisorNotes: string | undefined;
  knowledgeAndExperience: KnowledgeAndExperience;
  knowledgeAndExperienceAdvisorNotes: string | undefined;
  personFinancialSituation: PersonFinancialSituation;
  liquidity?: InformationField[];
  companyFinancialSituation: CompanyFinancialSituation;
  expectingFinancialChanges?: boolean | null;
  expectingFinancialChangesNote?: string | null;
  financialSituationAdvisorNotes: string | undefined;
  advisoryAdvisorNotes: string;
  investmentPlan: {
    monthlyDeposit: number;
    firstDeposit: number;
  };
  chartData: ChartData[];
  goals: Goal[];
  cashflowGoals: CashflowGoal[];
  cashflowGoalChartsData: CashflowGoalChartData[];
  cashflowForecastChartData: CashflowForecastChartData[];
  cashflowTableData: CashflowTableData[];
  expectedValueConfig: {
    bankReturn: number;
    compareWithBank: boolean;
  };
  cost: Cost;
  costData?: CostData[];
  expectedPathChartData: ExpectedPathChartData;
  expectedValueChartData: ExpectedValueChartData;
  historicalReturn: HistoricalReturn;
  orderSummaryData: OrderSummaryData[];
  orderSummaryProductAttributes: ProductAttribute[];
  sustainabilityData: SustainabilityDataReport;
  automaticClientClassification?: AutomaticClientClassification;
  analyticsComponents: AnalyticsComponents;
  otherDocuments: { [key: string]: boolean };
  otherDocumentsData: {
    name: string;
    number: string | undefined;
    advisorName: string;
    clientName: string;
    adress: string | undefined;
    riskModel: string;
    totalCostPortfolio: number;
  };
  isins: (string | null)[];
  emailData: {
    name: string;
    email: string | undefined;
    phoneNumber: string | null;
  };
  advisorName?: string;
  orderSummary?: OrderExecutionSummaryData[];
  productAttributes?: ProductAttribute[];
  language?: string;
  advisorNotes?: {
    orderInformationAdvisorNotes?: string;
    instrumentsAdvisorNotes?: string;
    proposalAdvisorNotes?: string;
  };
  productPlatformData?: {
    name: string;
    productPlatformNamespace?: number | null;
    productPlatformQuestions?: {
      question1?: boolean;
      question2?: boolean;
      question3?: boolean;
      question4?: boolean;
      question5?: boolean;
    };
    productPlatformReasoning?: string | null;
  }[];
  expectedValueData?: { x: number; y: number }[];
};

export type ReportStoreData = {
  userData: PdfReportPayload | undefined;
};

type ReportStoreApi = {
  reset: () => void;
  initialize: (initialData: Partial<ReportStoreData>) => void;
  setUserData: (userData: PdfReportPayload) => void;
};

type ReportStoreState = ReportStoreData & ReportStoreApi;

const defaultData = {
  userData: undefined
};

export const useReportStore = create<ReportStoreState>(set => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  initialize(initialData) {
    set(
      state => ({
        ...state,
        ...initialData
      }),
      true
    );
  },
  setUserData: userData => {
    set({ userData });
  }
}));
