import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import * as R from 'ramda';
import { useSelector } from 'react-redux';

import { i18n } from 'i18n/index.js';
import { formatNumber } from 'features/shared/utils/number.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { FontWeights } from '../../shared/constants/fonts.js';
import { useReportStore } from 'features/report/shared/services/reportStore';
import Heading2 from '../../shared/components/heading2';
import Section from '../../shared/components/section.js';
import Summary from './summary.js';

const useStyles = createUseStyles(theme => ({
  title: {
    width: '220px'
  },
  value: {
    fontWeight: FontWeights.normal
  },
  info: {
    marginTop: '14px'
  }
}));

const ExpectationsSection = ({ goal, expectedValuePlace }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const reportStore = useReportStore();

  const { userData } = reportStore;

  let chartData = {};
  if (userData?.chartData) {
    chartData = userData.chartData.find(c => c.goalId === goal.goalId);
  }

  const items = [
    {
      title: `${i18n(
        'report.expectations.expectedReturn',
        translationsConfig
      )}:`,
      value: `${formatNumber(
        cultureCode,
        chartData.expectedAnnualReturn,
        0,
        1
      )}%`
    },
    {
      title: `${i18n('report.expectations.expectedRisk', translationsConfig)}:`,
      value: `${formatNumber(cultureCode, chartData.expectedVolatility, 0, 1)}%`
    }
  ];

  return (
    <Section data-testid={'expectations-section'}>
      <Heading2>
        {i18n('report.expectations.header', translationsConfig)}
      </Heading2>
      <Summary
        items={
          expectedValuePlace === 'expectationsSection'
            ? [
                ...items,
                {
                  title: `${i18n(
                    'roboAdvice.proposal.expectedValueInXYears',
                    translationsConfig
                  ).replace(
                    '{0}',
                    goal.data?.analysisProjectionYearsValue ??
                      goal.data?.timeHorizon
                  )}:`,
                  value: R.isNil(goal?.expectedValue)
                    ? ''
                    : formatNumber(cultureCode, goal.expectedValue, 0, 0)
                }
              ]
            : items
        }
        titleClassName={classes.title}
        valueClassName={classes.value}
      />
      <div className={classes.info}>
        {i18n('report.expectations.info', translationsConfig)}
      </div>
    </Section>
  );
};

export default ExpectationsSection;
