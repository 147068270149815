import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  legend_1: {
    fill: theme.whatIsRiskLegendColor
  }
}));

const RiskAndReturnGraph_2 = ({
  className,
  legendText_1,
  legendText_2,
  arrowText_1,
  arrowText_2
}) => {
  const classes = useStyles();

  return (
    <svg
      width="320"
      height="247"
      viewBox="0 0 320 247"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect y="166.149" width="324" height="48.5107" fill="white" />
      <rect y="68.2769" width="324" height="48.5107" fill="white" />
      <path
        d="M0 15C0 6.71573 6.71573 0 15 0H309C317.284 0 324 6.71573 324 15V68H0V15Z"
        fill="#FAFAF8"
      />
      <rect y="116.787" width="324" height="49.3617" fill="#FAFAF8" />
      <text
        x="11.1367"
        y="72.5416"
        style={{
          fill: '#62796C',
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: '400'
        }}
      >
        15 000
      </text>
      <text
        x="11.1367"
        y="122.07"
        style={{
          fill: '#62796C',
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: '400'
        }}
      >
        10 000
      </text>
      <text
        x="11.1367"
        y="172.129"
        style={{
          fill: '#62796C',
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: '400'
        }}
      >
        5 000
      </text>
      <text
        x="11.1367"
        y="220.507"
        style={{
          fill: '#62796C',
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: '400'
        }}
      >
        0
      </text>
      <text
        x="79.33"
        y="233.273"
        style={{
          fill: '#62796C',
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: '400'
        }}
      >
        2 years
      </text>
      <text
        x="269.875"
        y="233.273"
        style={{
          fill: '#62796C',
          fontSize: '12px',
          lineHeight: '14px',
          fontWeight: '400'
        }}
      >
        4 years
      </text>
      <ellipse
        cx="54.9681"
        cy="29.3842"
        rx="10.3404"
        ry="10.2128"
        className={classes.legend_1}
      />
      {legendText_1.map((t, index) => (
        <text
          key={index}
          x="72.581"
          y={35 + 17 * index}
          style={{
            fill: '#333333',
            fontSize: '14px',
            lineHeight: '17px',
            fontWeight: '300'
          }}
        >
          {t}
        </text>
      ))}
      <ellipse
        cx="173.723"
        cy="29.3837"
        rx="10.3404"
        ry="10.2128"
        fill="#DAE0DC"
      />
      <text
        x="193.846"
        y="35"
        style={{
          fill: '#333333',
          fontSize: '14px',
          lineHeight: '17px',
          fontWeight: '300'
        }}
      >
        {legendText_2}
      </text>
      <path
        d="M208.232 162.557C207.987 162.429 207.685 162.523 207.557 162.768L205.469 166.754C205.341 166.999 205.435 167.301 205.68 167.429C205.925 167.557 206.227 167.463 206.355 167.218L208.211 163.675L211.754 165.531C211.999 165.659 212.301 165.565 212.429 165.32C212.557 165.075 212.463 164.773 212.218 164.645L208.232 162.557ZM213.477 178.851L208.477 162.851L207.523 163.149L212.523 179.149L213.477 178.851Z"
        fill="black"
      />
      <text
        x="179.07"
        y="193.11"
        style={{
          fill: '#000000',
          fontSize: '10px',
          lineHeight: '12px',
          fontWeight: '400'
        }}
      >
        {arrowText_1}
      </text>
      <text
        x="179.07"
        y="205.11"
        style={{
          fill: '#000000',
          fontSize: '10px',
          lineHeight: '12px',
          fontWeight: '400'
        }}
      >
        {arrowText_2}
      </text>
      <path
        d="M45.0001 166.221L275.622 152.073"
        stroke="#DAE0DC"
        strokeWidth="2"
      />
      <path
        d="M44.8085 166.575L66.3511 177.213L84.4468 147.426L102.543 155.085L123.654 132.106L148.644 138.064L159.846 120.617L188.713 124.872L213.702 109.127L241.707 102.319L261.096 88.2763L290.825 67.426"
        stroke="#3A7341"
        strokeWidth="2"
      />
    </svg>
  );
};

export default RiskAndReturnGraph_2;
