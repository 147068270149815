import React from 'react';
import { i18n } from 'i18n/index.js';
import { useSelector } from 'react-redux';

import SlickCarousel from 'features/shared/components/slickCarousel';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { TextFontSizes } from 'features/shared/constants/fonts';
import { Colors } from 'features/shared/constants/colors';

import Icon from 'features/shared/components/icon';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors';
import { formatNumber } from 'features/shared/utils/number';
import sessionSelectors from 'features/shared/services/session/selectors.js';

const useStyles = createUseStyles(theme => ({
  content: {
    width: '100%',
    paddingTop: '30px',
    paddingBottom: '8px'
  },
  text: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    textAlign: 'center'
  },
  icon: {
    marginBottom: '17px'
  },
  cardContainer: {
    padding: '14px',
    height: '100%'
  },
  card: {
    display: 'flex',
    justifyContent: 'center',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center',
    maxWidth: '320px',
    minHeight: '263px',
    background: Colors.white,
    boxShadow:
      '0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 4px 3px ' +
      Colors.listShadowColor,
    borderRadius: '15px',
    margin: '14px auto',
    padding: '14px'
  },
  cardText: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx
  }
}));

const CreditCardSlider = ({ translations }) => {
  const classes = useStyles();
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const userFinancialSituation = useSelector(
    userOptionsSelectors.getUserFinancialSituation
  );
  const sliderItemsConfig = [
    {
      iconName: 'unpaidDebt',
      key: 'unpaid-debt',
      text: i18n('creditCard.slider1', translations).replace(
        '{0}',
        formatNumber(cultureCode, userFinancialSituation.creditCard, 0, 0)
      )
    },
    {
      iconName: 'expensiveLoans',
      key: 'expensive-loans',
      text: i18n('creditCard.slider2', translations)
    },
    {
      iconName: 'roomEconomy',
      key: 'room-economy',
      text: i18n('creditCard.slider3', translations)
    }
  ];

  return (
    <div className={classes.content}>
      <SlickCarousel>
        {sliderItemsConfig.map(el => (
          <div className={classes.cardContainer} key={el.key}>
            <div className={classes.card}>
              <Icon type={el.iconName} className={classes.icon} />
              <span className={classes.cardText}>{el.text}</span>
            </div>
          </div>
        ))}
      </SlickCarousel>
    </div>
  );
};

export default CreditCardSlider;
