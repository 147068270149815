export const defaultTranslations = {
  'report.shared.advisorNotes': 'Advisor notes',
  'report.expectations.header': 'Expectations',
  'report.expectations.expectedReturn': 'Expected return',
  'report.expectations.expectedRisk': 'Expected risk',
  'report.expectations.info':
    'The expectation is based on figures provided by FNO (Finans Norge)',
  'report.expectedValue.header': 'Expected value',
  'report.expectedPath.header': 'Expected path',
  'report.expectedPath.text':
    'The graph below shows the expected path for the investment portfolio.',
  'report.advisory.reasoningForAdvice': 'Reasoning for advice',
  'report.generalInformation.experienceWithTheFollowing':
    'Experience with the following',
  'report.generalInformation.investmentAdvice': 'Investment advice',
  'report.generalInformation.orderExecution': 'Order execution',
  'report.generalInformation.activeManagement': 'Active management',
  'report.generalInformation.clientClassification': 'Client classification',
  'report.generalInformation.investorType': 'Investor Type',
  'report.generalInformation.reClassificationRequestDocumentation.header':
    'Re-classification request documentation',
  'report.generalInformation.reClassificationRequestDocumentation.description':
    'We have classified you as a {0}, you have asked to be re-classified as a {1}. This is a custom text that the tenant can configure. Investor types are taken from the front-end. ',
  'report.generalInformation.whatIsTheOriginOfTheMoney':
    'What is the origin of the money',
  'report.generalInformation.id': 'ID',
  'report.generalInformation.adress': 'Adress',
  'report.generalInformation.zipCode': 'Zip Code',
  'report.generalInformation.city': 'City',
  'report.generalInformation.companyName': 'Company name',
  'report.generalInformation.orgNumber': 'Org. number',
  'report.generalInformation.contactPerson': 'Contact person',
  'report.generalInformation.nationalId': 'National ID',
  'report.generalInformation.title': 'Title',
  'report.generalInformation.controlPerson': 'Control person',
  'report.generalInformation.controlPersonName': 'Control person name',
  'report.generalInformation.controlPersonId': 'Control person ID',
  'report.generalInformation.controlPersonTitle': 'Control person title',
  'report.generalInformation.beneficialOwner': 'Beneficial owner',
  'report.generalInformation.role': 'Role',
  'report.generalInformation.signatureRights': 'Signature Rights',
  'report.generalInformation.ubo': 'UBO',
  'report.financialSituation.assessmentOfFinancialSituation':
    'Assessment of financial situation',
  'report.financialSituation.investableAssets': 'Investable Assets',
  'report.financialSituation.accountingFigures': 'Accounting figures',
  'report.financialSituation.liquidity': 'Liquidity',
  'report.historicalValue.header': 'Historical Return',
  'historicalReturn.table.return': 'Return',
  'financialSituation.assets.sumAssets': 'Sum assets',
  'financialSituation.debt.sumDebt': 'Sum debt',
  'portfolioChart.default.portfolio': 'Portfolio',
  'portfolioChart.roboFront.fundAndWeight': 'Fund and weight',
  'portfolioChart.roboFront.isin': 'ISIN',
  'expectedPathChart.default.expectedValue': 'Expected Value',
  'expectedPathChart.default.upperAndLowerBound': 'Upper & lower bound',
  'expectedValueChart.default.yourSavingPlan': 'Your saving plan',
  'expectedValueChart.default.savingInYourBankAccount':
    'Saving in your bank account',
  'report.cost.header': 'Cost',
  'costChart.yearsTable.deposits': 'Deposits',
  'costChart.yearsTable.expValueBeforeCost': 'Expected value before cost',
  'costChart.yearsTable.expValueAfterCost': 'Expected value after cost',
  'costChart.yearsTable.reductionInReturn': 'Reduction in return',
  'costChart.yearsTable.reductionInReturnPercent': 'Reduction in return (%)',
  'costChart.yearsTable.expValueAfterCostPercent':
    'Expected return after cost (%)',
  'costChart.yearsTable.costValue': 'Cost value',
  'costChart.yearsTable.expectedReturn': 'Expected return',
  'costChart.costTable.platformFee': 'Platform fee',
  'costChart.costTable.entryFee': 'Entry fee',
  'costChart.costTable.managementFee': 'Management fee',
  'costChart.costTable.performanceFee': 'Performance fee',
  'costChart.costTable.transactionCost': 'Transaction Cost',
  'costChart.costTable.totalCost': 'Total costs',
  'costChart.summaryTable.title': 'Summary',
  'costChart.summaryTable.year': 'Cost for year',
  'costChart.summaryTable.element': 'Element',
  'costChart.summaryTable.costElement': 'Cost Element',
  'costChart.summaryTable.totalCostFund': 'Total cost fund',
  'costChart.summaryTable.advisoryFee': 'Advisory fee',
  'costChart.summaryTable.totalCostPortfolio': 'Total  cost portfolio',
  'costChart.fundsTable.titleAmount': 'Fund details, amount',
  'costChart.fundsTable.titlePercent': 'Fund details, %',
  'costChart.fundsTable.fund': 'Fund',
  'costChart.fundsTable.managementFee': 'Management Fee',
  'costChart.fundsTable.oneTimeCost': 'One Time Cost',
  'costChart.fundsTable.transactionCost': 'Transactional Cost',
  'costChart.fundsTable.totalExpenseRatio': 'Total Expense Ratio',
  'costChart.fundsTable.reasoningForOversteeredCost':
    'Reasoning for oversteered cost',
  'costChart.costTable.fundManagementFee': 'Fund management fee',
  'costChart.costTable.fundReturnCommissionPaidToClient':
    'Fund return commission paid to client',
  'costChart.costTable.fundReturnCommissionPaidToAdvisor':
    'Fund return commission paid to advisor',
  'costChart.costTable.fundTransactionCost': 'Fund transaction cost',
  'costChart.costTable.custodyFee': 'Custody fee',
  'costChart.costTable.totalPortfolioFee': 'Total portfolio fee',
  'costChart.costTable.fundPurchaseFee': 'Fund purchase fee',
  'costChart.costTable.advisoryPurchaseFee': 'Advisory purchase fee',
  'costChart.costTable.returnCommissionPaidToAdvisor':
    'Return commission paid to advisor',
  'costChart.costTable.annualReturnCommissionPaidToAdvisor':
    'Annual return commission paid to advisor',
  'costChart.costTable.ongoingFees': 'Ongoing fees',
  'costChart.costTable.ongoingFeesInPercent': 'Ongoing fees in %',
  'costChart.costTable.ongoingFeesInCurrency': 'Ongoing fees in {0}',
  'costChart.costTable.oneTimeFee': 'One time fee',
  'costChart.costTable.advisoryOnboardingFee': 'Advisory onboarding fee',
  'costChart.costTable.aggregatedCostEffect': 'Aggregated cost effect',
  'costChart.costTable.aggregatedOngoingFees': 'Aggregated ongoing fees',
  'costChart.costTable.aggregatedOneTimeFee': 'Aggregated one time fee',
  'costChart.costTable.aggregatedReturnCommissionPaidToAdvisor':
    'Aggregated return commission paid to advisor',
  'costChart.default.totalValue': 'Total value',
  'costChart.default.totalCost': 'Total cost',
  'costChart.default.asterisk':
    '* - measured in % of based on expected value before cost',
  'costChart.fundDetails.title': 'Fund details',
  'costChart.costTable.totalExpenseRatio': 'Total expense ratio',
  'roboAdvice.proposal.cost.asterisk':
    '* Measured in % based on expected value before costs',
  'historicalReturn.historyIsNotLongEnough':
    'The history is not long enough to estimate requested return calculation',
  'historicalReturn.lastUpdated': 'Last updated',
  'historicalReturn.return': 'Return',
  'report.purpose.header': 'Purpose',
  'report.purpose.text':
    'Based on your input / discussion we have identified that you have the following goals:',
  'report.purpose.goal': 'Goal',
  'report.purpose.years': 'Years',
  'report.purpose.riskScore': 'Risk score',
  'report.purpose.maximumRiskScore': 'Maximum risk score = {0}',
  'report.purpose.goalWithName': 'Goal - {0}',
  'report.risk.header': 'Risk',
  'report.risk.text':
    'During our process we have asked you some questions regarding you risk. These questions are key drivers to the risk score for each of the goals. This is the answers you have provided us with',
  'report.risk.expectationOfRisk.question': 'What is your expectation of risk?',
  'report.risk.expectationOfRisk.lowRisk':
    'Low risk - Accept lower returns, want minor fluctuations',
  'report.risk.expectationOfRisk.mediumRisk':
    'Medium risk - Hope for moderate returns, accept fluctuations',
  'report.risk.expectationOfRisk.highRisk':
    'High risk - Hope for high returns, accept large fluctuations',
  'report.risk.expectationOfRisk.maximumRisk':
    'Maximum risk - Hope for greatest possible return, accept larger fluctuations',
  'report.risk.riskStrategy.question': 'Where should you put your money?',
  'report.risk.riskStrategy.scenarioA': 'Scenario A - XXX in return',
  'report.risk.riskStrategy.scenarioB': 'Scenario B - XXX in return',
  'report.risk.riskStrategy.scenarioC': 'Scenario C - XXX in return',
  'report.risk.riskStrategy.scenarioD': 'Scenario D - XXX in return',
  'report.investmentPlan.details': 'Details',
  'report.investmentPlan.keyStatistics': 'Key statistics',
  'report.investmentPlan.initialDeposit': 'Initial deposit',
  'report.investmentPlan.monthlySaving': 'Monthly saving',
  'report.investmentPlan.aggregated': 'Aggregated',
  'report.investmentPlan.expectedAnnualReturn': 'Expected annual return',
  'report.investmentPlan.expectedVolatility': 'Expected volatility',
  'report.investmentPlan.expectedValue': 'Expected value',
  'report.investmentPlan.equityPortion': 'Equity portion',
  'report.expectedValue.text':
    'The graph below shows the expected value of the saving plan, compared to an interest rate of 1 %, illustrating the return in a bank account',
  'report.historicalReturn.historicalDevelopmentInPercent':
    'Historical development in percent',
  'report.cost.text1':
    'In Quantfolio we are open about our costs and want you as client to get an overview and all available details before you save/invest your money.',
  'report.cost.text2':
    'Below you can find an overview over expected costs that will occur when saving in your investment plan:',
  'report.financialSituation.expectingChangesToFinancialSituation':
    'Are you expecting changes to financial situation?',
  'report.cashflow.header': 'Cashflow',
  'report.cashflow.withdrawal': 'Withdrawal',
  'report.cashflow.withdrawals': 'Withdrawals',
  'report.cashflow.deposits': 'Deposits',
  'report.cashflow.year': 'Year',
  'report.cashflow.frequency': 'Frequency',
  'cashflow.capitalNeed': 'Capital need',
  'cashflow.estimatedGoalAchievement': 'Estimated goal achievement',
  'cashflow.savingPlan': 'Saving plan',
  'cashflow.savingPlanWithNoReturn': 'Saving plan with no return',
  'report.shared.value': 'Value',
  'shared.yearsTemplate': '{0} year(s)',
  'report.productPlatform.header': 'Selected product platform',
  'report.productPlatform.description':
    'In the advisory process we have selected a product platform per investment goal. Here is information about the selection per goal',
  'report.productPlatform.reasoningForSelection': 'Reasoning for selection:',
  'report.productPlatform.input': 'Input',
  'report.productPlatform.answer': 'Answer',
  'report.productPlatform.selectedProductPlatform': 'Selected product platform',
  'report.productPlatform.comment': 'Comment',
  'report.orderSummary.header': 'Order summary',
  'report.orderSummary.paragraph':
    'Below you can see details about your order, calculated across the different goals of your saving plan.',
  'report.orderSummary.bankAccountNumberForOrder':
    'Bank account number for order',
  'report.orderSummary.buyOrder': 'Buy order',
  'report.orderSummary.monthlyOrder': 'Monthly order',
  'report.shared.amount': 'Amount',
  'report.adviceInformation.title': 'Advice information',
  'report.sustainability.header': 'Sustainability Assessment',
  'report.sustainability.yourPreference': 'Your preference',
  'report.sustainability.undecided': 'Undecided',
  'report.sustainability.notImportant': 'Not Important',
  'report.sustainability.slightlyImportant': 'Slightly Important',
  'report.sustainability.moderatelyImportant': 'Moderately Important',
  'report.sustainability.important': 'Important',
  'report.sustainability.veryImportant': 'Very Important',
  'report.sustainability.preferenceCriteria': 'Preference Criteria',
  'report.sustainability.advisorNotes': 'Advisor Notes',
  'report.sustainability.fund': 'Fund',
  'report.sustainability.portfolioScore': 'Portfolio score',
  'report.sustainability.myPreference': 'My preference',
  'report.sustainability.score': 'Score',
  'report.sustainability.alignment': 'Alignment',
  'report.portfolio.broadAssetAllocationText': '',
  'report.portfolio.assetAllocationText': '',
  'report.portfolio.fundAllocationText': '',
  'report.orderExecution.expectedValue.header': 'Expected value',
  'report.orderExecution.expectedValue.text':
    'The graph below shows the expected value of the selected instruments',
  'report.orderExecution.cost.header': 'Cost',
  'report.orderExecution.cost.tableHeader': 'Cost details',
  'report.orderExecution.cost.text':
    'The table below shows the cost for the selected instruments: ',
  'counseling.leaveDetails.name.title': 'Name / company name',
  'counseling.leaveDetails.phoneNumber.title': 'Phone number',
  'counseling.leaveDetails.email.title': 'Email',
  'portfolioChart.fixedIncome': 'Fixed Income',
  'portfolioChart.equity': 'Equity'
};
