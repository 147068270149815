import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import * as R from 'ramda';

import { i18n } from 'i18n/index.js';
import KnowledgeFund from 'assets/knowledge-icons/fund.js';
import { getCurrentRouteConfig } from 'features/shared/services/router/selectors.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import { creators as userOptionsCreator } from 'features/shared/services/userOptions/actions.js';
import List from 'features/shared/components/list/index.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import Icon from 'features/shared/components/icon/index';
import Link from 'features/shared/components/link/index';
import { Colors } from 'features/shared/constants/colors';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';

const useStyles = createUseStyles(theme => {
  return {
    subTitle: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.largePx,
      lineHeight: TextFontSizes.largeLineHeightPx,
      textAlign: 'center',
      paddingTop: '30px',
      color: Colors.grayDark
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    contentHeader: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingBottom: '40px',
      paddingTop: '50px'
    },
    link: {
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.mediumPx,
      lineHeight: TextFontSizes.mediumLineHeightPx,
      textAlign: 'center',
      padding: '13px 0'
    }
  };
});

const Fund = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const currentRouteConfig = useSelector(getCurrentRouteConfig);
  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const fundQuiz = useSelector(sessionSelectors.getFundQuizConfig);
  const userOptions = useSelector(userOptionsSelectors.getUserOptions);

  const dispatch = useDispatch();
  const userKnowledgesChanged = (type, value) => {
    dispatch(userOptionsCreator.userKnowledgesChanged({ type, value }));
  };

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('knowledge.knowledges.naviTitle', translations)}
      </Navigation>

      <Page>
        <div className={classes.content}>
          <div className={classes.contentHeader}>
            <KnowledgeFund />
            <span className={classes.subTitle}>
              {i18n('knowledge.knowledges.subTitle.page_1', translations)}
            </span>

            <Link
              className={classes.link}
              to={routeTemplates.whatIsFund.build(
                customerId,
                currentRouteConfig.id
              )}
            >
              {i18n('knowledge.knowledges.link.page_1', translations)}
              <Icon type="navigation" />
            </Link>
          </div>

          <List
            options={fundQuiz}
            selectedOption={userOptions.knowledges.fundQuiz}
            userOptionType="fundQuiz"
            onSelect={(type, item) => {
              userKnowledgesChanged(type, item.id);
              window.scrollTo(0, document.body.scrollHeight);
            }}
          />
        </div>

        <Footer>
          {nextRoute && (
            <Button
              type={ButtonType.primary}
              disabled={R.isEmpty(userOptions.knowledges.fundQuiz)}
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n('shared.continue', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default Fund;
