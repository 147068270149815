import React from 'react';
import classNames from 'classnames';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import LinearProgressBar from '../progressBar/linearProgressBar';

export const useStyles = createUseStyles({
  page: {
    flex: '1 0 auto',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexDirection: 'column',
    padding: '48px 24px 5px 24px'
  }
});

const Page = ({
  className,
  children,
  showProgressBar,
  progressBarContainerClassName
}) => {
  const classes = useStyles();

  return (
    <div id="page" className={classNames(classes.page, className)}>
      {showProgressBar && (
        <LinearProgressBar containerClassName={progressBarContainerClassName} />
      )}
      {children}
    </div>
  );
};

Page.defaultProps = {
  showProgressBar: true
};

export default Page;
