import React from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import { i18n } from 'i18n/index.js';
import {
  replaceTextWithFormatting,
  defaultReplacements
} from 'features/shared/components/i18n/utils/i18n.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { FontWeights } from '../../shared/constants/fonts.js';
import Paragraph from '../../shared/components/paragraph';
import Heading1 from '../../shared/components/heading1';
import Section from '../../shared/components/section.js';

const useStyles = createUseStyles(theme => ({
  userName: {
    fontWeight: FontWeights.bold
  },
  customerName: {
    fontWeight: FontWeights.bold
  },
  thanksParagraphs: {
    marginTop: '30px'
  },
  thanksFooter: {
    marginTop: '100px'
  }
}));

const ThanksSection = ({ generalInformation, advisorName }) => {
  const classes = useStyles();

  const reportConfig = useSelector(sessionSelectors.getReportConfig);
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  const replacePosibilities = {
    '{userName}': (
      <span className={classes.userName}>{generalInformation.name}</span>
    ),
    '{clientName}': (
      <span className={classes.userName}>{generalInformation.name}</span>
    ),
    '{customerName}': (
      <span className={classes.customerName}>
        {i18n('report.background.customerName', translationsConfig)}
      </span>
    ),

    ...defaultReplacements
  };

  return (
    <>
      <Section data-testid={'thanks-section'}>
        <Heading1>
          {replaceTextWithFormatting(
            i18n('report.thanks.header', translationsConfig),
            replacePosibilities
          )}
        </Heading1>
        <div className={classes.thanksParagraphs}>
          {reportConfig.thanksTexts.map((t, index) => (
            <Paragraph key={index}>
              {replaceTextWithFormatting(
                i18n(t, translationsConfig),
                replacePosibilities
              )}
            </Paragraph>
          ))}
        </div>
        <div className={classes.thanksFooter}>
          <Paragraph key={'advisor-name'}>
            {replaceTextWithFormatting(
              i18n('report.thanks.footer', translationsConfig),
              replacePosibilities
            )}
          </Paragraph>
          <br />
          <br />
          <Paragraph>{advisorName}</Paragraph>
          {reportConfig.thanksTextsAdditionalName && (
            <Paragraph>{reportConfig.thanksTextsAdditionalName}</Paragraph>
          )}
        </div>
      </Section>
      <div style={{ pageBreakAfter: 'always' }} />
    </>
  );
};

export default ThanksSection;
