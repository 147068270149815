import React from 'react';
import { useSelector } from 'react-redux';
import { isEmpty, isNil } from 'ramda';
import { goalIcons } from '@quantfoliorepo/ui-components';

import { i18n } from 'i18n/index.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { useReportStore } from 'features/report/shared/services/reportStore';
import { FontWeights, TextFontSizes } from '../../shared/constants/fonts.js';
import {
  getClientInformation,
  getFinancialSituation,
  getGeneralInformation,
  getGoals,
  getCashflowGoals,
  getCashflowGoalChartsData,
  getCashflowForecastChartData,
  getCashflowTableData,
  getKnowledgeAndExperience,
  getPurposeAndRisk,
  getOrderSummaryData,
  getAdviceInformation,
  getSustainabilityData,
  getAdvancedSuitability
} from '../services/reportSelectors';
import Cover from './cover.js';
import Page from './page.js';
import KnowledgeAndExperienceSection from './knowledgeAndExperienceSection.js';
import FinancialSituationSection from './financialSituationSection.js';
import InvestmentPlanSection from './investmentPlanSection.js';
import GeneralInformationSection from './generalInformationSection.js';
import BackgroundSection from './backgroundSection.js';
import AboutCustomerSection from './aboutCustomerSection.js';
import AboutInvestmentSection from './aboutInvestmentSection.js';
import AdvisorNotesSection from './advisorNotesSection.js';
import ExpectedValueSection from './expectedValueSection';
import CostSection from './costSection';
import HistoricalReturnSection from 'features/report/body/components/historicalReturn';
import ExpectedPathSection from 'features/report/body/components/expectedPathSection';
import PurposeSection from './purposeSection.js';
import RiskSection from './riskSection.js';
import ThanksSection from './thanksSection';
import PortfolioSection from '../../portfolioSection/components';
import NextStepsSection from './nextStepsSection';
import DisclamerSection from './disclamerSection';
import PowOfAttornaySection from './powOfAttornaySection';
import TradeAuthorizationSection from './tradeAuthSection';
import SignatureSection from './signatureSection';
import Attachment from './attachment';
import CompanyVest from './companyVest';
import FinalClassification from './finalClassification';
import CashflowTable from './cashflowTable.js';
import CashflowCharts from './cashflowCharts.js';
import CashflowForecastTable from './cashflowForecastTable.js';
import Section from 'features/report/shared/components/section.js';
import ProductPlatform from './productPlatform.js';
import OrderSummary from './orderSummary';
import AdviceInformationSection from './adviceInformationSection.js';
import Sustainability from './sustainability';
import AdvancedSuitabilitySection from './advancedSuitability';

declare global {
  interface Window {
    chartsToLoad: string[];
    loadedCharts: string[];
    imagesToLoad: string[];
    loadedImages: string[];
  }
}

// No margin-bottom is allowed for top level page components (sections particularly) in order to make 1px offset fix work.
// We can only do calculation based on sections, becuase we can not predict how section itself will be splitted.

const useStyles = createUseStyles(theme => ({
  '@global': {
    html: {
      height: '100%'
    },
    body: {
      height: '100%',
      margin: 0,
      padding: 0,
      fontFamily: theme.reportFontFamily,
      fontSize: TextFontSizes.x1,
      lineHeight: TextFontSizes.x1LineHeight,
      fontWeight: FontWeights.normal,
      color: theme.reportTextColor
    },
    '*': {
      boxSizing: 'border-box'
    }
  }
}));

const CHART_WIDTH = 630;

const Body = () => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  const reportStore = useReportStore();
  const goals = getGoals(reportStore);
  const cashflowGoals = getCashflowGoals(reportStore);
  const cashflowForecastChartData = getCashflowForecastChartData(reportStore);
  const cashflowGoalChartsData = getCashflowGoalChartsData(reportStore);
  const cashflowTableData = getCashflowTableData(reportStore);
  const clientInformation = getClientInformation(reportStore);
  const adviceInformation = getAdviceInformation(reportStore);
  const orderSummaryData = getOrderSummaryData(reportStore);
  const sustainabilityData = getSustainabilityData(reportStore);
  const generalInformation = getGeneralInformation(reportStore);
  const purposeAndRisk = getPurposeAndRisk(reportStore);
  const isPurposeAndRiskEmpty = isNil(purposeAndRisk?.maxRiskScore);
  const knowledgeAndExperience = getKnowledgeAndExperience(reportStore);
  const advancedSuitability = getAdvancedSuitability(reportStore);
  const isKnowledgeAndExperienceEmpty = isEmpty(knowledgeAndExperience);
  const financialSituation = getFinancialSituation(reportStore);

  const isPortfolioEmpty = !reportStore.userData?.chartData;
  const isExpectedValueEmpty = isNil(reportStore.userData?.expectedValueConfig);
  const isCostValueEmpty = isNil(reportStore.userData?.cost);
  const isHistoricalReturnEmpty = isNil(reportStore.userData?.historicalReturn);
  const isOrderSummaryEmpty = isEmpty(reportStore.userData?.orderSummaryData);
  const isSustainabilityEmpty =
    isEmpty(reportStore.userData?.sustainabilityData?.data) &&
    !reportStore.userData?.sustainabilityData?.genericAssessment?.enabled;
  const analyticsComponents = reportStore.userData?.analyticsComponents;
  const reportConfig: any = useSelector(sessionSelectors.getReportConfig);
  const expectedValuePlace = reportConfig.expectedValuePlace;
  const isPercentColumnEnabled =
    reportConfig.orderSummary?.isPercentColumnEnabled;
  const orderSummaryProductAttributes =
    reportStore.userData?.orderSummaryProductAttributes;
  const isProductPlatformEnabled = reportConfig.productPlatform?.enabled;

  const accountNumber = clientInformation?.accountNumber || '';
  const clientClassification = clientInformation?.fields?.find(
    ({ name }) =>
      name === 'clientInformation.additionalData.clientClassification'
  )?.value;
  const language = reportStore.userData?.language
    ? reportStore.userData?.language
    : 'en';

  const isSustainabilitySectionVisible =
    !isSustainabilityEmpty &&
    analyticsComponents?.sustainability &&
    sustainabilityData &&
    goals;

  React.useEffect(() => {
    if (
      !isPortfolioEmpty &&
      analyticsComponents?.portfolio?.broadAssetAllocation
    ) {
      window.chartsToLoad.push('portfolioBroadAssetAllocation');
    }

    if (!isPortfolioEmpty && analyticsComponents?.portfolio?.assetAllocation) {
      window.chartsToLoad.push('portfolioAssetAllocation');
    }

    if (!isPortfolioEmpty && analyticsComponents?.portfolio?.assetAllocation) {
      window.chartsToLoad.push('portfolioAssetAllocation');
    }

    if (!isPortfolioEmpty && analyticsComponents?.portfolio?.fundAllocation) {
      window.chartsToLoad.push('portfolioFundAllocation');
    }

    if (!isExpectedValueEmpty && analyticsComponents?.expectedValue) {
      window.chartsToLoad.push('expectedValue');
    }

    if (analyticsComponents?.expectedPath) {
      window.chartsToLoad.push('expectedPath');
    }

    if (!isHistoricalReturnEmpty && analyticsComponents?.historicalReturn) {
      window.chartsToLoad.push('historicalReturn');
    }

    if (!isCostValueEmpty && analyticsComponents?.costValue) {
      window.chartsToLoad.push('cost');
    }

    if (reportConfig?.showGoalIcons) {
      goals
        ?.filter(({ icon }) => {
          return (
            icon && !goalIcons.find(({ name }) => name === icon)?.tableIcon
          );
        })
        .forEach(({ icon }) => {
          icon && window.imagesToLoad.push(icon);
        });
    }

    if (isSustainabilitySectionVisible) {
      sustainabilityData.sustainabilityPreference
        .filter(({ id }) => sustainabilityData.preferenceCriteria?.includes(id))
        .forEach(({ icon }) => {
          window.imagesToLoad.push(icon);
        });
    }
  }, []);

  const chartsSectionConfig = {
    historicalReturn: {
      display:
        !isHistoricalReturnEmpty && analyticsComponents?.historicalReturn,
      component: (
        <HistoricalReturnSection
          key={'historicalReturn'}
          chartWidth={CHART_WIDTH}
          showGoalIcons={reportConfig?.showGoalIcons}
        />
      )
    },
    expectedPath: {
      display: analyticsComponents?.expectedPath,
      component: (
        <ExpectedPathSection key={'expectedPath'} chartWidth={CHART_WIDTH} />
      )
    },
    expectedValue: {
      display: !isExpectedValueEmpty && analyticsComponents?.expectedValue,
      component: (
        <ExpectedValueSection
          chartWidth={CHART_WIDTH}
          header="report.expectedValue.header"
          key="expectedValue"
          text="report.expectedValue.text"
        />
      )
    },
    cost: {
      display: !isCostValueEmpty && analyticsComponents?.costValue,
      component: <CostSection key={'cost'} chartWidth={CHART_WIDTH} />
    }
  };

  return (
    <>
      <Cover
        generalInformation={generalInformation}
        advisorName={reportStore?.userData?.otherDocumentsData?.advisorName}
      />
      <Page>
        {reportConfig.thanksSection && (
          <ThanksSection
            advisorName={reportStore?.userData?.otherDocumentsData?.advisorName}
            generalInformation={generalInformation}
          />
        )}
        {reportConfig.background && (
          <BackgroundSection
            generalInformation={generalInformation}
            backgroundTexts={reportConfig.backgroundTexts}
          />
        )}
        {reportConfig.aboutCustomer && <AboutCustomerSection />}
        {reportConfig.aboutInvestment && <AboutInvestmentSection />}
        {reportConfig.generalInformation && (
          <GeneralInformationSection clientInformation={clientInformation} />
        )}
        {reportConfig.adviceInformation && (
          <AdviceInformationSection
            header={'report.adviceInformation.title'}
            adviceInformation={adviceInformation}
          />
        )}
        {!isPurposeAndRiskEmpty && (
          <>
            {reportConfig?.timeHorizonConfig && (
              <PurposeSection
                purposeAndRisk={purposeAndRisk}
                goals={goals}
                timeHorizonConfig={reportConfig.timeHorizonConfig}
                showGoalIcons={reportConfig?.showGoalIcons}
              />
            )}
            {analyticsComponents?.cashflow &&
              !isNil(cashflowGoals) &&
              !isEmpty(cashflowGoals) && (
                <CashflowTable cashflowGoals={cashflowGoals} />
              )}
            <RiskSection purposeAndRisk={purposeAndRisk} />
            <br />
            <AdvisorNotesSection
              text={reportStore.userData?.purposeAndRiskAdvisorNotes}
            />
            {!reportConfig.knowledgeAndExperience && (
              <KnowledgeAndExperienceSection
                knowledgeAndExperience={knowledgeAndExperience}
              />
            )}
          </>
        )}
        {!isKnowledgeAndExperienceEmpty && reportConfig.knowledgeAndExperience && (
          <>
            <KnowledgeAndExperienceSection
              knowledgeAndExperience={knowledgeAndExperience}
            />

            {advancedSuitability && (
              <AdvancedSuitabilitySection
                advancedSuitability={advancedSuitability}
              />
            )}

            <AdvisorNotesSection
              text={reportStore.userData?.knowledgeAndExperienceAdvisorNotes}
            />
          </>
        )}
        {!financialSituation.isEmpty && (
          <>
            <FinancialSituationSection
              financialSituation={financialSituation}
            />
            <AdvisorNotesSection
              text={reportStore.userData?.financialSituationAdvisorNotes}
            />
          </>
        )}
        <AdvisorNotesSection
          header={i18n(
            'report.advisory.reasoningForAdvice',
            translationsConfig
          )}
          text={reportStore.userData?.advisoryAdvisorNotes}
        />
        {analyticsComponents?.investmentPlan && (
          <InvestmentPlanSection
            goals={goals}
            chartData={reportStore.userData?.chartData}
            showGoalIcons={reportConfig?.showGoalIcons}
          />
        )}
        {isProductPlatformEnabled && <ProductPlatform />}
        {!isPortfolioEmpty && analyticsComponents?.portfolio && (
          <PortfolioSection
            goals={goals}
            analyticsComponents={analyticsComponents}
            expectedValuePlace={expectedValuePlace}
            expectationsSection={reportConfig.expectationsSection}
          />
        )}
        {reportConfig.chartsSectionsOrder.map(key =>
          chartsSectionConfig?.[key]?.display
            ? chartsSectionConfig?.[key]?.component
            : null
        )}
        {analyticsComponents?.cashflow &&
          !isNil(cashflowGoalChartsData) &&
          !isEmpty(cashflowGoalChartsData) && (
            <CashflowCharts
              cashflowForecastChartData={cashflowForecastChartData}
              cashflowGoalChartsData={cashflowGoalChartsData}
              chartWidth={CHART_WIDTH}
            />
          )}
        {analyticsComponents?.cashflow &&
          !isNil(cashflowTableData) &&
          !isEmpty(cashflowTableData) && (
            <CashflowForecastTable cashflowTableData={cashflowTableData} />
          )}
        {!isOrderSummaryEmpty && analyticsComponents?.orderSummary && (
          <OrderSummary
            orderSummaryData={orderSummaryData || []}
            orderSummaryProductAttributes={orderSummaryProductAttributes}
            accountNumber={accountNumber}
            goals={goals || []}
            isPercentColumnEnabled={isPercentColumnEnabled}
          />
        )}
        {isSustainabilitySectionVisible && (
          <Sustainability
            goals={goals}
            sustainabilityData={sustainabilityData}
          />
        )}
        {reportConfig.nextStepsSection && (
          <NextStepsSection
            advisorName={reportStore?.userData?.otherDocumentsData?.advisorName}
          />
        )}
        {reportConfig.disclamerSection && <DisclamerSection />}
        {accountNumber && (
          <Section>
            <PowOfAttornaySection accountNumber={accountNumber} />
            <TradeAuthorizationSection accountNumber={accountNumber} />
            {reportConfig.signature && (
              <SignatureSection name={generalInformation.name} />
            )}
          </Section>
        )}
        {reportConfig.attachments?.map(({ heading, sections }, index) => (
          <Attachment key={index} heading={heading} sections={sections} />
        ))}
        {reportConfig.companyVestVisible && <CompanyVest />}
        {reportConfig.finalClassification && (
          <FinalClassification
            name={generalInformation.name}
            classification={clientClassification}
          />
        )}
      </Page>
    </>
  );
};
export default Body;
