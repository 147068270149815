import {
  takeEvery,
  fork,
  put,
  race,
  take,
  delay
} from 'redux-saga/dist/redux-saga-effects-npm-proxy.esm';
import * as R from 'ramda';

import {
  types as notificationActionTypes,
  creators as notificationActionCreators
} from './actions.js';

function* autohideNotification(id, autohideMilliseconds) {
  const { hideNotification } = yield race({
    delayTimeout: delay(autohideMilliseconds),
    hideNotification: take(
      action =>
        action.type === notificationActionTypes.HIDE_NOTIFICATION &&
        action.payload.id === id
    )
  });

  if (!R.isNil(hideNotification)) {
    return;
  }

  yield put(notificationActionCreators.hideNotification(id));
}

function* onNotificationShown(action) {
  const {
    id,
    isAutohideEnabled,
    autohideMilliseconds
  } = action.payload.notification;

  if (!isAutohideEnabled) {
    return;
  }

  yield fork(autohideNotification, id, autohideMilliseconds);
}

export default function*() {
  yield takeEvery(
    notificationActionTypes.SHOW_NOTIFICATION,
    onNotificationShown
  );
}
