import React from 'react';

import { i18n } from 'i18n/index.js';
import Paragraph from '../../shared/components/paragraph';
import Heading1 from '../../shared/components/heading1';
import Section from '../../shared/components/section.js';
import { useSelector } from 'react-redux';
import sessionSelectors from '../../../shared/services/session/selectors';
import I18n from '../../../shared/components/i18n';

const FinalClassification = ({ name, classification }) => {
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  return (
    <>
      <Section>
        <Heading1>
          {i18n('report.finalClassification.header', translationsConfig)}
        </Heading1>
        <Paragraph>
          {i18n('report.finalClassification.text1', translationsConfig)}
        </Paragraph>
        <Paragraph>
          {i18n('report.finalClassification.text2', translationsConfig)}
        </Paragraph>
        <Paragraph>
          <I18n
            text={i18n('report.finalClassification.text3', translationsConfig)}
            replaceParams={{
              '{clientName}': (
                <span>
                  <b>{name}</b>
                </span>
              ),
              '{clientClassification}': (
                <span>
                  <b>{classification}</b>
                </span>
              )
            }}
          />
        </Paragraph>
        <Paragraph>
          <I18n
            text={i18n('report.finalClassification.text4', translationsConfig)}
            replaceParams={{
              '{link1}': (
                <a
                  href={i18n(
                    'report.finalClassification.link1',
                    translationsConfig
                  )}
                >
                  {i18n('report.finalClassification.link1', translationsConfig)}
                </a>
              ),
              '{link2}': (
                <a
                  href={i18n(
                    'report.finalClassification.link2',
                    translationsConfig
                  )}
                >
                  {i18n('report.finalClassification.link2', translationsConfig)}
                </a>
              )
            }}
          />
        </Paragraph>
      </Section>
    </>
  );
};

export default FinalClassification;
