import React from 'react';
import { useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router';

import { i18n } from 'i18n/index.js';
import { getStopScreenText } from 'features/shared/services/router/selectors.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import Worried from 'assets/saving-robo-icons/worried';
import { useStyles } from 'features/onboarding/stopScreen/components/default/layout';

const StopScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const stopScreenText = useSelector(getStopScreenText);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('stopScreen.naviTitle', translations)}
      </Navigation>

      <Page showProgressBar={false}>
        <div className={classes.content}>
          <Worried />

          <span className={classes.title}>
            {i18n('stopScreen.title', translations)}
          </span>
          <span className={classes.subTitle}>
            {i18n('stopScreen.subTitle', translations)}
          </span>

          <span className={classes.text}>
            {i18n(stopScreenText.text, translations)}
          </span>
          <span className={classes.text}>
            {i18n('stopScreen.text_2', translations)}
          </span>
          <span className={classes.text}>
            {i18n('stopScreen.text_3', translations)}
          </span>
        </div>

        <Footer>
          <div className={classes.buttons}>
            <Button
              className={classes.button}
              type={ButtonType.primary}
              isShowStartIcon={true}
              onClick={() => {
                switch (stopScreenText.type) {
                  case 'debtToAssetsRatio': {
                    history.push(routeTemplates.debt.build(customerId));
                    return;
                  }
                  case 'creditCardDebt': {
                    history.push(routeTemplates.left.build(customerId));
                    return;
                  }
                  default: {
                    history.push(routeTemplates.left.build(customerId));
                    return;
                  }
                }
              }}
            >
              <span className={classes.buttonText}>
                {i18n('stopScreen.correctInput', translations)}
              </span>
            </Button>

            <Button type={ButtonType.primary} onClick={() => {}}>
              <span className={classes.buttonText}>
                {i18n('stopScreen.connectWithAdvisor', translations)}
              </span>
            </Button>
          </div>
        </Footer>
      </Page>
    </Layout>
  );
};

export default StopScreen;
