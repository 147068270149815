import React from 'react';
import * as R from 'ramda';
import { StylesProvider } from '@material-ui/core/styles';
// import MuiTextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import Input from '@material-ui/core/Input';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { i18n } from 'i18n/index.js';
import { Colors } from 'features/shared/constants/colors.js';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import ErrorMessage from 'features/shared/components/errorMessage.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';

const useStyles = createUseStyles(theme => ({
  root: {
    width: '100%',

    '&.MuiInput-underline:after': {
      borderBottomColor: theme.primaryColor
    },

    '&.MuiInput-underline:hover:not(.Mui-disabled):not($invalid):before': {
      borderBottomColor: theme.primaryColor
    },

    '&$invalid.MuiInput-underline:before': {
      borderBottomColor: Colors.red50
    }
  },
  input: {
    fontFamily: theme.textFontFamily,
    fontWeight: FontWeights.normal,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    color: theme.textColor
  },
  invalid: {},
  formControl: {
    width: '212px'
  }
}));

const BaseTextInput = (
  { isInvalid, formControlClassName, ...restProps },
  ref
) => {
  const classes = useStyles();

  return (
    <FormControl
      className={classNames(classes.formControl, formControlClassName)}
    >
      <Input
        classes={{
          root: classNames(classes.root, {
            [classes.invalid]: isInvalid
          }),
          input: classes.input
        }}
        {...restProps}
        inputRef={ref}
      />
    </FormControl>
  );
};

export default React.forwardRef(BaseTextInput);
