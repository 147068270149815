import * as R from 'ramda';
import { useField as ffUseField } from 'react-final-form';

export function useField(name, config = {}) {
  return ffUseField(name, {
    format: R.identity,
    parse: R.identity,
    allowNull: true,
    ...config
  });
}
