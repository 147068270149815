import React from 'react';
import { injectSheet } from 'features/sharedModules/styles/components/styles.js';
import Snackbar from '@material-ui/core/Snackbar';
import MuiAlert from '@material-ui/lab/Alert';
import { withStyles } from '@material-ui/core';
import * as R from 'ramda';
import { connect } from 'react-redux';

import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import notificationSelectors from 'features/notification/services/selectors.js';
import { creators as notificationActionCreators } from 'features/notification/services/actions.js';

const styles = theme => {
  return {
    container: {
      position: 'fixed',
      bottom: '10px',
      zIndex: 9000,
      width: '100%',

      '& > * + *': {
        marginTop: '20px'
      }
    },
    snackbar: {
      position: 'relative !important',
      left: '0 !important',
      right: '0 !important',
      bottom: '0 !important',
      transform: 'none !important'
    }
  };
};

const CustomAlert = withStyles({
  icon: {
    alignItems: 'center'
  }
})(MuiAlert);

function Alert(props) {
  return <CustomAlert elevation={6} variant="filled" {...props} />;
}

const Notifications = ({
  notifications,
  onHideNotification,
  classes,
  translations
}) => {
  return (
    !R.isEmpty(notifications) && (
      <div className={classes.container}>
        {R.map(
          notification => (
            <Snackbar
              className={classes.snackbar}
              key={notification.id}
              open={notification.isShown}
              autoHideDuration={notification.autohideMilliseconds}
            >
              <Alert
                onClose={() => onHideNotification(notification.id)}
                severity={notification.type}
              >
                {i18n(notification.message, translations)}
              </Alert>
            </Snackbar>
          ),
          notifications
        )}
      </div>
    )
  );
};

export default R.compose(
  connect(
    state => {
      const translations = sessionSelectors.getTranslationsConfig(state);
      const notifications = notificationSelectors.getNotifications(state);

      return {
        translations,
        notifications
      };
    },
    dispatch => {
      return {
        onHideNotification: id =>
          dispatch(notificationActionCreators.hideNotification(id))
      };
    }
  ),
  injectSheet(styles)
)(Notifications);
