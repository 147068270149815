import React from 'react';
import classNames from 'classnames';
import * as R from 'ramda';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import { i18n } from 'i18n/index.js';
import SavingRoboHello from 'assets/saving-robo-icons/hello.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import Icon from 'features/shared/components/icon/index';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import SlickCarousel from 'features/shared/components/slickCarousel/index.js';
import { Colors } from 'features/shared/constants/colors.js';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';

const useStyles = createUseStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '50px',
    paddingBottom: '26px'
  },
  title: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.xLargePx,
    lineHeight: TextFontSizes.xLargeLineHeightPx,
    textAlign: 'center',
    paddingBottom: '10px',
    color: Colors.grayDark
  },
  subTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    fontWeight: FontWeights.bold,
    textAlign: 'center',
    paddingBottom: '10px',
    color: Colors.grayDark
  },
  text: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    textAlign: 'center',
    paddingBottom: '10px'
  },
  textPosition: {
    textAlign: 'center',
    paddingBottom: '50px'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '320px',
    minHeight: '280px',
    background: Colors.white,
    boxShadow:
      '0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 4px 3px ' +
      Colors.listShadowColor,
    borderRadius: '15px',
    margin: 'auto',
    height: '100%'
  },
  cardWithPadding: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '320px',
    minHeight: '280px',
    background: Colors.white,
    boxShadow:
      '0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 4px 3px ' +
      Colors.listShadowColor,
    borderRadius: '15px',
    margin: 'auto',
    height: '100%',
    padding: '20px'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  cardContentWithLargePadding: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 50px'
  },
  cardTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    textAlign: 'center',
    color: Colors.grayDark
  },
  cardTitleWithSmallPadding: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    textAlign: 'center',
    padding: '8px 0',
    color: Colors.grayDark
  },
  cardTitleWithPadding: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    textAlign: 'center',
    paddingBottom: '14px',
    color: Colors.grayDark
  },
  cardText: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  list: {
    padding: '0 10px',

    '& li': {
      listStyleType: 'none',
      display: 'flex',
      alignItems: 'center',
      height: '62px',

      '& :first-child': {
        fontFamily: theme.headerFontFamily,
        fontSize: TextFontSizes.xLargePx,
        lineHeight: TextFontSizes.xLargeLineHeightPx,
        color: theme.imagePrimaryColor,
        paddingRight: '25px'
      },

      '& :last-child': {
        fontFamily: theme.textFontFamily,
        fontSize: TextFontSizes.mediumPx,
        lineHeight: TextFontSizes.mediumLineHeightPx
      }
    }
  },
  graphLineIcon: {
    maxWidth: '320px',
    maxHeight: '120px',
    height: '100%',
    width: '100%'
  },
  divider: {
    height: '1px',
    backgroundColor: theme.placeholderColor
  },
  cardContainer: {
    padding: '14px',
    height: '100%'
  },
  areaImage: {
    '& + &': {
      marginLeft: '30px'
    }
  },
  listIndex: {
    width: '50px'
  }
}));

const HowWork = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const config = useSelector(sessionSelectors.getConfig);
  const cards = R.pathOr([], ['howWork', 'cards'], config);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('howWork.naviTitle', translations)}
      </Navigation>

      <Page>
        <div className={classes.content}>
          <SavingRoboHello />
          <span className={classes.title}>
            {i18n('howWork.title', translations)}
          </span>
          <span className={classes.subTitle}>
            {i18n('howWork.subTitle', translations)}
          </span>
          <span className={classes.text}>
            {i18n('howWork.text_1', translations)}
          </span>
          <span className={classes.text}>
            {i18n('howWork.text_2', translations)}
          </span>
        </div>

        <SlickCarousel>
          {R.includes('1', cards) && (
            <div className={classes.cardContainer}>
              <div className={classes.cardWithPadding}>
                <span className={classes.cardTitle}>
                  {i18n('howWork.card_1.title', translations)}
                </span>
                <ul className={classes.list}>
                  <li>
                    <span className={classes.listIndex}>1</span>
                    <span>{i18n('howWork.card_1.text_1', translations)}</span>
                  </li>
                  <div className={classes.divider} />
                  <li>
                    <span className={classes.listIndex}>2</span>
                    <span>{i18n('howWork.card_1.text_2', translations)}</span>
                  </li>
                  <div className={classes.divider} />
                  <li>
                    <span className={classes.listIndex}>3</span>
                    <span>{i18n('howWork.card_1.text_3', translations)}</span>
                  </li>
                </ul>
              </div>
            </div>
          )}
          {R.includes('2', cards) && (
            <div>
              <div className={classes.cardContainer}>
                <div className={classes.cardWithPadding}>
                  <span className={classes.cardTitleWithPadding}>
                    {i18n('howWork.card_2.title', translations)}
                  </span>
                  <div className={classes.cardContent}>
                    <span
                      className={classNames(
                        classes.cardText,
                        classes.textPosition
                      )}
                    >
                      {i18n('howWork.card_2.text', translations)}
                    </span>
                    <div className={classes.icons}>
                      <Icon
                        className={classes.areaImage}
                        type="vegetarianMark"
                      />
                      <Icon className={classes.areaImage} type="templeMark" />
                      <Icon
                        className={classes.areaImage}
                        type="windTurbineMark"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {R.includes('3', cards) && (
            <div>
              <div className={classes.cardContainer}>
                <div className={classes.card}>
                  <Icon type="dots" />
                  <div className={classes.cardContentWithLargePadding}>
                    <span className={classes.cardTitleWithSmallPadding}>
                      {i18n('howWork.card_3.title', translations)}
                    </span>
                    <span className={classes.text}>
                      {i18n('howWork.card_3.text', translations)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
          {R.includes('4', cards) && (
            <div>
              <div className={classes.cardContainer}>
                <div className={classes.card}>
                  <Icon className={classes.graphLineIcon} type="graphLine" />
                  <div className={classes.cardContentWithLargePadding}>
                    <span className={classes.cardTitleWithPadding}>
                      {i18n('howWork.card_4.title', translations)}
                    </span>
                    <span className={classes.text}>
                      {i18n('howWork.card_4.text', translations)}
                    </span>
                  </div>
                </div>
              </div>
            </div>
          )}
        </SlickCarousel>

        <Footer>
          {nextRoute && (
            <Button
              type={ButtonType.primary}
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n('shared.understand', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default HowWork;
