import React from 'react';
import * as R from 'ramda';
import { useDispatch, useStore } from 'react-redux';
import { useParams } from 'react-router';
import axios from 'axios';

import { throwSafeError } from 'features/shared/utils/throwSafeError.js';
import { creators as notificationActionCreators } from 'features/notification/services/actions.js';
import { NotificationTypes } from 'features/notification/constants/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import {
  metricsQueue,
  createSessionCompletedEvent
} from 'features/sharedModules/metrics/services/index.js';
import userOptionsSelectors, {
  getInvestmentFallPercentageByRisk
} from 'features/shared/services/userOptions/selectors.js';
import { form as leaveDetailsForm } from 'features/counseling/leaveDetails/services/form.js';
import { usePageStore } from 'features/counseling/successScreen/services/pageStore.js';
import { createPdfReport } from 'features/counseling/successScreen/api.js';
import { mapClientUserDataToReport } from '../services/mapping.js';
import { saveFile } from '../utils.js';
import { usePortfolioChartStore } from '../services/portfolioChart';
import { useExpectedPathChartStore } from '../services/expectedPathChart';
import { useExpectedValueChartStore } from '../services/expectedValueChart';
import { useReadExpectedPathChartData } from './useReadExpectedPathChartData';
import { useReadExpectedValueChartData } from './useReadExpectedValueChartData';
import { getExpectedChartGoalsData } from 'features/counseling/successScreen/utils.js';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';

export const useDownloadPdfReport = goals => {
  const dispatch = useDispatch();
  const { customerId } = useParams();

  const reduxStore = useStore();

  const pageStore = usePageStore();

  const portfolioChartStore = usePortfolioChartStore();
  const {
    expectedValue: { bankReturn }
  } = useCustomerConfig();

  const cancelTokenSourceRef = React.useRef(null);
  const readExpectedPathChartData = useReadExpectedPathChartData();
  const readExpectedValueChartData = useReadExpectedValueChartData();

  const downloadPdfReport = async () => {
    metricsQueue.enqueue(createSessionCompletedEvent(customerId));

    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      pageStore.setIsReadPdfReportPending(true);

      const { values: leaveDetailsFormValues } = leaveDetailsForm.getState();
      const reduxState = reduxStore.getState();

      const financialSituationConfig =
        sessionSelectors.getFinancialSituationConfig(reduxState);
      const reactsConfig = sessionSelectors.getReactsConfig(reduxState);
      const lossesConfig = sessionSelectors.getLossesConfig(reduxState);
      const investmentsKnowledgeConfig =
        sessionSelectors.getInvestmentsKnowledgeConfig(reduxState);
      const tradingConfig = sessionSelectors.getTradingConfig(reduxState);
      const horizonsConfig = sessionSelectors.getHorizonsConfig(reduxState);
      const translationsConfig =
        sessionSelectors.getTranslationsConfig(reduxState);
      const reportConfig = sessionSelectors.getReportConfig(reduxState);
      const cultureCode = sessionSelectors.getCultureCode(reduxState);
      const userOptions = userOptionsSelectors.getUserOptions(reduxState);
      const maxRiskScore = sessionSelectors.getMaxRiskScore(reduxState);
      const percentByRisk = getInvestmentFallPercentageByRisk(reduxState);

      const chartData = portfolioChartStore.chartData;
      const expectedChartGoalsData = getExpectedChartGoalsData(
        goals,
        horizonsConfig
      );

      await Promise.all([
        readExpectedPathChartData(expectedChartGoalsData),
        readExpectedValueChartData(expectedChartGoalsData)
      ]);

      useExpectedPathChartStore.getState().setChartData();
      const { chartData: expectedPathChartData } =
        useExpectedPathChartStore.getState();

      useExpectedValueChartStore.getState().setChartData();
      const { chartData: expectedValueChartData } =
        useExpectedValueChartStore.getState();

      const sourceParams = mapClientUserDataToReport(
        {
          financialSituationConfig,
          reactsConfig,
          lossesConfig,
          investmentsKnowledgeConfig,
          tradingConfig,
          horizonsConfig,
          translationsConfig,
          reportConfig
        },
        cultureCode,
        leaveDetailsFormValues,
        userOptions,
        maxRiskScore,
        percentByRisk,
        chartData,
        goals,
        expectedPathChartData,
        expectedValueChartData,
        bankReturn
      );

      const accessToken = await getQAuthAccessToken(
        customerId,
        cancelTokenSource.token
      );

      const response = await createPdfReport(
        accessToken,
        cancelTokenSource.token,
        customerId,
        sourceParams
      );

      saveFile(
        new Blob([response.data], { type: 'application/pdf; charset=utf-8' }),
        'Report.pdf'
      );

      pageStore.setIsReadPdfReportPending(false);
    } catch (error) {
      if (!axios.isCancel(error)) {
        pageStore.setIsReadPdfReportPending(false);

        dispatch(
          notificationActionCreators.showNotification({
            message: 'shared.getPdfReportErrorMessage',
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    }
  };

  return downloadPdfReport;
};
