import React from 'react';
import * as R from 'ramda';
import 'react-phone-number-input/style.css';
import PhoneInput from 'react-phone-number-input';
import {
  getCountries,
  getCountryCallingCode
} from 'react-phone-number-input/input';
import en from 'react-phone-number-input/locale/en.json';
import { useSelector } from 'react-redux';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import Input from '@material-ui/core/Input';
import classNames from 'classnames';
import { StylesProvider } from '@material-ui/core/styles';

import { Colors } from 'features/shared/constants/colors.js';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import { i18n } from 'i18n/index.js';
import ErrorMessage from 'features/shared/components/errorMessage.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { useField } from './useField.js';
import BaseTextInput from './baseTextInpit.js';

export const Context = React.createContext({});

const indexedReduce = R.addIndex(R.reduce);

const useBaseTextInputStyles = createUseStyles({
  formControl: {
    width: '163px'
  }
});

const BaseTextInputAdapter = React.forwardRef((props, ref) => {
  const { isInvalid } = React.useContext(Context);
  const classes = useBaseTextInputStyles();

  return (
    <BaseTextInput
      {...props}
      formControlClassName={classes.formControl}
      isInvalid={isInvalid}
      ref={ref}
    />
  );
});

const useSelectStyles = createUseStyles(theme => ({
  select: {
    '&:focus': {
      backgroundColor: 'rgba(0, 0, 0, 0) !important'
    }
  },
  selectValue: {
    paddingLeft: '1px'
  },
  menuItemRoot: {
    fontFamily: theme.textFontFamily,
    fontWeight: FontWeights.normal,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    color: theme.textColor
  },
  inputRoot: {
    '&.MuiInput-underline:after': {
      borderBottomColor: theme.primaryColor
    },

    '&.MuiInput-underline:hover:not(.Mui-disabled):not($invalid):before': {
      borderBottomColor: theme.primaryColor
    },

    '&$invalid.MuiInput-underline:before': {
      borderBottomColor: Colors.red50
    }
  },
  invalid: {}
}));

const CountrySelect = ({
  options,
  iconComponent: Icon,
  value,
  onChange,
  ...restProps
}) => {
  const { isInvalid } = React.useContext(Context);
  const classes = useSelectStyles();
  const handleChange = event => {
    const value = event.target.value;
    onChange(value === 'ZZ' ? undefined : value);
  };

  return (
    <Select
      classes={{
        select: classes.select
      }}
      input={
        <Input
          classes={{
            root: classNames(classes.inputRoot, {
              [classes.invalid]: isInvalid
            })
          }}
        />
      }
      renderValue={value => (
        <div className={classes.selectValue}>
          <Icon country={value === 'ZZ' ? undefined : value} label={''} />
        </div>
      )}
      value={value || 'ZZ'}
      onChange={handleChange}
      {...restProps}
    >
      {R.map(
        o => (
          <MenuItem
            classes={{ root: classes.menuItemRoot }}
            key={o.value || 'ZZ'}
            value={o.value || 'ZZ'}
          >
            {o.label}
          </MenuItem>
        ),
        options
      )}
    </Select>
  );
};

const useStyles = createUseStyles({
  '@global': {
    '.PhoneInputCountryIcon--border': {
      backgroundColor: 'rgba(0, 0, 0, 0.1)',
      boxShadow:
        'rgba(0, 0, 0, 0.5) 0px 0px 0px 1px, rgba(0, 0, 0, 0.5) 0px 0px 0px 1px inset'
    }
  },
  phoneNumber: {
    alignItems: 'stretch'
  },
  formControl: {
    width: '163px'
  }
});

const PhoneNumberInput = ({ name, fieldConfig, placeholder }) => {
  const classes = useStyles();
  const {
    input: { value, onChange, onFocus, onBlur },
    meta: { error, touched }
  } = useField(name, fieldConfig);

  const labels = React.useMemo(
    () =>
      R.reduce(
        (acc, c) => R.assoc(c, `${en[c]} +${getCountryCallingCode(c)}`, acc),
        {
          ZZ: en['ZZ']
        },
        getCountries()
      ),
    []
  );

  const translations = useSelector(sessionSelectors.getTranslationsConfig);

  const isErrorShown = touched && !R.isNil(error);

  let errorText = null;
  if (isErrorShown) {
    errorText = R.is(String, error)
      ? i18n(error, translations)
      : indexedReduce(
          (acc, arg, index) => acc.replace(`{${index}}`, arg),
          i18n(error.text, translations),
          error.args
        );
  }

  return (
    <Context.Provider value={{ isInvalid: isErrorShown }}>
      <StylesProvider injectFirst>
        <div>
          <PhoneInput
            className={classes.phoneNumber}
            international
            value={value}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            labels={labels}
            placeholder={placeholder}
            inputComponent={BaseTextInputAdapter}
            countrySelectComponent={CountrySelect}
            defaultCountry={'NO'}
          />
          {isErrorShown && <ErrorMessage>{`* ${errorText}`}</ErrorMessage>}
        </div>
      </StylesProvider>
    </Context.Provider>
  );
};

export default PhoneNumberInput;
