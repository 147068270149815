import React from 'react';
import * as R from 'ramda';
import { useSelector, useStore } from 'react-redux';
import { useParams } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import MuiIcon from '@material-ui/core/Icon';

import { i18n } from 'i18n/index.js';
import SavingRoboCelebrate from 'assets/saving-robo-icons/celebrate.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import Icon from 'features/shared/components/icon/index';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import SlickCarousel from 'features/shared/components/slickCarousel/index.js';
import { Colors } from 'features/shared/constants/colors.js';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import { usePageStore } from 'features/counseling/successScreen/services/pageStore.js';
import { usePageStore as useSavingsPlanPageStore } from 'features/counseling/savingsPlan/services/pageStore';
import { useReadPortfolioChart } from './useReadPortfolioChart.js';
import { useDownloadPdfReport } from './useDownloadPdfReport';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import { mapUserDataToGoals } from '../services/mapping.js';

const useStyles = createUseStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    paddingTop: '50px',
    paddingBottom: '26px'
  },
  title: {
    fontFamily: theme.headerFontFamily,
    fontSize: TextFontSizes.xLargePx,
    lineHeight: TextFontSizes.xLargeLineHeightPx,
    textAlign: 'center',
    padding: '20px 0 10px 0',
    color: Colors.grayDark
  },
  subTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    fontWeight: FontWeights.semiBold,
    textAlign: 'center',
    paddingBottom: '10px'
  },
  text: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    textAlign: 'center'
  },
  cardTextWithBackground: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    textAlign: 'center',
    backgroundColor: 'white',
    zIndex: '1',
    marginBottom: '20px'
  },
  cardText: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    textAlign: 'center',
    marginBottom: '20px'
  },
  textPosition: {
    textAlign: 'center',
    paddingBottom: '50px'
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '320px',
    minHeight: '250px',
    background: Colors.white,
    boxShadow:
      '0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 4px 3px ' +
      Colors.listShadowColor,
    borderRadius: '15px',
    margin: 'auto',
    height: '100%',
    position: 'relative'
  },
  cardWithPadding: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    maxWidth: '320px',
    minHeight: '280px',
    background: Colors.white,
    boxShadow:
      '0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 10px 10px ' +
      Colors.listShadowColor +
      ', 0px 4px 3px ' +
      Colors.listShadowColor,
    borderRadius: '15px',
    margin: 'auto',
    height: '100%',
    padding: '20px'
  },
  cardContent: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  cardContentWithLargePadding: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 50px'
  },
  cardTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    textAlign: 'center'
  },
  cardTitleWithSmallPadding: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    textAlign: 'center',
    margin: '20px 0'
  },
  cardTitleWithPadding: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    textAlign: 'center',
    margin: '20px 0',
    backgroundColor: 'white',
    zIndex: '1'
  },
  icons: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%'
  },
  list: {
    padding: '0 10px',
    '& li': {
      listStyleType: 'none',
      display: 'flex',
      alignItems: 'center',
      height: '62px',
      '& :first-child': {
        fontFamily: theme.headerFontFamily,
        fontSize: TextFontSizes.xLargePx,
        lineHeight: TextFontSizes.xLargeLineHeightPx,
        color: theme.primaryColor,
        paddingRight: '25px'
      },
      '& :last-child': {
        fontFamily: theme.textFontFamily,
        fontSize: TextFontSizes.mediumPx,
        lineHeight: TextFontSizes.mediumLineHeightPx
      }
    }
  },
  portfolioIcon: {
    height: 'auto',
    width: '100%'
  },
  graphLineIcon: {
    position: 'absolute',
    maxWidth: '320px',
    height: '100%',
    width: '100%'
  },
  cardContainer: {
    padding: '14px',
    height: '100%'
  },
  textWithBackground: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    textAlign: 'center',
    backgroundColor: 'white',
    zIndex: '1'
  },
  cardContent_1: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 20px'
  },
  cardContent_2: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '0 50px',
    justifyContent: 'center',
    height: '100%'
  },
  pdfIconContainer: {
    width: '24px',
    height: '24px'
  },
  pdfIcon: {
    fontSize: '24px !important'
  }
}));

const SuccessScreen = () => {
  const classes = useStyles();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const config = useSelector(sessionSelectors.getConfig);

  const pageStore = usePageStore();

  const reduxState = useStore().getState();
  const userOptions = userOptionsSelectors.getUserOptions(reduxState);
  const { expectedValue } = useSavingsPlanPageStore();

  const goals = React.useMemo(
    () => mapUserDataToGoals({ userOptions, expectedValue }),
    [userOptions, expectedValue]
  );

  const readPortfolioChart = useReadPortfolioChart();
  const downloadPdfReport = useDownloadPdfReport(goals);

  React.useEffect(() => {
    readPortfolioChart(goals[0]);
  }, []);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId} />

      <Page>
        <div className={classes.content}>
          <SavingRoboCelebrate />

          <span className={classes.title}>
            {i18n('counseling.successScreen.title', translations)}
          </span>
          {R.propOr([], 'texts', config.successScreen).map((t, index) => (
            <span key={index} className={classes.text}>
              {i18n(t, translations)}
            </span>
          ))}
        </div>

        <SlickCarousel>
          <div>
            <div className={classes.cardContainer}>
              <div className={classes.card}>
                <Icon className={classes.portfolioIcon} type="dots2" />
                <div className={classes.cardContent_1}>
                  <span className={classes.cardTitleWithSmallPadding}>
                    {i18n(
                      'counseling.successScreen.card_1.title',
                      translations
                    )}
                  </span>
                  <span className={classes.cardText}>
                    {i18n('counseling.successScreen.card_1.text', translations)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={classes.cardContainer}>
              <div className={classes.card}>
                <Icon className={classes.graphLineIcon} type="graphLine2" />
                <div className={classes.cardContent_2}>
                  <span className={classes.cardTitleWithPadding}>
                    {i18n(
                      'counseling.successScreen.card_2.title',
                      translations
                    )}
                  </span>
                  <span className={classes.cardTextWithBackground}>
                    {i18n('counseling.successScreen.card_2.text', translations)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </SlickCarousel>

        <Footer>
          <Button
            disabled={
              pageStore.isReadPdfReportPending ||
              pageStore.isReadPortfolioChartPending
            }
            type={ButtonType.primary}
            onClick={() => {
              downloadPdfReport();
            }}
            endIconClass={classes.pdfIconContainer}
            endIcon={
              <MuiIcon className={classes.pdfIcon}>picture_as_pdf</MuiIcon>
            }
          >
            {i18n('counseling.successScreen.seeMySavingsPlan', translations)}
          </Button>
        </Footer>
      </Page>
    </Layout>
  );
};

export default SuccessScreen;
