import {
  CashflowForecastChart,
  CashflowGoalChart
} from '@quantfoliorepo/ui-components';
import { useSelector } from 'react-redux';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { useTheme } from 'features/sharedModules/styles/components/styles.js';
import Section from '../../shared/components/section.js';
import Heading1 from '../../shared/components/heading1';
import Card from 'features/report/shared/components/card';
import { i18n } from 'i18n/index.js';

const useStyles = createUseStyles({
  portfolioGrid: {
    display: 'grid',
    gridTemplateColumns: 'repeat(2, 1fr);',
    rowGap: 20
  },
  card: {
    padding: '20px 0px'
  }
});

const CashflowCharts = ({
  cashflowForecastChartData,
  cashflowGoalChartsData,
  chartWidth
}) => {
  const classes = useStyles();
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const theme = useTheme();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  return (
    <Section data-testid={'cashflow-charts-section'}>
      <Heading1>{i18n('report.cashflow.header', translationsConfig)}</Heading1>
      <Card className={classes.card}>
        <div className={classes.portfolioGrid}>
          {cashflowGoalChartsData.length &&
            cashflowGoalChartsData.map((data, index) => (
              <CashflowGoalChart
                key={index}
                chartData={data.chartData}
                cultureCode={cultureCode}
                title={data.goalName}
                iconName={data.goalIcon}
                color={theme.chartPortfolioColors[index]}
                chartSize="180"
                chartTitleSize="16px"
              />
            ))}
        </div>

        {cashflowForecastChartData && (
          <CashflowForecastChart
            chartData={cashflowForecastChartData}
            cultureCode={cultureCode}
            width={chartWidth}
          />
        )}
      </Card>
    </Section>
  );
};

export default CashflowCharts;
