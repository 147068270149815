import React, { Fragment, useEffect } from 'react';
import * as R from 'ramda';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import { i18n } from 'i18n/index.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Navigation from 'features/shared/components/screenLayout/navigation';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import ProgressBar from 'features/shared/components/progressBar/index';
import Button, { ButtonType } from 'features/shared/components/button/index';
import { usePageStore } from 'features/counseling/portfolioFunds/services/pageStore.js';
import { usePortfolioItems } from '../services/selectors.js';
import PieChart from './pieChart.js';
import PortfolioItemsList from './portfolioItemsList.js';
import { useReadPortfolios } from './useReadPortfolios';

const useStyles = createUseStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%',
    paddingTop: '25px',
    paddingBottom: '11px'
  },
  subTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largePx,
    color: Colors.grayDark,
    paddingBottom: '20px'
  },
  chartTitle: {
    fontFamily: theme.headerFontFamily,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    fontWeight: FontWeights.bold,
    paddingBottom: '20px',
    color: Colors.grayDark
  },
  contentContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: '100%'
  }
}));

const PortfolioFunds = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const pageStore = usePageStore();
  const portfolioItems = usePortfolioItems({
    stores: { usePageStore },
    hooks: { useMemo: React.useMemo }
  });

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);

  const readPortfolios = useReadPortfolios();

  useEffect(() => {
    readPortfolios();
  }, []);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('counseling.portfolioFunds.naviTitle', translations)}
      </Navigation>

      <Page>
        {pageStore.isReadPortfolioPending && <ProgressBar />}
        {!pageStore.isReadPortfolioPending && (
          <Fragment>
            <div className={classes.content}>
              <span className={classes.subTitle}>
                {i18n('counseling.portfolioFunds.subTitle', translations)}
              </span>

              <div className={classes.contentContainer}>
                <PieChart data={portfolioItems} />
                <span className={classes.chartTitle}>
                  {i18n(
                    'counseling.portfolioFunds.yourPortfolio',
                    translations
                  )}
                </span>
                <PortfolioItemsList options={portfolioItems} />
              </div>
            </div>

            <Footer>
              {nextRoute && (
                <Button
                  type={ButtonType.primary}
                  onClick={() => {
                    history.push(nextRoute.build(customerId));
                  }}
                >
                  {i18n('shared.continue', translations)}
                </Button>
              )}
            </Footer>
          </Fragment>
        )}
      </Page>
    </Layout>
  );
};

export default PortfolioFunds;
