import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

export const useStyles = createUseStyles({
  footer: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    paddingTop: '50px'
  }
});

const Footer = ({ children }) => {
  const classes = useStyles();

  return <div className={classes.footer}>{children}</div>;
};

export default Footer;
