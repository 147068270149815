import * as R from 'ramda';
import t from 'tcomb';

import {
  startedType,
  succeedType,
  failedType,
  cancelledType
} from 'features/shared/utils/actions.js';

export const Status = t.interface(
  {
    isPending: t.Boolean,
    isFailed: t.Boolean,
    payload: t.Any
  },
  'Status'
);

const createReducer = config => {
  const startedActionTypesIndex = R.reduce(
    (acc, key) =>
      R.isNil(config[key].started)
        ? acc
        : R.assoc(config[key].started, key, acc),
    {},
    R.keys(config)
  );
  const succeedActionTypesIndex = R.reduce(
    (acc, key) =>
      R.isNil(config[key].succeed)
        ? acc
        : R.assoc(config[key].succeed, key, acc),
    {},
    R.keys(config)
  );
  const failedActionTypesIndex = R.reduce(
    (acc, key) =>
      R.isNil(config[key].failed) ? acc : R.assoc(config[key].failed, key, acc),
    {},
    R.keys(config)
  );
  const cancelledActionTypesIndex = R.reduce(
    (acc, key) =>
      R.isNil(config[key].cancelled)
        ? acc
        : R.assoc(config[key].cancelled, key, acc),
    {},
    R.keys(config)
  );

  return (state = {}, action) => {
    const startedKey = startedActionTypesIndex[action.type];
    if (!R.isNil(startedKey)) {
      return R.assoc(
        startedKey,
        {
          isPending: true,
          isFailed: false
        },
        state
      );
    }

    const succeedKey = succeedActionTypesIndex[action.type];
    if (!R.isNil(succeedKey)) {
      return R.assoc(
        succeedKey,
        {
          isPending: false,
          isFailed: false,
          payload: action.payload
        },
        state
      );
    }

    const failedKey = failedActionTypesIndex[action.type];
    if (!R.isNil(failedKey)) {
      return R.assoc(
        failedKey,
        {
          isPending: false,
          isFailed: true,
          payload: action.payload
        },
        state
      );
    }

    const cancelledKey = cancelledActionTypesIndex[action.type];
    if (!R.isNil(cancelledKey)) {
      return R.assoc(
        cancelledKey,
        {
          isPending: false,
          isFailed: false,
          payload: action.payload
        },
        state
      );
    }

    return state;
  };
};

export default createReducer;

export const getStatus = baseActionName => ({
  started: startedType(baseActionName),
  succeed: succeedType(baseActionName),
  failed: failedType(baseActionName),
  cancelled: cancelledType(baseActionName)
});
