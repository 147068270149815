import { useSelector } from 'react-redux';

import {
  Goal,
  OrderSummaryData,
  ProductAttribute
} from 'features/report/shared/services/reportStore';
import sessionSelectors from '../../../../shared/services/session/selectors';
import Heading1 from '../../../shared/components/heading1';
import Paragraph from '../../../shared/components/paragraph';
import { i18n } from 'i18n';
import {
  Table,
  TableBody,
  TableBodyRow,
  TableBodyCell
} from 'features/report/shared/components/table';
import OneTimeOrders from './oneTimeOrders';
import MonthlyOrders from './monthlyOrders';
import useStyles from './styles';
import Section from 'features/report/shared/components/section';

type Props = {
  orderSummaryData: OrderSummaryData[];
  orderSummaryProductAttributes?: ProductAttribute[];
  accountNumber?: string;
  goals: Goal[];
  isPercentColumnEnabled?: boolean;
};

const OrderSummary = ({
  orderSummaryData,
  orderSummaryProductAttributes,
  accountNumber,
  goals,
  isPercentColumnEnabled
}: Props) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const bankAccountTranslation = i18n(
    'report.orderSummary.bankAccountNumberForOrder',
    translationsConfig
  );

  const goalsTotalFirstDeposit = goals.reduce(
    (acc, current) => acc + (current.data.firstDeposit || 0),
    0
  );
  const goalsTotalMonthlyDeposit = goals.reduce(
    (acc, current) => acc + (current.data.monthlyDeposit || 0),
    0
  );

  return (
    <Section>
      <Heading1>
        {i18n('report.orderSummary.header', translationsConfig)}
      </Heading1>
      <Paragraph className={classes.spaceBelow}>
        {i18n('report.orderSummary.paragraph', translationsConfig)}
      </Paragraph>

      {bankAccountTranslation && (
        <Table className={classes.spaceBelow}>
          <TableBody>
            <TableBodyRow>
              <TableBodyCell className={classes.backAccountCell}>
                {bankAccountTranslation}
              </TableBodyCell>
              <TableBodyCell className={classes.backAccountCell}>
                {accountNumber || '-'}
              </TableBodyCell>
            </TableBodyRow>
          </TableBody>
        </Table>
      )}

      {!!goalsTotalFirstDeposit && (
        <OneTimeOrders
          orderSummaryData={orderSummaryData}
          orderSummaryProductAttributes={orderSummaryProductAttributes}
          isPercentColumnEnabled={isPercentColumnEnabled}
        />
      )}

      {!!goalsTotalMonthlyDeposit && (
        <MonthlyOrders
          orderSummaryData={orderSummaryData}
          orderSummaryProductAttributes={orderSummaryProductAttributes}
          isPercentColumnEnabled={isPercentColumnEnabled}
        />
      )}
    </Section>
  );
};

export default OrderSummary;
