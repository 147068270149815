import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import * as R from 'ramda';
import axios from 'axios';

import { i18n } from 'i18n/index.js';
import config from 'config/index.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Navigation from 'features/shared/components/screenLayout/navigation';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Header from 'features/shared/components/screenLayout/header.js';
import ProgressBar from 'features/shared/components/progressBar/index';
import { getQAuthAccessToken } from 'features/shared/api/index.js';
import { form as leaveDetailsForm } from 'features/counseling/leaveDetails/services/form.js';
import { usePageStore } from 'features/counseling/signing/services/pageStore.js';
import {
  createSignicatSession,
  getSignicatSession
} from 'features/counseling/signing/api.js';

const useStyles = createUseStyles(theme => ({
  page: {
    paddingLeft: '0',
    paddingRight: '0'
  },
  header: {
    paddingLeft: '24px',
    paddingRight: '24px'
  },
  iframe: {
    width: '100%',
    height: '440px',
    border: 'none'
  }
}));

const Signing = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const authConfig = useSelector(sessionSelectors.getAuthConfig);

  const pageStore = usePageStore();

  const createSessionCancelTokenSourceRef = React.useRef(null);
  const createSession = async () => {
    if (!R.isNil(createSessionCancelTokenSourceRef.current)) {
      createSessionCancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    createSessionCancelTokenSourceRef.current = cancelTokenSource;

    try {
      pageStore.setSession(null);
      pageStore.setCreateSessionStarted();

      const accessToken = await getQAuthAccessToken(
        customerId,
        cancelTokenSource.token
      );

      const response = await createSignicatSession(
        accessToken,
        cancelTokenSource.token,
        {
          allowedProviders: authConfig.providers,
          language: 'en'
        }
      );
      const { id, url } = response.data;

      pageStore.setSession({
        id,
        url
      });
      pageStore.setCreateSessionSucceed();
    } catch (error) {
      if (!axios.isCancel(error)) {
        pageStore.setCreateSessionFailed();
      }
    }
  };

  const readSessionCancelTokenSourceRef = React.useRef(null);
  const readSession = async sessionId => {
    if (!R.isNil(readSessionCancelTokenSourceRef.current)) {
      readSessionCancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    readSessionCancelTokenSourceRef.current = cancelTokenSource;

    try {
      const accessToken = await getQAuthAccessToken(
        customerId,
        cancelTokenSource.token
      );

      const response = await getSignicatSession(
        accessToken,
        cancelTokenSource.token,
        sessionId
      );
      const { identity } = response.data;

      pageStore.setIdentity(identity);
      leaveDetailsForm.initialize({
        name: R.prop('fullName', identity),
        email: R.prop('email', identity),
        phoneNumber: R.prop('phoneNumber', identity)
      });
      pageStore.setCompleteSessionSucceed();
      history.push(nextRoute.build(customerId));
    } catch (error) {
      if (!axios.isCancel(error)) {
        pageStore.setCompleteSessionFailed();
        createSession();
      }
    }
  };

  React.useEffect(() => {
    const onMessage = e => {
      if (e.origin !== config.REACT_APP_SIGNICAT_ORIGIN) {
        return;
      }

      const data = JSON.parse(e.data);
      const pageStoreState = usePageStore.getState();

      if (data.sessionId !== pageStoreState.session.id) {
        return;
      }

      if (data.status === 'success') {
        readSession(data.sessionId);
      }

      if (data.status === 'aborted') {
        pageStore.setCompleteSessionFailed();
        createSession();
      }

      if (data.status === 'error') {
        pageStore.setCompleteSessionFailed();
        createSession();
      }
    };

    window.addEventListener('message', onMessage);

    pageStore.resetCompleteSessionStatus();
    createSession();

    return () => {
      window.removeEventListener('message', onMessage);
    };
  }, []);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('counseling.signing.naviTitle', translations)}
      </Navigation>

      <Page className={classes.page}>
        {pageStore.createSessionStatus.pending && <ProgressBar />}
        {!pageStore.createSessionStatus.pending &&
          pageStore.createSessionStatus.failed && (
            <Header className={classes.header}>
              {i18n('counseling.signing.createSessionErrorTitle', translations)}
            </Header>
          )}
        {!pageStore.createSessionStatus.pending &&
          !pageStore.createSessionStatus.failed &&
          !R.isNil(R.prop('url', pageStore.session)) && (
            <Fragment>
              <Header className={classes.header}>
                {pageStore.completeSessionStatus.failed
                  ? i18n(
                      'counseling.signing.completeSessionErrorTitle',
                      translations
                    )
                  : i18n('counseling.signing.initialTitle', translations)}
              </Header>
              <iframe src={pageStore.session.url} className={classes.iframe} />
              <div />
            </Fragment>
          )}
      </Page>
    </Layout>
  );
};

export default Signing;
