import React from 'react';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { isNil } from 'ramda';

import { useI18n } from 'modules/settings/components/settings.js';
import { createUseStyles, useTheme } from 'modules/styles/components/styles.js';
import { FontFamilies, TextFontSizes } from 'modules/shared/constants/fonts.js';
import { formatNumber } from 'modules/shared/utils/number.js';
import { hexOpacity } from 'modules/shared/constants/colors';
import Legend from 'modules/shared/components/legend';
import { useSetHighchartsLocaleMonths } from 'modules/shared/utils/months';

HighchartsMore(Highcharts);

export const useStyles = createUseStyles({
  tooltipHeader: {
    fontFamily: FontFamilies.roboto,
    fontSize: TextFontSizes.xxSmall,
    lineHeight: TextFontSizes.xxSmallLineHeight
  },
  tooltipFirstLine: {
    fontFamily: FontFamilies.roboto,
    fontSize: TextFontSizes.xSmall,
    lineHeight: TextFontSizes.xSmallLineHeight,
    marginTop: 4
  },
  tooltipLastLine: {
    fontFamily: FontFamilies.roboto,
    fontSize: TextFontSizes.xSmall,
    lineHeight: TextFontSizes.xSmallLineHeight,
    marginTop: 2
  }
});

const ExpectedValueChart = ({
  cultureCode,
  chartData,
  isInteractive,
  onLoad,
  width,
  yMinValue = 0,
  customColors = null,
  savingPlanOnly = false,
  bankReturn = 0.01
}) => {
  const i18n = useI18n();
  const classes = useStyles();
  const theme = useTheme();
  const chartComponent = React.useRef();
  useSetHighchartsLocaleMonths();

  const savingPlanChartData = chartData?.savingPlan;
  const savingInBankAccountChartData = chartData?.savingInBankAccount;
  const colors = {
    savingPlanColor:
      customColors?.savingPlanColor || theme.chartPortfolioColors[0],
    savingInBankAccountColor:
      customColors?.savingInBankAccountColor || theme.chartPortfolioColors[1],
    labelsColor: customColors?.labelsColor || theme.secondaryColor
  };

  const series = React.useMemo(
    () => [
      {
        id: 'savingPlan',
        type: 'areaspline',
        fillColor: `${colors.savingPlanColor}${hexOpacity[60]}`,
        lineColor: colors.savingPlanColor,
        lineWidth: 3,
        states: {
          hover: {
            lineWidthPlus: 0
          },
          select: {
            lineWidthPlus: 0
          }
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
              radiusPlus: 0
            }
          }
        },
        data: savingPlanChartData
      },
      {
        id: 'savingInBankAccount',
        type: 'areaspline',
        fillColor: `${colors.savingInBankAccountColor}${hexOpacity[60]}`,
        lineColor: colors.savingInBankAccountColor,
        lineWidth: 3,
        data: savingInBankAccountChartData,
        states: {
          hover: {
            lineWidthPlus: 0
          },
          select: {
            lineWidthPlus: 0
          }
        },
        marker: {
          enabled: false,
          states: {
            hover: {
              enabled: false,
              radiusPlus: 0
            }
          }
        }
      }
    ],
    [
      colors.savingPlanColor,
      colors.savingInBankAccountColor,
      savingPlanChartData,
      savingInBankAccountChartData
    ]
  );

  React.useEffect(() => {
    const reflow = () => {
      chartComponent.current.chart.reflow();
    };

    window.addEventListener('resize', reflow);

    reflow();

    return () => {
      window.removeEventListener('resize', reflow);
    };
  }, []);

  const chartOptions = {
    credits: {
      enabled: false
    },
    legend: {
      enabled: false
    },
    exporting: {
      enabled: false
    },
    chart: {
      backgroundColor: 'transparent',
      marginTop: 20,
      marginRight: 0,
      animation: false,
      events: {
        load: onLoad
      }
    },
    title: {
      text: null
    },
    xAxis: {
      crosshair: {
        color: colors.labelsColor,
        zIndex: 5
      },
      labels: {
        format: '{value: %Y}',
        style: {
          color: colors.labelsColor,
          fontSize: TextFontSizes.xxSmall,
          lineHeight: TextFontSizes.xxSmallLineHeight,
          fontFamily: FontFamilies.roboto
        },
        y: 35
      },
      lineWidth: 0,
      maxPadding: 0,
      minPadding: 0,
      tickWidth: 0,
      type: 'datetime'
    },
    yAxis: {
      crosshair: {
        color: colors.labelsColor,
        zIndex: 5
      },
      gridLineColor: `${colors.labelsColor}${hexOpacity[50]}`,
      labels: {
        formatter: ({ value }) => formatNumber(cultureCode, value, 0, 0),
        style: {
          color: colors.labelsColor,
          fontSize: TextFontSizes.xxSmall,
          lineHeight: TextFontSizes.xxSmallLineHeight,
          fontFamily: FontFamilies.roboto
        },
        x: -32
      },
      min: yMinValue,
      tickAmount: 6,
      title: {
        enabled: false
      }
    },
    tooltip: {
      enabled: isInteractive,
      xDateFormat: '%b %Y',
      useHTML: true,
      headerFormat: `<span class='${classes.tooltipHeader}'>{point.key}</span><br>`,
      pointFormatter: function () {
        const totalValue = formatNumber(cultureCode, this.y, 0, 2);
        let tooltipContent = `<div class='${classes.tooltipFirstLine}'>${totalValue}</div>`;
        if (!savingPlanOnly) {
          const accReturnValue = `+ ${formatNumber(
            cultureCode,
            this.custom?.accReturn,
            0,
            2
          )}`;
          tooltipContent += `<div class='${classes.tooltipLastLine}'>${accReturnValue}</div>`;
        }

        return tooltipContent;
      }
    },
    plotOptions: {
      series: {
        enableMouseTracking: isInteractive,
        animation: false,
        states: {
          inactive: {
            opacity: 1
          }
        }
      }
    },
    series: savingPlanOnly ? [series[0]] : series
  };

  const legendItems = savingPlanOnly
    ? [
        {
          title: i18n('expectedValueChart.default.yourSavingPlan'),
          style: { backgroundColor: colors.savingPlanColor }
        }
      ]
    : [
        {
          title: i18n('expectedValueChart.default.yourSavingPlan'),
          style: { backgroundColor: colors.savingPlanColor }
        },
        {
          title: `${i18n(
            'expectedValueChart.default.savingInYourBankAccount'
          )} - ${formatNumber(cultureCode, bankReturn * 100, 0, 1)}%`,
          style: { backgroundColor: colors.savingInBankAccountColor }
        }
      ];

  return (
    <>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        containerProps={{
          style: {
            height: 281,
            width: isNil(width) ? 'auto' : `${width}px`
          }
        }}
        options={chartOptions}
      />

      <Legend items={legendItems} />
    </>
  );
};

ExpectedValueChart.defaultProps = {
  isInteractive: true
};

export default ExpectedValueChart;
