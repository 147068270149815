import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import * as R from 'ramda';

import { i18n } from 'i18n/index.js';
import { getSavingHorizonPrevRouteConfig } from 'features/shared/services/router/selectors.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import { creators as userOptionsCreator } from 'features/shared/services/userOptions/actions.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import TileInput from 'features/shared/components/tileInput/index.js';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import Slider from 'features/shared/components/slider/index.js';
import {
  getMinSliderHorizonYear,
  getMaxSliderHorizonYear,
  getRiskHorizonValue
} from './utils';
import { TimeHorizonTypes } from 'features/report/body/constants';

const useStyles = createUseStyles(theme => {
  return {
    title: {
      fontFamily: theme.headerFontFamily,
      fontSize: TextFontSizes.largePx,
      lineHeight: TextFontSizes.largeLineHeightPx,
      textAlign: 'center',
      paddingTop: '50px',
      paddingBottom: '28px',
      color: Colors.grayDark
    },
    text: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      textAlign: 'center',
      marginBottom: '30px'
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center'
    },
    slider: {
      marginTop: '130px'
    }
  };
});

const SavingHorizon = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute(getSavingHorizonPrevRouteConfig);
  const nextRoute = useNextRoute();

  const horizonsConfig = useSelector(sessionSelectors.getHorizonsConfig);
  const horizonType = horizonsConfig.type;
  const horizons = horizonsConfig.items;
  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const userOptions = useSelector(userOptionsSelectors.getUserOptions);

  const dispatch = useDispatch();
  const userOptionsChanged = (type, value) => {
    dispatch(userOptionsCreator.userOptionChanged({ type, value }));
  };

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('savingHorizon.naviTitle', translations)}
      </Navigation>

      <Page>
        <div className={classes.content}>
          <span className={classes.title}>
            {i18n('savingHorizon.title', translations)}
          </span>

          <span className={classes.text}>
            {i18n('savingHorizon.text', translations)}
          </span>

          {horizonType === TimeHorizonTypes.radio && (
            <TileInput
              options={horizons}
              selectedOption={userOptions.horizon}
              userOptionType="horizon"
              onSelect={(type, value) => {
                userOptionsChanged(type, value);
                window.scrollTo(0, document.body.scrollHeight);
              }}
            />
          )}

          {horizonType === TimeHorizonTypes.slider && (
            <div className={classes.slider}>
              <Slider
                type="horizon"
                options={{
                  icon: '',
                  max: getMaxSliderHorizonYear(horizons),
                  min: getMinSliderHorizonYear(horizons),
                  step: 1,
                  title: 'report.purpose.years'
                }}
                userOption={
                  userOptions.horizonYear || getMinSliderHorizonYear(horizons)
                }
                onChange={(type, value) => {
                  userOptionsChanged(
                    type,
                    getRiskHorizonValue(value, horizons)
                  );
                  userOptionsChanged('horizonYear', value);
                }}
                displayTitle={true}
                displayValue={true}
              />
            </div>
          )}
        </div>

        <Footer>
          {nextRoute && (
            <Button
              type={ButtonType.primary}
              disabled={R.isNil(userOptions.horizon)}
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n('shared.continue', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default SavingHorizon;
