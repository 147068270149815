import * as R from 'ramda';

const makeOptional = optional => (optional != null ? `/${optional}` : '');

const routeTemplatesInternal = [
  {
    id: 'report',
    config: '/report/:customerId',
    build: customerId => `/report/${customerId}`,
    children: [
      {
        id: 'reportBody',
        config: '/report/:customerId/body',
        build: customerId => `/report/${customerId}/body`
      }
    ]
  },
  {
    id: 'orderExecutionReportBody',
    config: '/report-order-execution/:customerId/body',
    build: customerId => `/report-order-execution/${customerId}/body`
  },
  {
    id: 'home',
    config: '/:customerId?',
    build: customerId => `/${customerId}`,
    children: [
      {
        id: 'onboarding',
        config: '/:customerId/onboarding',
        build: customerId => `/${customerId}/onboarding`,
        title: 'title.savingRoboOnboarding',
        children: [
          {
            id: 'intro',
            config: '/:customerId/onboarding/intro',
            build: customerId => `/${customerId}/onboarding/intro`
          },
          {
            id: 'howWork',
            config: '/:customerId/onboarding/how-work',
            build: customerId => `/${customerId}/onboarding/how-work`
          },
          {
            id: 'whyUse',
            config: '/:customerId/onboarding/why-use',
            build: customerId => `/${customerId}/onboarding/why-use`
          },
          {
            id: 'savingGoals',
            config: '/:customerId/onboarding/saving-goals',
            build: customerId => `/${customerId}/onboarding/saving-goals`
          },
          {
            id: 'goalPension',
            config: '/:customerId/onboarding/goal-pension',
            build: customerId => `/${customerId}/onboarding/goal-pension`
          },
          {
            id: 'savingHorizon',
            config: '/:customerId/onboarding/saving-horizon',
            build: customerId => `/${customerId}/onboarding/saving-horizon`
          },
          {
            id: 'riskAssessment',
            config: '/:customerId/onboarding/risk-assessment',
            build: customerId => `/${customerId}/onboarding/risk-assessment`,
            children: [
              {
                id: 'riskAssessmentIntro',
                config: '/:customerId/onboarding/risk-assessment/intro',
                build: customerId =>
                  `/${customerId}/onboarding/risk-assessment/intro`
              },
              {
                id: 'whatIsRisk',
                config: '/:customerId/onboarding/risk-assessment/what-is-risk',
                build: customerId =>
                  `/${customerId}/onboarding/risk-assessment/what-is-risk`
              },
              {
                id: 'riskPreference',
                config:
                  '/:customerId/onboarding/risk-assessment/risk-preference',
                build: customerId =>
                  `/${customerId}/onboarding/risk-assessment/risk-preference`
              },
              {
                id: 'returnPreference',
                config:
                  '/:customerId/onboarding/risk-assessment/return-preference',
                build: customerId =>
                  `/${customerId}/onboarding/risk-assessment/return-preference`
              },
              {
                id: 'disconnectedAnswers',
                config:
                  '/:customerId/onboarding/risk-assessment/disconnected-answers',
                build: customerId =>
                  `/${customerId}/onboarding/risk-assessment/disconnected-answers`
              }
            ]
          },
          {
            id: 'knowledge',
            config: '/:customerId/onboarding/knowledge',
            build: customerId => `/${customerId}/onboarding/knowledge`,
            children: [
              {
                id: 'experienceAssessment',
                config:
                  '/:customerId/onboarding/knowledge/experience-assessment',
                build: customerId =>
                  `/${customerId}/onboarding/knowledge/experience-assessment`
              },
              {
                id: 'loss',
                config: '/:customerId/onboarding/knowledge/loss',
                build: customerId => `/${customerId}/onboarding/knowledge/loss`
              },
              {
                id: 'fund',
                config: '/:customerId/onboarding/knowledge/fund',
                build: customerId => `/${customerId}/onboarding/knowledge/fund`
              },
              {
                id: 'whatIsFund',
                config: '/:customerId/onboarding/knowledge/what-is-fund',
                build: (customerId, prevId) =>
                  `/${customerId}/onboarding/knowledge/what-is-fund?prevId=${prevId}`
              },
              {
                id: 'affectFactor',
                config: '/:customerId/onboarding/knowledge/affect-factors',
                build: customerId =>
                  `/${customerId}/onboarding/knowledge/affect-factors`
              },
              {
                id: 'resultKnowledge',
                config: '/:customerId/onboarding/knowledge/result',
                build: customerId =>
                  `/${customerId}/onboarding/knowledge/result`
              },
              {
                id: 'investmentKnowledge',
                config: '/:customerId/onboarding/knowledge/investment',
                build: customerId =>
                  `/${customerId}/onboarding/knowledge/investment`
              },
              {
                id: 'tradingKnowledge',
                config: '/:customerId/onboarding/knowledge/trading',
                build: customerId =>
                  `/${customerId}/onboarding/knowledge/trading`
              }
            ]
          },
          {
            id: 'financialSituation',
            config: '/:customerId/onboarding/financial-situation',
            build: customerId =>
              `/${customerId}/onboarding/financial-situation`,
            children: [
              {
                id: 'left',
                config: '/:customerId/onboarding/financial-situation/left',
                build: customerId =>
                  `/${customerId}/onboarding/financial-situation/left`
              },
              {
                id: 'debt',
                config: '/:customerId/onboarding/financial-situation/debt',
                build: customerId =>
                  `/${customerId}/onboarding/financial-situation/debt`
              },
              {
                id: 'assets',
                config: '/:customerId/onboarding/financial-situation/assets',
                build: customerId =>
                  `/${customerId}/onboarding/financial-situation/assets`
              },
              {
                id: 'buffer',
                config: '/:customerId/onboarding/financial-situation/buffer',
                build: customerId =>
                  `/${customerId}/onboarding/financial-situation/buffer`
              },
              {
                id: 'whatIsBuffer',
                config:
                  '/:customerId/onboarding/financial-situation/what-is-buffer',
                build: customerId =>
                  `/${customerId}/onboarding/financial-situation/what-is-buffer`
              },
              {
                id: 'creditCard',
                config:
                  '/:customerId/onboarding/financial-situation/credit-card',
                build: customerId =>
                  `/${customerId}/onboarding/financial-situation/credit-card`
              }
            ]
          },
          {
            id: 'summary',
            config: '/:customerId/onboarding/summary',
            build: customerId => `/${customerId}/onboarding/summary`
          },
          {
            id: 'stopScreen',
            config: '/:customerId/onboarding/stop-screen',
            build: customerId => `/${customerId}/onboarding/stop-screen`
          },
          {
            id: 'creditCardStopScreen',
            config: '/:customerId/onboarding/credit-card-stop-screen',
            build: customerId =>
              `/${customerId}/onboarding/credit-card-stop-screen`
          }
        ]
      },
      {
        id: 'counseling',
        config: '/:customerId/counseling',
        build: customerId => `/${customerId}/counseling`,
        title: 'title.savingRoboOnboarding',
        children: [
          {
            id: 'themes',
            config: '/:customerId/counseling/themes',
            build: customerId => `/${customerId}/counseling/themes`
          },
          {
            id: 'savingsPlan',
            config: '/:customerId/counseling/savings-plan',
            build: customerId => `/${customerId}/counseling/savings-plan`
          },
          {
            id: 'assessmentSummary',
            config: '/:customerId/counseling/assessment-summary',
            build: customerId => `/${customerId}/counseling/assessment-summary`
          },
          {
            id: 'portfolioFunds',
            config: '/:customerId/counseling/portfolio-funds',
            build: customerId => `/${customerId}/counseling/portfolio-funds`
          },
          {
            id: 'leaveDetails',
            config: '/:customerId/counseling/leave-details',
            build: customerId => `/${customerId}/counseling/leave-details`
          },
          {
            id: 'backtest',
            config: '/:customerId/counseling/backtest',
            build: customerId => `/${customerId}/counseling/backtest`
          },
          {
            id: 'montecarlo',
            config: '/:customerId/counseling/montecarlo',
            build: customerId => `/${customerId}/counseling/montecarlo`
          },
          {
            id: 'fees',
            config: '/:customerId/counseling/fees',
            build: customerId => `/${customerId}/counseling/fees`
          },
          {
            id: 'aml1',
            config: '/:customerId/counseling/aml-1',
            build: customerId => `/${customerId}/counseling/aml-1`
          },
          {
            id: 'aml2',
            config: '/:customerId/counseling/aml-2',
            build: customerId => `/${customerId}/counseling/aml-2`
          },
          {
            id: 'signing',
            config: '/:customerId/counseling/signing',
            build: customerId => `/${customerId}/counseling/signing`
          },
          {
            id: 'successScreen',
            config: '/:customerId/counseling/success-screen',
            build: customerId => `/${customerId}/counseling/success-screen`
          }
        ]
      }
    ]
  }
];

const initRouteTemplateTitles = R.curry((defaultTitle, routeTemplates) => {
  return R.map(routeTemplate => {
    const title = routeTemplate.title || defaultTitle;
    return {
      ...routeTemplate,
      title,
      children: R.isNil(routeTemplate.children)
        ? routeTemplate.children
        : initRouteTemplateTitles(title, routeTemplate.children)
    };
  }, routeTemplates);
});

const flattenRouteTemplates = routeTemplates => {
  let flattenedRouteTemplates = [];

  routeTemplates.forEach(rt => {
    flattenedRouteTemplates.push(rt);
    if (rt.children != null) {
      flattenedRouteTemplates = flattenedRouteTemplates.concat(
        flattenRouteTemplates(rt.children)
      );
    }
  });

  return flattenedRouteTemplates;
};

const reduceRouteTemplates = routeTemplates => {
  return R.reduce(
    (reducedRouteTemplates, rt) => {
      return {
        ...reducedRouteTemplates,
        [rt.id]: {
          ...rt,
          children:
            rt.children != null ? flattenRouteTemplates(rt.children) : []
        }
      };
    },
    {},
    routeTemplates
  );
};

export const routeTemplates = initRouteTemplateTitles(
  'title.deepAlpha',
  routeTemplatesInternal
);

export default R.pipe(
  flattenRouteTemplates,
  reduceRouteTemplates
)(routeTemplates);
