import * as R from 'ramda';

export const runMetricsQueueHandler = ({
  queue,
  batchInterval,
  sendMetrics,
  onSessionEnd
}) => {
  const sendQueueMetrics = () => {
    const metrics = queue.dequeueAll();

    if (R.length(metrics) > 0) {
      sendMetrics(metrics);
    }
  };

  onSessionEnd(sendQueueMetrics);

  setInterval(sendQueueMetrics, batchInterval);
};
