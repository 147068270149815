import create from 'zustand';

const defaultData = {
  isReadPdfReportPending: false,
  isReadPortfolioChartPending: false
};

export const usePageStore = create(set => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  setIsReadPdfReportPending: isReadPdfReportPending => {
    set({ isReadPdfReportPending });
  },
  setIsReadPortfolioChartPending: isReadPortfolioChartPending => {
    set({ isReadPortfolioChartPending });
  }
}));
