import { createSelector } from 'reselect';
import * as R from 'ramda';

import sessionSelectors from 'features/shared/services/session/selectors.js';
import routerSelectors from 'features/shared/services/router/selectors.js';

export const getCurrentRoute = createSelector(
  state => sessionSelectors.getRoutesFlowConfig(state),
  state => routerSelectors.getRoute(state),
  (routesFlow, route) => {
    const currentRouteId = R.prop('id', route.route);

    if (R.isNil(currentRouteId)) {
      return null;
    }

    const routeConfig = R.find(R.propEq('id', currentRouteId))(routesFlow);

    if (R.isNil(routeConfig)) {
      return null;
    }

    return {
      routeId: currentRouteId,
      customerId: R.path(['params', 'customerId'], route.match)
    };
  }
);
