import * as R from 'ramda';
import { buildPublicFileUrl } from 'features/shared/utils/axios.js';

export const riskScoreToNumber = riskScore => {
  if (R.isNil(riskScore)) {
    return null;
  }

  return Number(riskScore.replace('R', ''));
};

export const numberToRiskScore = number => {
  if (R.isNil(number)) {
    return null;
  }

  return R.length(`${number}`) < 2 ? `R0${number}` : `R${number}`;
};

export const mapManifest = manifest => ({
  rel: manifest.rel,
  href: buildPublicFileUrl(manifest.href),
  media: manifest.media,
  sizes: manifest.sizes
});

export const mapManifests = R.map(mapManifest);
