import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  chart: {
    fill: theme.imageSecondaryColor
  },
  item: {
    fill: theme.imagePrimaryColor,
    stroke: theme.imageSecondaryColor
  }
}));

const GrapthGroup = () => {
  const classes = useStyles();

  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="100"
      height="100"
      fill="none"
    >
      <rect
        id="backgroundrect"
        width="100%"
        height="100%"
        x="0"
        y="0"
        fill="none"
        stroke="none"
      />
      <path
        d="M88 0V8H84C81.7969 8 80 9.79688 80 12V32C80 34.2031 81.7969 36 84 36H96C98.2031 36 100 34.2031 100 32V12C100 9.79688 98.2031 8 96 8H92V0H88ZM34 26V34H30C27.7969 34 26 35.7969 26 38V58C26 60.2031 27.7969 62 30 62H42C44.2031 62 46 60.2031 46 58V38C46 35.7969 44.2031 34 42 34H38V26H34ZM64 30V38H60C57.7969 38 56 39.7969 56 42V62C56 64.2031 57.7969 66 60 66H72C74.2031 66 76 64.2031 76 62V42C76 39.7969 74.2031 38 72 38H68V30H64ZM88 38V44H92V38H88ZM8 56V64H4C1.79688 64 0 65.7969 0 68V88C0 90.2031 1.79688 92 4 92H16C18.2031 92 20 90.2031 20 88V68C20 65.7969 18.2031 64 16 64H12V56H8ZM34 64V70H38V64H34ZM4 68H16V88H4V68ZM64 68V74H68V68H64ZM8 94V100H12V94H8Z"
        className={classes.chart}
        id="svg_1"
      />
      <rect
        className={classes.item}
        strokeWidth="2"
        strokeLinejoin="round"
        strokeDashoffset=""
        fillRule="nonzero"
        id="svg_2"
        x="2.9583334922790527"
        y="66.05555558204651"
        width="14.291666507720947"
        height="23.819444179534912"
        strokeOpacity="1"
        opacity="1"
      />
    </svg>
  );
};

export default GrapthGroup;
