import { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { isNil } from 'ramda';

import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { Colors } from 'features/report/shared/constants/colors';
import { FontWeights, TextFontSizes } from 'features/shared/constants/fonts';
import { i18n } from 'i18n';
import sessionSelectors from 'features/shared/services/session/selectors';
import { formatNumber } from 'features/shared/utils/number';

const useStyles = createUseStyles({
  table: {
    borderCollapse: 'collapse',
    width: '100%'
  },
  headerRow: {
    backgroundColor: Colors.gray600
  },
  headerCell: {
    color: Colors.gray50,
    fontSize: 10,
    fontWeight: FontWeights.normal,
    lineHeight: TextFontSizes.xxSmallLineHeightPx,
    padding: '13px 16px',
    textAlign: 'right',

    '&:first-child': {
      fontSize: 12,
      lineHeight: '14px',
      padding: '12px 16px',
      textAlign: 'left'
    }
  },
  row: {
    backgroundColor: Colors.gray600,

    '&:nth-child(odd)': {
      backgroundColor: Colors.gray500
    }
  },
  cell: {
    color: Colors.black,
    fontSize: 12,
    lineHeight: '14px',
    padding: '6.5px 16px',
    textAlign: 'right',

    '&:first-child': {
      textAlign: 'left'
    }
  },
  portfolioRow: {
    backgroundColor: '#E0E0ED',

    '& $cell': {
      fontWeight: FontWeights.semiBold,
      '&:first-child': {
        fontWeight: FontWeights.medium
      }
    }
  }
});

const AlignmentTableStandard = ({
  cultureCode,
  fundAnalysisData,
  goals,
  sustainabilityData,
  totalValue
}) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  const alignmentValues = useMemo(
    () =>
      Array.from(
        new Set(
          sustainabilityData.data
            .map(({ data }) =>
              Object.entries(data.portfolioAnalysis.alignmentValuesWeighted)
                .filter(([_, value]) => !isNil(value))
                .map(([key]) => key)
            )
            .flat()
        )
      ).map((alignmentCriteria, index) => {
        return {
          name: alignmentCriteria as string,
          title: sustainabilityData.sustainabilityAssessment[index]
            ?.header as string,
          preferenceScore:
            sustainabilityData.sustainabilityAssessment[index]?.answer
        };
      }),
    [sustainabilityData]
  );

  const portfolioScore = useMemo(
    () =>
      alignmentValues
        .map(({ name }) =>
          sustainabilityData.data
            .map(d => {
              const goal = goals.find(({ goalId }) => goalId === d.goalId);
              const value =
                goal.data.firstDeposit || goal.data.monthlyDeposit || 0;

              return {
                score:
                  d.data.portfolioAnalysis.alignmentValuesWeighted[name]! *
                  (value / totalValue),
                name
              };
            })
            .reduce(
              (acc, curr) => ({ ...acc, score: acc.score + curr.score }),
              {
                score: 0,
                name
              }
            )
        )
        .map(value => ({
          ...value,
          status:
            value.score >=
            (alignmentValues.find(({ name }) => name === value.name)
              ?.preferenceScore || 0)
        })),
    [alignmentValues, goals, sustainabilityData.data, totalValue]
  );

  return (
    <table className={classes.table}>
      <thead>
        <tr className={classes.headerRow}>
          <th className={classes.headerCell}>
            {i18n('report.sustainability.fund', translationsConfig)}
          </th>

          {alignmentValues.map(({ name, title }) => (
            <th key={name} className={classes.headerCell}>
              {title}
            </th>
          ))}
        </tr>
      </thead>

      <tbody>
        {fundAnalysisData.map(
          ({ fundStandardName, name, isin, alignmentCriteria }) => (
            <tr key={isin} className={classes.row}>
              <td className={classes.cell}>{fundStandardName || name}</td>

              {alignmentValues.map(({ name }) => (
                <td key={name} className={classes.cell}>
                  {formatNumber(cultureCode, alignmentCriteria[name], 1, 1)}
                </td>
              ))}
            </tr>
          )
        )}

        <tr className={classes.portfolioRow}>
          <td className={classes.cell}>
            {i18n('report.sustainability.portfolioScore', translationsConfig)}
          </td>

          {portfolioScore.map(({ score, name }) => (
            <td key={name} className={classes.cell}>
              {formatNumber(cultureCode, score, 1, 1)}
            </td>
          ))}
        </tr>

        <tr className={classes.portfolioRow}>
          <td className={classes.cell}>
            {i18n('report.sustainability.myPreference', translationsConfig)}
          </td>

          {alignmentValues.map(({ name, preferenceScore }) => (
            <td key={name} className={classes.cell}>
              {formatNumber(cultureCode, preferenceScore, 0, 0)}
            </td>
          ))}
        </tr>
      </tbody>
    </table>
  );
};

export default AlignmentTableStandard;
