import React from 'react';
import classNames from 'classnames';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  section: {
    breakInside: 'avoid',

    '& + &': {
      marginTop: '26px'
    }
  }
}));

const Section = ({ children, className = {}, ...restProps }) => {
  const classes = useStyles();

  return (
    <div
      data-testid={restProps['data-testid']}
      className={classNames(classes.section, className)}
    >
      {children}
    </div>
  );
};

export default Section;
