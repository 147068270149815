export const getMaxSliderHorizonYear = sliderItems => {
  return sliderItems
    ? Math.max(...sliderItems.map(item => item.yearIntervalStop))
    : [];
};

export const getMinSliderHorizonYear = sliderItems => {
  return sliderItems
    ? Math.min(...sliderItems.map(item => item?.yearIntervalStart))
    : [];
};

export const getRiskHorizonValue = (timeHorizon, sliderItems) => {
  const sortedItems = [...sliderItems];
  sortedItems.sort((a, b) => a.yearIntervalStop - b.yearIntervalStop);

  return sortedItems.find(
    ({ yearIntervalStop }) => timeHorizon <= yearIntervalStop
  )?.riskHorizonValue;
};
