import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  chart: {
    fill: theme.imageSecondaryColor
  },
  arrow: {
    fill: theme.imagePrimaryColor
  }
}));

const Grapth = () => {
  const classes = useStyles();

  return (
    <svg
      width="99"
      height="100"
      viewBox="0 0 99 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M4.8125 25.8125V99.8125H12.8125V25.8125H4.8125ZM18.8125 39.8125V99.8125H26.8125V39.8125H18.8125ZM32.8125 49.8125V99.8125H40.8125V49.8125H32.8125ZM46.8125 53.8125V99.8125H54.8125V53.8125H46.8125ZM60.8125 63.8125V99.8125H68.8125V63.8125H60.8125ZM74.8125 75.8125V99.8125H82.8125V75.8125H74.8125ZM88.8125 79.8125V99.8125H96.8125V79.8125H88.8125Z"
        className={classes.chart}
      />
      <path
        d="M5.625 0L0 5.625L28 33.625L30.8125 36.5L33.625 33.625L38.8125 28.4375L60 49.625L62.8125 52.5L65.625 49.625L70.8125 44.4375L85.1875 58.8125H74.8125V66.8125H98.8125V42.8125H90.8125V53.1875L73.625 36L70.8125 33.125L68 36L62.8125 41.1875L41.625 20L38.8125 17.125L36 20L30.8125 25.1875L5.625 0Z"
        className={classes.arrow}
      />
    </svg>
  );
};

export default Grapth;
