import React from 'react';

const Confirmation = ({
  onDecline,
  onConfirm,
  content,
  confirmButtonText,
  declineButtonText
}) => {
  return (
    <div>
      <div>{content}</div>
      <div>
        <button onClick={onDecline}>{declineButtonText}</button>
        <button onClick={onConfirm}>{confirmButtonText}</button>
      </div>
    </div>
  );
};

export default Confirmation;
