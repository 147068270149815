import { useSelector } from 'react-redux';

import { i18n } from 'i18n/index.js';
import I18n from 'features/shared/components/i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Paragraph from '../../shared/components/paragraph';
import Heading1 from '../../shared/components/heading1';
import Section from '../../shared/components/section.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';

const useStyles = createUseStyles(theme => ({
  dateAndSigning: {
    marginTop: '75px'
  },
  grid: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '200px'
  },
  gridColumn: {
    flex: '1 1 auto'
  },
  handwriteField: {
    borderBottom: '1px solid',
    width: '300px'
  },
  handwriteField2: {
    borderBottom: '1px solid',
    width: '300px'
  },
  handwriteFieldAnnotation: {
    width: '300px',
    paddingTop: '5px'
  }
}));

const AcceptanceSection = ({
  bankAccountNumber,
  advisorName,
  generalInformation
}) => {
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const classes = useStyles();

  return (
    <Section data-testid={'background-section'}>
      <Heading1>
        {i18n('report.acceptance.header', translationsConfig)}
      </Heading1>
      <Paragraph>
        <I18n
          text={i18n('report.acceptance.text', translationsConfig)}
          replaceParams={{
            '{customerName}': (
              <span>
                {i18n('report.background.customerName', translationsConfig)}
              </span>
            ),
            '{bankAccountNumber}': <span>{bankAccountNumber}</span>
          }}
        />
      </Paragraph>
      <div className={classes.dateAndSigning}>
        <Paragraph>
          {i18n('report.acceptance.dateAndSigning', translationsConfig)}
        </Paragraph>
      </div>
      <div className={classes.grid}>
        <div className={classes.gridColumn}>
          <div className={classes.handwriteField}></div>
          <div className={classes.handwriteFieldAnnotation}>
            <Paragraph>Customer</Paragraph>
            {generalInformation.name}
          </div>
        </div>
        <div className={classes.gridColumn}>
          <div className={classes.handwriteField2}></div>
          <div className={classes.handwriteFieldAnnotation}>
            <Paragraph>Advisor</Paragraph>
            {advisorName}
          </div>
        </div>
      </div>
    </Section>
  );
};

export default AcceptanceSection;
