import { v1 } from 'uuid';

import { NotificationTypes } from 'features/notification/constants/index.js';

export const types = {
  SHOW_NOTIFICATION: 'NOTIFICATION/SHOW_NOTIFICATION',
  HIDE_NOTIFICATION: 'NOTIFICATION/HIDE_NOTIFICATION'
};

const getDefaultNotification = () => ({
  id: v1(),
  type: NotificationTypes.info,
  isHideEnabled: true,
  isAutohideEnabled: true,
  autohideMilliseconds: 3500,
  isShown: true
});

export const creators = {
  showNotification: notification => ({
    type: types.SHOW_NOTIFICATION,
    payload: {
      notification: {
        ...getDefaultNotification(),
        ...notification
      }
    }
  }),
  hideNotification: id => ({
    type: types.HIDE_NOTIFICATION,
    payload: {
      id
    }
  })
};
