import { ReactNode, MouseEventHandler } from 'react';
import * as R from 'ramda';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import classNames from 'classnames';
import { StylesProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import { useSelector } from 'react-redux';

import { Colors } from 'features/shared/constants/colors.js';
import {
  FontWeights,
  ButtonFontSizes
} from 'features/shared/constants/fonts.js';
import Icon from 'features/shared/components/icon/index';
import sessionSelectors from 'features/shared/services/session/selectors.js';

const useStyles = createUseStyles(theme => {
  return {
    button: {
      boxSizing: 'border-box',
      cursor: 'pointer',
      fontFamily: theme.buttonFontFamily,
      fontWeight: FontWeights.normal,
      outline: 'none',
      padding: '5px 33px !important'
    },
    primary: {
      background: `${theme.primaryButtonBackground} !important`,
      border: `${theme.primaryButtonBorder} !important`,
      borderRadius: `${theme.primaryButtonBorderRadius} !important`,
      color: `${Colors.gray170} !important`,

      '& svg': {
        '& path': {
          stroke: theme.primaryButtonIconColor
        }
      }
    },
    secondary: {
      border: `${theme.secondaryButtonBorder} !important`,
      borderRadius: `${theme.secondaryButtonBorderRadius} !important`,
      background: `${theme.secondaryButtonBackground} !important`,
      color: `${Colors.gray170} !important`
    },
    primaryText: {
      textTransform: 'initial'
    },
    secondaryText: {
      textTransform: 'initial'
    },
    medium: {
      minWidth: '255px !important',
      minHeight: '44px !important',
      fontSize: ButtonFontSizes.medium,
      lineHeight: ButtonFontSizes.mediumLineHeight
    },
    buttonText: {
      alignItems: 'center',
      display: 'flex',
      justifyContent: 'center',
      position: 'relative',
      width: '100%'
    },
    disabled: {
      cursor: 'default !important',
      border: `2px solid ${Colors.disabledButtonColor} !important`,
      color: `${Colors.disabledButtonColor} !important`,

      '& svg': {
        '& path': {
          stroke: `${Colors.disabledButtonColor} !important`
        }
      }
    },
    coloredButton: {
      color: `${Colors.white} !important`,
      border: `${theme.coloredButtonBorder} !important`,

      '&$primary': {
        background: `${theme.coloredButtonBackground} !important`
      },

      '&$disabled': {
        background: `${Colors.disabledButtonColor} !important`
      },

      '& svg': {
        '& path': {
          stroke: `${Colors.white} !important`
        }
      }
    },
    muiStartIcon: {
      position: 'absolute',
      display: 'block',
      left: '10px',
      marginRight: '0',
      marginLeft: '0',
      lineHeight: '1',
      height: '14px'
    },
    muiEndIcon: {
      position: 'absolute',
      display: 'block',
      right: '10px',
      marginRight: '0',
      marginLeft: '0',
      lineHeight: '1',
      height: '14px'
    }
  };
});

export const ButtonSize = {
  medium: 'medium',
  large: 'large'
};

const getSizeClassName = (buttonSize, classes) => {
  switch (buttonSize) {
    case ButtonSize.medium:
      return classes.medium;
    case ButtonSize.large:
      return classes.large;
    default:
      return classes.medium;
  }
};

export const ButtonType = {
  primary: 'primary',
  secondary: 'secondary'
};

const getTypeClassName = (buttonType, classes) => {
  switch (buttonType) {
    case ButtonType.primary:
      return classes.primary;
    case ButtonType.secondary:
      return classes.secondary;
    default:
      return classes.primary;
  }
};

const getTypeClassNameForText = (buttonType, classes) => {
  switch (buttonType) {
    case ButtonType.primary:
      return classes.primaryText;
    case ButtonType.secondary:
      return classes.secondaryText;
    default:
      return null;
  }
};

const getStartIcon = (classes, buttonType, isShowStartIcon) => {
  if (isShowStartIcon) {
    switch (buttonType) {
      case ButtonType.primary:
        return <Icon type="arrow_back" />;
      case ButtonType.secondary:
        return null;
      default:
        return null;
    }
  } else {
    return null;
  }
};

const getIcon = (classes, buttonType, isShowStartIcon) => {
  if (!isShowStartIcon) {
    switch (buttonType) {
      case ButtonType.primary:
        return <Icon type="arrow_forward" />;
      case ButtonType.secondary:
        return null;
      default:
        return null;
    }
  } else {
    return null;
  }
};

const getDisabledClassName = (disabled, classes) => {
  return disabled ? classes.disabled : null;
};

const getBackgroundClass = (isColoredButton, classes) => {
  return isColoredButton ? classes.coloredButton : null;
};

const ButtonComponent = ({
  children,
  className,
  type,
  size,
  href,
  onClickButton,
  disabled,
  isColoredButton,
  isShowStartIcon,
  doShowIcon,
  endIconClass,
  endIcon: endIconProp,
  ...rest
}: {
  children: ReactNode;
  className?: string;
  type?: string;
  size?: string;
  href?: string;
  onClick: MouseEventHandler<HTMLButtonElement>;
  onClickButton?: MouseEventHandler<HTMLButtonElement>;
  disabled?: boolean;
  isColoredButton?: boolean;
  isShowStartIcon?: boolean;
  doShowIcon?: boolean;
  endIconClass?: string;
  endIcon?: ReactNode;
}) => {
  const classes = useStyles();
  const config: {
    areButtonsColored: boolean;
  } = useSelector(sessionSelectors.getConfig);

  let endIcon: ReactNode | null = null;
  if (!R.isNil(endIconProp)) {
    endIcon = endIconProp;
  } else if (doShowIcon) {
    endIcon = getIcon(classes, type, isShowStartIcon);
  }

  return (
    <StylesProvider injectFirst>
      <Button
        {...rest}
        classes={{
          startIcon: classes.muiStartIcon,
          endIcon: classNames(classes.muiEndIcon, endIconClass)
        }}
        className={classNames(
          classes.button,
          getTypeClassName(type, classes),
          getSizeClassName(size, classes),
          getDisabledClassName(disabled, classes),
          getBackgroundClass(
            isColoredButton ?? config.areButtonsColored,
            classes
          ),
          className
        )}
        disabled={disabled}
        variant="outlined"
        color="primary"
        startIcon={
          doShowIcon ? getStartIcon(classes, type, isShowStartIcon) : null
        }
        endIcon={endIcon}
      >
        <span
          className={classNames(
            classes.buttonText,
            getTypeClassNameForText(type, classes)
          )}
        >
          {children}
        </span>
      </Button>
    </StylesProvider>
  );
};

ButtonComponent.defaultProps = {
  doShowIcon: true
};

export default ButtonComponent;
