import React from 'react';
import { useSelector } from 'react-redux';

import Heading1 from 'features/report/shared/components/heading1';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { i18n } from 'i18n';
import sessionSelectors from 'features/shared/services/session/selectors';

const useStyles = createUseStyles({
  root: {
    marginTop: '26px'
  },
  paragraph: {
    marginTop: '5px'
  },
  explanationList: {
    '& > li + li': {
      marginTop: '7px'
    }
  }
});

const ExplanationOfTerms = ({ costExplanationOfTerms }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  return (
    <div className={classes.root}>
      <Heading1>
        {i18n('costChart.explanationOfTerms.title', translationsConfig)}
      </Heading1>
      <p className={classes.paragraph}>
        {i18n('costChart.explanationOfTerms.paragraph', translationsConfig)}
      </p>
      <ul className={classes.explanationList}>
        {costExplanationOfTerms.map(expl => (
          <li key={expl}>{expl}</li>
        ))}
      </ul>
    </div>
  );
};

export default ExplanationOfTerms;
