import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  chart: {
    fill: theme.imageSecondaryColor
  },
  currency: {
    fill: theme.imagePrimaryColor
  }
}));

const Fund = () => {
  const classes = useStyles();

  return (
    <svg
      width="100"
      height="100"
      viewBox="0 0 100 100"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <ellipse
        cx="46.5"
        cy="36.5"
        rx="16.5"
        ry="21.5"
        className={classes.currency}
      />
      <ellipse
        cx="49.5"
        cy="61.5"
        rx="16.5"
        ry="13.5"
        className={classes.currency}
      />
      <ellipse
        cx="47"
        cy="72.5"
        rx="10"
        ry="13.5"
        className={classes.currency}
      />
      <path
        d="M50 4C24.64 4 4 24.64 4 50C4 75.36 24.64 96 50 96C56.04 96 61.8216 94.8197 67.1016 92.6797L58.6602 74.1211C59.8802 73.6011 61.0411 72.9181 62.1211 72.0781L70.7383 91.0391C85.1583 83.7391 95.2214 69.06 95.9414 52H65.7812C64.6413 50.3 63.1209 49 61.4609 48H95.9414C95.3414 33.56 88.0381 20.8398 77.0781 12.8398L64.1406 32.3789C63.5006 31.1189 62.6992 29.9194 61.6992 28.8594L73.7812 10.6211C66.8413 6.42109 58.7 4 50 4ZM48 22H52V28.1406C59.74 29.1806 61.96 36.1405 62 39.9805L58 40.0195C57.98 39.1995 57.62 32 50 32C45.82 32 42.8812 33.3997 41.2812 36.1797C40.0213 38.3597 40 40.6402 40 40.6602C40 45.8002 43.9614 46.9389 50.4414 48.3789C56.4814 49.7189 64 51.4 64 60C64 68.3 57 71.2589 52 71.8789V78H48V71.8984C38.56 71.0184 36 63.88 36 60H38L40 59.9805C40 60.8005 40.38 68 50 68C50.4 68 60 67.86 60 60C60 54.86 56.0386 53.7212 49.5586 52.2812C43.5186 50.9412 36 49.2802 36 40.6602C36 40.3802 36.0206 35.4412 39.6406 31.7812C41.7206 29.6613 44.54 28.4416 48 28.1016V22Z"
        className={classes.chart}
      />
    </svg>
  );
};

export default Fund;
