import React from 'react';
import { useSelector } from 'react-redux';
import classNames from 'classnames';

import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Paragraph from '../../shared/components/paragraph';
import Heading1 from '../../shared/components/heading1';
import Section from '../../shared/components/section.js';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  highlightAdvisorName: {
    color: theme.primaryColor
  }
}));

const NextStepsSection = ({ advisorName }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  const reportConfig = useSelector(sessionSelectors.getReportConfig);
  return (
    <Section>
      <Heading1>{i18n('report.nextSteps.header', translationsConfig)}</Heading1>
      {reportConfig.nextStepsItems.map(el => (
        <Paragraph key={el}>{i18n(el, translationsConfig)}</Paragraph>
      ))}
      <div style={{ marginTop: '50px' }}>
        <Paragraph>
          {i18n('report.nextSteps.footer', translationsConfig)}
        </Paragraph>
        <Paragraph>
          <span
            className={classNames({
              [classes.highlightAdvisorName]:
                reportConfig.nextStepsHighlightAdvisorName
            })}
          >
            {advisorName}
          </span>
        </Paragraph>
        {reportConfig.thanksTextsAdditionalName && (
          <Paragraph>{reportConfig.thanksTextsAdditionalName}</Paragraph>
        )}
      </div>
    </Section>
  );
};

export default NextStepsSection;
