import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import classNames from 'classnames';

import { Colors } from '../constants/colors.js';

const useStyles = createUseStyles(theme => ({
  card: {
    backgroundColor: Colors.gray300,
    borderRadius: '13px',
    padding: '23px 27px',
    boxShadow: '0px 5px 8px rgba(0, 0, 0, 0.06)'
  }
}));

const Card = ({ children, className, ...restProps }) => {
  const classes = useStyles();

  return (
    <div
      data-testid={restProps['data-testid']}
      className={classNames(classes.card, className)}
    >
      {children}
    </div>
  );
};

export default Card;
