import { useSelector } from 'react-redux';

import sessionSelectors from 'features/shared/services/session/selectors';
import Heading1 from 'features/report/shared/components/heading1';
import Paragraph from 'features/report/shared/components/paragraph';
import ChartTable from 'features/report/shared/components/chartTable';
import { i18n } from 'i18n';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { Colors } from 'features/report/shared/constants/colors';
import { CostData } from 'features/report/shared/services/reportStore';
import { formatNumber } from 'features/shared/utils/number.js';
import Section from 'features/report/shared/components/section';
import AdvisorNotesSection from '../../advisorNotesSection.js';

const AnyChartTable: any = ChartTable;

const useStyles = createUseStyles({
  headerCell: {
    backgroundColor: Colors.gray600,
    color: Colors.gray50,
    fontSize: 10,
    fontWeight: 400,
    padding: '13px 16px',
    lineHeight: '12px',
    overflowWrap: 'break-word',

    '&:first-child': {
      fontSize: 12,
      lineHeight: '14px',
      padding: '24px 16px'
    }
  },
  cell: {
    fontSize: 11,
    lineHeight: '13px',
    padding: '6.5px 0',

    '&:first-child': {
      fontSize: 12,
      lineHeight: '14px',
      paddingLeft: 16,
      whiteSpace: 'nowrap'
    }
  },
  row: {
    '&:nth-of-type(even) $cell': {
      backgroundColor: Colors.gray600
    },
    '&:nth-of-type(odd) $cell': {
      backgroundColor: Colors.gray500
    }
  },
  advisorNotes: {
    marginTop: 32
  }
});

const costDataKeys = [
  'Name',
  'fund_management_fee',
  'fund_transaction_cost',
  'fund_return_commission_paid_to_client',
  'fund_performance_fee',
  'fund_platform_fee',
  'fund_custody_fee',
  'fund_total_ongoing_fees'
];

type Props = {
  costData: CostData[];
  advisorNotes?: string;
};

const OrderExecutionCostSection = ({ costData, advisorNotes }: Props) => {
  const classes = useStyles();
  const cultureCode = useSelector(sessionSelectors.getCultureCode);
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  const roundedCostData = costData.map(item => {
    return Object.entries(item).reduce((acc, [key, value]) => {
      return {
        ...acc,
        [key]:
          typeof value === 'number'
            ? formatNumber(cultureCode, value, 0, 2)
            : value
      };
    }, {});
  });

  return (
    <>
      <Section>
        <Heading1>
          {i18n('report.orderExecution.cost.header', translationsConfig)}
        </Heading1>

        <Paragraph>
          {i18n('report.orderExecution.cost.text', translationsConfig)}
        </Paragraph>
      </Section>

      <Section>
        <Heading1>
          {i18n('report.orderExecution.cost.tableHeader', translationsConfig)}
        </Heading1>

        <AnyChartTable
          addPercentToAllValues
          customClasses={{
            headerCell: classes.headerCell,
            cell: classes.cell
          }}
          data={roundedCostData}
          dataKeys={costDataKeys}
          headerTitles={[
            i18n('costChart.fundsTable.fund', translationsConfig),
            i18n('costChart.costTable.fundManagementFee', translationsConfig),
            i18n('costChart.costTable.fundTransactionCost', translationsConfig),
            i18n(
              'costChart.costTable.fundReturnCommissionPaidToClient',
              translationsConfig
            ),
            i18n('costChart.costTable.performanceFee', translationsConfig),
            i18n('costChart.costTable.platformFee', translationsConfig),
            i18n('costChart.costTable.custodyFee', translationsConfig),
            i18n('costChart.costTable.totalExpenseRatio', translationsConfig)
          ]}
          rowClassName={classes.row}
        />

        {advisorNotes && (
          <AdvisorNotesSection
            text={advisorNotes}
            className={classes.advisorNotes}
          />
        )}
      </Section>
    </>
  );
};

export default OrderExecutionCostSection;
