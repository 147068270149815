import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import * as R from 'ramda';

import { i18n } from 'i18n/index.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors';
import SlickCarousel from 'features/shared/components/slickCarousel/index.js';
import RiskAndReturnGraph_1 from './riskAndReturnGraph_1.js';
import RiskAndReturnGraph_2 from './riskAndReturnGraph_2.js';
import ExternalIcon from 'features/shared/components/icon/externalIcon.js';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';

const useStyles = createUseStyles(theme => {
  return {
    title: {
      fontFamily: theme.headerFontFamily,
      fontSize: TextFontSizes.xLargePx,
      lineHeight: TextFontSizes.xLargeLineHeightPx,
      textAlign: 'center',
      paddingTop: '50px',
      color: Colors.grayDark
    },
    text: {
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      textAlign: 'center',
      paddingTop: '19px',
      paddingBottom: '34px'
    },
    card: {
      maxWidth: '320px',
      minHeight: '465px',
      background: Colors.white,
      boxShadow:
        '0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 10px 10px ' +
        Colors.listShadowColor +
        ', 0px 4px 3px ' +
        Colors.listShadowColor,
      borderRadius: '15px',
      margin: 'auto',
      height: '100%'
    },
    cardTitle: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.largePx,
      lineHeight: TextFontSizes.largeLineHeightPx,
      color: Colors.grayDark
    },
    cardText: {
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      padding: '12px 17px 0 17px'
    },
    layoutColumn: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      padding: '0 15px 15px 15px'
    },
    icon: {
      borderRadius: '15px',
      width: '100%',
      height: 'auto'
    },
    cardContentWithoutGraph: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      justifyContent: 'center',
      paddingBottom: '25px',
      paddingTop: '43px'
    },
    cardContent: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      textAlign: 'center',
      padding: '40px 15px 15px 15px'
    },
    cardContainer: {
      padding: '14px',
      height: '100%'
    }
  };
});

const WhatIsRiskScreen = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const config = useSelector(sessionSelectors.getConfig);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('whatIsRiskScreen.naviTitle', translations)}
      </Navigation>

      <Page>
        <span className={classes.title}>
          {i18n('whatIsRiskScreen.title', translations)}
        </span>

        <span className={classes.text}>
          {i18n('whatIsRiskScreen.text', translations)}
        </span>

        <SlickCarousel>
          <div>
            <div className={classes.cardContainer}>
              <div className={classes.card}>
                <div className={classes.cardContentWithoutGraph}>
                  <ExternalIcon
                    type={R.path(['whatIsRisk', 'card_1Image'], config)}
                  />
                </div>
                <div className={classes.layoutColumn}>
                  <span className={classes.cardTitle}>
                    {i18n('whatIsRiskScreen.card_1.title', translations)}
                  </span>
                  <span className={classes.cardText}>
                    {i18n('whatIsRiskScreen.card_1.text_1', translations)}
                  </span>
                  <span className={classes.cardText}>
                    {i18n('whatIsRiskScreen.card_1.text_2', translations)}
                  </span>
                  <span className={classes.cardText}>
                    {i18n('whatIsRiskScreen.card_1.text_3', translations)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={classes.cardContainer}>
              <div className={classes.card}>
                <RiskAndReturnGraph_1
                  className={classes.icon}
                  legendText_1={R.map(
                    i => i18n(i, translations),
                    R.pathOr([], ['whatIsRisk', 'card_2LegendText_1'], config)
                  )}
                  legendText_2={i18n(
                    'whatIsRiskScreen.card_2.legendText_2',
                    translations
                  )}
                  arrowText_1={i18n(
                    'whatIsRiskScreen.card_2.arrowText_1',
                    translations
                  )}
                  arrowText_2={i18n(
                    'whatIsRiskScreen.card_2.arrowText_2',
                    translations
                  )}
                />
                <div className={classes.cardContent}>
                  <span className={classes.cardTitle}>
                    {i18n('whatIsRiskScreen.card_2.title', translations)}
                  </span>
                  <span className={classes.cardText}>
                    {i18n('whatIsRiskScreen.card_2.text', translations)}
                  </span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <div className={classes.cardContainer}>
              <div className={classes.card}>
                <RiskAndReturnGraph_2
                  className={classes.icon}
                  legendText_1={R.map(
                    i => i18n(i, translations),
                    R.pathOr([], ['whatIsRisk', 'card_3LegendText_1'], config)
                  )}
                  legendText_2={i18n(
                    'whatIsRiskScreen.card_3.legendText_2',
                    translations
                  )}
                  arrowText_1={i18n(
                    'whatIsRiskScreen.card_3.arrowText_1',
                    translations
                  )}
                  arrowText_2={i18n(
                    'whatIsRiskScreen.card_3.arrowText_2',
                    translations
                  )}
                />

                <div className={classes.cardContent}>
                  <span className={classes.cardTitle}>
                    {i18n('whatIsRiskScreen.card_3.title', translations)}
                  </span>
                  <span className={classes.cardText}>
                    {i18n('whatIsRiskScreen.card_3.text', translations)}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </SlickCarousel>

        <Footer>
          {nextRoute && (
            <Button
              type={ButtonType.primary}
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n('whatIsRiskScreen.startAssessment', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default WhatIsRiskScreen;
