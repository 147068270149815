import React from 'react';
import * as R from 'ramda';
import InputBase from '@material-ui/core/InputBase';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { useSelector, useDispatch } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import { i18n } from 'i18n/index.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import { creators as userOptionsCreator } from 'features/shared/services/userOptions/actions.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';

const useStyles = createUseStyles(theme => ({
  subTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    textAlign: 'center',
    paddingTop: '50px',
    paddingBottom: '40px',
    color: Colors.grayDark
  },
  textField: {
    width: '270px',

    '& input, textarea': {
      fontWeight: FontWeights.semiBold,
      fontSize: TextFontSizes.xxLargePx,
      lineHeight: TextFontSizes.xxLargeLineHeightPx,
      textAlign: 'center',
      color: theme.primaryColor
    }
  }
}));

const GoalPension = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const goal = useSelector(userOptionsSelectors.getUserGoal);

  const dispatch = useDispatch();
  const userNoSpecificGoalChanged = value => {
    dispatch(userOptionsCreator.userNoSpecificGoalChanged(value));
  };

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('goalPension.naviTitle', translations)}
      </Navigation>

      <Page>
        <span className={classes.subTitle}>
          {i18n('goalPension.subTitle', translations)}
        </span>

        <InputBase
          autoFocus
          multiline
          inputProps={{
            maxLength: 24
          }}
          placeholder="Enter goal"
          className={classes.textField}
          value={goal.title}
          onChange={event => {
            if (goal.id === 'noSpecificGoal') {
              userNoSpecificGoalChanged(event.target.value);
            }
          }}
          onKeyDown={event => {
            if (
              event.key === 'Enter' &&
              event.target.value.split('\n').length > 1
            ) {
              event.preventDefault();
            }
          }}
        />

        <Footer>
          {nextRoute && (
            <Button
              type={ButtonType.primary}
              disabled={R.isEmpty(goal.title)}
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n('shared.continue', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default GoalPension;
