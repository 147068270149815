import React from 'react';
import * as R from 'ramda';
import axios from 'axios';
import { useParams } from 'react-router';
import {
  dataSourcesMapping,
  PORTFOLIO_MODES
} from '@quantfoliorepo/ui-components';
import { useDispatch, useSelector } from 'react-redux';

import { throwSafeError } from 'features/shared/utils/throwSafeError.js';
import {
  readPortfolio,
  getQAuthAccessToken,
  readSelection,
  readFunds,
  createRiskReturn
} from 'features/shared/api/index.js';
import { useCustomerConfig } from 'features/sharedModules/customerConfig/components/useCustomerConfig';
import { creators as notificationActionCreators } from 'features/notification/services/actions.js';
import { NotificationTypes } from 'features/notification/constants/index.js';
import { usePortfolioChartStore } from '../services/portfolioChart';
import { useTheme } from 'features/sharedModules/styles/components/styles.js';
import { usePageStore } from 'features/counseling/successScreen/services/pageStore.js';
import sessionSelectors from 'features/shared/services/session/selectors';
import { i18n } from 'i18n';

export const useReadPortfolioChart = () => {
  const theme = useTheme();
  const portfolioChartColors = theme.chartPortfolioColors;
  const { customerId } = useParams();
  const dispatch = useDispatch();
  const { roboPortfolioPrecision } = useCustomerConfig();
  const portfolioChartStore = usePortfolioChartStore.getState();
  const cancelTokenSourceRef = React.useRef(null);
  const pageStore = usePageStore();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  const readPortfolioChart = async goal => {
    if (!R.isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;
    portfolioChartStore.reset();

    try {
      pageStore.setIsReadPortfolioChartPending(true);
      const qAuthAccessToken = await getQAuthAccessToken(
        customerId,
        cancelTokenSource.token
      );

      const [
        getRoboPortfolioResponse,
        getRoboSelectionResponse,
        getFundsListResponse
      ] = await Promise.all([
        readPortfolio(
          qAuthAccessToken,
          cancelTokenSource.token,
          R.reject(R.isNil)({
            risk_tolerance: R.prop('portfolio', goal.data),
            optionals: R.prop('themes', goal.data),
            precision: roboPortfolioPrecision
          })
        ),
        readSelection(qAuthAccessToken, cancelTokenSource.token),
        readFunds(qAuthAccessToken, cancelTokenSource.token)
      ]);

      const roboPortfolio = R.prop('data', getRoboPortfolioResponse);
      const roboSelection = R.prop('data', getRoboSelectionResponse);

      const portfolio = dataSourcesMapping.getPortfolio(
        PORTFOLIO_MODES.standard,
        {
          dataSources: {
            roboPortfolio,
            roboSelection
          }
        }
      );

      const createRiskReturnResponse = await createRiskReturn(
        qAuthAccessToken,
        cancelTokenSource.token,
        {
          portfolio
        }
      );

      portfolioChartStore.addStandardPortfolioChartData({
        dataSources: {
          roboPortfolio: R.prop('data', getRoboPortfolioResponse),
          roboSelection: R.prop('data', getRoboSelectionResponse),
          fundList: R.prop('data', getFundsListResponse),
          analyzeRiskReturn: R.prop('data', createRiskReturnResponse)
        },
        portfolioChartColors,
        goalId: goal.goalId,
        goalName: goal.name,
        goalIcon: goal.icon,
        i18n: key => i18n(key, translationsConfig)
      });
    } catch (error) {
      if (!axios.isCancel(error)) {
        dispatch(
          notificationActionCreators.showNotification({
            message: 'shared.getPdfReportErrorMessage',
            type: NotificationTypes.error
          })
        );

        throwSafeError(error);
      }
    } finally {
      pageStore.setIsReadPortfolioChartPending(false);
    }
  };

  return readPortfolioChart;
};
