import * as R from 'ramda';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import { v1 } from 'uuid';

import { i18n } from 'i18n/index.js';
import { formatNumber } from 'features/shared/utils/number.js';
import { buildPublicFileUrl } from 'features/shared/utils/axios.js';

const getKnowledgeAndExperience = (
  reactsConfig,
  lossesConfig,
  investmentsKnowledgeConfig,
  tradingConfig,
  translationsConfig,
  cultureCode,
  userOptions,
  percentByRisk
) => {
  const reactId = R.prop('react', userOptions);
  const lossId = R.prop('loss', userOptions);
  const investmentKnowledgeIds = R.propOr(
    [],
    'investmentKnowledges',
    userOptions
  );
  const tradingId = R.prop('trading', userOptions);
  const percentByRiskFormatted = R.isNil(percentByRisk)
    ? null
    : formatNumber(cultureCode, percentByRisk, 0, 2);

  const reactsConfigItem = R.find(R.propEq('id', reactId), reactsConfig);
  const lossesConfigItem = R.find(R.propEq('id', lossId), lossesConfig);
  const investmentsKnowledgeConfigItems = R.map(
    id => R.find(R.propEq('id', id), investmentsKnowledgeConfig),
    investmentKnowledgeIds
  );
  const tradingConfigItem = R.find(R.propEq('id', tradingId), tradingConfig);

  return R.reject(R.isNil, [
    R.isNil(reactId)
      ? null
      : {
          question: i18n(
            'knowledge.experienceAssessment.subTitle',
            translationsConfig
          ).replace('{0}', percentByRiskFormatted),
          answers: [i18n(R.prop('title', reactsConfigItem), translationsConfig)]
        },
    R.isNil(lossId)
      ? null
      : {
          question: i18n('knowledge.loss.subTitle', translationsConfig),
          answers: [i18n(R.prop('title', lossesConfigItem), translationsConfig)]
        },
    R.isNil(tradingId)
      ? null
      : {
          question: i18n('knowledge.trading.subTitle', translationsConfig),
          answers: [
            i18n(R.prop('title', tradingConfigItem), translationsConfig)
          ]
        },
    R.isEmpty(investmentKnowledgeIds)
      ? null
      : {
          question: i18n(
            'knowledge.investmentKnowledge.subTitle',
            translationsConfig
          ),
          answers: R.map(
            i => i18n(R.prop('title', i), translationsConfig),
            investmentsKnowledgeConfigItems
          )
        }
  ]);
};

export const getFinancialSituation = (
  financialSituationConfig,
  translationsConfig,
  cultureCode,
  userOptions
) => {
  const assetsValues = R.pathOr(
    {},
    ['financialSituation', 'assets'],
    userOptions
  );
  const debtValues = R.pathOr({}, ['financialSituation', 'debt'], userOptions);

  let assetsConfig = R.propOr([], 'assets', financialSituationConfig);
  let debtConfig = R.propOr([], 'debt', financialSituationConfig);

  const lengthsDiff = R.length(assetsConfig) - R.length(debtConfig);
  const lengthsDiffArray = R.pipe(
    Math.abs,
    R.range(0),
    R.map(R.always(null))
  )(lengthsDiff);
  if (lengthsDiff > 0) {
    debtConfig = R.concat(debtConfig, lengthsDiffArray);
  } else if (lengthsDiff < 0) {
    assetsConfig = R.concat(assetsConfig, lengthsDiffArray);
  }

  return R.pipe(
    R.map(i => {
      const assetItem = R.isNil(i[0])
        ? {}
        : {
            asset: i18n(i[0].title, translationsConfig),
            assetSum: formatNumber(cultureCode, assetsValues[i[0].id], 0, 2)
          };
      const debtItem = R.isNil(i[1])
        ? {}
        : {
            debt: i18n(i[1].title, translationsConfig),
            debtSum: formatNumber(cultureCode, debtValues[i[1].id], 0, 2)
          };

      return { ...assetItem, ...debtItem };
    }),
    R.append({
      asset: i18n('financialSituation.assets.sumAssets', translationsConfig),
      assetSum: R.pipe(R.values, R.reject(R.isNil), R.sum, v =>
        formatNumber(cultureCode, v, 0, 2)
      )(assetsValues),
      debt: i18n('financialSituation.debt.sumDebt', translationsConfig),
      debtSum: R.pipe(R.values, R.reject(R.isNil), R.sum, v =>
        formatNumber(cultureCode, v, 0, 2)
      )(debtValues)
    })
  )(R.zip(assetsConfig, debtConfig));
};

const formatPhoneNumber = phoneNumber => {
  if (R.isNil(phoneNumber) || R.isEmpty(phoneNumber)) {
    return null;
  }

  const formattedPhoneNumber = formatPhoneNumberIntl(phoneNumber);

  if (R.isNil(formattedPhoneNumber) || R.isEmpty(formattedPhoneNumber)) {
    return phoneNumber;
  }

  return formattedPhoneNumber;
};

export const mapClientUserDataToReport = (
  configuration,
  cultureCode,
  leaveDetailsFormValues,
  userOptions,
  maxRiskScore,
  percentByRisk,
  chartData,
  goals,
  expectedPathChartData,
  expectedValueChartData,
  bankReturn
) => {
  const {
    financialSituationConfig,
    reactsConfig,
    lossesConfig,
    investmentsKnowledgeConfig,
    tradingConfig,
    translationsConfig,
    reportConfig
  } = configuration;
  const phoneNumber = R.prop('phoneNumber', leaveDetailsFormValues);

  return {
    chartColorsConfig: {
      expectedPath: {
        expectedReturnColor: '',
        boundsColor: '',
        labelsColor: ''
      },
      expectedValue: {
        savingPlanColor: '',
        savingInBankAccountColor: '',
        labelsColor: ''
      }
    },
    clientInformation: {
      clientType: 'person',
      fields: [
        {
          name: 'name',
          order: 1,
          title: i18n('counseling.leaveDetails.name.title', translationsConfig),
          value: leaveDetailsFormValues?.name
        },
        {
          name: 'phoneNumber',
          order: 2,
          title: i18n(
            'counseling.leaveDetails.phoneNumber.title',
            translationsConfig
          ),
          value: formatPhoneNumber(phoneNumber)
        },
        {
          name: 'email',
          order: 3,
          title: i18n(
            'counseling.leaveDetails.email.title',
            translationsConfig
          ),
          value: leaveDetailsFormValues?.email
        }
      ]
    },
    generalInformation: {
      name: R.prop('name', leaveDetailsFormValues)
    },
    purposeAndRisk: {
      maxRiskScore,
      expectationOfRisk: R.prop('risk', userOptions),
      riskStrategy: R.prop('return', userOptions)
    },
    knowledgeAndExperience: getKnowledgeAndExperience(
      reactsConfig,
      lossesConfig,
      investmentsKnowledgeConfig,
      tradingConfig,
      translationsConfig,
      cultureCode,
      userOptions,
      percentByRisk
    ),
    personFinancialSituation: getFinancialSituation(
      financialSituationConfig,
      translationsConfig,
      cultureCode,
      userOptions
    ),
    investmentPlan: {
      monthlyDeposit: R.path(
        ['counseling', 'savingsPlan', 'monthlyDeposit'],
        userOptions
      ),
      firstDeposit: R.path(
        ['counseling', 'savingsPlan', 'firstDeposit'],
        userOptions
      )
    },
    expectedValueConfig: {
      bankReturn,
      compareWithBank: true
    },
    chartData,
    goals,
    analyticsComponents: {
      investmentPlan: true,
      portfolio: {
        assetAllocation: reportConfig.portfolio.isAssetAllocationChartVisible,
        broadAssetAllocation:
          reportConfig.portfolio.isBroadAssetAllocationChartVisible,
        fundAllocation: reportConfig.portfolio.isFundAllocationChartVisible
      },
      expectations: true,
      expectedPath: true,
      expectedValue: true
    },
    emailData: {
      name: R.prop('name', leaveDetailsFormValues),
      email: R.prop('email', leaveDetailsFormValues),
      phoneNumber: formatPhoneNumber(phoneNumber)
    },
    expectedPathChartData,
    expectedValueChartData
  };
};

export const mapUserDataToGoals = ({ userOptions, expectedValue }) => {
  return [
    {
      goalId: v1(),
      name: userOptions?.goal?.title,
      icon: buildPublicFileUrl(userOptions?.goal?.icon),
      expectedValue: expectedValue,
      purposeAndRiskScore: userOptions?.initialRiskCategory,
      data: {
        timeHorizon: userOptions?.horizon,
        firstDeposit: userOptions?.counseling?.savingsPlan?.firstDeposit,
        monthlyDeposit: userOptions?.counseling?.savingsPlan?.monthlyDeposit,
        portfolio: userOptions?.riskCategory,
        isPortfolioCustom: false,
        themes: userOptions?.counseling?.theme,
        riskScore: userOptions?.riskCategory
      }
    }
  ];
};
