import React from 'react';

import { CustomerConfig } from './types';
import { CustomerConfigContext } from './context';

export function useCustomerConfig() {
  const customerConfig = React.useContext<CustomerConfig>(
    CustomerConfigContext
  );

  return customerConfig;
}
