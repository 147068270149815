import * as R from 'ramda';

export const getYearOffset = (horizonsConfig, horizonCategory) => {
  let horizonsConfigItems = horizonsConfig;
  // Check if horizonsConfig is an array or an object with property items
  if (
    R.is(Object, horizonsConfigItems) &&
    horizonsConfigItems.items !== undefined
  ) {
    horizonsConfigItems = horizonsConfig.items;
  }

  const horizon = R.find(R.propEq('id', horizonCategory))(horizonsConfigItems);

  return !R.isNil(horizon) ? horizon.analysisProjectionYearsValue : null;
};

export const mapServerForecastToClient = (
  horizonsItems,
  forecast,
  horizon,
  currentDate
) => {
  const yearOffset = getYearOffset(horizonsItems, horizon);
  const monthsOffset = yearOffset * 12;
  const months = currentDate.getFullYear() * 12 + currentDate.getMonth();

  const trasformedForecast = R.map(i => {
    const date = new Date(i.date);

    return {
      value: i.value,
      lower_bound: i.lower_bound,
      upper_bound: i.upper_bound,
      timestamp: date.getTime(),
      months: date.getFullYear() * 12 + date.getMonth()
    };
  }, forecast);

  const expectedValue = R.pipe(
    R.find(i => i.months - months === monthsOffset),
    R.prop('value')
  )(trasformedForecast);

  const filteredForecast = R.filter(
    i => i.months - months <= monthsOffset,
    trasformedForecast
  );
  const averageTimeseries = R.map(
    i => [i.timestamp, i.value],
    filteredForecast
  );
  const deviationTimeseries = R.map(
    i => [i.timestamp, i.lower_bound, i.upper_bound],
    filteredForecast
  );

  return { expectedValue, averageTimeseries, deviationTimeseries };
};
