import * as R from 'ramda';
import moment from 'moment';

export const utcNow = () => new Date();

export const createUtc = (year, month, day) =>
  new Date(Date.UTC(year, month, day));

export const getHighchartsDayFormatKey = cultureCode =>
  mapFormatString(getMomentjsFormatKey(cultureCode));
export const getHighchartsMonthFormatKey = () => '%b %Y';
export const getHighchartsYearFormatKey = () => '%Y';

export const formatDate = (cultureCode, dateIsoString) => {
  const format = getMomentjsFormatKey(cultureCode);

  const result = moment.utc(dateIsoString).format(format);

  return result;
};

const getMomentjsFormatKey = cultureCode => {
  const format = cultureToFormatMap[cultureCode];

  return R.isNil(format) ? defaultFormat : format;
};

const defaultFormat = 'D/M/YYYY';
// culture code represents {ISO-639-1}-{ISO-3166-1}
// list of codes https://wiki.openstreetmap.org/wiki/Nominatim/Country_Codes
const cultureToFormatMap = {
  'no-no': 'DD.MM.YYYY',
  'en-us': 'M/D/YYYY',
  'sv-se': 'YYYY-MM-DD',
  'en-gb': 'DD/MM/YYYY',
  'ru-ru': 'DD.MM.YYYY',
  'fi-fi': 'D.M.YYYY',
  'da-dk': 'DD-MM-YYYY',
  'is-is': 'D.M.YYYY',
  'de-de': 'DD.MM.YYYY',
  'nl-nl': 'D-M-YYYY'
};

const Separators = ['/', '.', '-'];
const PartsMap = {
  D: '%e',
  DD: '%d',
  M: '%o',
  MM: '%m',
  YYYY: '%Y'
};
const mapFormatString = formatString => {
  const separator = R.find(
    separator => R.includes(separator, formatString),
    Separators
  );

  return R.pipe(
    R.split(separator),
    R.map(part => PartsMap[part]),
    R.join(separator)
  )(formatString);
};

// * Highcharts supported format keys:
// * - `%a`: Short weekday, like 'Mon'
// * - `%A`: Long weekday, like 'Monday'
// * - `%d`: Two digit day of the month, 01 to 31
// * - `%e`: Day of the month, 1 through 31
// * - `%w`: Day of the week, 0 through 6
// * - `%b`: Short month, like 'Jan'
// * - `%B`: Long month, like 'January'
// * - `%m`: Two digit month number, 01 through 12
// * - `%o`: Month number, 1 through 12
// * - `%y`: Two digits year, like 09 for 2009
// * - `%Y`: Four digits year, like 2009
// * - `%H`: Two digits hours in 24h format, 00 through 23
// * - `%k`: Hours in 24h format, 0 through 23
// * - `%I`: Two digits hours in 12h format, 00 through 11
// * - `%l`: Hours in 12h format, 1 through 12
// * - `%M`: Two digits minutes, 00 through 59
// * - `%p`: Upper case AM or PM
// * - `%P`: Lower case AM or PM
// * - `%S`: Two digits seconds, 00 through 59
// * - `%L`: Milliseconds (naming from Ruby)
// It is possible to create custom format letters and parsers for them for highcharts.

// date format sources:
// https://github.com/unicode-cldr/cldr-json (problem: short year format not acceptable for date input)
// moment js locale folder (problem: no en-us file)
// https://gist.github.com/mlconnor/1887156
