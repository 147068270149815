import { useSelector } from 'react-redux';
import { isNil } from 'ramda';

import Heading1 from 'features/report/shared/components/heading1';
import Section from 'features/report/shared/components/section';
import {
  Table,
  TableBody,
  TableBodyRow,
  TableBodyCell
} from 'features/report/shared/components/table';
import { i18n } from 'i18n';
import sessionSelectors from '../../../shared/services/session/selectors';
import { createUseStyles } from 'features/sharedModules/styles/components/styles';
import { useReportStore } from 'features/report/shared/services/reportStore';
import AdvisorNotesSection from './advisorNotesSection';

const useStyles = createUseStyles({
  tableCell: {
    width: '50%'
  },
  advisorNotes: {
    marginTop: '16px'
  }
});

const AdviceInformationSection = ({ header, adviceInformation }) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const reportStore = useReportStore();
  const adviceInformationNotes = reportStore.userData?.adviceInformationNotes;

  if (isNil(adviceInformation) || adviceInformation.length === 0) {
    return null;
  }

  return (
    <Section>
      <Heading1>{i18n(header, translationsConfig)}</Heading1>

      <Table>
        <TableBody>
          {adviceInformation.map(({ label, value, name }) => (
            <TableBodyRow key={name}>
              <TableBodyCell className={classes.tableCell}>
                {label}
              </TableBodyCell>
              <TableBodyCell className={classes.tableCell}>
                {value}
              </TableBodyCell>
            </TableBodyRow>
          ))}
        </TableBody>
      </Table>

      {adviceInformationNotes && (
        <AdvisorNotesSection
          text={adviceInformationNotes}
          className={classes.advisorNotes}
        />
      )}
    </Section>
  );
};

export default AdviceInformationSection;
