import React from 'react';
import { useSelector } from 'react-redux';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import { i18n } from 'i18n/index.js';
import {
  replaceTextWithFormatting,
  defaultReplacements
} from 'features/shared/components/i18n/utils/i18n.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import { FontWeights } from '../../shared/constants/fonts.js';
import Paragraph from '../../shared/components/paragraph';
import Heading1 from '../../shared/components/heading1';
import Section from '../../shared/components/section.js';

const useStyles = createUseStyles(theme => ({
  userName: {
    fontWeight: FontWeights.bold
  },
  customerName: {
    fontWeight: FontWeights.bold
  },
  advisorName: {
    fontWeight: FontWeights.bold
  }
}));

const BackgroundSection = ({
  generalInformation,
  backgroundTexts,
  advisorName = ''
}) => {
  const classes = useStyles();
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  const replacePosibilities = {
    '{userName}': (
      <span key="userName" className={classes.userName}>
        {generalInformation.name}
      </span>
    ),
    '{customerName}': (
      <span key="customerName" className={classes.customerName}>
        {i18n('report.background.customerName', translationsConfig)}
      </span>
    ),
    '{advisorName}': (
      <span key="advisorName" className={classes.advisorName}>
        {advisorName}
      </span>
    ),

    ...defaultReplacements
  };

  return (
    <Section data-testid={'background-section'}>
      <Heading1>
        {replaceTextWithFormatting(
          i18n('report.background.header', translationsConfig),
          replacePosibilities
        )}
      </Heading1>
      {backgroundTexts.map((t, index) => (
        <Paragraph key={index}>
          {replaceTextWithFormatting(
            i18n(t, translationsConfig),
            replacePosibilities
          )}
        </Paragraph>
      ))}
    </Section>
  );
};

export default BackgroundSection;
