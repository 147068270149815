import React from 'react';
import * as R from 'ramda';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import { i18n } from 'i18n/index.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors, {
  getNumberOfYearsSaving
} from 'features/shared/services/userOptions/selectors.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import Icon from 'features/shared/components/icon/index';

const useStyles = createUseStyles(theme => ({
  content: {
    display: 'flex',
    flexDirection: 'column',
    flex: '1',
    paddingTop: '50px',
    width: '100%'
  },
  subTitle: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.largePx,
    lineHeight: TextFontSizes.largeLineHeightPx,
    color: Colors.grayDark,
    paddingBottom: '18px',
    color: Colors.grayDark
  },
  text: {
    fontFamily: theme.textFontFamily,
    fontWeight: FontWeights.light,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    paddingBottom: '15px'
  },
  infoContainer: {
    padding: '0',
    '& li': {
      listStyleType: 'none',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      padding: '25px 0',

      '& div': {
        display: 'flex',
        alignItems: 'center'
      }
    }
  },
  mark: {
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.xLargePx,
    lineHeight: TextFontSizes.xLargeLineHeightPx,
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    color: theme.primaryColor,
    paddingLeft: '15px',
    whiteSpace: 'nowrap'
  },
  infoTitle: {
    fontFamily: theme.textFontFamily,
    paddingLeft: '20px'
  },
  divider: {
    height: '1px',
    backgroundColor: theme.placeholderColor
  },
  runTestButton: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  runAgainButton: {
    fontWeight: FontWeights.medium,
    color: `${theme.textColor} !important`
  }
}));

const AssessmentSummary = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const riskScore = useSelector(userOptionsSelectors.getRiskScore);
  const maxRiskScore = useSelector(sessionSelectors.getMaxRiskScore);
  const experienceAndKnowledgeScore = useSelector(
    userOptionsSelectors.getExperienceAndKnowledgeScore
  );
  const numberOfYearsSaving = useSelector(getNumberOfYearsSaving);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('counseling.assessmentSummary.naviTitle', translations)}
      </Navigation>

      <Page>
        <div className={classes.content}>
          <span className={classes.subTitle}>
            {i18n('counseling.assessmentSummary.subTitle', translations)}
          </span>
          <span className={classes.text}>
            {i18n('counseling.assessmentSummary.text_1', translations)}
          </span>
          <span className={classes.text}>
            {i18n('counseling.assessmentSummary.text_2', translations)}
          </span>
          <ul className={classes.infoContainer}>
            <li>
              <div>
                <Icon type="riskScore" />
                <span className={classes.infoTitle}>
                  {i18n('counseling.assessmentSummary.riskScore', translations)}
                </span>
              </div>
              <span className={classes.mark}>
                {!R.isNil(riskScore)
                  ? `${riskScore}/${maxRiskScore}`
                  : i18n('shared.noValueText', translations)}
              </span>
            </li>
            <div className={classes.divider} />
            <li>
              <div>
                <Icon type="experienceAndKnowledge" />
                <span className={classes.infoTitle}>
                  {i18n(
                    'counseling.assessmentSummary.experienceAndKnowledge',
                    translations
                  )}
                </span>
              </div>
              <span className={classes.mark}>
                {experienceAndKnowledgeScore
                  ? experienceAndKnowledgeScore
                  : i18n('shared.noValueText', translations)}
              </span>
            </li>
            <div className={classes.divider} />
            <li>
              <div>
                <Icon type="yearsSaving" />
                <span className={classes.infoTitle}>
                  {i18n(
                    'counseling.assessmentSummary.numberOfYearsSaving',
                    translations
                  )}
                </span>
              </div>
              <span className={classes.mark}>
                {numberOfYearsSaving
                  ? numberOfYearsSaving
                  : i18n('shared.noValueText', translations)}
              </span>
            </li>
          </ul>
          <div className={classes.runTestButton}>
            <Button
              type={ButtonType.secondary}
              onClick={() => {
                history.push(routeTemplates.savingGoals.build(customerId));
              }}
              className={classes.runAgainButton}
            >
              {i18n('shared.runItAgain', translations)}
            </Button>
          </div>
        </div>

        <Footer>
          {nextRoute && (
            <Button
              type={ButtonType.primary}
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n(
                'counseling.assessmentSummary.seeYourPortfolio',
                translations
              )}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default AssessmentSummary;
