import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';

import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { i18n } from 'i18n/index.js';
import Loading from 'assets/icons/loading.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import userOptionsSelectors from 'features/shared/services/userOptions/selectors.js';
import { creators as userOptionsCreator } from 'features/shared/services/userOptions/actions.js';
import Link from 'features/shared/components/link/index';
import Navigation from 'features/shared/components/screenLayout/navigation';
import Icon from 'features/shared/components/icon/index';
import YesNoInput from 'features/shared/components/yesNoInput/index.js';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';

const useStyles = createUseStyles(theme => {
  return {
    subTitle: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.largePx,
      lineHeight: TextFontSizes.largeLineHeightPx,
      textAlign: 'center',
      color: Colors.grayDark
    },
    text: {
      fontFamily: theme.subTitleFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      textAlign: 'center',
      paddingTop: '15px'
    },
    link: {
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.mediumPx,
      lineHeight: TextFontSizes.mediumLineHeightPx,
      textAlign: 'center',
      padding: '13px 0'
    },
    YesNoInputContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%'
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      width: '100%',
      flex: '1',
      justifyContent: 'center',
      padding: '30px 0'
    }
  };
});

const Buffer = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const userFinancialSituation = useSelector(
    userOptionsSelectors.getUserFinancialSituation
  );

  const dispatch = useDispatch();
  const userFinancialSituationBufferChanged = value => {
    dispatch(userOptionsCreator.userFinancialSituationBufferChanged(value));
  };

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('financialSituation.buffer.naviTitle', translations)}
      </Navigation>

      <Page>
        <div className={classes.content}>
          <Loading />

          <span className={classes.subTitle}>
            {i18n('financialSituation.buffer.subTitle', translations)}
          </span>

          <span className={classes.text}>
            {i18n('financialSituation.buffer.text', translations)}
          </span>

          <Link
            to={routeTemplates.whatIsBuffer.build(customerId)}
            className={classes.link}
          >
            {i18n('financialSituation.buffer.link.whatIsBuffer', translations)}
            <Icon type="navigation" />
          </Link>
        </div>

        <Footer>
          <div className={classes.YesNoInputContainer}>
            <YesNoInput
              onSelect={value => {
                userFinancialSituationBufferChanged(value);
                history.push(nextRoute.build(customerId));
              }}
            />
          </div>
        </Footer>
      </Page>
    </Layout>
  );
};

export default Buffer;
