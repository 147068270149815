import create from 'zustand';

import { PageStatus, PageStatuses } from 'features/shared/constants/statuses';

export type PageStoreData = {
  expectedValue: number | null;
  readExpectedValueStatus: PageStatus;
  returnValue: number | null;
  readRiskReturnStatus: PageStatus;
};

export type PageStoreApi = {
  reset: () => void;
  setExpectedValue: (expectedValue: number | null) => void;
  setReadExpectedValueStatus: (readExpectedValueStatus: PageStatus) => void;
  setReturnValue: (returnValue: number | null) => void;
  setReadRiskReturnStatus: (readRiskReturnStatus: PageStatus) => void;
};

export type PageStoreState = PageStoreData & PageStoreApi;

const defaultData: PageStoreData = {
  expectedValue: null,
  readExpectedValueStatus: PageStatuses.ready,
  returnValue: null,
  readRiskReturnStatus: PageStatuses.ready
};

export const usePageStore = create<PageStoreState>(set => ({
  ...defaultData,
  reset: () => {
    set(defaultData);
  },
  setExpectedValue: expectedValue => {
    set({ expectedValue });
  },
  setReadExpectedValueStatus: readExpectedValueStatus => {
    set({ readExpectedValueStatus });
  },
  setReadRiskReturnStatus: readRiskReturnStatus => {
    set({ readRiskReturnStatus });
  },
  setReturnValue: returnValue => {
    set({ returnValue });
  }
}));
