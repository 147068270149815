import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles({
  root: {
    display: 'inline-block',
    marginBottom: '12px'
  }
});

const DialogContentText = ({ children }) => {
  const classes = useStyles();

  return <span className={classes.root}>{children}</span>;
};

export default DialogContentText;
