import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  head: {
    fill: theme.primaryColor
  }
}));

const Looking = () => {
  const classes = useStyles();

  return (
    <svg
      width="110"
      height="80"
      viewBox="67 40 110 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="132.998" cy="103" r="10" fill="#132737" />
      <circle cx="108.999" cy="103" r="10" fill="#132737" />
      <path
        d="M126.584 81.1316C126.868 79.2871 128.44 77.9153 130.306 77.9618C132.229 78.0098 134.649 78.1327 136.508 78.4347C138.16 78.7032 140.222 79.2517 141.898 79.7421C143.687 80.2659 144.768 82.0479 144.483 83.8908C144.16 85.9848 142.194 87.4146 140.176 86.7668C138.519 86.2348 137.061 85.666 135.43 85.4142C133.73 85.1517 131.843 85.2333 129.817 85.2015C127.701 85.1682 126.261 83.2228 126.584 81.1316Z"
        fill="#132737"
      />
      <path
        d="M114.585 81.1009C114.202 79.2714 112.554 77.9861 110.691 78.1354C108.718 78.2935 106.225 78.5551 104.362 78.9602C102.75 79.3106 100.796 79.9452 99.203 80.5088C97.4484 81.1298 96.4718 82.9648 96.853 84.7866C97.2881 86.8662 99.3342 88.1954 101.315 87.4271C102.865 86.8259 104.215 86.2059 105.808 85.8727C107.509 85.5169 109.485 85.4879 111.58 85.3346C113.685 85.1805 115.018 83.1675 114.585 81.1009Z"
        fill="#132737"
      />
      <mask
        id="mask2"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="243"
        height="243"
      >
        <path
          d="M190.793 122.449H172.125V106.197V51.8906C172.125 49.2053 171.058 46.63 169.16 44.7312C167.261 42.8324 164.685 41.7656 162 41.7656H81C78.3147 41.7656 75.7394 42.8324 73.8405 44.7312C71.9417 46.63 70.875 49.2053 70.875 51.8906L70.875 106.629H58.8516C42.1048 106.629 34.1719 116.144 34.1719 132.891V159.785H50.625V132.891C50.625 127.312 55.1711 122.766 60.75 122.766H70.875V186.363H60.75V202.5H95.5547V186.363H85.4297V156.938H157.57V186.363H147.445V202.5H182.25V186.363H172.125V126.82V122.449V106.197H190.793C208.828 106.197 206.93 113.791 206.93 132.891V161.252H190.793V132.891V122.449ZM85.4297 57.2696H157.57V122.449H85.4297V57.2696Z"
          fill="#132736"
        />
      </mask>
      <g mask="url(#mask2)">
        <path
          d="M248 -10.5C248 59.364 191.364 116 121.5 116C51.636 116 -5 59.364 -5 -10.5C-5 -80.364 51.636 -137 121.5 -137C191.364 -137 248 -80.364 248 -10.5Z"
          className={classes.head}
        />
      </g>
    </svg>
  );
};

export default Looking;
