import * as R from 'ramda';

import { getColor } from 'features/shared/utils/colors.js';

const indexedMap = R.addIndex(R.map);

export const viewModes = {
  category: 'category',
  categorySelection: 'categorySelection'
};

export const getPortfolioDataSourcesByConfig = portfolioFundsConfig => {
  if (R.prop('viewMode', portfolioFundsConfig) === viewModes.category) {
    return { portfolio: true };
  }

  if (
    R.prop('viewMode', portfolioFundsConfig) === viewModes.categorySelection
  ) {
    return { portfolio: true, selection: true, funds: true };
  }

  return [];
};

const getCategoryName = (titleKey, portfolioItem) => {
  if (titleKey === 'subAssetClass') {
    return portfolioItem.AssetClass.SubAssetClass;
  }

  if (titleKey === 'mainAssetClass') {
    return portfolioItem.AssetClass.MainAssetClass;
  }

  return null;
};

export const getPortfolioDataFromServerDataByConfig = (
  portfolioFundsConfig,
  portfolioItems,
  selection,
  funds,
  chartColors
) => {
  if (R.prop('viewMode', portfolioFundsConfig) === viewModes.category) {
    const items = R.pipe(
      indexedMap((i, index, list) => {
        return {
          id: i.AssetClass.CategoryId,
          name: getCategoryName(R.prop('titleKey', portfolioFundsConfig), i),
          color: getColor(chartColors, index, R.length(list)),
          weight: Number(i.Weight),
          mainAssetClass: i.AssetClass.MainAssetClass,
          doesHaveDetails: false
        };
      }),
      R.prop('titleKey', portfolioFundsConfig) === 'mainAssetClass'
        ? R.pipe(
            R.groupBy(R.prop('mainAssetClass')),
            R.toPairs,
            indexedMap(
              ([mainAssetClass, groupedItems], groupIndex, groupsList) => ({
                id: mainAssetClass,
                name: mainAssetClass,
                color: getColor(chartColors, groupIndex, R.length(groupsList)),
                weight: R.pipe(R.map(R.prop('weight')), R.sum)(groupedItems),
                mainAssetClass: mainAssetClass,
                doesHaveDetails: false
              })
            )
          )
        : R.identity
    )(portfolioItems);

    return {
      items,
      isIsinVisible: false
    };
  }

  if (
    R.prop('viewMode', portfolioFundsConfig) === viewModes.categorySelection
  ) {
    const fundsByTicker = R.indexBy(R.prop('Ticker'), funds);
    const categoriesSelection = R.reduce(
      (acc, c) => {
        if (R.isNil(c.Instruments) || R.isEmpty(c.Instruments)) {
          return acc;
        }

        const instrument = c.Instruments[0];
        const instrumentDetails = fundsByTicker[instrument.Ticker];

        return R.assoc(
          c.CategoryId,
          {
            id: instrument.Ticker,
            isin: R.prop('ISIN', instrumentDetails),
            title: R.prop('Name', instrumentDetails)
          },
          acc
        );
      },
      {},
      selection
    );

    const items = R.pipe(
      indexedMap((i, index, list) => {
        const instrument = categoriesSelection[i.AssetClass.CategoryId];

        if (R.isNil(instrument)) {
          return null;
        }

        return {
          id: i.AssetClass.CategoryId,
          name: R.isNil(instrument) ? null : instrument.title,
          color: getColor(chartColors, index, R.length(list)),
          weight: Number(i.Weight),
          mainAssetClass: i.AssetClass.MainAssetClass,
          instrument,
          isin: R.isNil(instrument) ? null : instrument.isin,
          doesHaveDetails: true
        };
      }),
      R.reject(R.isNil)
    )(portfolioItems);

    return {
      items,
      isIsinVisible: true
    };
  }

  return {
    items: [],
    isIsinVisible: false
  };
};
