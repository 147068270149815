import React, { useEffect, useRef } from 'react';
import * as R from 'ramda';
import HighchartsReact from 'highcharts-react-official';
import Highcharts from 'highcharts';
import HighchartsMore from 'highcharts/highcharts-more';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import { useSelector } from 'react-redux';

import { useTheme } from 'features/sharedModules/styles/components/styles.js';
import { i18n } from 'i18n/index.js';
import { formatNumber } from 'features/shared/utils/number.js';
import { Colors } from 'features/shared/constants/colors.js';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import {
  getHighchartsDayFormatKey,
  getHighchartsMonthFormatKey,
  getHighchartsYearFormatKey
} from 'features/shared/utils/dateTime.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';

HighchartsMore(Highcharts);

// let ranges1 = [
//     [2, 22.1, 22.1],
//     [3, 22.1, 22.5],
//     [4, 22.4, 22.8],
//     [5, 22.5, 23.3],
//     [6, 22.6, 23.4],
//     [7, 22.8, 23.6],
//     [8, 23, 24],
//     [9, 23.4, 24.4],
//     [10, 23.5, 24.5]
//   ],
//   ranges2 = [
//     [2, 22.1, 22.1],
//     [3, 21.8, 22.8],
//     [4, 22.1, 23.1],
//     [5, 22.2, 23.6],
//     [6, 22.3, 23.7],
//     [7, 22.5, 23.9],
//     [8, 22.7, 24.3],
//     [9, 23.1, 24.7],
//     [10, 23.2, 24.8]
//   ],
//   ranges3 = [
//     [2, 22.1, 22.1],
//     [3, 21.5, 23.3],
//     [4, 21.8, 23.6],
//     [5, 21.9, 24.1],
//     [6, 21.8, 24.2],
//     [7, 22, 24.4],
//     [8, 22.2, 24.8],
//     [9, 22.6, 25.2],
//     [10, 22.7, 25.4]
//   ],
//   averages = [
//     [1, 21.5],
//     [2, 22.1],
//     [3, 22.3],
//     [4, 22.6],
//     [5, 22.9],
//     [6, 23],
//     [7, 23.2],
//     [8, 23.5],
//     [9, 23.9],
//     [10, 24.0]
//   ];

const useStyles = createUseStyles(theme => ({
  highcharts: {
    flex: '1 1 auto',
    width: '100%',
    '& svg': {
      width: '100%'
    },
    '& .highcharts-container': {
      width: '100% !important'
    }
  },
  headerFormat: {
    fontFamily: theme.textFontFamily,
    fontSize: '12px',
    lineHeight: '14.54px',
    color: Colors.gray500
  },
  tooltipFirstLine: {
    fontFamily: theme.textFontFamily,
    fontSize: '12px',
    lineHeight: '14.54px',
    color: Colors.blue300,
    display: 'inline-block',
    marginTop: '10px',
    marginBottom: '5px'
  },
  tooltipLastLine: {
    fontFamily: theme.textFontFamily,
    fontSize: '12px',
    lineHeight: '14.54px',
    color: Colors.gray155
  }
}));

const getOptions = (cultureCode, classes) => {
  const highchartsMonthFormatKey = getHighchartsMonthFormatKey();

  return {
    credits: {
      enabled: false
    },
    chart: {
      // type: 'arearange',
      animation: false,
      spacingTop: 0,
      spacingRight: 0
      // spacingLeft: 0
    },
    title: {
      text: null
    },
    legend: {
      enabled: false
    },
    xAxis: {
      tickWidth: 0,
      lineWidth: 0,
      type: 'datetime',
      labels: {
        format: '{value: %Y}'
      },
      crosshair: true
    },
    yAxis: {
      alternateGridColor: Colors.gridLineColor,
      title: {
        text: null
      },
      gridLineColor: Colors.white,
      labels: {
        style: {
          color: Colors.gridLabelColor
        }
        // align: 'left',
        // x: 5,
        // y: 4
      },
      showLastLabel: false
    },
    tooltip: {
      xDateFormat: highchartsMonthFormatKey,
      headerFormat: `<span class="${classes.headerFormat}">{point.key}</span><br>`,
      pointFormatter: function () {
        const value =
          this.series.options.id === 'average'
            ? formatNumber(cultureCode, this.y, 0, 0)
            : `${formatNumber(cultureCode, this.low, 0, 0)} - ${formatNumber(
                cultureCode,
                this.high,
                0,
                0
              )}`;

        return `<span class="${classes.tooltipFirstLine}">${this.series.name}</span><br><span class="${classes.tooltipLastLine}">${value}</span><br>`;
      },
      shared: true,
      useHTML: true
      // enabled: false
    },
    plotOptions: {
      series: {
        // enableMouseTracking: false,
        animation: false,
        marker: {
          enabled: false
        }
      },
      spline: {
        lineWidth: 4,
        states: {
          hover: {
            lineWidth: 5
          }
        },
        marker: {
          enabled: false
        },
        pointStart: 2010
      }
    },

    series: [
      // {
      //   data: averages,
      //   zIndex: 1,
      //   color: '#FC8834',
      //   marker: {
      //     enabled: false
      //   }
      // },
      // {
      //   data: ranges1,
      //   type: 'arearange',
      //   lineWidth: 0,
      //   linkedTo: ':previous',
      //   color: Colors.montecarloGraphColor,
      //   fillOpacity: 0.2,
      //   zIndex: 0,
      //   marker: {
      //     enabled: false
      //   }
      // },
      // {
      //   data: ranges2,
      //   type: 'arearange',
      //   lineWidth: 0,
      //   linkedTo: ':previous',
      //   color: Colors.montecarloGraphColor,
      //   fillOpacity: 0.2,
      //   zIndex: -1,
      //   marker: {
      //     enabled: false
      //   }
      // },
      // {
      //   data: ranges3,
      //   type: 'arearange',
      //   lineWidth: 0,
      //   linkedTo: ':previous',
      //   color: Colors.montecarloGraphColor,
      //   fillOpacity: 0.2,
      //   zIndex: -2,
      //   marker: {
      //     enabled: false
      //   }
      // }
    ]
  };
};

const Montecarlo = ({ averageTimeseries, deviationTimeseries }) => {
  const theme = useTheme();
  const classes = useStyles();
  const chartComponent = useRef();
  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const cultureCode = useSelector(sessionSelectors.getCultureCode);

  const chartOptions = R.pipe(
    R.over(
      R.lensProp('series'),
      R.append({
        id: 'average',
        name: i18n('counseling.montecarlo.legend.expectedReturn', translations),
        data: averageTimeseries,
        zIndex: 1,
        color: theme.imagePrimaryColor,
        marker: {
          enabled: false
        }
      })
    ),
    R.over(
      R.lensProp('series'),
      R.append({
        id: 'deviation',
        name: i18n(
          'counseling.montecarlo.legend.optimisticAndPessimisticScenario',
          translations
        ),
        data: deviationTimeseries,
        type: 'arearange',
        lineWidth: 0,
        linkedTo: ':previous',
        color: Colors.montecarloGraphColor,
        fillOpacity: 0.2,
        zIndex: 0,
        marker: {
          enabled: false
        }
      })
    )
  )(getOptions(cultureCode, classes));

  useEffect(() => {
    const reflow = () => {
      chartComponent.current.chart.reflow();
    };

    reflow();
    window.addEventListener('resize', reflow);
    return () => {
      window.removeEventListener('resize', reflow);
    };
  }, []);

  return (
    <div className={classes.highcharts}>
      <HighchartsReact
        ref={chartComponent}
        highcharts={Highcharts}
        containerProps={{ style: { width: '100%' } }}
        options={chartOptions}
      />
    </div>
  );
};

export default Montecarlo;
