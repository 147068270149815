import axios from 'axios';

import config from 'config/index.js';
import { removeQAuthApiAccessToken } from 'features/shared/services/session/storage.js';

const createAxios = config => {
  const newInstance = axios.create(config);

  const UNAUTHORIZED = 401;
  newInstance.interceptors.response.use(
    response => response,
    error => {
      if (error?.response?.status === UNAUTHORIZED) {
        removeQAuthApiAccessToken();
      }
      return Promise.reject(error);
    }
  );

  return newInstance;
};

export const savingCalculatorApi = createAxios({
  baseURL: config.REACT_APP_SAVING_CALCULATOR_API_URL
});

export const savingRoboApi = createAxios({
  baseURL: config.REACT_APP_FRONT_API_URL
});

export const clientsApiUrl = createAxios({
  baseURL: config.REACT_APP_USE_LEGACY_CONFIGURATION_ENDPOINT
    ? `${config.REACT_APP_FRONT_API_URL}/customers`
    : `${config.REACT_APP_FRONT_API_V2_URL}/customers`
});

export const advisoryRoboApi = createAxios({
  baseURL: config.REACT_APP_ADVISORY_API_URL
});

export const advisoryRoboApiV2 = createAxios({
  baseURL: config.REACT_APP_ADVISORY_API_V2_URL
});

export const qAuthApi = axios.create({
  baseURL: config.REACT_APP_QAUTH_API_URL
});

export const getUri = settings =>
  `${config.REACT_APP_FRONT_API_URL}/${savingRoboApi.getUri(settings)}`;

export const buildPublicFileUrl = (
  fileUrl,
  asAttachment = false,
  attachmentFilename
) => {
  if (fileUrl != null) {
    return fileUrl.slice(0, 4) === 'http'
      ? fileUrl
      : getUri({
          method: 'get',
          url: fileUrl,
          params: asAttachment
            ? {
                asAttachment,
                attachmentFilename
              }
            : undefined
        });
  }
};
