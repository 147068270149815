import React from 'react';
import { useSelector } from 'react-redux';

import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Paragraph from '../../shared/components/paragraph';
import Heading2 from '../../shared/components/heading2';
import Section from '../../shared/components/section.js';

const AboutCustomerSection = () => {
  const reportConfig = useSelector(sessionSelectors.getReportConfig);
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  return (
    <Section>
      <Heading2>
        {i18n('report.aboutCustomer.header', translationsConfig)}
      </Heading2>
      {reportConfig.aboutCustomerTexts.map((t, index) => (
        <Paragraph key={index}>{i18n(t, translationsConfig)}</Paragraph>
      ))}
    </Section>
  );
};

export default AboutCustomerSection;
