import React from 'react';
import { Route, Switch } from 'react-router-dom';

import MainLayout from 'features/shared/components/mainLayout/index.js';
import routeTemplates from 'features/shared/utils/routeTemplates.js';
import Themes from 'features/counseling/themes/components/index.js';
import SavingsPlan from 'features/counseling/savingsPlan/components/index.js';
import AssessmentSummary from 'features/counseling/assessmentSummary/components/index.js';
import PortfolioFunds from 'features/counseling/portfolioFunds/components/index.js';
import LeaveDetails from 'features/counseling/leaveDetails/components/index.js';
import Backtest from 'features/counseling/backtest/components/index.js';
import Montecarlo from 'features/counseling/montecarlo/components/index';
import Fees from 'features/counseling/fees/components/index.js';
import SuccessScreen from 'features/counseling/successScreen/components/index.js';
import Signing from 'features/counseling/signing/components/index.js';
import Aml1 from '../../aml1/components/index.js';
import Aml2 from '../../aml2/components/index.js';

const Counseling = () => {
  return (
    <MainLayout>
      <Switch>
        <Route path={routeTemplates.themes.config} component={Themes} />
        <Route
          path={routeTemplates.savingsPlan.config}
          component={SavingsPlan}
        />
        <Route
          path={routeTemplates.assessmentSummary.config}
          component={AssessmentSummary}
        />
        <Route
          path={routeTemplates.portfolioFunds.config}
          component={PortfolioFunds}
        />
        <Route
          path={routeTemplates.leaveDetails.config}
          component={LeaveDetails}
        />
        <Route path={routeTemplates.backtest.config} component={Backtest} />
        <Route path={routeTemplates.montecarlo.config} component={Montecarlo} />
        <Route path={routeTemplates.fees.config} component={Fees} />
        <Route path={routeTemplates.aml1.config} component={Aml1} />
        <Route path={routeTemplates.aml2.config} component={Aml2} />
        <Route path={routeTemplates.signing.config} component={Signing} />
        <Route
          path={routeTemplates.successScreen.config}
          component={SuccessScreen}
        />
      </Switch>
    </MainLayout>
  );
};

export default Counseling;
