import React from 'react';
import { useSelector } from 'react-redux';

import { i18n } from 'i18n/index.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Paragraph from '../../shared/components/paragraph';
import Heading1 from '../../shared/components/heading1';
import Section from '../../shared/components/section.js';

const TradeAuthorizationSection = ({ accountNumber }) => {
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );

  return (
    <Section>
      <Heading1>
        {i18n('report.tradeOfAuth.header', translationsConfig)}
      </Heading1>
      <Paragraph>
        {i18n('report.tradeOfAuth.text_1', translationsConfig).replace(
          '{accountNumber}',
          accountNumber
        )}
      </Paragraph>
      <Paragraph>
        {i18n('report.tradeOfAuth.text_2', translationsConfig)}
      </Paragraph>
      <Paragraph>
        {i18n('report.tradeOfAuth.text_3', translationsConfig)}
      </Paragraph>
    </Section>
  );
};

export default TradeAuthorizationSection;
