import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  chart: {
    fill: theme.imageSecondaryColor
  },
  section: {
    fill: theme.imagePrimaryColor
  }
}));

const Chart = () => {
  const classes = useStyles();

  return (
    <svg
      width="112"
      height="112"
      viewBox="0 0 112 112"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M52.2667 11.3896C30.5089 13.1891 13.1891 30.5089 11.3896 52.2667H52.2667V11.3896ZM90.1907 27.0886L61.2792 56.0001L90.1907 84.9115C96.795 77.1051 100.8 67.0283 100.8 56.0001C100.8 44.9718 96.795 34.895 90.1907 27.0886ZM11.3896 59.7334C13.2936 82.7233 32.5174 100.8 56.0001 100.8C67.0283 100.8 77.1051 96.7949 84.9115 90.1907L54.4542 59.7334H11.3896Z"
          className={classes.chart}
        />
        <path
          d="M62.4668 4.92285V40.5208L87.6449 15.3354C80.7308 9.48895 72.0279 5.71432 62.4668 4.92285Z"
          className={classes.section}
        />
      </g>
      <defs>
        <clipPath id="clip0">
          <rect width="112" height="112" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Chart;
