import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  head: {
    fill: theme.primaryColor
  }
}));

const Aha = () => {
  const classes = useStyles();

  return (
    <svg
      width="110"
      height="80"
      viewBox="67 40 110 80"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="135" cy="93" r="10" fill="#132737" />
      <circle cx="109" cy="93" r="10" fill="#132737" />
      <path
        d="M131.252 69.78C131.537 67.9355 133.109 66.5637 134.975 66.6103C136.898 66.6583 139.317 66.7812 141.176 67.0832C142.829 67.3517 144.891 67.9002 146.567 68.3905C148.356 68.9143 149.437 70.6964 149.152 72.5392C148.829 74.6332 146.862 76.0631 144.845 75.4153C143.188 74.8833 141.729 74.3145 140.098 74.0626C138.399 73.8001 136.512 73.8818 134.485 73.8499C132.37 73.8166 130.929 71.8713 131.252 69.78Z"
        fill="#132737"
      />
      <path
        d="M112.866 69.8637C112.483 68.0341 110.834 66.7488 108.971 66.8982C106.998 67.0563 104.506 67.3178 102.642 67.723C101.031 68.0733 99.0765 68.7079 97.4837 69.2716C95.729 69.8925 94.7524 71.7276 95.1336 73.5494C95.5688 75.6289 97.6149 76.9582 99.5956 76.1899C101.146 75.5887 102.496 74.9687 104.089 74.6355C105.789 74.2797 107.766 74.2507 109.86 74.0974C111.966 73.9432 113.298 71.9302 112.866 69.8637Z"
        fill="#132737"
      />
      <mask
        id="mask0"
        mask-type="alpha"
        maskUnits="userSpaceOnUse"
        x="0"
        y="0"
        width="243"
        height="243"
      >
        <path
          d="M190.793 122.449H172.125V106.197V51.8906C172.125 49.2053 171.058 46.63 169.16 44.7312C167.261 42.8324 164.685 41.7656 162 41.7656H81C78.3147 41.7656 75.7394 42.8324 73.8405 44.7312C71.9417 46.63 70.875 49.2053 70.875 51.8906L70.875 106.629H58.8516C42.1048 106.629 34.1719 116.144 34.1719 132.891V159.785H50.625V132.891C50.625 127.312 55.1711 122.766 60.75 122.766H70.875V186.363H60.75V202.5H95.5547V186.363H85.4297V156.938H157.57V186.363H147.445V202.5H182.25V186.363H172.125V126.82V122.449V106.197H190.793C208.828 106.197 206.93 113.791 206.93 132.891V161.252H190.793V132.891V122.449ZM85.4297 57.2696H157.57V122.449H85.4297V57.2696Z"
          fill="#900032"
        />
      </mask>
      <g mask="url(#mask0)">
        <path
          d="M248 -10.5C248 59.364 191.364 116 121.5 116C51.636 116 -5 59.364 -5 -10.5C-5 -80.364 51.636 -137 121.5 -137C191.364 -137 248 -80.364 248 -10.5Z"
          className={classes.head}
        />
      </g>
    </svg>
  );
};

export default Aha;
