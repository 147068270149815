import { useSelector, useStore } from 'react-redux';

import { i18n } from '../../../../i18n';
import sessionSelectors from '../../../shared/services/session/selectors';
import Heading1 from '../../shared/components/heading1';
import Paragraph from '../../shared/components/paragraph';
import GoalPortfolio from './goalPortfolio';
import ExpectationsSection from '../../body/components/expectationsSection';
import { getExpectedChartGoalsData } from 'features/counseling/successScreen/utils';

const PortfolioSection = ({
  goals,
  analyticsComponents,
  expectedValuePlace,
  expectationsSection
}) => {
  const translationsConfig = useSelector(
    sessionSelectors.getTranslationsConfig
  );
  const reduxStore = useStore();
  const horizonsConfig = sessionSelectors.getHorizonsConfig(
    reduxStore.getState()
  );
  const goalsWithAdjustedTimeHorizon = getExpectedChartGoalsData(
    goals,
    horizonsConfig
  );

  return (
    <>
      <Heading1>{i18n('report.portfolio.header', translationsConfig)}</Heading1>
      <Paragraph>{i18n('report.portfolio.text', translationsConfig)}</Paragraph>
      <br />
      {goalsWithAdjustedTimeHorizon.map((goal, idx) => (
        <div key={`goal-${idx}`}>
          <GoalPortfolio
            analyticsComponents={analyticsComponents}
            assetClassAllocationAdvisorNotes={
              goal.data.assetClassAllocationAdvisorNotes
            }
            fundAllocationAdvisorNotes={goal.data.fundAllocationAdvisorNotes}
            goalId={goal.goalId}
            goalName={goal.name}
            isPortfolioCustom={goal.data.isPortfolioCustom}
          />
          {expectationsSection && analyticsComponents?.expectations && (
            <ExpectationsSection
              expectedValuePlace={expectedValuePlace}
              goal={goal}
            />
          )}
        </div>
      ))}
    </>
  );
};

export default PortfolioSection;
