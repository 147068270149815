import React from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

const useStyles = createUseStyles(theme => ({
  circle: {
    fill: theme.imageSecondaryColor
  },
  dots: {
    fill: theme.imagePrimaryColor
  }
}));

const Loading = () => {
  const classes = useStyles();

  return (
    <svg
      width="205"
      height="205"
      viewBox="0 0 205 205"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0)">
        <path
          d="M64.1719 65.2507L60.4277 62.789L56.218 64.3062L54.9131 68.589L55.5116 70.2507L59.2558 72.7123L63.4655 71.1952L64.7704 66.9123L64.1719 65.2507ZM102.849 154.703L99.1136 152.236L94.8954 153.758L93.5906 158.041L94.1891 159.703L97.9332 162.165L102.143 160.647L103.448 156.365L102.849 154.703ZM54.6258 82.6812L50.8816 80.2196L46.6719 81.7367L45.3719 86.028L45.9655 87.6812L49.7097 90.1428L53.9194 88.6257L55.2243 84.3429L54.6258 82.6812ZM83.6234 149.723L79.8792 147.261L75.6695 148.778L74.3646 153.061L74.9631 154.723L78.7073 157.185L82.917 155.667L84.217 151.376L83.6234 149.723ZM51.8657 102.373L48.1167 99.9032L43.9118 101.429L42.607 105.712L43.2055 107.373L46.9412 109.84L51.1594 108.318L52.4594 104.027L51.8657 102.373ZM67.3654 138.282L63.6212 135.82L59.403 137.342L58.0982 141.625L58.7051 143.282L62.4408 145.749L66.659 144.227L67.9639 139.944L67.3654 138.282ZM56.3187 121.746L52.5745 119.285L48.3563 120.807L47.0564 125.098L47.6584 126.746L51.3941 129.213L55.6123 127.691L56.9172 123.408L56.3187 121.746Z"
          className={classes.dots}
        />
        <g clipPath="url(#clip1)">
          <path
            d="M156.952 73.2298C141.702 43.8775 105.4 32.399 76.0472 47.6486C75.455 47.9458 74.9277 48.3577 74.496 48.8604C74.0643 49.3631 73.7368 49.9466 73.5326 50.5769C73.3283 51.2073 73.2514 51.872 73.3063 52.5323C73.3612 53.1927 73.5468 53.8355 73.8523 54.4235C74.1578 55.0115 74.5771 55.533 75.0858 55.9575C75.5946 56.3821 76.1827 56.7012 76.8159 56.8965C77.449 57.0918 78.1147 57.1593 78.7742 57.0951C79.4337 57.0309 80.0739 56.8363 80.6575 56.5225C105.214 43.7645 135.32 53.2838 148.078 77.8401C160.836 102.396 151.317 132.503 126.761 145.261C126.168 145.558 125.641 145.97 125.209 146.473C124.778 146.975 124.45 147.559 124.246 148.189C124.042 148.82 123.965 149.484 124.02 150.145C124.075 150.805 124.26 151.448 124.566 152.036C124.871 152.624 125.29 153.145 125.799 153.57C126.308 153.994 126.896 154.313 127.529 154.509C128.162 154.704 128.828 154.772 129.488 154.707C130.147 154.643 130.787 154.448 131.371 154.135C160.723 138.885 172.202 102.582 156.952 73.2298Z"
            className={classes.circle}
          />
        </g>
      </g>
      <defs>
        <clipPath id="clip0">
          <rect
            x="129.904"
            width="150"
            height="150"
            transform="rotate(60 129.904 0)"
            fill="white"
          />
        </clipPath>
        <clipPath id="clip1">
          <rect
            x="129.808"
            y="-2"
            width="150"
            height="150"
            transform="rotate(60 129.808 -2)"
            fill="white"
          />
        </clipPath>
      </defs>
    </svg>
  );
};

export default Loading;
