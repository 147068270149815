import React from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import * as R from 'ramda';

import { i18n } from 'i18n/index.js';
import SavingRoboHello from 'assets/saving-robo-icons/hello.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import Logo from 'features/shared/components/logo/index.js';
import { TextFontSizes, FontWeights } from 'features/shared/constants/fonts.js';
import { Colors } from 'features/shared/constants/colors.js';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';

const useStyles = createUseStyles(theme => {
  return {
    logo: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      paddingTop: '15px'
    },
    content: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      padding: '50px 0'
    },
    title: {
      fontFamily: theme.headerFontFamily,
      fontSize: TextFontSizes.xLargePx,
      lineHeight: TextFontSizes.xLargeLineHeightPx,
      paddingBottom: '15px',
      color: Colors.grayDark
    },
    text: {
      fontFamily: theme.textFontFamily,
      fontSize: TextFontSizes.smallPx,
      lineHeight: TextFontSizes.smallLineHeightPx,
      fontWeight: FontWeights.light,
      textAlign: 'center'
    }
  };
});

const Intro = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);
  const logos = useSelector(sessionSelectors.getLogosConfig);
  const introMessages = useSelector(sessionSelectors.getIntroMessages);

  return (
    <Layout>
      <Page showProgressBar={false}>
        <div className={classes.logo}>
          <Logo type={logos.logo} />
        </div>

        <div className={classes.content}>
          <SavingRoboHello />

          <span className={classes.title}>
            {i18n('intro.hello', translations)}
          </span>

          {introMessages.map((m, index) => (
            <span key={index} className={classes.text}>
              {i18n(m, translations)}
            </span>
          ))}
        </div>

        <Footer>
          {nextRoute && (
            <Button
              type={ButtonType.primary}
              onClick={() => {
                history.push(nextRoute.build(customerId));
              }}
            >
              {i18n('shared.start', translations)}
            </Button>
          )}
        </Footer>
      </Page>
    </Layout>
  );
};

export default Intro;
