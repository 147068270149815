import React, { Fragment } from 'react';
import { useSelector } from 'react-redux';
import { useParams, useHistory } from 'react-router';
import { Form, FormSpy } from 'react-final-form';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import { i18n } from 'i18n/index.js';
import profileImage from 'assets/profile.svg';
import emailImage from 'assets/email.svg';
import phoneImage from 'assets/phone.svg';
import { TextFontSizes } from 'features/shared/constants/fonts.js';
import { usePrevRoute } from 'features/sharedModules/router/components/usePrevRoute.js';
import { useNextRoute } from 'features/sharedModules/router/components/useNextRoute.js';
import sessionSelectors from 'features/shared/services/session/selectors.js';
import Navigation from 'features/shared/components/screenLayout/navigation';
import Layout from 'features/shared/components/screenLayout/layout.js';
import Header from 'features/shared/components/screenLayout/header.js';
import Page from 'features/shared/components/screenLayout/page.js';
import Footer from 'features/shared/components/screenLayout/footer.js';
import Button, { ButtonType } from 'features/shared/components/button/index';
import FinalFormTextInput from 'features/sharedModules/finalForm/components/textInput.js';
import FinalFormPhoneNumberInput from 'features/sharedModules/finalForm/components/phoneNumberInput.js';
import { form as leaveDetailsForm } from '../services/form.js';

const useStyles = createUseStyles(theme => ({
  text: {
    fontFamily: theme.subTitleFontFamily,
    fontSize: TextFontSizes.smallPx,
    lineHeight: TextFontSizes.smallLineHeightPx,
    paddingBottom: '10px'
  },
  form: {
    marginTop: '20px'
  },
  section: {
    display: 'flex',
    alignItems: 'flex-start',

    '& + &': {
      marginTop: '20px'
    }
  },
  icon: {
    marginRight: '20px'
  }
}));

const LeaveDetails = () => {
  const classes = useStyles();
  const history = useHistory();
  const { customerId } = useParams();

  const prevRoute = usePrevRoute();
  const nextRoute = useNextRoute();

  const translations = useSelector(sessionSelectors.getTranslationsConfig);

  return (
    <Layout>
      <Navigation prevRoute={prevRoute} customerId={customerId}>
        {i18n('counseling.leaveDetails.naviTitle', translations)}
      </Navigation>

      <Page>
        <div>
          <Header>{i18n('counseling.leaveDetails.title', translations)}</Header>
          <div className={classes.text}>
            {i18n('counseling.leaveDetails.text_1', translations)}
          </div>
          <div className={classes.text}>
            {i18n('counseling.leaveDetails.text_2', translations)}
          </div>
        </div>

        <Form form={leaveDetailsForm}>
          {() => (
            <Fragment>
              <div className={classes.form}>
                <div className={classes.section}>
                  <div className={classes.icon}>
                    <img src={profileImage} />
                  </div>
                  <div>
                    <FinalFormTextInput
                      name="name"
                      placeholder={i18n(
                        'counseling.leaveDetails.namePlaceholder',
                        translations
                      )}
                    />
                  </div>
                </div>
                <div className={classes.section}>
                  <div className={classes.icon}>
                    <img src={emailImage} />
                  </div>
                  <div>
                    <FinalFormTextInput
                      name="email"
                      placeholder={i18n(
                        'counseling.leaveDetails.emailPlaceholder',
                        translations
                      )}
                    />
                  </div>
                </div>
                <div className={classes.section}>
                  <div className={classes.icon}>
                    <img src={phoneImage} />
                  </div>
                  <div>
                    <FinalFormPhoneNumberInput
                      name="phoneNumber"
                      placeholder={i18n(
                        'counseling.leaveDetails.phoneNumberPlaceholder',
                        translations
                      )}
                    />
                  </div>
                </div>
              </div>

              <Footer>
                {nextRoute && (
                  <FormSpy subscription={{ hasValidationErrors: true }}>
                    {({ hasValidationErrors }) => (
                      <Button
                        disabled={hasValidationErrors}
                        type={ButtonType.primary}
                        onClick={() => {
                          history.push(nextRoute.build(customerId));
                        }}
                      >
                        {i18n('shared.continue', translations)}
                      </Button>
                    )}
                  </FormSpy>
                )}
              </Footer>
            </Fragment>
          )}
        </Form>
      </Page>
    </Layout>
  );
};

export default LeaveDetails;
