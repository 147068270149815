import { useState, useEffect } from 'react';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';
import CircularProgress from '@material-ui/core/CircularProgress';

const useCircularProgressStyles = createUseStyles(theme => ({
  root: {
    color: `${theme.primaryColor} !important`
  }
}));

const CircularProgressContainer = props => {
  const classes = useCircularProgressStyles();

  return <CircularProgress classes={classes} {...props} />;
};

const useStyles = createUseStyles(theme => ({
  root: {
    flex: '1 1 auto',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  }
}));

const ProgressBar = () => {
  const classes = useStyles();
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    function tick() {
      setProgress(oldProgress => (oldProgress >= 100 ? 0 : oldProgress + 1));
    }

    const timer = setInterval(tick, 20);
    return () => {
      clearInterval(timer);
    };
  }, []);

  return (
    <div className={classes.root}>
      <CircularProgressContainer
        variant="determinate"
        value={progress}
        color="secondary"
      />
    </div>
  );
};

export default ProgressBar;
