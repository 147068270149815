import React from 'react';
import axios from 'axios';
import { isNil } from 'ramda';
import { useParams } from 'react-router';

import {
  createAnalyzeForecast,
  getQAuthAccessToken
} from 'features/shared/api';
import { useExpectedPathChartStore } from '../services/expectedPathChart';
import { throwSafeError } from 'features/shared/utils/throwSafeError';
import { usePortfolioChartStore } from '../services/portfolioChart';
import { RoboFrontParams } from 'features/shared/utils/types';
import { DEFAULT_CONFIDENCE } from 'features/shared/constants/chart';

export function useReadExpectedPathChartData() {
  const { customerId } = useParams<RoboFrontParams>();
  const cancelTokenSourceRef = React.useRef<any>();
  const expectedPathChartStoreState = useExpectedPathChartStore.getState();

  const readExpectedPathChartData = async goals => {
    if (!isNil(cancelTokenSourceRef.current)) {
      cancelTokenSourceRef.current.cancel();
    }
    const cancelTokenSource = axios.CancelToken.source();
    cancelTokenSourceRef.current = cancelTokenSource;

    try {
      const { chartData } = usePortfolioChartStore.getState();
      const currentDate = new Date();
      const qAuthAccessToken = await getQAuthAccessToken(
        customerId,
        cancelTokenSource.token
      );

      for (const goal of goals) {
        const portfolio = chartData
          .find(({ goalId }) => goalId === goal.goalId)
          .categorySelectionPortfolio.map(({ id, weight }) => ({ id, weight }));

        const timeHorizon = goal.data.timeHorizon;

        const createAnalyzeForecastResponse = await createAnalyzeForecast(
          qAuthAccessToken,
          cancelTokenSource.token,
          {
            confidence_interval: DEFAULT_CONFIDENCE,
            initial_deposit: goal.data.firstDeposit,
            monthly_saving: goal.data.monthlyDeposit,
            portfolio,
            time_horizon: timeHorizon
          }
        );

        const data = {
          forecast: createAnalyzeForecastResponse.data.forecast,
          timeHorizon,
          currentDate,
          goalId: goal.goalId
        };

        expectedPathChartStoreState.addGoalData(data);
      }
    } catch (error) {
      if (!axios.isCancel(error)) {
        expectedPathChartStoreState.resetChartData();
        throwSafeError(error);
      }
    }
  };

  return readExpectedPathChartData;
}
