import React, { Fragment } from 'react';
import classNames from 'classnames';
import * as R from 'ramda';
import MuiCheckbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Icon from '@material-ui/core/Icon';
import { connect } from 'react-redux';
import { createUseStyles } from 'features/sharedModules/styles/components/styles.js';

import sessionSelectors from 'features/shared/services/session/selectors.js';
import { i18n } from 'i18n/index.js';
import { Colors } from 'features/shared/constants/colors.js';
import { TextFontSizes } from 'features/shared/constants/fonts.js';

export const SelectModes = {
  checkbox: 'checkbox',
  full: 'full'
};

const useCheckboxStyles = createUseStyles(theme => ({
  root: {
    color: `${Colors.sliderBackgroundColor} !important`,

    '&$checked': {
      color: `${theme.primaryColor} !important`
    }
  },
  checked: {}
}));

const Checkbox = props => {
  const classes = useCheckboxStyles();

  return <MuiCheckbox color="default" classes={classes} {...props} />;
};

const useStyles = createUseStyles(theme => ({
  list: {
    width: '100%',
    backgroundColor: Colors.white,
    boxShadow:
      '0px 4px 15px ' +
      Colors.listShadowColor +
      ', 0px 6px 15px ' +
      Colors.listShadowColor +
      ', 0px 8px 15px ' +
      Colors.listShadowColor +
      ', 0px 10px 15px ' +
      Colors.listShadowColor +
      ', 0px 12px 15px ' +
      Colors.listShadowColor +
      ', 0px 14px 35px ' +
      Colors.listShadowColor,
    padding: '0',
    borderRadius: '2px'
  },
  listItem: {
    listStyleType: 'none',
    minHeight: '60px',
    display: 'flex',
    alignItems: 'center',
    padding: '10px 14px',
    cursor: 'pointer',
    fontFamily: theme.textFontFamily,
    fontSize: TextFontSizes.mediumPx,
    lineHeight: TextFontSizes.mediumLineHeightPx,
    position: 'relative',
    color: Colors.grayLight,
    '-webkit-tap-highlight-color': 'transparent',

    '& + &': {
      borderTop: `1px solid ${theme.placeholderColor}`
    }
  },
  imgSize: {
    marginRight: '19px',
    height: '40px',
    width: '40px'
  },
  label: {
    marginLeft: '3px'
  },
  iconButton: {
    padding: '9px !important'
  },
  icon: {
    color: Colors.grayLight
  }
}));

const MultipleSelectList = ({
  className,
  options,
  selectedOption,
  onSelect: onSelectProp,
  userOptionType,
  selectMode,
  translations
}) => {
  const classes = useStyles();

  if (R.isNil(options) || R.isEmpty(options)) {
    return null;
  }

  return (
    <ul className={classNames(classes.list, className)}>
      {options.map(item => {
        const isChecked =
          !R.isNil(selectedOption) && R.indexOf(item.id, selectedOption) !== -1;
        const onSelect = () =>
          onSelectProp(userOptionType, item.id, !isChecked, item);

        return (
          <Fragment key={item.id}>
            <li
              className={classes.listItem}
              onClick={selectMode === SelectModes.full ? onSelect : null}
            >
              <Checkbox
                checked={isChecked}
                onClick={selectMode === SelectModes.checkbox ? onSelect : null}
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              <div
                className={classes.label}
                onClick={selectMode === SelectModes.checkbox ? onSelect : null}
              >
                {i18n(item.title, translations)}
              </div>
              <div style={{ flex: '1 1 auto' }} />
              {item.showIcon && (
                <IconButton
                  classes={{ root: classes.iconButton }}
                  onClick={e => {
                    e.stopPropagation();
                    item.onIconClick();
                  }}
                >
                  <Icon classes={{ root: classes.icon }}>{item.iconType}</Icon>
                </IconButton>
              )}
            </li>
          </Fragment>
        );
      })}
    </ul>
  );
};

MultipleSelectList.defaultProps = {
  selectMode: SelectModes.full
};

export default R.compose(
  connect(state => {
    const translations = sessionSelectors.getTranslationsConfig(state);

    return {
      translations
    };
  })
)(MultipleSelectList);
