import React from 'react';

import { Icons } from 'features/shared/constants/icons';
import { buildPublicFileUrl } from 'features/shared/utils/axios.js';

const Icon = ({
  type,
  imagePath,
  alt,
  className,
  ...rest
}: {
  type?: keyof typeof Icons;
  imagePath?: string;
  alt?: string;
  className?: string;
}) => {
  if (imagePath) {
    return (
      <img
        className={className}
        src={buildPublicFileUrl(imagePath)}
        alt={alt ? alt : 'icon'}
      />
    );
  }
  if (type) {
    const SVG = Icons[type];
    return <SVG {...rest} />;
  }
  return <img className={className} alt={alt ? alt : 'icon'} />;
};

export default Icon;
