let REACT_APP_USE_LEGACY_CONFIGURATION_ENDPOINT = false;
if (process.env.REACT_APP_USE_LEGACY_CONFIGURATION_ENDPOINT !== undefined) {
  REACT_APP_USE_LEGACY_CONFIGURATION_ENDPOINT =
    process.env.REACT_APP_USE_LEGACY_CONFIGURATION_ENDPOINT.trim().toLowerCase() ===
    'true';
}

let REACT_APP_SHOULD_THROW_SAFE_ERROR = false;
if (process.env.REACT_APP_SHOULD_THROW_SAFE_ERROR !== undefined) {
  REACT_APP_SHOULD_THROW_SAFE_ERROR =
    process.env.REACT_APP_SHOULD_THROW_SAFE_ERROR.trim().toLowerCase() === 'on';
}

const config = {
  PORTAL_ELEMENT_ID: 'portal-root',
  CONFIRMATION_ELEMENT_ID: 'confirmation-root',
  APP_ELEMENT_ID: 'root',
  ...process.env,
  REACT_APP_USE_LEGACY_CONFIGURATION_ENDPOINT,
  REACT_APP_SHOULD_THROW_SAFE_ERROR
};

export default config;
